import { createSelector } from 'reselect';
import { AppState } from '../store';
import { ProgressBarState } from '../reducer/progressBarStatus';

const selectProgressBarState = (state: AppState) => state.progressBarStatus;
const selectLoading = (state: ProgressBarState) => state.loading;

// eslint-disable-next-line import/prefer-default-export
export const selectProgressBarStatus = createSelector(
  selectProgressBarState,
  selectLoading,
);
