import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({

  headerText: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
  },

  inputForm: {
    borderTop: '1px solid rgba(142, 139, 153, 0.25)',
    padding: '20px 20% 50px 20%',
  },

  navButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabled: {
    filter: 'grayscale(1)',
    opacity: '0.5',
    pointerEvents: 'none',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
