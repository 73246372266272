import * as SnackbarsActions from '../actions/snackbars';
import { ValueOf } from '../../types/main';

export const CREATE_SNACKBAR = 'CREATE_SNACKBAR';
export const DELETE_SNACKBAR = 'DELETE_SNACKBAR';

export type SnackbarActionsTypes = ReturnType<ValueOf<typeof SnackbarsActions>>;

type SnackBarVariant = 'success' | 'error' | 'warning' | 'info';

export interface SnackbarType {
  id: string | number
  message: string
  variant: SnackBarVariant
}

export type SnackbarsState = SnackbarType[];
