import { SET_FILTER_DATA, CLEAR_FILTER_DATA, SetFilterPayload, SET_ENTIRE_FILTER_DATA, UrlFilterState } from '../types/filter';


export const setFilter = (payload: SetFilterPayload) => ({
  type: SET_FILTER_DATA,
  payload,

} as const);

export const clearFilter = () => ({
  type: CLEAR_FILTER_DATA,

} as const);

export const setEntireFilter = (payload: UrlFilterState) => ({
  type: SET_ENTIRE_FILTER_DATA,
  payload,

} as const);
