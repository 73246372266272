import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '@material-ui/core/CircularProgress';

import useStyles from './styles';
import { getBuilderName } from '../../redux/selectors/auth';
import CustomButton from '../CustomButton';
import { builderSettingsRequest } from '../../redux/actions/builders';
import { selectBuilderSettings } from '../../redux/selectors/builders';
import { beautifyErrors, ValidationErrors } from '../../utils/helpers';
import { BuilderSettings } from '../../redux/types/builders';
import { disclosuresSchema } from './disclosuresValidation';

type Props = {
  saveDisclosures: (disclosures: any) => void;
  builderId: number;
  isLoading: boolean;
};

const Disclosures: React.FC<Props> = (props) => {
  const { saveDisclosures, builderId, isLoading } = props;

  const [isCustomDisclosureActive, setIsCustomDisclosureActive] = useState(
    false,
  );
  const [customDisclosure, setCustomDisclosure] = useState({
    custom_disclosure_name: '',
    custom_disclosure_text: '',
  });
  const [validationErrors, setValidationErrors] = useState<
  ValidationErrors<BuilderSettings>
  >({});

  const classes = useStyles();
  const dispatch = useDispatch();
  const builderName = useSelector(getBuilderName);
  const builderSettings = useSelector(selectBuilderSettings);
  const companyName = builderSettings?.legal_name || builderSettings?.webflow_name || builderName;

  useEffect(() => {
    dispatch(builderSettingsRequest(builderId));
  }, [dispatch, builderId]);

  useEffect(() => {
    if (builderSettings?.custom_disclosure_active) {
      setIsCustomDisclosureActive(true);
      setCustomDisclosure({
        custom_disclosure_name: builderSettings?.custom_disclosure_name ?? '',
        custom_disclosure_text: builderSettings?.custom_disclosure_text ?? '',
      });
    }
  }, [builderSettings]);

  const handleSelectDisclosure = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setIsCustomDisclosureActive(checked);
    if (!checked) {
      setCustomDisclosure({
        custom_disclosure_name: '',
        custom_disclosure_text: '',
      });
    }
  };

  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setCustomDisclosure((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const validSettings = disclosuresSchema.validateSync(
        {
          custom_disclosure_active: isCustomDisclosureActive,
          ...customDisclosure,
        },
        {
          abortEarly: false,
        },
      ) as BuilderSettings;

      const {
        custom_disclosure_name: customDisclosureName,
        custom_disclosure_text: customDisclosureText,
      } = validSettings;

      const formData = new FormData();

      formData.append(
        'custom_disclosure_active',
        String(isCustomDisclosureActive),
      );
      formData.append('custom_disclosure_name', customDisclosureName ?? '');
      formData.append('custom_disclosure_text', customDisclosureText ?? '');

      saveDisclosures(formData);
      setValidationErrors({});
    } catch (errors) {
      setValidationErrors(beautifyErrors(errors));
    }
  };

  return isLoading ? (
    <Grid container justify="center">
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
    </Grid>
  ) : (
    <>
      <Divider />
      <div className={classes.dropZoneContainer}>
        <div className={classes.headerText}>
          <span>Disclosures</span>
        </div>
        <Grid container alignItems="center">
          <Typography className={classes.subtitle}>
            NterNow default disclosure (not editable)
          </Typography>
        </Grid>
        <div className={`${classes.disclosureContainer}`}>
          <Typography className={`${classes.disclaimer} ${classes.disabled}`}>
            {`Customer agrees that any entry onto the property is at the Customer’s own risk. Customer hereby waives any and all claims against ${companyName} Inc., its affiliates, subsidiaries, owners, officers, directors, managers, employees, representatives or agents (collectively, “${companyName}”) for injury or damage to person or property arising from, or related to, Customer’s entry onto the property. Customer will defend, indemnify and hold ${companyName} harmless against any injury, loss, damage or expense to person or property arising from, or related to, the acts or omissions of Customer, or of any person accompanying Customer onto the property. Customer will act in accordance with all applicable laws while on the property. Customer will not provide any false information or identification. Customer will be responsible for all damage to furniture, fixtures and equipment caused, directly or indirectly, by Customer.`}
          </Typography>
        </div>
        <Grid container alignItems="center">
          <Checkbox
            id="customDisclosure"
            style={{ marginRight: '5px' }}
            onChange={handleSelectDisclosure}
            checked={isCustomDisclosureActive}
          />
          <Typography className={classes.subtitle}>
            Custom disclosure
          </Typography>
        </Grid>
        <div className={`${!isCustomDisclosureActive ? classes.disabled : ''}`}>
          <TextField
            label="Disclosure Name"
            name="custom_disclosure_name"
            style={{ marginTop: '14px' }}
            value={customDisclosure.custom_disclosure_name}
            onChange={handleChange}
            error={Boolean(validationErrors.custom_disclosure_name)}
            helperText={
              validationErrors.custom_disclosure_name && 'Required field!'
            }
          />
          <TextField
            style={{ margin: '24px 0' }}
            fullWidth
            multiline
            rows={10}
            inputProps={{
              maxLength: 65000,
            }}
            variant="outlined"
            name="custom_disclosure_text"
            value={customDisclosure.custom_disclosure_text}
            onChange={handleChange}
            error={Boolean(validationErrors.custom_disclosure_text)}
            helperText={
              validationErrors.custom_disclosure_text && 'Required field!'
            }
          />
        </div>
      </div>
      <Divider style={{ marginBottom: '20px' }} />
      <div className={classes.navButtons}>
        <CustomButton variant="orange" onClick={() => handleSave()}>
          Submit
        </CustomButton>
      </div>
    </>
  );
};

export default React.memo(Disclosures);
