import { ErrorMessage } from '../../types/main';
import {
  SurveyQuestion,
  SURVEY_QUESTIONS,
  SURVEY_QUESTIONS_FAILED,
  SURVEY_QUESTIONS_SUCCESS,
  SURVEY_QUESTIONS_UPDATE,
  SURVEY_QUESTIONS_UPDATE_FAILED,
  SURVEY_QUESTIONS_UPDATE_SUCCESS,
} from '../types/survey';

export const surveyQuestionsRequest = () => ({
  type: SURVEY_QUESTIONS,
} as const);

export const surveyQuestionsRequestFailed = (payload: ErrorMessage) => ({
  type: SURVEY_QUESTIONS_FAILED,
  payload,
} as const);

export const surveyQuestionsRequestSuccess = (payload: SurveyQuestion[]) => ({
  type: SURVEY_QUESTIONS_SUCCESS,
  payload,
} as const);

export const updateSurveyQuestions = (questions: SurveyQuestion[]) => ({
  type: SURVEY_QUESTIONS_UPDATE,
  payload: questions,
} as const);

export const updateSurveyQuestionsFailed = (payload: ErrorMessage) => ({
  type: SURVEY_QUESTIONS_UPDATE_FAILED,
  payload,
} as const);

export const updateSurveyQuestionsSuccess = (payload: SurveyQuestion[]) => ({
  type: SURVEY_QUESTIONS_UPDATE_SUCCESS,
  payload,
} as const);
