import { connect } from 'react-redux';
import Webhooks from './Webhooks';
import { AppState } from '../../redux/store';
import { deleteWebhooksRequest, webhooksRequest } from '../../redux/actions/webhooks';
import { selectTotalWebhooks, selectWebhooks, selectWebhooksRequestStatus } from '../../redux/selectors/webhooks';

const mapStateToProps = (state: AppState) => ({
  webhooks: selectWebhooks(state),
  webhooksTotal: selectTotalWebhooks(state),
  loading: selectWebhooksRequestStatus(state),
});

const mapDispatchToProps = {
  loadWebhooks: webhooksRequest,
  deleteWebhooks: deleteWebhooksRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Webhooks);
