import React from 'react';
import { WithStyles } from '@material-ui/styles';
import clsx from 'clsx';
import styles from './styles';

type Props = {
  title: string;
  recordName?: string;
  icon: React.ReactElement;
} & WithStyles<typeof styles>;

const HubFormTitle: React.FC<Props> = (props: Props) => {
  const {
    classes, title, recordName, icon,
  } = props;

  return (
    <div>
      <div className={classes.titleContainer}>
        <div>
          {React.cloneElement(icon, { className: classes.titleIcon })}
        </div>
        <div className={clsx({ [classes.textContainer]: !recordName })}>
          <div className={classes.title}>{title}</div>
          {recordName && (
            <div className={clsx(classes.title, classes.titleRecordName)}>{recordName}</div>
          )}
        </div>
      </div>
      <div className={classes.lineBreak} />
    </div>
  );
};

export default HubFormTitle;
