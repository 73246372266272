export const propertyStatuses = [
  {
    name: 'Property Disposition',
    value: 'Archived',
    catalog_id: 'PROPERTY_ARCHIVED',
    catalog_type_id: 1,
  },
  {
    name: 'Property Disposition',
    value: 'Accessible',
    catalog_id: 'PROPERTY_AVAILABLE',
    catalog_type_id: 1,
  },
  {
    name: 'Property Disposition',
    value: 'Under Contract',
    catalog_id: 'PROPERTY_UNDER_CONTRACT',
    catalog_type_id: 1,
  },
  {
    name: 'Property Disposition',
    value: 'Sold',
    catalog_id: 'PROPERTY_SOLD',
    catalog_type_id: 1,
  },
  {
    name: 'Property Disposition',
    value: 'Rented',
    catalog_id: 'PROPERTY_RENTED',
    catalog_type_id: 1,
  },
  // {
  //   name: 'Property Disposition',
  //   value: 'Removed',
  //   catalog_id: 'PROPERTY_REMOVED',
  //   catalog_type_id: 1,
  // },
  {
    name: 'Property Disposition',
    value: 'Created',
    catalog_id: 'PROPERTY_CREATED',
    catalog_type_id: 1,
  },
  // {
  //   name: 'Property Disposition',
  //   value: 'Awaiting Activation',
  //   catalog_id: 'PROPERTY_AWAITING_ACTIVATION',
  //   catalog_type_id: 1,
  // },
  // {
  //   name: 'Property Disposition',
  //   value: 'Activated',
  //   catalog_id: 'PROPERTY_ACTIVATED',
  //   catalog_type_id: 1,
  // },
  {
    name: 'Property Disposition',
    value: 'Non-Accessible',
    catalog_id: 'PROPERTY_NOT_AVAILABLE',
    catalog_type_id: 1,
  },
  {
    name: 'Property Disposition',
    value: 'Smart Lock Maintenance',
    catalog_id: 'PROPERTY_SMART_LOCK_MAINTENANCE',
    catalog_type_id: 1,
  },
  {
    name: 'Property Disposition',
    value: 'Staff Access',
    catalog_id: 'PROPERTY_STAFF_ACCESS',
    catalog_type_id: 1,
  },
];

export const INTEGRATION_STUB_PROJECT_ID = 'This integration does have any property level fields to configure';
