import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  form: {
    width: '100%',
    height: '100%',
    maxWidth: 480,
    padding: '16px 24px',
  },
  content: {
    height: '100%',
  },
});
