import {
  COMMUNITIES_REQUEST,
  COMMUNITIES_REQUEST_SUCCESS,
  COMMUNITIES_REQUEST_FAILED,
  COMMUNITIES_DETAILS_REQUEST,
  COMMUNITIES_DETAILS_SUCCESS,
  QR_CODES_REQUEST,
  QR_CODES_REQUEST_SUCCESS,
  COMMUNITIES_DETAILS_FAILED,
  COMMUNITIES_EXTERNAL_IDS_REQUEST,
  BUILDER_COMMUNITIES_SUCCESS,
  COMMUNITIES_EXPORT_REQUEST,
  COMMUNITIES_DELETE_REQUEST_SUCCESS,
  COMMUNITIES_DELETED_RESET,
  SAVE_QR_CODE_REQUEST,
  SAVE_COMMUNITY,
  SAVE_COMMUNITY_SUCCESS,
  CANCEL_SUCCESSFULLY_SAVE,
  BUILDER_COMMUNITIES,
  COMMUNITIES_DELETE_REQUEST,
  QR_CODE_DELETE_REQUEST_SUCCESS,
  QR_CODE_DELETE_REQUEST,
  CommunitiesRequestSuccess,
  Community,
  BuilderCommunities,
  SelectedCommunity,
  QRCode,
  COMMUNITIES_STATUSES,
  CommunityStatus,
  COMMUNITIES_STATUSES_SUCCESS,
  LOCK_COMMUNITIES_REQUEST,
  LOCK_COMMUNITIES_SUCCESS,
  LOCK_COMMUNITIES_FAILED,
  SMART_LOCK_COMMUNITIES_REQUEST,
  SMART_LOCK_COMMUNITIES_SUCCESS,
  SMART_LOCK_COMMUNITIES_FAILED,
} from '../types/communities';

import { Query } from '../../utils/query';
import { MimeType } from '../types/mime';
import { ErrorMessage } from '../../types/main';

export const getCommunities = (query?: Query & { builderId: number }) => ({
  type: COMMUNITIES_REQUEST,
  payload: query,
} as const);

export const getCommunitiesSuccess = (payload: CommunitiesRequestSuccess) => ({
  type: COMMUNITIES_REQUEST_SUCCESS,
  payload,
} as const);

export const getCommunitiesFailed = (payload: ErrorMessage) => ({
  type: COMMUNITIES_REQUEST_FAILED,
  payload,
} as const);

export const getCommunityDetails = (communityId: number) => ({
  type: COMMUNITIES_DETAILS_REQUEST,
  payload: { communityId },
} as const);

export const getQRCodes = (communityId: number) => ({
  type: QR_CODES_REQUEST,
  payload: { communityId },
} as const);

export const saveQRCodeData = (communityId: number, QRCodeText: string, QRCodeName: string) => ({
  type: SAVE_QR_CODE_REQUEST,
  payload: { communityId, QRCodeText, QRCodeName },
} as const);

export const getCommunityDetailsSuccess = (payload: SelectedCommunity) => ({
  type: COMMUNITIES_DETAILS_SUCCESS,
  payload,
} as const);

export const getQRCodesSuccess = (payload: QRCode[]) => ({
  type: QR_CODES_REQUEST_SUCCESS,
  payload,
} as const);

export const getCommunityDetailsFailed = (payload: Community) => ({
  type: COMMUNITIES_DETAILS_FAILED,
  payload,
} as const);

export const loadCommunityExternalId = () => ({
  type: COMMUNITIES_EXTERNAL_IDS_REQUEST,
} as const);

export const saveCommunity = (payload: Community) => ({
  type: SAVE_COMMUNITY,
  payload,
} as const);

export const saveCommunitySuccess = (payload: boolean) => ({
  type: SAVE_COMMUNITY_SUCCESS,
  payload,
} as const);

export const cancelSuccessfullySave = () => ({
  type: CANCEL_SUCCESSFULLY_SAVE,
} as const);

export const getBuilderCommunities = (payload: number) => ({
  type: BUILDER_COMMUNITIES,
  payload,
} as const);

export const getCommunitiesStatuses = () => ({
  type: COMMUNITIES_STATUSES,
} as const);

export const getCommunitiesStatusesSuccess = (payload: CommunityStatus[]) => ({
  type: COMMUNITIES_STATUSES_SUCCESS,
  payload,
} as const);

export const getBuilderCommunitiesSuccess = (payload: BuilderCommunities[]) => ({
  type: BUILDER_COMMUNITIES_SUCCESS,
  payload,
} as const);

export const communitiesExportRequest = (selection: Array<number | string>, mimeType: MimeType = 'text/csv') => ({
  type: COMMUNITIES_EXPORT_REQUEST,
  payload: {
    selection,
    mimeType,
  },
} as const);

export const communitiesDeleteRequest = (payload: Array<number | string>) => ({
  type: COMMUNITIES_DELETE_REQUEST,
  payload,
} as const);

export const QRCodeDeleteRequest = (id: number, communityId: number) => ({
  type: QR_CODE_DELETE_REQUEST,
  payload: { id, communityId },
} as const);

export const communitiesDeleteRequestSuccess = (payload: Array<number | string>) => ({
  type: COMMUNITIES_DELETE_REQUEST_SUCCESS,
  payload,
} as const);


export const QRCodeDeleteRequestSuccess = (payload: QRCode[]) => ({
  type: QR_CODE_DELETE_REQUEST_SUCCESS,
  payload,
} as const);

export const communitiesDeletedReset = () => ({
  type: COMMUNITIES_DELETED_RESET,
} as const);

export const getLockCommunitiesRequest = () => ({
  type: LOCK_COMMUNITIES_REQUEST,
} as const);

export const getLockCommunitiesSuccess = (payload: Array<string>) => ({
  type: LOCK_COMMUNITIES_SUCCESS,
  payload,
} as const);

export const getLockCommunitiesFailed = (payload: ErrorMessage) => ({
  type: LOCK_COMMUNITIES_FAILED,
  payload,
} as const);

export const getSmartLockCommunitiesRequest = () => ({
  type: SMART_LOCK_COMMUNITIES_REQUEST,
} as const);

export const getSmartLockCommunitiesSuccess = (payload: Array<string>) => ({
  type: SMART_LOCK_COMMUNITIES_SUCCESS,
  payload,
} as const);

export const getSmartLockCommunitiesFailed = (payload: ErrorMessage) => ({
  type: SMART_LOCK_COMMUNITIES_FAILED,
  payload,
} as const);
