import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  deleteIcon: {
    marginRight: 8,
    cursor: 'pointer',
  },
  title: {
    color: '#8E8B99',
    fontSize: 28,
  },
  container: {
    maxHeight: 584,
    overflowX: 'hidden',
    overflowY: 'auto',
    marginBottom: 20
  },
});
