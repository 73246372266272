import React from 'react';
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import useStyles from '../styles';
import newIcon from '../../../assets/img/new.png';
import betaIcon from '../../../assets/beta.svg';

type Props = {
  items: any;
  icon: React.ElementType;
  title: string;
};

const SubMenuItem: React.FC<Props> = ({ icon: Icon, items, title }) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(
    () => items.some((item: any) => item.path === location.pathname) ?? false,
  );

  const classes = useStyles();
  return (
    <>
      <ListItem
        button
        className={classes.menuItem}
        onClick={() => {
          setIsOpen((prevState: any) => !prevState);
        }}
      >
        <ListItemIcon>
          <Icon className={isOpen ? classes.selectedSvgLink : 'none'} />
        </ListItemIcon>
        <ListItemText
          primary={title}
          classes={{
            primary: classNames(
              classes.menuItemText,
              isOpen ? classes.selectedLink : '',
            ),
          }}
        />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {items.map(
            ({
              path,
              label,
              id,
              newFeature,
              beta,
            }: {
              path: string;
              label: string;
              id: string;
              newFeature: boolean;
              beta: boolean;
            }) => (
              // todo: remove "disabled" after "Custom Survey Questions" completed
              <ListItem id={id} disabled={label === 'Survey (Coming soon)'} button key={path} component={Link} to={path}>
                <ListItemText
                  primary={label}
                  className={`${classes.sublink} ${location.pathname === path
                    && classes.selectedLink}`}
                />
                {newFeature && (
                  <img className={classes.newIcon} src={newIcon} alt="New" />
                )}
                {beta && (
                  <img className={classes.betaIcon} src={betaIcon} alt="Beta" />
                )}
              </ListItem>
            ),
          )}
        </List>
      </Collapse>
    </>
  );
};
export default SubMenuItem;
