import { createStyles, makeStyles } from '@material-ui/core';

const styles = () => createStyles({
  headerText: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '40px',
    marginBottom: '20px',

  },

  navButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  inputForm: {
    padding: '20px 20% 50px 20%',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
