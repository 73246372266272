/* eslint-disable @typescript-eslint/camelcase */
import { createSelector } from 'reselect';
import { AppState } from '../store';

const selectStatsState = (state: AppState) => state.stats;

export const selectContractors = createSelector(
  selectStatsState,
  (state) => state.contractors,
);

export const selectPropertiesStatsState = createSelector(
  selectStatsState,
  (state) => state.properties,
);

export const selectWeeklyVisitsState = createSelector(
  selectStatsState,
  (state) => state.weeklyVisits,
);

const selectDayLabelsState = createSelector(
  selectWeeklyVisitsState,
  (state) => state.items.map((item) => item.name_of_day),
);

const selectWeeklyVisitCount = createSelector(
  selectWeeklyVisitsState,
  (state) => state.items.map((item) => item.visit_count),
);

export const selectWeeklyChartData = createSelector(
  selectDayLabelsState,
  selectWeeklyVisitCount,
  (labels, data) => ({ labels, data }),
);

export const selectMonthlyVisitsState = createSelector(
  selectStatsState,
  (state) => state.monthlyVisits,
);

export const selectYearlyVisitsState = createSelector(
  selectStatsState,
  (state) => state.yearlyVisits,
);

const selectMonthLabelsState = createSelector(
  selectYearlyVisitsState,
  (state) => state.items.map((item) => item.name_of_month),
);

const selectYearlyVisitCount = createSelector(
  selectYearlyVisitsState,
  (state) => state.items.map((item) => item.visit_count),
);

export const selectYearlyChartData = createSelector(
  selectMonthLabelsState,
  selectYearlyVisitCount,
  (labels, data) => ({ labels, data }),
);

export const selectAllVisitsState = createSelector(
  selectStatsState,
  (state) => state.allVisits,
);

export const selectUniqueVisitorsID = createSelector(
  selectAllVisitsState,
  ({ items }) => {
    const ids = items.map(({ customer_id }) => customer_id);

    return [...new Set(ids)];
  },
);
