import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  wrapper: {
    borderRadius: 10,
    backgroundColor: '#fff',
    boxShadow: theme.shadows[3],
  },
  header: {
    paddingTop: 29,
    paddingLeft: 40,
    paddingRight: 40,
  },
  content: {
    padding: '29px 40px',
    display: 'flex',
  },
  title: {
    fontSize: 28,
  },
  customers: {
    marginRight: 8,
    '& > p': {
      fontSize: 18,
      color: theme.appPalette.orange,
      margin: 0,
    },
  },
  profitBars: {
    flexGrow: 1,
    '& > div': {
      marginTop: 2,
    },
  },
  profitAmounts: {
    paddingLeft: 8,
    '& > p': {
      fontSize: 18,
      color: '#040620',
      margin: 0,
    },
  },
});
