import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useUrlLocation from './useUrlLocation';
import {
  customerLastStatuses, customerStatuses, inventoryLockStatuses,
  propertyLockDispositions, propertyLockStatuses, visitorTypes, visitsLastStatuses,
} from '../components/SearchFilterElement/constants';
// import { getLockCommunitiesRequest } from '../redux/actions/communities';
import { getLockCommunitiesState } from '../redux/selectors/communities';
import { getEnterpriseId } from '../redux/selectors/auth';
import { enterpriseBuildersRequest } from '../redux/actions/builders';
import { selectBuilders } from '../redux/selectors/builders';

export interface FilterElement {
  [key: string]: string[]
}

const useFilterStateProvider = () => {
  const path = useUrlLocation();
  const [data, setData] = useState<FilterElement>({});
  const dispatch = useDispatch();
  const lockCommunity = useSelector(getLockCommunitiesState);
  const enterpriseId = useSelector(getEnterpriseId);
  const enterpriseBuilders = useSelector(selectBuilders);

  useEffect(
    () => {
      switch (path) {
        case 'customers': {
          setData({
            customerStatus: [...customerStatuses],
            visitorType: [...visitorTypes],
            lastStatus: [...customerLastStatuses],
          });
          break;
        }
        case 'properties': {
          if (enterpriseId && !enterpriseBuilders.length) {
            dispatch(enterpriseBuildersRequest(enterpriseId));
          }


          if (enterpriseId) {
            const builderNames = enterpriseBuilders.map((builder) => builder.name);

            setData({
              lockStatus: [...propertyLockStatuses],
              lockDisposition: [...propertyLockDispositions],
              builder: [...builderNames],
            });
            break;
          }

          setData({
            lockStatus: [...propertyLockStatuses],
            lockDisposition: [...propertyLockDispositions],
          });
          break;
        }
        case 'communities':
        case 'agents': {
          if (enterpriseId && !enterpriseBuilders.length) {
            dispatch(enterpriseBuildersRequest(enterpriseId));
          }


          if (enterpriseId) {
            const builderNames = enterpriseBuilders.map((builder) => builder.name);

            setData({
              builder: [...builderNames],
            });
            break;
          }
          break;
        }
        case 'visits': {
          setData({
            visitorType: [...visitorTypes],
            lastStatus: [...visitsLastStatuses],
          });
          break;
        }
        case 'inventory': {
          // if (!lockCommunity.length) {
          // dispatch(getLockCommunitiesRequest());
          // }

          setData({
            lockStatus: [...inventoryLockStatuses],
            ...lockCommunity.length ? { community: lockCommunity } : {},
          });

          break;
        }
        default: setData({});
      }
    }, [path, lockCommunity, dispatch, enterpriseId, enterpriseBuilders],
  );

  return data;
};

export default useFilterStateProvider;
