import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Staff from './Staff';
import styles from './styles';


import { AppState } from '../../redux/store';
import { selectAvailableAgents } from '../../redux/selectors/agents';
import { getBuilderName, getUsername } from '../../redux/selectors/auth';
import { teamsRequest } from '../../redux/actions/teams';

const mapStateToProps = (state: AppState) => ({
  builder: getBuilderName(state),
  username: getUsername(state),
  availableAgents: selectAvailableAgents(state),
});

const mapDispatchToProps = {
  loadTeams: teamsRequest,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Staff),
);
