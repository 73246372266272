import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import HubLockForm from './HubLockForm';
import styles from './styles';
import {
  createHubLockMasterCodeRequest,
  deleteHubLockCodeRequest,
  updateHubLockAutoLockRequest,
  updateHubLockCodesRequest,
  updateHubLockMasterCodeRequest,
  updateHubLockStatusRequest,
} from '../../redux/actions/hubs/hubLocks';
import { AppState } from '../../redux/store';
import { selectHubRefreshLoading, selectHubUpdating } from '../../redux/selectors/hubs';

const styledComponent = withStyles(styles)(HubLockForm);

const mapStateToProps = (state: AppState) => ({
  updating: selectHubUpdating(state),
  refreshLoading: selectHubRefreshLoading(state),
});

const mapDispatchToProps = {
  updateHubLockStatus: updateHubLockStatusRequest,
  updateHubLockAutoLock: updateHubLockAutoLockRequest,
  createHubLockMasterCode: createHubLockMasterCodeRequest,
  updateHubLockMasterCode: updateHubLockMasterCodeRequest,
  updateHubLockCodes: updateHubLockCodesRequest,
  deleteHubLockCode: deleteHubLockCodeRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(styledComponent);
