import axios from './axios';
import { Query, buildQuery } from '../utils/query';
import { DeleteSmartLockCode, SmartLockCode } from '../redux/types/smartLocks';

export const loadSmartLocks = <T>(queryObject?: Query) => {
  const query = buildQuery(queryObject);

  console.log('should search here');
  return axios.get<T>(`/smart-locks${query}`);
};

export const loadSmartLocksCommunity = () => axios.get<string[]>('/smart-locks/community');

export const loadSmartLockDetails = <T>(smartLockSerial: number | string) => axios.get<T>(`/smart-locks/${smartLockSerial}`);

export const updateSmartLockCode = (smartLock: SmartLockCode) => axios.post(`/smart-locks/${smartLock.device_id}/code`, smartLock);

export const deleteSmartLockCode = (smartLock: DeleteSmartLockCode) => axios.delete('/smart-locks/code', { data: smartLock });
