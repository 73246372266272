import { createSelector } from 'reselect';
import { AppState } from '../store';
import { ScheduledVisitsState } from '../types/scheduledVisit';

export const selectVisitsState = (state: AppState) => state.scheduledVisits;
const selectItems = (state: ScheduledVisitsState) => state.items;
const selectLoading = (state: ScheduledVisitsState) => state.loading;
const selectTotal = (state: ScheduledVisitsState) => state.total;
const selectSelected = (state: ScheduledVisitsState) => state.selected;

export const selectScheduledVisits = createSelector(
  selectVisitsState,
  selectItems,
);

export const selectTotalScheduledVisits = createSelector(
  selectVisitsState,
  selectTotal,
);

export const selectScheduledVisitsRequestStatus = createSelector(
  selectVisitsState,
  selectLoading,
);

export const getSelected = createSelector(
  selectVisitsState,
  selectSelected
)