import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { visitsRequest, visitsExportRequest, visitsExportAndPrintRequest } from '../../redux/actions/visits';
import { exportAllRequest } from '../../redux/actions/exportAll';
import { selectTotalVisits, selectVisits, selectVisitsRequestStatus } from '../../redux/selectors/visits';
import Visits from './Visits';
import styles from './styles';
import { AppState } from '../../redux/store';
import { selectExportAllLoadingState } from '../../redux/selectors/exportAll';
import { clearFilter } from '../../redux/actions/filter';
import { builderTimezoneRequest } from '../../redux/actions/builders';
import { selectBuilderTimezone } from '../../redux/selectors/builders';

const mapStateToProps = (state: AppState) => ({
  visits: selectVisits(state),
  visitsTotal: selectTotalVisits(state),
  loading: selectVisitsRequestStatus(state),
  exportAllLoading: selectExportAllLoadingState(state),
  builderTimezone: selectBuilderTimezone(state),
});
const mapDispatchToProps = {
  loadVisits: visitsRequest,
  exportVisits: visitsExportRequest,
  printVisits: visitsExportAndPrintRequest,
  exportAllVisits: exportAllRequest,
  clearFilterState: clearFilter,
  getBuilderTimezone: builderTimezoneRequest,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Visits));
