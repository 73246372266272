import React from 'react';
import classNames from 'classnames';
import { WithStyles } from '@material-ui/core';
import styles from './styles';

type Props = {
  stats?: boolean,
}& WithStyles<typeof styles>;

const CardFooter: React.FC<Props> = props => {
  const {
    classes,
    children,
    stats,
    ...rest
  } = props;
  const cardFooterClasses = classNames({
    [classes.cardFooter]: true,
    [classes.cardFooterStats]: stats,
  });

  return (
    <div className={cardFooterClasses} {...rest}>
      {children}
    </div>
  );
};

export default React.memo(CardFooter);
