import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  title: {
    fontWeight: 600,
    color: '#DB5C0E',
  },
  primaryTitle: {
    fontSize: 20,
  },
  secondaryTitle: {
    fontSize: 16,
    minWidth: '130px',
    marginRight: '50px',
  },
  statusTitle: {
    marginTop: 16,
    fontSize: 16,
    color: '#8E8B99',
  },
  progress: {
    color: theme.appPalette.orange,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: 20,
    marginLeft: -12,
  },
  progressWrapper: {
    position: 'relative',
    height: '100%',
  },
  switchText: {
    padding: '4px 10px',
    backgroundColor: '#04A777',
    borderRadius: '20px',
    color: '#fff',
  },
  disabled: {
    filter: 'grayscale(1)',
    opacity: '0.5',
    pointerEvents: 'none',
  },
  select: {
    minWidth: '120px',
    height: '40px',
    '& .MuiSelect-root': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      padding: '0 0 0 10px',
      margin: 0,
    },
  },
  btnStyle: {
    marginTop: '20px',
  },
  rebootButton: {
    marginTop: '40px'
  }
});
