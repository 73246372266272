import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  DELETE_SMART_LOCK_CODE_REQUEST,
  REFRESH_SMART_LOCK_DETAILS_REQUEST,
  SMART_LOCKS_REQUEST,
  SMART_LOCK_DETAILS_REQUEST,
  UPDATE_SMART_LOCK_CODE_REQUEST,
} from '../types/smartLocks';
import {
  loadSmartLocks,
  loadSmartLockDetails,
  loadSmartLocksCommunity,
  updateSmartLockCode,
  deleteSmartLockCode,
} from '../../services/smartLocks';
import {
  smartLocksRequestSuccess,
  smartLocksRequestFailed,
  smartLockDetailsRequestFailed,
  smartLockDetailsRequestSuccess,
  smartLockDetailsRequest,
  smartLocksRequest,
  updateSmartLockCodeRequest,
  updateSmartLockCodeRequestSuccess,
  updateSmartLockCodeRequestFailed,
  deleteSmartLockCodeRequest,
  deleteSmartLockCodeRequestSuccess,
  deleteSmartLockCodeRequestFailed,
} from '../actions/smartLocks';
import { SMART_LOCK_COMMUNITIES_REQUEST } from '../types/communities';
import {
  getSmartLockCommunitiesFailed,
  getSmartLockCommunitiesSuccess,
} from '../actions/communities';
import {
  createErrorSnackBar,
  createSuccessSnackBar,
} from '../actions/snackbars';

function* loadSmartLocksSaga(action: ReturnType<typeof smartLocksRequest>) {
  try {
    const { data } = yield call(loadSmartLocks, action.payload);

    yield put(smartLocksRequestSuccess(data));
  } catch (err) {
    yield put(smartLocksRequestFailed(err));
  }
}

function* loadSmartLockDetailsSaga(
  action: ReturnType<typeof smartLockDetailsRequest>,
) {
  try {
    const { data } = yield call(loadSmartLockDetails, action.payload);
    yield put(smartLockDetailsRequestSuccess(data));
  } catch (err) {
    yield put(smartLockDetailsRequestFailed(err));
  }
}

function* loadSmartLockCommunity() {
  try {
    const { data } = yield call(loadSmartLocksCommunity);

    yield put(getSmartLockCommunitiesSuccess(data));
  } catch (err) {
    yield put(getSmartLockCommunitiesFailed(err.message));
  }
}

function* updateSmartLockCodeSaga({
  payload,
}: ReturnType<typeof updateSmartLockCodeRequest>) {
  try {
    const { data } = yield call(updateSmartLockCode, payload);
    yield put(updateSmartLockCodeRequestSuccess(data));
    yield put(createSuccessSnackBar('Success!'));
  } catch (err) {
    yield put(updateSmartLockCodeRequestFailed(err));
    yield put(createErrorSnackBar(err.response.data.message));
  }
}

function* deleteSmartLockCodeSaga({
  payload,
}: ReturnType<typeof deleteSmartLockCodeRequest>) {
  try {
    const { data } = yield call(deleteSmartLockCode, payload);
    yield put(deleteSmartLockCodeRequestSuccess(data));
    yield put(createSuccessSnackBar('Success!'));
  } catch (err) {
    yield put(deleteSmartLockCodeRequestFailed(err));
    yield put(createErrorSnackBar(err.response.data.message));
  }
}

export default all([
  takeLatest<any>(SMART_LOCKS_REQUEST, loadSmartLocksSaga),
  takeLatest<any>(
    [REFRESH_SMART_LOCK_DETAILS_REQUEST, SMART_LOCK_DETAILS_REQUEST],
    loadSmartLockDetailsSaga,
  ),
  takeLatest<any>(SMART_LOCK_COMMUNITIES_REQUEST, loadSmartLockCommunity),
  takeLatest<any>(UPDATE_SMART_LOCK_CODE_REQUEST, updateSmartLockCodeSaga),
  takeLatest<any>(DELETE_SMART_LOCK_CODE_REQUEST, deleteSmartLockCodeSaga),
]);
