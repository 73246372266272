import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Spinner from '@material-ui/core/CircularProgress';
import {
  Divider, MenuItem, Select, Switch,
} from '@material-ui/core';
import styles from './styles';
import PropertyHubLockDeviceList from '../PropertyHubLockDeviceList';
import { ActionPayload } from '../../types/main';
import { getHubDevicesRequest } from '../../redux/actions/hubs/hubs';
import CustomButton from '../CustomButton';
import {
  PropertyAccessSettings,
  PropertyDetailsInfo,
} from '../../redux/types/properties';

type Props = {
  hubHasDevices: boolean;
  loadHubDevices: (hubId: ActionPayload<typeof getHubDevicesRequest>) => void;
  handleAccessSettings: (settings: PropertyAccessSettings) => void;
  handleSave: () => void;
  loading: boolean;
  hubId: string;
  hubType: string;
  property: PropertyDetailsInfo;
  rebootHub: (hubId: string) => void;
} & WithStyles<typeof styles>;

const relockIntervalOptions = ['15 min', '20 min', '25 min', '30 min'];
const lockupOptions = ['8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM'];

const HubPanel: React.FC<Props> = (props: Props) => {
  const {
    loadHubDevices,
    classes,
    loading,
    hubHasDevices,
    hubId,
    hubType,
    handleAccessSettings,
    handleSave,
    property,
    rebootHub,
  } = props;

  const isADCHub = hubType === 'ADC';

  const {
    access_settings: accessSettings,
    end_of_day_lockup: endOfDayLockup,
    relock_interval: interval,
  } = property;

  const [checked, setChecked] = useState(Boolean(accessSettings) ?? false);
  const [relockInterval, setRelockInterval] = useState<string | null>(
    interval ?? null,
  );
  const [lockup, setLockup] = useState<string | null>(endOfDayLockup ?? null);

  useEffect(() => {
    loadHubDevices(hubId);
  }, [loadHubDevices, hubId]);

  useEffect(() => {
    const settings = {
      propertyId: property.property_id,
      accessSettings: checked,
      relockInterval,
      endOfDayLockup: lockup,
    };

    handleAccessSettings(settings);
  }, [
    checked,
    lockup,
    relockInterval,
    handleAccessSettings,
    property.property_id,
  ]);

  useEffect(() => {
    if (!checked) {
      setRelockInterval(null);
      setLockup(null);

      return;
    }

    setRelockInterval(interval ?? '15 min');
    setLockup(endOfDayLockup ?? '10:00 PM');
  }, [checked, endOfDayLockup, interval]);

  return (
    <Grid item xs={12} sm={12}>
      {loading && (
        <div className={classes.progressWrapper}>
          <Spinner className={classes.progress} />
        </div>
      )}
      {!loading && (
        <>
          {hubHasDevices && (
            <>
              <Grid container alignItems="center" spacing={4}>
                <Grid item>
                  <Switch
                    checked={checked}
                    onChange={() => {
                      setChecked(!checked);
                    }}
                  />
                </Grid>

                <Grid item>
                  <h2 className={`${classes.title} ${classes.primaryTitle}`}>
                    Staffed Model Access Settings
                  </h2>
                </Grid>
                <Grid item>
                  <p
                    className={`${classes.switchText} ${!checked
                      && classes.disabled}`}
                  >
                    {checked ? 'Enabled' : 'Disabled'}
                  </p>
                </Grid>
              </Grid>

              <Divider style={{ marginBottom: '20px' }} />

              <div className={`${!checked && classes.disabled}`}>
                <Grid container alignItems="center">
                  <Grid item>
                    <p className={`${classes.title} ${classes.secondaryTitle}`}>
                      Relock Interval
                    </p>
                  </Grid>
                  <Grid item>
                    <Select
                      disabled={!checked}
                      className={classes.select}
                      variant="outlined"
                      value={relockInterval}
                      onChange={(
                        event: React.ChangeEvent<{ value: unknown }>,
                      ) => {
                        const { value } = event.target;

                        setRelockInterval(value as string);
                      }}
                    >
                      {relockIntervalOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item>
                    <p className={`${classes.title} ${classes.secondaryTitle}`}>
                      End Of Day Lockup
                    </p>
                  </Grid>
                  <Grid item>
                    <Select
                      disabled={!checked}
                      className={classes.select}
                      variant="outlined"
                      value={lockup}
                      onChange={(
                        event: React.ChangeEvent<{ value: unknown }>,
                      ) => {
                        const { value } = event.target;

                        setLockup(value as string);
                      }}
                    >
                      {lockupOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </div>
              <CustomButton
                onClick={handleSave}
                variant="orange"
                className={classes.btnStyle}
              >
                Save
              </CustomButton>
              <Divider style={{ marginTop: '20px' }} />

              <div>
                <h2 className={`${classes.title} ${classes.primaryTitle}`}>
                  Connected Devices
                </h2>
                <CustomButton
                  onClick={() => loadHubDevices(hubId)}
                  variant="orange"
                >
                  Refresh List
                </CustomButton>
                <Grid container direction="row">
                  <Grid item xs={6} />
                  <Grid item xs={6} className={classes.statusTitle}>
                    Status/Activity
                  </Grid>
                </Grid>
                <PropertyHubLockDeviceList hubId={hubId} />
                {/* <PropertyHubLightDeviceList hubId={hubId} /> */}
              </div>
            </>
          )}
          {!hubHasDevices && !isADCHub && (
            <>
              <div className={classes.statusTitle}>
                Hub doesn&apos;t have devices
              </div>
              <CustomButton
                onClick={() => loadHubDevices(hubId)}
                variant="orange"
                className={classes.rebootButton}
              >
                Refresh List
              </CustomButton>
            </>
          )}
          <CustomButton
            onClick={() => rebootHub(hubId)}
            variant="orange"
            className={classes.rebootButton}
          >
            Reboot Hub
          </CustomButton>
          {isADCHub && (
            <>
              <div className={classes.statusTitle}>
                Login to
                {' '}
                <a
                  href="https://www.alarm.com/login.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Alarm.com
                </a>
                {' '}
                to control Smart Home devices.
              </div>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default HubPanel;
