import React from 'react';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { WithStyles } from '@material-ui/styles';
import styles from './styles';

type Props = {
  closeForm: () => void;
} & WithStyles<typeof styles>;

const InputLabelProps = { shrink: true };
const preventFormSubmit = (e: React.FormEvent): void => {
  e.preventDefault();
};

const AddForm: React.FC<Props> = props => {
  const { classes, closeForm } = props;

  return (
    <>
      <Grid container justify="space-between" className={classes.navRow}>
        <div>
          <Button
            disabled
            className={classnames(classes.button, classes.buttonGroup)}
          >
            <ArrowLeftIcon />
          </Button>
          <Button
            disabled
            className={classnames(classes.button, classes.buttonGroup)}
          >
            <ArrowRightIcon />
          </Button>
        </div>
        <Button
          variant="contained"
          className={classes.button}
          onClick={closeForm}
        >
          <ArrowLeftIcon />
          Inventory
        </Button>
      </Grid>

      <div className={classes.cardWrapper}>
        <Card className={classes.card}>
          <Tabs
            value={0}
            classes={{ root: classes.tabs, indicator: classes.tabIndicator }}
          >
            <Tab classes={{ root: classes.tabRoot }} label="Details" />
          </Tabs>
          <CardContent className={classes.cardContent}>
            <form onSubmit={preventFormSubmit}>
              <Grid container>
                <Grid item xs>
                  <Typography className={classes.formTitle} variant="h4">
                    New Device
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="filled"
                    fullWidth
                    name="lockId"
                    label="Lock ID"
                    defaultValue="Lock ID"
                    InputLabelProps={InputLabelProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="filled"
                    fullWidth
                    name="secretId"
                    label="Secret ID"
                    defaultValue="Secret ID"
                    InputLabelProps={InputLabelProps}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item xs={12} sm={3}>
                  <TextField
                    variant="filled"
                    fullWidth
                    name="batteryChangeDate"
                    label="Battery Change Date"
                    defaultValue="05/03/2019"
                    InputLabelProps={InputLabelProps}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    variant="filled"
                    fullWidth
                    name="lockVersion"
                    label="Lock Version"
                    defaultValue="Lock Version"
                    InputLabelProps={InputLabelProps}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="filled"
                    fullWidth
                    name="licensee"
                    label="Licensee"
                    defaultValue="Licensee"
                    InputLabelProps={InputLabelProps}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="filled"
                    fullWidth
                    select
                    name="lockStatus"
                    label="Lock Status"
                  >
                    {[
                      { value: 'locked', label: 'Locked' },
                      { value: 'unlocked', label: 'Unlocked' },
                    ].map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="filled"
                    fullWidth
                    name="email"
                    label="Email"
                    defaultValue="Email"
                    InputLabelProps={InputLabelProps}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <TextField
                        variant="filled"
                        fullWidth
                        name="manufacturer"
                        label="Manufacturer"
                        defaultValue="Manufacturer"
                        InputLabelProps={InputLabelProps}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="filled"
                        fullWidth
                        name="purchaseDate"
                        label="Purchase Date"
                        defaultValue="03/01/2019"
                        InputLabelProps={InputLabelProps}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="filled"
                        fullWidth
                        name="licenseStartDate"
                        label="License Start Date"
                        defaultValue="03/01/2019"
                        InputLabelProps={InputLabelProps}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="filled"
                    fullWidth
                    multiline
                    rows={5}
                    rowsMax={6}
                    name="comments"
                    label="Comments"
                    defaultValue="Add comments here."
                    InputLabelProps={InputLabelProps}
                  />
                </Grid>
              </Grid>
              <div className={classes.cardActions}>
                <Grid container>
                  <Grid item xs>
                    <Button
                      type="submit"
                      variant="contained"
                      className={classnames(classes.button, classes.saveButton)}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default React.memo(AddForm);
