import { connect } from 'react-redux';
import { AppState } from '../../redux/store';
import SurveyQuestions from './SurveyQuestions';
import { surveyQuestionsRequest, updateSurveyQuestions } from '../../redux/actions/survey';
import { selectSurveyQuestions, selectSurveyRequestStatus, selectSurveyUpdateStatus } from '../../redux/selectors/survey';


const mapStateToProps = (state: AppState) => ({
  surveyQuestions: selectSurveyQuestions(state),
  loading: selectSurveyRequestStatus(state),
  updating: selectSurveyUpdateStatus(state),
});

const mapDispatchToProps = {
  updateQuestions: updateSurveyQuestions,
  loadSurveyQuestions: surveyQuestionsRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SurveyQuestions);
