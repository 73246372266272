import React from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';

export type PropertyHubDeviceProps = {
  name: string;
  status: string | JSX.Element;
  onClick: () => void;
};


const PropertyHubDevice: React.FC<PropertyHubDeviceProps> = (props) => {
  const { status, name, onClick } = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.card} direction="row" onClick={onClick}>
      <Grid item xs={6} className={classes.cardTitle}>
        {name}
      </Grid>
      <Grid item xs={6} className={classes.cardStatus}>{status}</Grid>
    </Grid>
  );
};

export default React.memo(PropertyHubDevice);
