import {
  SET_FILTER_DATA, CLEAR_FILTER_DATA, FilterState, setFilterActionType, SET_ENTIRE_FILTER_DATA,
} from '../types/filter';
import { lastNDays } from '../../utils/date';


const initialState: FilterState = {
  customers: {
    customerStatus: '',
    visitorType: '',
    lastStatus: '',
  },
  properties: {
    lockStatus: '',
    lockDisposition: '',
    builder: null,
  },
  communities: {
    builder: null,
  },
  agents: {
    builder: null,
  },
  visits: {
    visitorType: '',
    lastStatus: '',
    startDate: null,
    endDate: null,
  },
  reports: {
    startDate: null,
    endDate: null,
  },
  inventory: {
    lockStatus: '',
    shipmentStatus: '',
    community: '',
  },
  hubAccessHistory: {
    startDate: lastNDays(7),
    endDate: null,
  },
};

export default function FilterReducer(
  state = initialState,
  action: setFilterActionType,
) {
  switch (action.type) {
    case SET_FILTER_DATA: {
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          [action.payload.name]: action.payload.value,
        },
      };
    }

    case CLEAR_FILTER_DATA: {
      return initialState;
    }

    case SET_ENTIRE_FILTER_DATA: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
