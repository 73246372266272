import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import SmartLockPanel from './SmartLockPanel';
import styles from './styles';
import { AppState } from '../../redux/store';
import {
  selectSmartLockDetails,
  selectSmartLocksLoading,
  selectSmartLocksRefreshing,
} from '../../redux/selectors/smartLocks';
import {
  updateSmartLockCodeRequest,
  deleteSmartLockCodeRequest,
  smartLockDetailsRequest,
  refreshSmartLockDetailsRequest,
} from '../../redux/actions/smartLocks';

const styledComponent = withStyles(styles)(SmartLockPanel);

const mapStateToProps = (state: AppState) => ({
  loading: selectSmartLocksLoading(state),
  refreshing: selectSmartLocksRefreshing(state),
  smartLockDetails: selectSmartLockDetails(state),
});

const mapDispatchToProps = {
  loadSmartLockDetails: smartLockDetailsRequest,
  refreshSmartLockDetails: refreshSmartLockDetailsRequest,
  updateSmartLockCode: updateSmartLockCodeRequest,
  deleteSmartLockCode: deleteSmartLockCodeRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(styledComponent);
