import {
  VISITS_REQUEST,
  VISITS_REQUEST_SUCCESS,
  VISITS_REQUEST_FAILED, VisitsActionsTypes,
} from '../types/visits';
import {
  CUSTOMERS_REQUEST,
  CUSTOMERS_REQUEST_FAILED,
  CUSTOMERS_REQUEST_SUCCESS,
  CUSTOMER_DETAILS_REQUEST,
  CUSTOMER_DETAILS_REQUEST_FAILED,
  CUSTOMER_DETAILS_REQUEST_SUCCESS, CustomersActionsTypes,
} from '../types/customers';
import {
  LOCKS_REQUEST,
  LOCKS_REQUEST_FAILED,
  LOCKS_REQUEST_SUCCESS, LocksActionsTypes,
} from '../types/locks';
import {
  PROPERTIES_REQUEST,
  PROPERTIES_REQUEST_FAILED,
  PROPERTIES_REQUEST_SUCCESS,
  PropertiesActionsTypes,
} from '../types/properties';

export type ProgressBarState = {
  loading: boolean;
};

export type ProgressBarActionsTypes = VisitsActionsTypes &
CustomersActionsTypes &
LocksActionsTypes &
PropertiesActionsTypes;

const initialState: ProgressBarState = {
  loading: false,
};

export default function ProgressBarReducer(
  state = initialState,
  action: ProgressBarActionsTypes,
): ProgressBarState {
  switch (action.type) {
    case PROPERTIES_REQUEST:
    case CUSTOMERS_REQUEST:
    case CUSTOMER_DETAILS_REQUEST:
    case LOCKS_REQUEST:
    case VISITS_REQUEST: {
      return {
        loading: true,
      };
    }

    case PROPERTIES_REQUEST_FAILED:
    case PROPERTIES_REQUEST_SUCCESS:
    case CUSTOMERS_REQUEST_FAILED:
    case CUSTOMERS_REQUEST_SUCCESS:
    case CUSTOMER_DETAILS_REQUEST_FAILED:
    case CUSTOMER_DETAILS_REQUEST_SUCCESS:
    case LOCKS_REQUEST_FAILED:
    case LOCKS_REQUEST_SUCCESS:
    case VISITS_REQUEST_FAILED:
    case VISITS_REQUEST_SUCCESS: {
      return {
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
