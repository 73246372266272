import { connect } from 'react-redux';
import { builderSettingsRequest, saveBuilderSettings } from '../../redux/actions/builders';
import {
  selectBuilderSettingsLoading,
} from '../../redux/selectors/builders';
import { AppState } from '../../redux/store';
import BuilderSettingsForm from './BuilderContentSettingsForm';
import {
  getBuilderId,
} from '../../redux/selectors/auth';

const mapStateToProps = (state: AppState) => ({
  builderId: getBuilderId(state),
  isLoading: selectBuilderSettingsLoading(state),
});

const mapDispatchToProps = {
  saveSettings: saveBuilderSettings,
  builderSettingsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuilderSettingsForm);
