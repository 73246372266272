import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Query } from '../../../utils/query';
import { Order } from '../../../utils/table';
import { Customer } from '../../../redux/types/customers';
import { SearchSortContext } from '../../../context/SearchSortContext';
import { ChangeSearchPayload, PageSearchSortContextActionType } from '../../../context/types';
import { selectCustomerFilters } from '../../../redux/selectors/filter';
import parseUrlFilterSearch from '../../../hooks/parseUrlFilterSearch';
import useUrlFilterSearch from '../../../hooks/useUrlFilterSearch';
import { UrlParam } from '../../../redux/types/filter';

interface UrlParamProperties extends UrlParam {
  customerStatus?: string,
  visitorType?: string,
  lastStatus?: string
}

const useCustomers = (loadCustomers: (query: Query) => void) => {
  const urlParse = parseUrlFilterSearch<UrlParamProperties>();
  const { dispatch, state: { customers } } = useContext(SearchSortContext);
  const {
    currentOrderBy, currentOrder, currentPage, rowsPerPageAmount, contextSearch,
  } = customers;
  const [search, setSearch] = useState<string>(urlParse.search || contextSearch);
  const [order, setOrder] = useState<Order>(urlParse.order || currentOrder);
  const [orderBy, setOrderBy] = useState<keyof Customer>(urlParse.orderBy || currentOrderBy);
  const [page, setPage] = useState<number>(urlParse.page || currentPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(urlParse.limit || rowsPerPageAmount);
  const { customerStatus, visitorType, lastStatus } = useSelector(selectCustomerFilters);

  useUrlFilterSearch<UrlParamProperties>({
    search, order, orderBy, page, limit: rowsPerPage, customerStatus, visitorType, lastStatus,
  });

  const dispatchCustomers = (
    type: PageSearchSortContextActionType<'CHANGE_CUSTOMER'>,
    payload?: ChangeSearchPayload<Customer>,
  ) => dispatch({ type, payload: { ...customers, ...payload } });


  useEffect(() => {
    loadCustomers({
      search, order, orderBy, page, limit: rowsPerPage, customerStatus, visitorType, lastStatus,
    });
  }, [order, orderBy, page, rowsPerPage, search, loadCustomers,
    customerStatus, visitorType, lastStatus]);

  const searchRequest = () => loadCustomers({
    search, order, orderBy, page, limit: rowsPerPage, customerStatus, visitorType, lastStatus,
  });

  return {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    dispatchCustomers,
    searchRequest,
  };
};

export default useCustomers;
