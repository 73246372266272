import { useState, useEffect } from 'react';
import { AgentDetails, AgentRoleType } from '../../redux/types/agents';

type InitialAgentPayload = {
  firstname: string,
  phone: string,
  lastname: string,
  email: string,
  notification_email?: string,
  send_sms: number,
  send_email: number,
  oldEmail: string,
  oldPhone: string,
  pass_word: string | null,
  confirm_password: string | null,
  roles: AgentDetails['roles']
};

const useAgentDetails = (
  loadAgentDetails: (username: string | number) => void,
  formType: 'add' | 'edit',
  username: string,
  agentDetails: AgentDetails | null,
) => {
  const roles: AgentRoleType = { Label: '' };

  const [agentPayload, setAgentPayload] = useState<InitialAgentPayload>({
    firstname: '',
    phone: '',
    lastname: '',
    email: '',
    notification_email: '',
    send_sms: 0,
    send_email: 0,
    oldEmail: '',
    oldPhone: '',
    pass_word: null,
    confirm_password: null,
    roles,
  });

  useEffect(() => {
    if (formType !== 'add') {
      loadAgentDetails(username);
    }
  }, [loadAgentDetails, username, formType]);

  useEffect(() => {
    if (agentDetails && formType !== 'add') {
      setAgentPayload({
        ...agentDetails,
        oldEmail: agentDetails.email,
        oldPhone: agentDetails.phone,
        pass_word: agentDetails.pass_word || null,
        confirm_password: null,
      });
    }
  }, [agentDetails, username, formType]);

  return { agentPayload, setAgentPayload };
};

export default useAgentDetails;
