import { connect } from 'react-redux';
import Invoices from './Invoices';
import { downloadFakePdf } from '../../redux/actions/billing';
import { AppState } from '../../redux/store';
import {
  selectInvoicesDetails,
  selectInvoicesRequestStatus,
  selectTotalInvoicesAmount,
} from '../../redux/selectors/invoices';

const mapStateToProps = (state: AppState) => ({
  loading: selectInvoicesRequestStatus(state),
  invoicesDetails: selectInvoicesDetails(state),
  totalInvoiceAmount: selectTotalInvoicesAmount(state),
});

const mapDispatchToProps = {
  downloadFakePdf,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
