import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootSaga from './saga/rootSaga';
import rootReducer from './reducer/rootReducer';
import { initialState } from './reducer/auth';
import { getLocalAuthState } from '../services/auth';
import { AUTH_USER_LOGOUT } from './types/auth';

export type AppState = ReturnType<typeof rootReducer>;

const auth = getLocalAuthState(initialState);
const sagaMiddleware = createSagaMiddleware();
const store = createStore<AppState, any, any, any>((state, action) => {
  // forcing to use initialState value for all reducers
  if (action.type === AUTH_USER_LOGOUT) {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
}, { auth }, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

export default store;
