import { createStyles, makeStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  card: {
    padding: '14px 24px',
    borderRadius: 10,
    boxShadow: '0px 0px 10px 0px #00000040',
    cursor: 'pointer',

    '& + div': {
      marginTop: 16,
    },
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#DB5C0E',
  },
  cardStatus: {
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  deviceIcon: {
    color: '#DB5C0E',
  },
  card1: {
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    height: '100%',
  },
  iconWrapper: {
    textAlign: 'center',
    margin: '15px 0 31px',
    width: '100%',
  },
  icon: {
    height: 250,
    fontSize: 114,
    backgroundSize: 'contain',
    color: '#DB5C0E',
    display: 'block',
  },
  primaryText: {
    color: '#DB5C0E',
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: '28px',
    '& .deviceId': {
      fontWeight: 'bold',
      color: '#000',
    },
  },
  secondaryText: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#979797',
  },
  link: {
    color: theme.appPalette.orange,
  },
});

export default makeStyles(styles);
