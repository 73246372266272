import { HeaderRowConfig } from '../components/CustomTable/CustomTable';
import { ScheduledVisit } from '../redux/types/scheduledVisit';
import { formatToESTDate } from '../utils/date';

export const headRows: HeaderRowConfig<ScheduledVisit>[] = [
  {
    key: 'first_name',
    align: 'left',
    disablePadding: false,
    label: 'First Name',
  },
  {
    key: 'last_name',
    align: 'left',
    disablePadding: false,
    label: 'Last Name',
  },
  {
    key: 'email',
    align: 'center',
    disablePadding: true,
    label: 'Email',
  },
  {
    key: 'phone',
    align: 'center',
    disablePadding: false,
    label: 'Phone',
  },
  {
    key: 'status',
    align: 'center',
    disablePadding: true,
    label: 'Status'
  },
  {
    key: 'visit_date',
    align: 'center',
    disablePadding: false,
    label: 'Visit Date',
    prepareData: ({
      visit_date,
      timezoneAbbr,
      timezoneValue,
    }) => formatToESTDate(visit_date, timezoneAbbr, timezoneValue),
  },
  {
    key: 'property_id',
    align: 'center',
    disablePadding: true,
    label: 'Property Id',
  },
];
