import { Theme, createStyles, fade } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  contentWrapper: {
    marginTop: '33px',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  tableWrapper: {
    overflowX: 'auto',
    position: 'relative',
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  spacer: {
    flexGrow: 1,
  },
  toolbarActions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
      color: theme.appPalette.orange,
      textTransform: 'capitalize',
    },
  },
  table: {
    minWidth: 1230,
    '& tr': {
      '&:hover > td': {
        color: theme.appPalette.orange,
        cursor: 'pointer',
      },
    },
  },
  controlButton: {
    color: '#FFFFFF',
    backgroundColor: '#DB5C0E',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    minWidth: 100,
    
    '&:hover': {
      backgroundColor: fade('#DB5C0E', 0.7),
    },
  },
});
