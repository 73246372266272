import { createSelector } from 'reselect';
import { AppState } from '../store';
import {
  BuildersState,
  BuildersPMsState,
  BuildersSettingsState,
} from '../types/builders';

const selectBuilderSettingsState = (state: AppState) => state.builderSettings;
const selectCurrentBuilderSettings = (state: BuildersSettingsState) => state.item;
const selectCurrentContentBuilderSettings = (state: BuildersSettingsState) => JSON.parse(state.item?.content_settings || '{}');
const selectBuildersState = (state: AppState) => state.builders;
const selectBuilderPMsState = (state: AppState) => state.builderPMs;
const selectItems = (state: BuildersState) => state.items;
const selectLoading = (state: BuildersState) => state.loading;
const selectSettingsLoading = (state: BuildersSettingsState) => state.loading;
const selectPMsLoading = (state: BuildersPMsState) => state.loading;
const selectPMs = (state: BuildersPMsState) => state.items;
const selectBuilderIntegrationsState = (state: BuildersState) => state.integrations;
const selectBuilderTimezoneState = (state: BuildersState) => state.builderTimezone;
const selectBuilderFeedSettingsState = (state: BuildersState) => state.feedSettings;


export const selectBuilderSettingsLoading = createSelector(
  selectBuilderSettingsState,
  selectSettingsLoading,
);

export const selectBuilders = createSelector(
  selectBuildersState,
  selectItems,
);

export const selectBuildersRequestStatus = createSelector(
  selectBuildersState,
  selectLoading,
);

export const selectBuilderPMs = createSelector(
  selectBuilderPMsState,
  selectPMs,
);

export const selectBuilderPMsRequestStatus = createSelector(
  selectBuilderPMsState,
  selectPMsLoading,
);

export const selectBuilderSettings = createSelector(
  selectBuilderSettingsState,
  selectCurrentBuilderSettings,
);

export const selectContentBuilderSettings = createSelector(
  selectBuilderSettingsState,
  selectCurrentContentBuilderSettings,
);

export const selectBuilderIntegrations = createSelector(
  selectBuildersState,
  selectBuilderIntegrationsState,
);

export const selectBuilderTimezone = createSelector(
  selectBuildersState,
  selectBuilderTimezoneState,
);

export const selectBuilderFeedSettings = createSelector(
  selectBuildersState,
  selectBuilderFeedSettingsState,
);
