import { connect } from 'react-redux';
import PropertyHubLockDeviceList from './PropertyHubLockDeviceList';
import { setHubSelectedLock } from '../../redux/actions/hubs/hubs';
import { AppState } from '../../redux/store';
import {
  selectHubLocks, selectHubLockSelected,
} from '../../redux/selectors/hubs';

const mapStateToProps = (state: AppState) => ({
  selectedHubLock: selectHubLockSelected(state),
  hubLocks: selectHubLocks(state),
});

const mapDispatchToProps = {
  setHubSelectedLock,
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyHubLockDeviceList);
