import {
  STATES_REQUEST,
  STATES_REQUEST_SUCCESS,
  STATES_REQUEST_FAILED,
  StatesRequestSuccess,
} from '../types/states';
import { ErrorMessage } from '../../types/main';

export const statesRequest = () => ({
  type: STATES_REQUEST,
} as const);

export const statesRequestFailed = (payload: ErrorMessage) => ({
  type: STATES_REQUEST_FAILED,
  payload,
} as const);

export const statesRequestSuccess = (payload: StatesRequestSuccess) => ({
  type: STATES_REQUEST_SUCCESS,
  payload,
} as const);
