import {
  SMART_LOCKS_REQUEST,
  SMART_LOCKS_REQUEST_SUCCESS,
  SMART_LOCKS_REQUEST_FAILED,
  SMART_LOCK_DETAILS_REQUEST,
  SMART_LOCK_DETAILS_REQUEST_SUCCESS,
  SMART_LOCK_DETAILS_REQUEST_FAILED,
  SmartLocksState,
  SmartLocksActionsTypes,
  UPDATE_SMART_LOCK_CODE_REQUEST,
  UPDATE_SMART_LOCK_CODE_REQUEST_SUCCESS,
  UPDATE_SMART_LOCK_CODE_REQUEST_FAILED,
  DELETE_SMART_LOCK_CODE_REQUEST_FAILED,
  DELETE_SMART_LOCK_CODE_REQUEST,
  DELETE_SMART_LOCK_CODE_REQUEST_SUCCESS,
  REFRESH_SMART_LOCK_DETAILS_REQUEST,
} from '../types/smartLocks';

const initialState: SmartLocksState = {
  items: [],
  error: '',
  loading: false,
  refreshing: false,
  selected: null,
};

export default function SmartLocksReducer(
  state = initialState,
  action: SmartLocksActionsTypes,
): SmartLocksState {
  switch (action.type) {
    case SMART_LOCKS_REQUEST:
    case UPDATE_SMART_LOCK_CODE_REQUEST:
    case DELETE_SMART_LOCK_CODE_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case SMART_LOCKS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        error: '',
        loading: false,
        refreshing: false,
      };
    }

    case SMART_LOCKS_REQUEST_FAILED:
    case SMART_LOCK_DETAILS_REQUEST_FAILED:
    case DELETE_SMART_LOCK_CODE_REQUEST_FAILED:
    case UPDATE_SMART_LOCK_CODE_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
        refreshing: false,
      };
    }

    case SMART_LOCK_DETAILS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
        selected: null,
      };
    }

    case REFRESH_SMART_LOCK_DETAILS_REQUEST: {
      return {
        ...state,
        refreshing: true,
      };
    }

    case SMART_LOCK_DETAILS_REQUEST_SUCCESS:
    case UPDATE_SMART_LOCK_CODE_REQUEST_SUCCESS:
    case DELETE_SMART_LOCK_CODE_REQUEST_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        refreshing: false,
        selected: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
