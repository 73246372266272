import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PastDueInvoices from './PastDueInvoices';
import { downloadFakePdf } from '../../redux/actions/billing';
import { AppState } from '../../redux/store';
import { selectInvoicesRequestStatus, selectPastDueInvoices, selectPastDueInvoicesAmount } from '../../redux/selectors/invoices';

import styles from './styles';

const mapStateToProps = (state: AppState) => ({
  loading: selectInvoicesRequestStatus(state),
  invoicesDetails: selectPastDueInvoices(state),
  totalInvoiceAmount: selectPastDueInvoicesAmount(state),
});

const mapDispatchToProps = {
  downloadFakePdf,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PastDueInvoices),
);
