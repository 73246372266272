import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Query } from '../../../utils/query';
import { Order } from '../../../utils/table';
import { Visit } from '../../../redux/types/visits';
import { SearchSortContext } from '../../../context/SearchSortContext';
import { selectVisitsFilters } from '../../../redux/selectors/filter';
import { ChangeSearchPayload, PageSearchSortContextActionType } from '../../../context/types';
import parseUrlFilterSearch from '../../../hooks/parseUrlFilterSearch';
import useUrlFilterSearch from '../../../hooks/useUrlFilterSearch';
import { UrlParam } from '../../../redux/types/filter';

interface UrlParamProperties extends UrlParam {
  visitorType?: string
  lastStatus?: string
  startDate?: Date | null
  endDate?: Date | null
}

const useVisits = (loadVisits: (query: Query) => void) => {
  const urlParse = parseUrlFilterSearch<UrlParamProperties>();
  const { dispatch, state: { visits } } = useContext(SearchSortContext);
  const {
    currentOrderBy, currentOrder, currentPage, rowsPerPageAmount, contextSearch,
  } = visits;

  const [page, setPage] = useState<number>(urlParse.page || currentPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(urlParse.limit || rowsPerPageAmount);
  const [search, setSearch] = useState<string>(urlParse.search || contextSearch);
  const [order, setOrder] = useState<Order>(urlParse.order || currentOrder);
  const [orderBy, setOrderBy] = useState<keyof Visit>(urlParse.orderBy || currentOrderBy);
  const {
    visitorType, lastStatus, startDate, endDate,
  } = useSelector(selectVisitsFilters);

  useUrlFilterSearch<UrlParamProperties>({
    search, order, orderBy, page, limit: rowsPerPage, visitorType, lastStatus, startDate, endDate,
  });

  const dispatchVisits = (
    type: PageSearchSortContextActionType<'CHANGE_VISIT'>,
    payload?: ChangeSearchPayload<Visit>,
  ) => {
    dispatch({ type, payload: { ...visits, ...payload } });
  };

  useEffect(() => {
    loadVisits({
      search, order, orderBy, page, limit: rowsPerPage, visitorType, lastStatus, startDate, endDate,
    });
  }, [order, orderBy, page, rowsPerPage, search,
    loadVisits, visitorType, lastStatus, startDate, endDate]);

  const searchRequest = () => loadVisits({
    search, order, orderBy, page, limit: rowsPerPage, visitorType, lastStatus, startDate, endDate,
  });

  return {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    dispatchVisits,
    searchRequest,
  };
};

export default useVisits;
