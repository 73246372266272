import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  headerText: {
    paddingBottom: '12px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
  },

  inputForm: {
    borderTop: '1px solid rgba(142, 139, 153, 0.25)',
    padding: '20px 20% 50px 20%',
  },

  submitButton: {
    display: 'block',
    marginTop: '52px',
    marginLeft: 'auto',
    marginRight: '20%',
  },

  paper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: 25,
  },

  label: {
    margin: 0,
    fontSize: '12px',
    lineHeight: 1,
    color: 'rgba(0, 0, 0, 0.54)',
  },

  hidden: {
    opacity: 0,
    visibility: 'hidden',
    pointerEvents: 'none',
  },
  
  radio: {
    '&.Mui-checked': {
      color: `${theme.appPalette.orange}`,
    },
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
