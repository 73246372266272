import React from 'react';
import classNames from 'classnames';
import { WithStyles } from '@material-ui/core';
import styles from './styles';

type Props = {
  color: IconColorType,
} & WithStyles<typeof styles>;

const CardIcon: React.FC<Props> = props => {
  const {
    classes, children, color, ...rest
  } = props;
  const cardIconClasses = classNames({
    [classes.cardIcon]: true,
    [classes[color]]: color,
  });

  return (
    <div className={cardIconClasses} {...rest}>
      {children}
    </div>
  );
};

export type IconColorType = 'warningCardHeader' | 'successCardHeader' | 'dangerCardHeader' | 'infoCardHeader' | 'primaryCardHeader' | 'roseCardHeader';

export default React.memo(CardIcon);
