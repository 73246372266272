import React from 'react';
import { WithStyles } from '@material-ui/styles';
import classNames from 'classnames';

import styles from './styles';

export type IStatus = Exclude<keyof Props['classes'], 'badgeBase'>;

type Props = {
  status: string;
} & WithStyles<typeof styles>;

function getStatusClasses(status: string): IStatus {
  return status.toLowerCase() as IStatus;
}

const StatusBadge: React.FC<Props> = ({ classes, status }) => (
  <div className={classNames(classes.badgeBase, classes[getStatusClasses(status)])}>
    {status}
  </div>
);

export default React.memo(StatusBadge);
