import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const CloseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="23" height="23" viewBox="0 0 23 23" fill="none" {...props}>
    <path d="M14.8222 16.3556L11.5 13.0333L8.17778 16.3556C7.18111 17.3522 5.67333 15.8189 6.64444 14.8222L9.96667 11.5L6.64444 8.17778C5.64778 7.18111 7.18111 5.67333 8.17778 6.64444L11.5 9.96667L14.8222 6.64444C15.8189 5.64778 17.3267 7.18111 16.3556 8.17778L13.0333 11.5L16.3556 14.8222C17.3267 15.7933 15.7933 17.3267 14.8222 16.3556ZM11.5 0C5.13667 0 0 5.13667 0 11.5C0 17.8633 5.13667 23 11.5 23C17.8633 23 23 17.8633 23 11.5C23 5.13667 17.8633 0 11.5 0Z" fill="#8E8B99" />
  </SvgIcon>
);

export default React.memo(CloseIcon);
