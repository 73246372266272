import { connect } from 'react-redux';
import FeatureWraper from './FeatureWrapper';
import { AppState } from '../../redux/store';
import { selectFeatures } from '../../redux/selectors/split';
import { selectIsPM } from '../../redux/selectors/auth';

const mapStateToProps = (state: AppState) => ({
  features: selectFeatures(state),
  isPM: selectIsPM(state),
});

export default connect(mapStateToProps)(FeatureWraper);
