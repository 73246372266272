import {
  CustomerLastStatusFilter,
  CustomerStatusFilter,
  PropertyLockDispositionFilter,
  PropertyLockStatusFilter,
  VisitorTypeFilter,
} from '../../components/SearchFilterElement/type';
import * as FilterActions from '../actions/filter';
import { ValueOf } from '../../types/main';
import { Order } from '../../utils/table';

export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const CLEAR_FILTER_DATA = 'CLEAR_FILTER_DATA';
export const SET_ENTIRE_FILTER_DATA = 'SET_ENTIRE_FILTER_DATA';

export type Filter = {
  [key: string]: string,
};

export type setFilterActionType = ReturnType<ValueOf<typeof FilterActions>>;

interface CustomersFilters {
  customerStatus: CustomerStatusFilter | ''
  visitorType: VisitorTypeFilter | ''
  lastStatus: CustomerLastStatusFilter | ''
}
interface PropertiesFilters {
  lockStatus: PropertyLockStatusFilter | ''
  lockDisposition: PropertyLockDispositionFilter | ''
  builder: number | null
}

interface EnterpriseFilters {
  builder: number | null
}
interface VisitsFilters {
  visitorType: VisitorTypeFilter | ''
  lastStatus: string
  startDate: Date | null
  endDate: Date | null
}
interface ReportsFilters {
  builderId?: number | string
  startDate: Date | null
  endDate: Date | null
}
interface InventoryFilters {
  lockStatus: string
  shipmentStatus: string
  community: string
}

interface HubAccessHistoryFilters {
  startDate: Date | null
  endDate: Date | null
}

export interface FilterState {
  customers: CustomersFilters
  properties: PropertiesFilters
  communities: EnterpriseFilters
  agents: EnterpriseFilters
  visits: VisitsFilters
  reports: ReportsFilters
  inventory: InventoryFilters,
  hubAccessHistory: HubAccessHistoryFilters
}

export interface UrlFilterState {
  customers?: CustomersFilters
  properties?: PropertiesFilters
  visits?: VisitsFilters
  inventory?: InventoryFilters,
}

export type FilterPageKey = keyof FilterState;
export type FilterPagePropertyKey = keyof FilterState[FilterPageKey];

export interface SetFilterPayload {
  name: string,
  value: string | unknown,
  type: FilterPageKey,
}

export interface UrlParam {
  search: string,
  order: Order,
  orderBy: any,
  page: any,
  limit: number,
  showAll?: boolean,
  filterByBuilder?: number | null
}
