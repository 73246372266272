import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Query } from '../../../utils/query';
import { Order } from '../../../utils/table';
import { Property } from '../../../redux/types/properties';
import { SearchSortContext } from '../../../context/SearchSortContext';
import {
  ChangeSearchPayload,
  PageSearchSortContextActionType,
} from '../../../context/types';
import { selectPropertyFilters } from '../../../redux/selectors/filter';
import useUrlFilterSearch from '../../../hooks/useUrlFilterSearch';
import parseUrlFilterSearch from '../../../hooks/parseUrlFilterSearch';
import { UrlParam } from '../../../redux/types/filter';

interface UrlParamProperties extends UrlParam {
  lockStatus?: string;
  lockDisposition?: string;
  filterByBuilder?: number | null;
}

const useProperties = (loadProperties: (query: Query) => void) => {
  const urlParse = parseUrlFilterSearch<UrlParamProperties>();
  const { lockStatus, lockDisposition, builder: filterByBuilder } = useSelector(
    selectPropertyFilters,
  );
  const {
    dispatch,
    state: { properties },
  } = useContext(SearchSortContext);
  const {
    currentOrderBy,
    currentOrder,
    currentPage,
    rowsPerPageAmount,
    contextSearch,
  } = properties;
  const [search, setSearch] = useState<string>(
    urlParse.search || contextSearch,
  );
  const [order, setOrder] = useState<Order>(urlParse.order || currentOrder);
  const [orderBy, setOrderBy] = useState<keyof Property>(
    urlParse.orderBy || currentOrderBy,
  );
  const [page, setPage] = useState<number>(urlParse.page || currentPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    urlParse.limit || rowsPerPageAmount,
  );
  const [showAll, setShowAll] = useState<boolean>(urlParse?.showAll || false);
  useUrlFilterSearch<UrlParamProperties>({
    search,
    order,
    orderBy,
    page,
    limit: rowsPerPage,
    lockStatus,
    lockDisposition,
    filterByBuilder,
  });

  const dispatchProperty = (
    type: PageSearchSortContextActionType<'CHANGE_PROPERTY'>,
    payload?: ChangeSearchPayload<Property>,
  ) => dispatch({ type, payload: { ...properties, ...payload } });


  useEffect(() => {
    loadProperties({
      search,
      order,
      orderBy,
      page,
      limit: rowsPerPage,
      lockStatus,
      lockDisposition,
      filterByBuilder,
      showAll,
    });
  }, [
    order,
    orderBy,
    page,
    rowsPerPage,
    search,
    loadProperties,
    lockStatus,
    lockDisposition,
    filterByBuilder,
    showAll,
  ]);

  const searchRequest = () => loadProperties({
    search,
    order,
    orderBy,
    page,
    limit: rowsPerPage,
    lockStatus,
    lockDisposition,
    filterByBuilder,
  });

  return {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    setShowAll,
    dispatchProperty,
    searchRequest,
  };
};

export default useProperties;
