import { ErrorMessage } from '../../types/main';
import { Query } from '../../utils/query';
import {
  VisitsAfterTourHoursRequestSuccess,
  VISITS_AFTER_TOUR_HOURS_EXPORT_AND_PRINT_REQUEST,
  VISITS_AFTER_TOUR_HOURS_EXPORT_REQUEST,
  VISITS_AFTER_TOUR_HOURS_EXPORT_REQUEST_FAILED,
  VISITS_AFTER_TOUR_HOURS_EXPORT_REQUEST_SUCCESS,
  VISITS_AFTER_TOUR_HOURS_REQUEST,
  VISITS_AFTER_TOUR_HOURS_REQUEST_FAILED,
  VISITS_AFTER_TOUR_HOURS_REQUEST_SUCCESS,
} from '../types/afterTourHours';
import { MimeType } from '../types/mime';

export const visitsAfterTourHoursRequest = (query?: Query) => ({
  type: VISITS_AFTER_TOUR_HOURS_REQUEST,
  payload: query,
} as const);

export const visitsAfterTourHoursRequestSuccess = (
  payload: VisitsAfterTourHoursRequestSuccess,
) => ({
  type: VISITS_AFTER_TOUR_HOURS_REQUEST_SUCCESS,
  payload,
} as const);

export const visitsAfterTourHoursRequestFailed = (payload: ErrorMessage) => ({
  type: VISITS_AFTER_TOUR_HOURS_REQUEST_FAILED,
  payload,
} as const);

export const visitsAfterTourHoursExportRequest = (
  selected: Array<string | number>,
  mimeType: MimeType = 'text/csv',
) => ({
  type: VISITS_AFTER_TOUR_HOURS_EXPORT_REQUEST,
  payload: {
    selected,
    mimeType,
  },
} as const);

export const visitsAfterTourHoursExportFailed = (payload: ErrorMessage) => ({
  type: VISITS_AFTER_TOUR_HOURS_EXPORT_REQUEST_FAILED,
  payload,
} as const);

export const visitsAfterTourHoursExportSuccess = () => ({
  type: VISITS_AFTER_TOUR_HOURS_EXPORT_REQUEST_SUCCESS,
} as const);

export const visitsAfterTourHoursExportAndPrintRequest = (
  selected: Array<string | number>,
  mimeType: MimeType = 'application/pdf',
) => ({
  type: VISITS_AFTER_TOUR_HOURS_EXPORT_AND_PRINT_REQUEST,
  payload: {
    selected,
    mimeType,
  },
} as const);
