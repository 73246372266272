import {
  OFFERS_REQUEST,
  OFFERS_REQUEST_SUCCESS,
  OFFERS_REQUEST_FAILED,
  OFFER_DETAILS_REQUEST_SUCCESS,
  OffersState, OffersActionsTypes,
} from '../types/offers';

const initialState: OffersState = {
  items: [],
  selected: null,
  total: 0,
  error: '',
  loading: false,
};

export default function OffersReducer(
  state = initialState,
  action: OffersActionsTypes,
): OffersState {
  switch (action.type) {
    case OFFERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case OFFERS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total,
        loading: false,
      };
    }

    case OFFERS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case OFFER_DETAILS_REQUEST_SUCCESS: {
      return {
        ...state,
        selected: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
