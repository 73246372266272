import {
  ALL_PROPERTIES_STATS_REQUEST,
  ALL_PROPERTIES_STATS_REQUEST_FAILED,
  ALL_PROPERTIES_ACTIVE_STATS_REQUEST_SUCCESS,
  ALL_PROPERTIES_SOLD_STATS_REQUEST_SUCCESS,
  SOLD_PROPERTIES_REQUEST_SUCCESS,
  SOLD_PROPERTIES_REQUEST_FAILED,
  WEEKLY_VISITS_REQUEST,
  WEEKLY_VISITS_REQUEST_FAILED,
  WEEKLY_VISITS_REQUEST_SUCCESS,
  MONTHLY_VISITS_REQUEST,
  SOLD_PROPERTIES_STATS,
  MONTHLY_VISITS_REQUEST_FAILED,
  MONTHLY_VISITS_REQUEST_SUCCESS,
  YEARLY_VISITS_REQUEST,
  ALL_VISITS_REQUEST,
  YEARLY_VISITS_REQUEST_FAILED,
  YEARLY_VISITS_REQUEST_SUCCESS,
  ALL_VISITS_REQUEST_FAILED,
  ALL_VISITS_REQUEST_SUCCESS,
  CONTRACTORS_SERVED_COUNT_REQUEST,
  CONTRACTORS_SERVED_COUNT_REQUEST_SUCCESS,
  CONTRACTORS_SERVED_COUNT_REQUEST_FAILED,
  WeeklyVisitStats,
  MonthlyVisitStats,
  YearlyVisitStats,
  PropertyStats,
  PropertiesStatsQuery,
  AllVisitStats,
} from '../types/stats';
import { Query } from '../../utils/query';
import { ErrorMessage } from '../../types/main';

export const propertiesStatsRequest = (query: PropertiesStatsQuery) => ({
  type: ALL_PROPERTIES_STATS_REQUEST,
  payload: query,
} as const);

export const propertiesStatsRequestFailed = (payload: ErrorMessage) => ({
  type: ALL_PROPERTIES_STATS_REQUEST_FAILED,
  payload,
} as const);

export const propertiesActiveStatsRequestSuccess = (
  payload: [PropertyStats],
) => ({
  type: ALL_PROPERTIES_ACTIVE_STATS_REQUEST_SUCCESS,
  payload,
} as const);

export const propertiesSoldStatsRequestSuccess = (
  payload: PropertyStats[],
) => ({
  type: ALL_PROPERTIES_SOLD_STATS_REQUEST_SUCCESS,
  payload,
} as const);

export const loadSoldPropertiesStats = (payload: number) => ({
  type: SOLD_PROPERTIES_STATS,
  payload,
} as const);

export const soldPropertiesRequestSuccess = (payload: PropertyStats[]) => ({
  type: SOLD_PROPERTIES_REQUEST_SUCCESS,
  payload,
} as const);

export const soldPropertiesRequestFailed = (payload: string) => ({
  type: SOLD_PROPERTIES_REQUEST_FAILED,
  payload,
} as const);

export const weeklyVisitsRequest = () => ({
  type: WEEKLY_VISITS_REQUEST,
} as const);

export const weeklyVisitsRequestFailed = (payload: ErrorMessage) => ({
  type: WEEKLY_VISITS_REQUEST_FAILED,
  payload,
} as const);

export const weeklyVisitsRequestSuccess = (payload: WeeklyVisitStats[]) => ({
  type: WEEKLY_VISITS_REQUEST_SUCCESS,
  payload,
} as const);

export const monthlyVisitsRequest = (query: Query) => ({
  type: MONTHLY_VISITS_REQUEST,
  payload: query,
} as const);

export const monthlyVisitsRequestFailed = (payload: ErrorMessage) => ({
  type: MONTHLY_VISITS_REQUEST_FAILED,
  payload,
} as const);

export const monthlyVisitsRequestSuccess = (
  payload: MonthlyVisitStats[],
) => ({
  type: MONTHLY_VISITS_REQUEST_SUCCESS,
  payload,
} as const);

export const yearlyVisitsRequest = () => ({
  type: YEARLY_VISITS_REQUEST,
} as const);

export const yearlyVisitsRequestFailed = (payload: ErrorMessage) => ({
  type: YEARLY_VISITS_REQUEST_FAILED,
  payload,
} as const);

export const yearlyVisitsRequestSuccess = (payload: YearlyVisitStats[]) => ({
  type: YEARLY_VISITS_REQUEST_SUCCESS,
  payload,
} as const);

export const allVisitsRequest = (query: Query) => ({
  type: ALL_VISITS_REQUEST,
  payload: query,
} as const);

export const allVisitsRequestFailed = (payload: ErrorMessage) => ({
  type: ALL_VISITS_REQUEST_FAILED,
  payload,
} as const);

export const allVisitsRequestSuccess = (payload: AllVisitStats[]) => ({
  type: ALL_VISITS_REQUEST_SUCCESS,
  payload,
} as const);

export const contractorsCountRequest = () => ({
  type: CONTRACTORS_SERVED_COUNT_REQUEST,
} as const);

export const contractorsCountRequestFailed = (payload: ErrorMessage) => ({
  type: CONTRACTORS_SERVED_COUNT_REQUEST_FAILED,
  payload,
} as const);

export const contractorsCountRequestSuccess = (payload: number) => ({
  type: CONTRACTORS_SERVED_COUNT_REQUEST_SUCCESS,
  payload,
} as const);
