import { createStyles } from '@material-ui/core';

export default () => createStyles({
  root: {
    width: 'unset',
    borderCollapse: 'collapse',

    '& thead.MuiTableHead-root': {
      '& tr.MuiTableRow-root': {
        '& th.MuiTableCell-root': {
          border: '1px solid #040620',
          color: '#040620',
          padding: '5px 40px 5px 16px',
          fontSize: 14,
        },
      },
    },

    '& tbody.MuiTableBody-root': {
      '& tr.MuiTableRow-root': {
        '& td.MuiTableCell-root': {
          border: '1px solid #040620',
          padding: '5px 40px 5px 16px',
        },
      },
    },
  },

  bodyClass: {
    height: 150,
    display: 'block',
  },

  bill: {
    width: 300,
    marginBottom: 50,
  },

  hideBorder: {
    border: '0px solid #040620 !important',
    padding: '0 0 0 8px !important',
  },

  border: {
    border: '1px solid #040620',
  },

  alignLeft: {
    alignSelf: 'flex-start',
    marginLeft: 30,
  },

  periodWidth: {
    width: '36%',
  },
});
