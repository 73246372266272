import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  container: {
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    backgroundColor: '#fff',
    paddingTop: 10,
    '& > div': {
      paddingLeft: 43,
      paddingRight: 43,
    }
  },
  header: {
    paddingTop: 25,
    backgroundColor: '#E9ECF2',
  },
  titleWrapper: {
    margin: '32px 0',
  },
  title: {
    fontSize: 28,
  },
  content: {
    height: '100vh'
  },
  input: {
    margin: 5
  },
  buttonContainer: {
    margin: '32px 0',
    height: 80
  }
});

const useStyles = makeStyles(styles);

export default useStyles;