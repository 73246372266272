import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import InputProperty from './PropertyInput';
import styles from './styles';
import { catalogRequest } from '../../redux/actions/catalog';
import { statesRequest } from '../../redux/actions/states';
import { createProperty } from '../../redux/actions/properties';

import { AppState } from '../../redux/store';
import { propertyDispositionCatalog, lockStatusCatalog, selectCatalogRequestStatus } from '../../redux/selectors/catalog';
import { selectStates, selectStatesRequestStatus } from '../../redux/selectors/states';
import {
  getBuilderName, getBuilderId, selectIsRental, getEnterpriseId,
  selectEnterpriseStatus,
} from '../../redux/selectors/auth';
import { selectBuilderCommunities, selectCommunitiesRequestStatus, selectCommunitySelected } from '../../redux/selectors/communities';
import { availableAgentsRequest } from '../../redux/actions/agents';
import { builderIntegrationsRequest, enterpriseBuildersRequest } from '../../redux/actions/builders';
import { selectBuilderIntegrations, selectBuilders, selectBuildersRequestStatus } from '../../redux/selectors/builders';
import { selectTeams } from '../../redux/selectors/teams';
import { teamsByBuilderRequest, teamsRequest } from '../../redux/actions/teams';
import { getCommunityDetails } from '../../redux/actions/communities';

const mapStateToProps = (state: AppState) => ({
  loadingCatalog: selectCatalogRequestStatus(state),
  dispositionCatalog: propertyDispositionCatalog(state),
  lockStatusCatalog: lockStatusCatalog(state),
  states: selectStates(state),
  loadingStates: selectStatesRequestStatus(state),
  builder: getBuilderName(state),
  builder_id: getBuilderId(state),
  isEnterprise: selectEnterpriseStatus(state.auth),
  enterpriseId: getEnterpriseId(state),
  enterpriseBuilders: selectBuilders(state),
  loadingEnterpriseBuilders: selectBuildersRequestStatus(state),
  communities: selectBuilderCommunities(state),
  builderIntegrations: selectBuilderIntegrations(state),
  teams: selectTeams(state),
  communityDetails: selectCommunitySelected(state),
  isRental: selectIsRental(state.auth),
  loadingCommunity: selectCommunitiesRequestStatus(state),
});

const mapDispatchToProps = {
  loadCatalog: catalogRequest,
  loadStates: statesRequest,
  loadAvailableAgents: availableAgentsRequest,
  loadIntegrations: builderIntegrationsRequest,
  createProperty,
  loadTeams: teamsRequest,
  loadTeamsByBuilder: teamsByBuilderRequest,
  loadCommunityDetails: getCommunityDetails,
  loadEnterpriseBuilders: enterpriseBuildersRequest,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InputProperty));
