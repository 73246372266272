/* eslint-disable quotes */
import { connect } from "react-redux";
import { AppState } from "../../redux/store";
import CommunityInfoCard from "./CommunityInfoCard";
import {
  getCommunityDetails,
  saveCommunity,
  cancelSuccessfullySave,
  getCommunitiesStatuses,
} from "../../redux/actions/communities";
import {
  getCommunityStatuses,
  selectCommunitiesRequestStatus,
  selectSavedStatus,
} from "../../redux/selectors/communities";
import { selectStates } from "../../redux/selectors/states";
import { selectIsPM, getBuilderId } from "../../redux/selectors/auth";
import { selectAvailableAgents } from "../../redux/selectors/agents";

const mapStateToProps = (state: AppState) => ({
  loading: selectCommunitiesRequestStatus(state),
  states: selectStates(state),
  successfullySaved: selectSavedStatus(state),
  isPM: selectIsPM(state),
  builderId: getBuilderId(state),
  communityStatuses: getCommunityStatuses(state),
  agents: selectAvailableAgents(state),
});

const mapDispatchToProps = {
  loadCommunitiesStatuses: getCommunitiesStatuses,
  loadCommunityDetails: getCommunityDetails,
  communitySave: saveCommunity,
  cancelSuccessfullySave,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityInfoCard);
