import { Query } from '../../utils/query';
import {
  SCHEDULED_VISITS_REQUEST,
  SCHEDULED_VISITS_REQUEST_SUCCESS,
  SCHEDULED_VISIT_DETAILS_REQUEST,
  SCHEDULED_VISIT_DETAILS_REQUEST_SUCCESS,
  DELETE_SCHEDULED_VISIT_REQUEST,
  EXPORT_SCHEDULED_VISITS_REQUEST,
  ScheduledVisitsRequestSuccess,
  ScheduledVisit,
} from '../types/scheduledVisit';
import { MimeType } from '../types/mime';

export const scheduledVisitsRequest = (query?: Query) => ({
  type: SCHEDULED_VISITS_REQUEST,
  payload: query,
} as const);

export const scheduledVisitsRequestSuccess = (payload: ScheduledVisitsRequestSuccess) => ({
  type: SCHEDULED_VISITS_REQUEST_SUCCESS,
  payload,
} as const);

export const scheduledVisitDetailsRequest = (visitId: number) => ({
  type: SCHEDULED_VISIT_DETAILS_REQUEST,
  payload: visitId,
} as const);

export const scheduledVisitDetailsRequestSuccess = (visit: ScheduledVisit) => ({
  type: SCHEDULED_VISIT_DETAILS_REQUEST_SUCCESS,
  payload: visit,
} as const);

export const deleteScheduledVisits = (selected: number[]) => ({
  type: DELETE_SCHEDULED_VISIT_REQUEST,
  payload: selected,
} as const);

export const exportScheduledVisits = (selected: number[], mimeType: MimeType = 'text/csv') => ({
  type: EXPORT_SCHEDULED_VISITS_REQUEST,
  payload: { selected, mimeType },
} as const);
