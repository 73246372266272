/* eslint-disable quotes */
import { connect } from "react-redux";
import { AppState } from "../../redux/store";
import CommunityDetails from "./CommunityDetails";
import {
  getCommunityDetails,
  saveCommunity,
  cancelSuccessfullySave,
  saveQRCodeData,
  getQRCodes,
  QRCodeDeleteRequest,
} from "../../redux/actions/communities";
import {
  selectCommunitySelected,
  selectCommunitiesRequestStatus,
  selectQRCodesData,
} from "../../redux/selectors/communities";
import { selectStates } from "../../redux/selectors/states";
import { selectIsPM, getBuilderId, selectEnterpriseStatus } from "../../redux/selectors/auth";
import { teamsByBuilderRequest, teamsRequest } from "../../redux/actions/teams";
import { availableAgentsRequest } from "../../redux/actions/agents";
import { selectTeams } from "../../redux/selectors/teams";
import { selectAgents, selectAvailableAgents } from "../../redux/selectors/agents";

const mapStateToProps = (state: AppState) => ({
  communityDetails: selectCommunitySelected(state),
  loading: selectCommunitiesRequestStatus(state),
  states: selectStates(state),
  isPM: selectIsPM(state),
  builderId: getBuilderId(state),
  QRCodes: selectQRCodesData(state),
  agents: selectAgents(state),
  availableAgents: selectAvailableAgents(state),
  teams: selectTeams(state),
  isEnterprise: selectEnterpriseStatus(state.auth),
});

const mapDispatchToProps = {
  loadCommunityDetails: getCommunityDetails,
  loadQRCodes: getQRCodes,
  communitySave: saveCommunity,
  saveQRCode: saveQRCodeData,
  deleteQRCode: QRCodeDeleteRequest,
  cancelSuccessfullySave,
  loadAvailableAgents: availableAgentsRequest,
  loadTeams: teamsRequest,
  loadTeamsByBuilder: teamsByBuilderRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityDetails);
