import { ErrorMessage, ValueOf } from '../../types/main';
import * as SurveyActions from '../actions/survey';

export const SURVEY_ADD_QUESTION = 'SURVEY_ADD_QUESTION';
export const SURVEY_ADD_QUESTION_SUCCESS = 'SURVEY_ADD_QUESTION_SUCCESS';
export const SURVEY_ADD_QUESTION_FAILED = 'SURVEY_ADD_QUESTION_FAILED';
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION';
export const SURVEY_DELETE_QUESTION_SUCCESS = 'SURVEY_DELETE_QUESTION_SUCCESS';
export const SURVEY_DELETE_QUESTION_FAILED = 'SURVEY_DELETE_QUESTION_FAILED';
export const SURVEY_QUESTIONS = 'SURVEY_QUESTIONS';
export const SURVEY_QUESTIONS_SUCCESS = 'SURVEY_QUESTIONS_SUCCESS';
export const SURVEY_QUESTIONS_FAILED = 'SURVEY_QUESTIONS_FAILED';
export const SURVEY_QUESTIONS_UPDATE = 'SURVEY_QUESTIONS_UPDATE';
export const SURVEY_QUESTIONS_UPDATE_SUCCESS = 'SURVEY_QUESTIONS_UPDATE_SUCCESS';
export const SURVEY_QUESTIONS_UPDATE_FAILED = 'SURVEY_QUESTIONS_UPDATE_FAILED';

export type SurveyActionsTypes = ReturnType<ValueOf<typeof SurveyActions>>;

export type SurveyQuestion = {
  text?: string;
  type?: number;
  choices?: string[];
  questionId?: number;
  index?: number;
};

export interface SurveyState {
  questions: SurveyQuestion[];
  loading: boolean;
  updating: boolean;
  error: ErrorMessage['message'];
}

export default interface SurveyResult {
  question: string;
  answer: string;
}
