import { connect } from 'react-redux';
import CommunitySearch from './CommunitySearch';
import { getBuilderCommunities } from '../../redux/actions/communities';
import { AppState } from '../../redux/store';
import { selectCommunityExternalIds, selectBuilderCommunities } from '../../redux/selectors/communities';

const mapStateToProps = (state: AppState) => ({
  communityExternalIds: selectCommunityExternalIds(state),
  builderCommunities: selectBuilderCommunities(state),
});

const mapDispatchToProps = {
  getBuilderCommunities,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunitySearch);
