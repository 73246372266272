import React, { useEffect } from 'react';
import Tab from '@material-ui/core/Tab';
import {
  Switch, Route, RouteComponentProps, Redirect,
} from 'react-router-dom';

import { WithStyles } from '@material-ui/core/styles';
import NotFound from '../../pages/NotFound';
import Tabs from '../../components/CustomTabs';
import Invoices from '../Invoices';
import PastDueInvoices from '../PastDueInvoices';
import PaidInvoices from '../PaidInvoices';

import styles from './styles';

type Props = {
  loadInvoices: (payload: object)=> {readonly type: 'BILLING_REQUEST'; readonly payload: object;};
} & WithStyles<typeof styles> & RouteComponentProps;

const tabs: Record<string, string> = {
  invoices: 'Invoices',
  'past-due': 'Past due',
  paid: 'Paid',
};

const defaultTab = 'invoices';

const Billing: React.FC<Props> = props => {
  const {
    classes, location, history, match, loadInvoices,
  } = props;

  useEffect(() => {
    loadInvoices({ });
  }, [loadInvoices]);

  const toggleTab = (key: string) => () => {
    history.push(key);
  };
  const defaultPath = `${match.path}/invoices`;

  return (
    <div>
      <Tabs classes={{ root: classes.root }} value={location.pathname.split('/')[3] || defaultTab}>
        {
        Object.entries(tabs)
          .map(([key, label]) => (
            <Tab
              key={key}
              value={key}
              label={label}
              onClick={toggleTab(key)}
            />
          ))
    }
      </Tabs>

      <Switch>
        <Route exact path={`${match.path}`} component={() => <Redirect to={defaultPath} />} />
        <Route exact path={`${match.path}/invoices`} component={Invoices} />
        <Route exact path={`${match.path}/past-due`} component={PastDueInvoices} />
        <Route exact path={`${match.path}/paid`} component={PaidInvoices} />

        <Route component={NotFound} />
      </Switch>
      <div />
    </div>
  );
};

export default Billing;
