import {
  VisitsAfterTourHoursActionsTypes,
  VisitsAfterTourHoursState,
  VISITS_AFTER_TOUR_HOURS_REQUEST,
  VISITS_AFTER_TOUR_HOURS_REQUEST_FAILED,
  VISITS_AFTER_TOUR_HOURS_REQUEST_SUCCESS,
} from '../types/afterTourHours';

const afterTourHoursVisitsInitialState: VisitsAfterTourHoursState = {
  items: [],
  total: 0,
  error: '',
  loading: false,
};

export default function VisitsAfterTourHoursReducer(
  state = afterTourHoursVisitsInitialState,
  action: VisitsAfterTourHoursActionsTypes,
): VisitsAfterTourHoursState {
  switch (action.type) {
    case VISITS_AFTER_TOUR_HOURS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case VISITS_AFTER_TOUR_HOURS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total,
        error: '',
        loading: false,
      };
    }

    case VISITS_AFTER_TOUR_HOURS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
