import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import FeedbackDialog from './FeedbackDialog';
import { sendFeedback } from '../../redux/actions/feedback';

import styles from './styles';

const mapDispatchToProps = {
  sendUserFeedback: sendFeedback,
};

export default withStyles(styles)(connect(null, mapDispatchToProps)(FeedbackDialog));
