import { createStyles, makeStyles } from '@material-ui/core';

const styles = () => createStyles({
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
