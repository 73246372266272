import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  OFFERS_REQUEST,
  OFFERS_EXPORT_REQUEST,
  OFFERS_EXPORT_AND_PRINT_REQUEST,
  OFFER_DETAILS_REQUEST, OffersExportRequestActionType,
} from '../types/offers';

import { loadOffers, loadOffersExport, loadOfferDetails } from '../../services/offers';
import {
  offersRequestSuccess,
  offersRequestFailed,
  offersExportSuccess,
  offersExportFailed,
  offerDetailsRequestSuccess,
  offerDetailsRequest,
  offersRequest,
} from '../actions/offers';
import { openPrintDialog } from '../../services/print';
import { exportToFile } from '../../services/export';
import { createErrorSnackBar } from '../actions/snackbars';

function* loadOffersSaga(action: ReturnType<typeof offersRequest>) {
  try {
    const { data } = yield call(loadOffers, action.payload);
    yield put(offersRequestSuccess(data));
  } catch (err) {
    yield put(offersRequestFailed(err));
  }
}

function* loadOffersExportSaga(
  action: OffersExportRequestActionType,
) {
  try {
    const { selected, mimeType } = action.payload;
    const { data } = yield call(loadOffersExport, selected, mimeType);
    const fileType = { type: mimeType } as const;
    const blob = new Blob([data], fileType);

    if (blob && action.type === OFFERS_EXPORT_AND_PRINT_REQUEST) {
      openPrintDialog(blob);
    } else {
      const fileName = 'offers-export.csv';

      yield call(exportToFile, blob, fileType, fileName);
    }

    yield put(offersExportSuccess());
  } catch (err) {
    yield put(offersExportFailed(err));
  }
}

function* loadOfferDetailsSaga(action: ReturnType<typeof offerDetailsRequest>) {
  try {
    const { data } = yield call(loadOfferDetails, action.payload);
    yield put(offerDetailsRequestSuccess(data));
  } catch (err) {
    yield put(createErrorSnackBar(err.response.data.message));
  }
}


export default all([
  takeLatest<any>(OFFERS_REQUEST, loadOffersSaga),
  takeLatest<any>(
    [OFFERS_EXPORT_REQUEST, OFFERS_EXPORT_AND_PRINT_REQUEST],
    loadOffersExportSaga,
  ),
  takeLatest<any>(
    OFFER_DETAILS_REQUEST,
    loadOfferDetailsSaga,
  ),
]);
