import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell, { TableCellProps } from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { Order, countEmptyRows } from '../../utils/table';
import CustomTableRow from '../CustomTableRow';

export type HeaderRowConfig<T extends object> = {
  key: keyof T,
  align: TableCellProps['align'],
  disablePadding: boolean,
  label: string,
  prepareData?: (data: T) => string | number,
  render?: (data: T) => React.ReactNode,
};

export type CustomTableProps<T extends object> = {
  hover?: boolean,
  className?: string,
  header: HeaderRowConfig<T>[],
  data: T[],
  order: Order,
  orderBy: keyof T,
  IDKey: keyof T,
  rowsPerPage: number,
  // you need provide none or both 'selected' & 'onSelect'
  selected?: any[],
  onSelect?: (selected: any[]) => void,
  onChangeOrder: (newOrder: Order, newOrderBy: keyof T) => void,
  onRowClick?: (rowData: T, shiftPressed?: boolean) => void,
};

const InputProps = { 'aria-label': 'Select all Properties' };

const CustomTable = <T extends object>(props: CustomTableProps<T>) => {
  const {
    className,
    header,
    data,
    order,
    orderBy,
    onSelect,
    onChangeOrder,
    onRowClick,
    selected = [],
    IDKey,
    rowsPerPage,
    hover,
  } = props;
  const rowCount = data.length;
  const numSelected = selected.length;
  const Indeterminate = numSelected > 0 && numSelected < rowCount;
  const selectedAll = numSelected === rowCount;
  const emptyRows = countEmptyRows(rowCount, rowsPerPage);
  const emptyRowsStyle = React.useMemo(() => ({ height: 49 * emptyRows }), [emptyRows]);
  const colSpan = onSelect ? header.length + 1 : header.length;
  const visitCreatedHeader = 'platform';
  const dummy = () => {};

  function handleRequestSort(
    key: keyof T,
  ) {
    return (event: React.MouseEvent<HTMLTableHeaderCellElement, MouseEvent>) => {
      event.preventDefault();

      const isDesc = orderBy === key && order === 'desc';

      onChangeOrder(isDesc ? 'asc' : 'desc', key);
    };
  }

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (!onSelect) {
      return;
    }

    if (event.target.checked) {
      const newSelected = data.map((d) => d[IDKey]);
      onSelect(newSelected);
      return;
    }

    onSelect([]);
  }

  const extractId = (rowData: any) => rowData._id || rowData.id || rowData.customer_id || rowData.property_id;
  const [lastChecked, setLastChecked] = useState<number|null>(null);
  const ids = data.map((prop) => extractId(prop));

  const handleShiftSelectClick = (id: number, event: any) => {
    if (!onSelect) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    let newSelected: number[] = [];

    if (!lastChecked || !event.shiftKey) {
      setLastChecked(id);
      const selectedIndex = selected.indexOf(id);
      newSelected = getNewSelected(selectedIndex, id);
      onSelect(newSelected);

      return;
    }

    if (event.shiftKey) {
      const start = ids.indexOf(id);
      const end = ids.indexOf(lastChecked);
      const chosen = ids.slice(Math.min(start, end), Math.max(start, end) + 1);
      if (selected.indexOf(id) === -1) {
        newSelected = [...selected, ...chosen];
      } else {
        newSelected = selected.filter((ind) => !chosen.includes(ind));
      }

      onSelect([...new Set(newSelected)]);
    }

    setLastChecked(id);
  };

  const selectClick = (id: number) => (event: any) => {
    handleShiftSelectClick(id, event);
  };

  const getNewSelected = (selectedIndex: number, currentSelect: number) => {
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, currentSelect);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    return newSelected;
  };

  const handleRowClick = (rowData: any) => (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (onRowClick) {
      onRowClick(rowData);
    }
  };

  const renderTableRow = (rowData: T, i: number) => {
    const labelId: string = `enhanced-table-checkbox-${i}`;

    return (
      <CustomTableRow
        key={i}
        hover={hover}
        rowData={rowData}
        handleRowClick={handleRowClick}
        IDKey={IDKey}
        handleSelectClick={selectClick}
        onSelect={onSelect}
        selected={selected}
        labelId={labelId}
        header={header}
      />
    );
  };
  return (
    <Table className={className}>
      <TableHead>
        <TableRow>
          {onSelect && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={Indeterminate}
                checked={selectedAll}
                onChange={handleSelectAllClick}
                inputProps={InputProps}
              />
            </TableCell>
          )}
          {header.map((h) => {
            const isPlatform = h.key !== visitCreatedHeader;
            return (
              <TableCell
                key={String(h.key)}
                align={h.align}
                padding={h.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === h.key ? order : false}
                onClick={isPlatform ? handleRequestSort(h.key) : dummy}
                style={{ color: '#979797' }}
              >
                {h.label}
                {isPlatform && (
                  <TableSortLabel
                    active={orderBy === h.key}
                    direction={order}
                    IconComponent={ArrowIcon}
                  />
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(renderTableRow)}
        {emptyRows > 0 && (
          <TableRow style={emptyRowsStyle}>
            <TableCell colSpan={colSpan} />
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default CustomTable;
