import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import IntegrationDialog from './IntegrationDialog';
import { sendIntegrtation } from '../../redux/actions/integrations';

import styles from './styles';

const mapDispatchToProps = {
  sendIntegrationRequest: sendIntegrtation,
};

export default withStyles(styles)(connect(null, mapDispatchToProps)(IntegrationDialog));
