import {
  Checkbox,
  Divider,
  Grid,
  TextField,
  Typography,
  WithStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { useRouteMatch } from 'react-router-dom';
import { Agent } from '../../redux/types/agents';
import { Team } from '../../redux/types/teams';
import AvailableAgents from '../AvailableAgents';
import styles from './styles';

type Props = {
  handlePreviousPage?: () => void;
  handleNextPage?: () => void;
  handleAddUsers: (agents: Agent[], teams?: number[]) => void;
  availableAgents: Agent[];
  username: string;
  teams: Team[];
  teamIds?: number[];
  agents?: Agent[];
} & WithStyles<typeof styles>;

const Staff = (props: Props) => {
  const {
    classes,
    handleAddUsers,
    availableAgents: availableAg,
    username,
    teams,
    teamIds,
    agents,
  } = props;

  const [availableAgents, setAvailableAgents] = useState<Array<Agent>>([]);
  const [assignedAgents, setAssignedAgents] = useState<Array<Agent>>([]);
  const [selectedTeams, setSelectedTeams] = useState<Array<Team>>([]);
  const [newAgent, setNewAgent] = useState<Agent>({
    firstname: '',
    lastname: '',
    username: '',
    is_project_manager: false,
  });

  const { path } = useRouteMatch();
  const isCommunitiesPath = path.split('/')[1] === 'communities';
  const isNewProperty = !isCommunitiesPath && path.split('/')[2] === 'add';

  useEffect(() => {
    setAvailableAgents(availableAg);
  }, [availableAg]);

  useEffect(() => {
    const defaultAssignedAgent = availableAgents.find(
      (agent: Agent) => agent?.username === username,
    );

    const isUserAssigned = assignedAgents?.find(
      (agent) => agent.username === defaultAssignedAgent?.username
    );

    if (
      !assignedAgents.length
      && !agents?.length
      && !isCommunitiesPath
      && defaultAssignedAgent
    ) {
      setAssignedAgents([defaultAssignedAgent]);
      handleAddUsers([defaultAssignedAgent]);
    }

    if (agents?.length && !assignedAgents.length) {
      setAssignedAgents(agents);
      handleAddUsers(agents);
    }

    if (
      assignedAgents.length
      && defaultAssignedAgent
      && !isUserAssigned
      && !isCommunitiesPath
      && isNewProperty
    ) {
      setAssignedAgents([...assignedAgents, defaultAssignedAgent]);
      handleAddUsers([...assignedAgents, defaultAssignedAgent]);
    }
  }, [
    availableAgents,
    assignedAgents,
    agents,
    username,
    handleAddUsers,
    isCommunitiesPath,
    isNewProperty,
  ]);

  useEffect(() => {
    if (teamIds && teamIds.length > 0) {
      const selected = teams.filter((tm) => teamIds?.includes(tm.id));

      setSelectedTeams(selected);

      const usernames: string[] = [];
      const teamsAgents = selected.flatMap((team: Team) => team.agents);
      const filteredAssignedTeamsAgents = availableAg.filter((agent: Agent) => teamsAgents.find((teamAgent: any) => {
        const thisUsername = agent.username;

        if (!usernames.includes(thisUsername)) {
          if (thisUsername === teamAgent.agent_username) {
            usernames.push(thisUsername);
            return true;
          }
        }

        return false;
      }));

      if (assignedAgents.length < 1 && isNewProperty) {
        if (filteredAssignedTeamsAgents.length > 0) {
          setAssignedAgents(filteredAssignedTeamsAgents);
        }
      }
    }
  }, [teamIds, teams, availableAg, assignedAgents, isNewProperty]);

  const handleInputChange = (value: Agent) => {
    setNewAgent((prevState: Agent) => ({
      ...prevState,
      ...value,
    }));
  };

  const onAddAgent = () => {
    setAssignedAgents((prevAssigned) => [...prevAssigned, newAgent]);
    setAvailableAgents((prevState) => prevState.filter((el) => el?.username !== newAgent.username));
    setNewAgent({
      firstname: '',
      lastname: '',
      username: '',
      is_project_manager: newAgent.is_project_manager,
    });
    handleAddUsers([...assignedAgents, newAgent]);
  };

  const onRemoveAgent = (agent: Agent) => {
    const filteredAssignedAgents = assignedAgents.filter(
      (propertyAgent) => propertyAgent.username !== agent.username,
    );
    const filteredAvailableAgents = availableAgents.filter(
      (el) => el?.username !== agent.username,
    );

    setAssignedAgents(filteredAssignedAgents);
    setAvailableAgents([...filteredAvailableAgents, agent]);
    handleAddUsers(filteredAssignedAgents);
  };

  const handleTeamSelect = (e: React.ChangeEvent<{}>, newTeams: Team[]) => {
    const teamsAgents = newTeams.flatMap((team: Team) => team.agents);
    const newTeamIds = newTeams.map((team: Team) => team.id);

    var usernames: string[] = [];

    const filteredAssignedTeamsAgents = availableAg.filter((agent: Agent) => 
      teamsAgents.find((teamAgent: any) => {
        const thisUsername = agent.username;
          if (!usernames.includes(thisUsername)) {
            if (thisUsername === teamAgent.agent_username) {
              usernames.push(thisUsername);
              return true
            }
          }
          return false
        })
    );

    setSelectedTeams(newTeams);
    setAssignedAgents(filteredAssignedTeamsAgents);
    handleAddUsers(
      filteredAssignedTeamsAgents,
      newTeamIds,
    );
  };

  return (
    <>
      <Grid container className={classes.inputForm}>
        <Grid item xs>
          <Autocomplete
            multiple
            options={teams}
            disableCloseOnSelect
            disableClearable
            getOptionLabel={(team: Team) => team.name}
            value={selectedTeams}
            renderTags={() => null}
            noOptionsText="No record exist!"
            onChange={handleTeamSelect}
            renderOption={(team: Team, { selected }) => {
              const checked = teamIds?.includes(team.id);

              return (
                <>
                  <Checkbox
                    style={{ marginRight: 8 }}
                    checked={checked || selected}
                  />
                  <>
                    <span>{team.name}</span>
                  </>
                </>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                style={{ width: '100%', marginBottom: '40px' }}
                variant="standard"
                label="Add Team"
                placeholder="Search Team"
              />
            )}
          />
          {selectedTeams.length > 0 && (
            <>
              {selectedTeams.map((team) => (
                <Grid container key={team.id}>
                  <CheckIcon className={classes.icon} />
                  <Typography className={classes.text}>{team.name}</Typography>
                </Grid>
              ))}
            </>
          )}
        </Grid>
        <Divider
          orientation="vertical"
          style={{ margin: '0 20px', height: '504px' }}
        />
        <Grid item xs style={{ height: '546px', overflowY: 'scroll' }}>
          <AvailableAgents
            availableAgents={availableAgents}
            assignedAgents={assignedAgents}
            handleInputChange={handleInputChange}
            onAddAgent={onAddAgent}
            newAgent={newAgent}
            onRemoveAgent={onRemoveAgent}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Staff;
