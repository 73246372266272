export const pagesWithDateFilter = ['visits', 'reports'] as const;

export const customerStatuses = ['Contact', 'No contact'] as const;

export const customerLastStatuses = ['Accessed', 'NC', 'NA-V', 'NA-T', 'NA-L'] as const;

export const visitorTypes = ['V', 'CL', 'AG', 'TC'] as const;

export const propertyLockStatuses = ['Active', 'Awaiting Repair', 'Broken', 'Down', 'Lost', 'Maintenance', 'Retired', 'Unassigned'] as const;

export const propertyLockDispositions = ['Accessible', 'Awaiting Activation', 'Created', 'Removed', 'Sold', 'Non-Accessible', 'Under Contract'] as const;

export const visitsLastStatuses = ['Accessed', 'Deny', 'NA-I', 'NA-L', 'NA-T', 'NA-V', 'NC'] as const;

export const inventoryLockStatuses = [
  'Activated', 'Active', 'Awaiting Repair', 'Broken', 'Created',
  'Down', 'Installed', 'Lost', 'Maintenance', 'Ready', 'Retired',
  'To be returned', 'Unassigned'] as const;
