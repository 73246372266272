import {
  CUSTOMER_DETAILS_REQUEST,
  CUSTOMER_DETAILS_REQUEST_SUCCESS,
  CUSTOMER_DETAILS_REQUEST_FAILED,
  CUSTOMER_SELECTED_CLEAR,
  CUSTOMERS_REQUEST,
  CUSTOMERS_REQUEST_SUCCESS,
  CUSTOMERS_REQUEST_FAILED,
  CUSTOMERS_EXPORT_REQUEST,
  CUSTOMERS_EXPORT_REQUEST_SUCCESS,
  CUSTOMERS_EXPORT_REQUEST_FAILED,
  CUSTOMERS_EXPORT_AND_PRINT_REQUEST,
  CustomersRequestSuccess,
  CustomerDetailsRequestSuccess,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_REQUEST_FAILED,
  UPDATE_CUSTOMER_REQUEST_SUCCESS,
  CustomerInfo,
  UpdateCustomer,
} from '../types/customers';
import { Query } from '../../utils/query';
import { MimeType } from '../types/mime';
import { ErrorMessage } from '../../types/main';

export const customersRequest = (query?: Query) => ({
  type: CUSTOMERS_REQUEST,
  payload: query,
} as const);

export const customersRequestFailed = (payload: ErrorMessage) => ({
  type: CUSTOMERS_REQUEST_FAILED,
  payload,
} as const);

export const customersRequestSuccess = (payload: CustomersRequestSuccess) => ({
  type: CUSTOMERS_REQUEST_SUCCESS,
  payload,
} as const);

export const customersExportAndPrintRequest = (selection: Array<string | number>, mimeType: MimeType = 'application/pdf') => ({
  type: CUSTOMERS_EXPORT_AND_PRINT_REQUEST,
  payload: {
    selection,
    mimeType,
  },
} as const);

export const customersExportRequest = (selection: Array<string | number>, mimeType: MimeType = 'text/csv') => ({
  type: CUSTOMERS_EXPORT_REQUEST,
  payload: {
    selection,
    mimeType,
  },
} as const);

export const customersExportRequestFailed = (payload: ErrorMessage) => ({
  type: CUSTOMERS_EXPORT_REQUEST_FAILED,
  payload,
} as const);

export const customersExportRequestSuccess = () => ({
  type: CUSTOMERS_EXPORT_REQUEST_SUCCESS,
} as const);

export const customerDetailsRequest = (payload: number) => ({
  type: CUSTOMER_DETAILS_REQUEST,
  payload,
} as const);

export const customerDetailsRequestFailed = (payload: ErrorMessage) => ({
  type: CUSTOMER_DETAILS_REQUEST_FAILED,
  payload,
} as const);

export const customerDetailsRequestSuccess = (
  payload: CustomerDetailsRequestSuccess,
) => ({
  type: CUSTOMER_DETAILS_REQUEST_SUCCESS,
  payload,
} as const);

export const customerDetailsClear = () => ({
  type: CUSTOMER_SELECTED_CLEAR,
} as const);

export const updateCustomerRequest = (payload: UpdateCustomer) => ({
  type: UPDATE_CUSTOMER_REQUEST,
  payload,
} as const);

export const updateCustomerRequestFailed = (payload: ErrorMessage) => ({
  type: UPDATE_CUSTOMER_REQUEST_FAILED,
  payload,
} as const);

export const updateCustomerRequestSuccess = (
  payload: CustomerInfo,
) => ({
  type: UPDATE_CUSTOMER_REQUEST_SUCCESS,
  payload,
} as const);
