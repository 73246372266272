import {
  BUILDERS_REQUEST_SUCCESS,
  BUILDERS_REQUEST_FAILED,
  BUILDERS_REQUEST,
  ENTERPRISE_BUILDERS_REQUEST,
  ENTERPRISE_BUILDERS_REQUEST_SUCCESS,
  ENTERPRISE_BUILDERS_REQUEST_FAILED,
  BuildersState, BuildersActionsTypes, BUILDER_INTEGRATIONS_REQUEST, BUILDER_INTEGRATIONS_SUCCESS, BUILDER_INTEGRATIONS_FAILED, BUILDER_TIMEZONE_REQUEST, BUILDER_TIMEZONE_SUCCESS, BUILDER_TIMEZONE_FAILED, BUILDER_TIMEZONE_UPDATE_REQUEST, BUILDER_TIMEZONE_UPDATE_FAILED, BUILDER_TIMEZONE_UPDATE_SUCCESS, BUILDER_FEED_SETTINGS_REQUEST, BUILDER_FEED_SETTINGS_REQUEST_SUCCESS, BUILDER_FEED_SETTINGS_REQUEST_FAILED,
  ENTERPRISE_USER_BUILDERS_REQUEST,
  ENTERPRISE_USER_BUILDERS_REQUEST_SUCCESS,
  ENTERPRISE_USER_BUILDERS_REQUEST_FAILED,
} from '../types/builders';

const initialState: BuildersState = {
  items: [],
  error: '',
  loading: false,
  selected: null,
  integrations: null,
  builderTimezone: null,
  feedSettings: null,
};

export default function BuilderReducer(
  state = initialState,
  action: BuildersActionsTypes,
): BuildersState {
  switch (action.type) {
    case BUILDERS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case BUILDERS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        error: '',
        loading: false,
      };
    }

    case BUILDERS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case ENTERPRISE_USER_BUILDERS_REQUEST:
    case ENTERPRISE_BUILDERS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case ENTERPRISE_USER_BUILDERS_REQUEST_SUCCESS:
    case ENTERPRISE_BUILDERS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        error: '',
        loading: false,
      };
    }

    case ENTERPRISE_USER_BUILDERS_REQUEST_FAILED:
    case ENTERPRISE_BUILDERS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case BUILDER_INTEGRATIONS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case BUILDER_INTEGRATIONS_SUCCESS: {
      return {
        ...state,
        integrations: action.payload,
        error: '',
        loading: false,
      };
    }

    case BUILDER_INTEGRATIONS_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case BUILDER_TIMEZONE_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case BUILDER_TIMEZONE_SUCCESS: {
      return {
        ...state,
        builderTimezone: action.payload,
        error: '',
        loading: false,
      };
    }

    case BUILDER_TIMEZONE_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case BUILDER_TIMEZONE_UPDATE_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case BUILDER_TIMEZONE_UPDATE_SUCCESS: {
      return {
        ...state,
        builderTimezone: action.payload,
        error: '',
        loading: false,
      };
    }

    case BUILDER_TIMEZONE_UPDATE_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case BUILDER_FEED_SETTINGS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case BUILDER_FEED_SETTINGS_REQUEST_SUCCESS: {
      return {
        ...state,
        feedSettings: action.payload,
        error: '',
        loading: false,
      };
    }

    case BUILDER_FEED_SETTINGS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
