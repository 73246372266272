import React from 'react';
import { WithStyles } from '@material-ui/core';
import classNames from 'classnames';
import styles from './styles';

type Props = {
  color?: 'gray' | 'orange' | 'black' | 'white';
  title?: string,
  subtitle?: string,
  iconColor?:'gray' | 'orange' | 'black' | 'white';
} & WithStyles<typeof styles>;

const CustomCardHeader: React.FC<Props> = props => {
  const {
    classes,
    color,
    title,
    subtitle,
  } = props;

  return (
    <div className={classNames(classes.root)}>
      <span className={classNames(classes.title)}>{title || ''}</span>
      <span className={classNames(classes.subTitle, classes[color || 'gray'])}>{subtitle || ''}</span>
    </div>
  );
};

export default CustomCardHeader;
