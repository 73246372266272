import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import HubPanel from './HubPanel';
import styles from './styles';
import { getHubDevicesRequest, hubRebootRequest } from '../../redux/actions/hubs/hubs';
import { AppState } from '../../redux/store';
import { selectHubHasDevices, selectHubLoading } from '../../redux/selectors/hubs';

const styledComponent = withStyles(styles)(HubPanel);

const mapStateToProps = (state: AppState) => ({
  loading: selectHubLoading(state),
  hubHasDevices: selectHubHasDevices(state),
});

const mapDispatchToProps = { 
  loadHubDevices: getHubDevicesRequest,
  rebootHub: hubRebootRequest,
 };

export default (connect(mapStateToProps, mapDispatchToProps)(styledComponent));
