import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  AGENTS_REQUEST,
  AGENT_DETAILS_REQUEST,
  ADD_AGENT_REQUEST,
  UPDATE_AGENT_REQUEST,
  UPDATE_PROFILE_REQUEST,
  DELETE_AGENTS_REQUEST,
  RESEND_EMAIL_REQUEST,
  AVAILABLE_AGENTS_REQUEST,
} from '../types/agents';
import {
  agentsRequest,
  agentsRequestSuccess,
  agentsRequestFailed,
  agentDetailsRequest,
  agentDetailsSuccess,
  agentDetailsFailed,
  addAgentRequest,
  addAgentFailed,
  addAgentSuccess,
  updateAgentRequest,
  updateAgentSuccess,
  updateAgentFailed,
  updateProfileRequest,
  deleteAgentsRequest,
  insertSuggestedUsername,
  resetSuggestedUsername,
  resendEmailFailed,
  resendEmailSuccess,
  availableAgentsRequestSuccess,
  availableAgentsRequestFailed,
  availableAgentsRequest,
} from '../actions/agents';
import {
  createErrorSnackBar,
  createSuccessSnackBar,
} from '../actions/snackbars';
import {
  loadAgents,
  loadAgentById,
  createAgent,
  updateAgentById,
  deleteAgentsByUsername,
  resendEmail,
  loadAvailableAgents,
} from '../../services/agents';
import { navTo } from '../../browserHsitory';
import { updateProfileRequestSuccess } from '../actions/auth';

function* loadAgentsSaga(action: ReturnType<typeof agentsRequest>) {
  try {
    const { data } = yield call(loadAgents, action.payload);
    yield put(agentsRequestSuccess(data));
  } catch (err) {
    yield put(agentsRequestFailed(err));
  }
}

function* loadAvailableAgentsSaga(action: ReturnType<typeof availableAgentsRequest>) {
  try {
    const { data } = yield call(loadAvailableAgents, action.payload);
    yield put(availableAgentsRequestSuccess(data));
  } catch (err) {
    yield put(availableAgentsRequestFailed(err));
  }
}

function* loadAgentDetails(action: ReturnType<typeof agentDetailsRequest>) {
  try {
    const { data } = yield call(loadAgentById, action.payload);
    yield put(agentDetailsSuccess(data));
  } catch (err) {
    yield put(agentDetailsFailed(err.message));
    yield put(
      createErrorSnackBar(err.response ? err.response.data : err.message),
    );
  }
}

function* addAgent(action: ReturnType<typeof addAgentRequest>) {
  try {
    yield call(createAgent, action.payload);
    yield put(addAgentSuccess());
    yield put(createSuccessSnackBar('Agent added successfully'));
    navTo('/agents');
    yield put(resetSuggestedUsername());
  } catch (err) {
    const { data } = err.response;
    yield put(addAgentFailed(data.message));

    if (data.suggestedUsername) {
      yield put(insertSuggestedUsername(data.suggestedUsername));
    }

    yield put(createErrorSnackBar(data ? data.message : err.message));
  }
}

function* resendEmailSaga(action: any) {
  try {
    yield call(resendEmail, action.payload);
    yield put(resendEmailSuccess());
    yield put(createSuccessSnackBar('Success resend email!'));
  } catch (err) {
    const { data } = err.response;
    yield put(resendEmailFailed(data.message));

    yield put(resendEmailFailed(data));
    yield put(
      createErrorSnackBar(
        err.response ? err.response.data.message : err.message,
      ),
    );
  }
}

function* deleteAgents(action: ReturnType<typeof deleteAgentsRequest>) {
  try {
    yield call(deleteAgentsByUsername, action.payload);
    const { data } = yield call(loadAgents);
    yield put(agentsRequestSuccess(data));
    yield put(createSuccessSnackBar('Successfully deleted'));
  } catch (err) {
    yield put(
      createErrorSnackBar(err.response ? err.response.data : err.message),
    );
  }
}

function* updateAgent(action: ReturnType<typeof updateAgentRequest>) {
  try {
    const { username, ...agentData } = action.payload;
    yield call(updateAgentById, agentData, username);
    yield put(updateAgentSuccess());
    yield put(createSuccessSnackBar('Updated successfully'));
  } catch (err) {
    yield put(updateAgentFailed(err.message));
    yield put(
      createErrorSnackBar(
        err.response ? err.response.data.message : err.message,
      ),
    );
  }
}

function* updateProfile(action: ReturnType<typeof updateProfileRequest>) {
  try {
    const { username, ...agentData } = action.payload;
    const { data } = yield call(updateAgentById, agentData, username);
    yield put(updateProfileRequestSuccess(data));
    yield put(createSuccessSnackBar('Updated successfully'));
  } catch (err) {
    yield put(updateAgentFailed(err.message));
    yield put(
      createErrorSnackBar(err.response ? err.response.data : err.message),
    );
  }
}

export default all([
  takeLatest(AGENTS_REQUEST, loadAgentsSaga),
  takeLatest(AVAILABLE_AGENTS_REQUEST, loadAvailableAgentsSaga),
  takeLatest(AGENT_DETAILS_REQUEST, loadAgentDetails),
  takeLatest(ADD_AGENT_REQUEST, addAgent),
  takeLatest(UPDATE_AGENT_REQUEST, updateAgent),
  takeLatest(UPDATE_PROFILE_REQUEST, updateProfile),
  takeLatest(DELETE_AGENTS_REQUEST, deleteAgents),
  takeLatest(RESEND_EMAIL_REQUEST, resendEmailSaga),
]);
