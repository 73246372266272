import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Login from './Login';
import styles from './styles';
import { authUserRequest } from '../../redux/actions/auth';
import { createSuccessSnackBar } from '../../redux/actions/snackbars';
import { selectAuthRequestStatus } from '../../redux/selectors/auth';

import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  loading: selectAuthRequestStatus(state),
});
const mapDispatchToProps = {
  authUserRequest,
  createSuccessSnackBar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Login));
