import { ErrorMessage, ValueOf } from '../../types/main';
import * as scheduledVisitsActions from '../actions/scheduledVisits';

export const SCHEDULED_VISITS_REQUEST = 'SCHEDULEDVISITS_REQUEST';
export const SCHEDULED_VISITS_REQUEST_SUCCESS = 'SCHEDULEDVISITS_REQUEST_SUCCESS';

export const SCHEDULED_VISIT_DETAILS_REQUEST = 'SCHEDULED_VISIT_DETAILS_REQUEST';
export const SCHEDULED_VISIT_DETAILS_REQUEST_SUCCESS = 'SCHEDULED_VISIT_DETAILS_REQUEST_SUCCESS';

export const DELETE_SCHEDULED_VISIT_REQUEST = 'DELETE_SCHEDULED_VISIT_REQUEST';
export const EXPORT_SCHEDULED_VISITS_REQUEST = 'EXPORT_SCHEDULED_VISITS_REQUEST';

export type ScheduledVisitsActionsTypes = ReturnType<ValueOf<typeof scheduledVisitsActions>>;

export interface ScheduledVisit {
  id: number
  first_name: string
  last_name: string
  email: string
  phone: string
  visit_date: string
  comments: string
  source: string
  status: string
  external_id: string
  property_id: number
  timezoneAbbr: number
  timezoneValue?: string
  house_num: number
  address_1: string
  builderName: string
  propertyAgents: string[]
}

export interface ScheduledVisitsRequestSuccess {
  items: ScheduledVisit[]
  total: number
}

export interface ScheduledVisitsState {
  items: ScheduledVisit[]
  selected: ScheduledVisit | null
  total: number
  loading: boolean
  error: ErrorMessage['message']
}
