import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  SCHEDULED_VISITS_REQUEST,
  SCHEDULED_VISIT_DETAILS_REQUEST,
  DELETE_SCHEDULED_VISIT_REQUEST,
  EXPORT_SCHEDULED_VISITS_REQUEST,
  ScheduledVisitsActionsTypes,
} from '../types/scheduledVisit';

import {
  loadScheduledVisits, loadScheduledVisitDetails, deleteScheduleVisits, exportScheduleVisits,
} from '../../services/scheduledVisits';
import {
  scheduledVisitsRequestSuccess,
  scheduledVisitDetailsRequestSuccess,
  deleteScheduledVisits,
  scheduledVisitDetailsRequest,
  exportScheduledVisits as exportScheduledVisitsRequest,
} from '../actions/scheduledVisits';
import { createErrorSnackBar } from '../actions/snackbars';
import { exportToFile } from '../../services/export';

function* loadScheduledVisitsSaga(action: ScheduledVisitsActionsTypes) {
  try {
    const { data } = yield call(loadScheduledVisits, action.payload);
    yield put(scheduledVisitsRequestSuccess(data));
  } catch (err) {
    yield put(createErrorSnackBar(err.response ? err.response.data.message : err.message));
  }
}

function* loadScheduledVisitDetailsSaga(action: ReturnType<typeof scheduledVisitDetailsRequest>) {
  try {
    const { data } = yield call(loadScheduledVisitDetails, action.payload);

    yield put(scheduledVisitDetailsRequestSuccess(data.visit));
  } catch (err) {
    yield put(createErrorSnackBar(err.response ? err.response.data.message : err.message));
  }
}

function* deleteScheduledVisit(action: ReturnType<typeof deleteScheduledVisits>) {
  try {
    yield call(deleteScheduleVisits, action.payload);
  } catch (err) {
    yield put(createErrorSnackBar(err.response ? err.response.data.message : err.message));
  }
}

function* exportScheduledVisits(action: ReturnType<typeof exportScheduledVisitsRequest>) {
  try {
    const { data } = yield call(exportScheduleVisits, action.payload.selected);
    const fileType = { type: action.payload.mimeType } as const;
    const blob = new Blob([data], fileType);
    const fileName = 'scheduled-visits-export.csv';

    yield call(exportToFile, blob, fileType, fileName);
  } catch (err) {
    yield put(createErrorSnackBar(err.response ? err.response.data.message : err.message));
  }
}


export default all([
  takeLatest<any>(SCHEDULED_VISITS_REQUEST, loadScheduledVisitsSaga),
  takeLatest<any>(SCHEDULED_VISIT_DETAILS_REQUEST, loadScheduledVisitDetailsSaga),
  takeLatest<any>(DELETE_SCHEDULED_VISIT_REQUEST, deleteScheduledVisit),
  takeLatest<any>(EXPORT_SCHEDULED_VISITS_REQUEST, exportScheduledVisits),
]);
