import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import { APP_INIT } from '../types/appInit';
import { loadCatalog } from '../../services/catalog';
import { loadStates } from '../../services/states';
import { catalogRequestSuccess } from '../actions/catalog';
import { statesRequestSuccess } from '../actions/states';
import { appInitSuccess, appInitFailed } from '../actions/appResources';

export function* appInitSaga() {
  try {
    const [catalog, states] = yield all([
      call(loadCatalog),
      call(loadStates),
    ]);

    yield all([
      put(catalogRequestSuccess(catalog.data)),
      put(statesRequestSuccess(states.data)),
      put(appInitSuccess()),
    ]);
  } catch (e) {
    yield put(appInitFailed());
  }
}

export default takeLatest(APP_INIT, appInitSaga);
