import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from '../Integrations/styles';
import Reports from './Reports';
import { AppState } from '../../redux/store';
import { setFilter } from '../../redux/actions/filter';
import {
  getBuilderId, getBuilderName, getEnterpriseId, selectEnterpriseStatus,
} from '../../redux/selectors/auth';
import { selectBuilders, selectBuildersRequestStatus } from '../../redux/selectors/builders';
import { enterpriseBuildersRequest } from '../../redux/actions/builders';

const mapStateToProps = (state: AppState) => ({
  builderId: getBuilderId(state),
  enterpriseId: getEnterpriseId(state),
  isEnterprise: selectEnterpriseStatus(state.auth),
  enterpriseBuilders: selectBuilders(state),
  loadingEnterpriseBuilders: selectBuildersRequestStatus(state),
  builder: getBuilderName(state),
});

const mapDispatchToProps = {
  setFilterState: setFilter,
  loadEnterpriseBuilders: enterpriseBuildersRequest,
};

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reports));
