import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PaidInvoices from './PaidInvoices';
import { downloadFakePdf } from '../../redux/actions/billing';
import { AppState } from '../../redux/store';
import { selectPaidInvoices, selectPaidInvoicesAmount, selectInvoicesRequestStatus } from '../../redux/selectors/invoices';


import styles from './styles';

const mapStateToProps = (state: AppState) => ({
  loading: selectInvoicesRequestStatus(state),
  invoicesDetails: selectPaidInvoices(state),
  totalInvoiceAmount: selectPaidInvoicesAmount(state),
});

const mapDispatchToProps = {
  downloadFakePdf,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(PaidInvoices),
);
