import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import { LOCKS_REQUEST, LOCK_DETAILS_REQUEST } from '../types/locks';
import { loadLocks, loadLockDetails, loadLocksCommunity } from '../../services/locks';
import {
  locksRequestSuccess,
  locksRequestFailed,
  lockDetailsRequestFailed,
  lockDetailsRequestSuccess,
  lockDetailsRequest, locksRequest,
} from '../actions/locks';
import { LOCK_COMMUNITIES_REQUEST } from '../types/communities';
import { getLockCommunitiesFailed, getLockCommunitiesSuccess } from '../actions/communities';

function* loadLocksSaga(action: ReturnType<typeof locksRequest>) {
  try {
    const { data } = yield call(loadLocks, action.payload);

    yield put(locksRequestSuccess(data));
  } catch (err) {
    yield put(locksRequestFailed(err));
  }
}

function* loadLockDetailsSaga(action: ReturnType<typeof lockDetailsRequest>) {
  try {
    const { data } = yield call(loadLockDetails, action.payload);
    yield put(lockDetailsRequestSuccess(data));
  } catch (err) {
    yield put(lockDetailsRequestFailed(err));
  }
}

function* loadLockCommunity() {
  try {
    const { data } = yield call(loadLocksCommunity);

    yield put(getLockCommunitiesSuccess(data));
  } catch (err) {
    yield put(getLockCommunitiesFailed(err.message));
  }
}

export default all([
  takeLatest<any>(LOCKS_REQUEST, loadLocksSaga),
  takeLatest<any>(LOCK_DETAILS_REQUEST, loadLockDetailsSaga),
  takeLatest<any>(LOCK_COMMUNITIES_REQUEST, loadLockCommunity),
]);
