/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable lines-between-class-members */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import useStyles from './styles';
import { Community, CommunityStatus } from '../../redux/types/communities';
import { State } from '../../redux/types/states';
import {
  ValidationErrors,
  beautifyErrors,
  generateLabel,
  identicalCodesSchema,
} from '../../utils/helpers';
import { navTo } from '../../browserHsitory';
import appConfig from '../../config';
import {
  createSuccessSnackBar,
  createErrorSnackBar,
} from '../../redux/actions/snackbars';
import { communityInputSchema } from '../CommunityAddForm/CommunityInputValidation';
import { Agent } from '../../redux/types/agents';
import { selectBuilderSettings } from '../../redux/selectors/builders';
import { builderSettingsRequest } from '../../redux/actions/builders';

type Props = {
  loadCommunityDetails: (communityId: number) => void;
  communityDetails: Community;
  states: State[];
  agents: Agent[];
  handleCommunityInfoChange: (community: Community) => void;
  communitySave: (community: Community) => void;
  successfullySaved: boolean;
  cancelSuccessfullySave: () => void;
  isPM: boolean;
  builderId: number;
  communityStatuses: CommunityStatus[];
  loadCommunitiesStatuses: () => void;
  handleValidationErrors: (errors: ValidationErrors<Community>) => void;
};

const CommunityDetails: React.FC<Props> = ({
  communityDetails,
  communityStatuses,
  states,
  agents,
  handleCommunityInfoChange,
  communitySave,
  successfullySaved,
  cancelSuccessfullySave,
  isPM,
  builderId,
  loadCommunitiesStatuses,
  handleValidationErrors,
}) => {
  const classes = useStyles();
  const [community, setCommunity] = useState<Community>(communityDetails);
  const [webflowUrl, setWebflowUrl] = useState<string>('');

  const builderSettings = useSelector(selectBuilderSettings);

  const handleChangeCommunity = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (name) {
      setCommunity((prevCommunity) => ({
        ...prevCommunity,
        [name]: value,
      }));
    }
  };
  const [validationErrors, setValidationErrors] = useState<
  ValidationErrors<Community>
  >({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(builderSettingsRequest(builderId));
  }, [dispatch, builderId]);

  useEffect(() => {
    handleValidationErrors(validationErrors);
  }, [validationErrors, handleValidationErrors]);

  useEffect(() => {
    loadCommunitiesStatuses();
  }, [loadCommunitiesStatuses]);

  useEffect(() => {
    if (communityDetails) {
      setCommunity(communityDetails);
    }
  }, [communityDetails]);

  useEffect(() => {
    setWebflowUrl(
      encodeURI(
        `${appConfig.WEBFLOW_URL}${builderSettings?.webflow_name
          || community.builderName}/community/${community.id}`,
      ),
    );
  }, [community, builderSettings]);

  useEffect(() => {
    if (successfullySaved) {
      navTo('/communities');
      cancelSuccessfullySave();
    }
  }, [successfullySaved, cancelSuccessfullySave]);

  // const handleStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setCommunity((prevState) => ({
  //     ...prevState,
  //     state: e.target.value,
  //   }));
  // };

  const handleCommunitySave = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const codes = {
        community_backup_code: community.community_backup_code ?? '',
        trade_code: community.trade_code ?? '',
        sales_code: community.sales_code ?? '',
      };

      communityInputSchema.validateSync(community, { abortEarly: false });
      identicalCodesSchema.validateSync(codes, { abortEarly: false });
      setValidationErrors({});
      communitySave({
        ...community,
        builder_id: community.builder_id ?? builderId,
        status: communityStatuses?.find(
          (status) => status.status === community.status
        )?.status_id,
      });
      handleCommunityInfoChange(community);
    } catch (errors) {
      setValidationErrors(beautifyErrors<Community>(errors));
    }
  };

  const handleCopyWebflowUrl = async () => {
    try {
      await navigator.clipboard.writeText(webflowUrl);

      dispatch(createSuccessSnackBar('URL copied to clipboard!'));
    } catch (error) {
      console.log(error);
      dispatch(createErrorSnackBar('Failed to copy'));
    }
  };

  return (
    <>
      {communityDetails && (
        <form
          className={classes.content}
          onSubmit={handleCommunitySave}
          noValidate
        >
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  disabled
                  required
                  fullWidth
                  label="Community ID"
                  name="cammunityId"
                  value={community.id}
                  onChange={handleChangeCommunity}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                label="Community Name"
                name="name"
                value={community.name ?? ''}
                onChange={handleChangeCommunity}
                error={Boolean(validationErrors.name)}
                helperText={validationErrors.name}
              />
            </Grid>

            <Grid item xs={12}>
              <div className={classes.progressWrapper}>
                <TextField
                  select
                  required
                  fullWidth
                  margin="normal"
                  id="state"
                  name="state"
                  label="State/Province"
                  value={community?.state || ''}
                  onChange={handleChangeCommunity}
                  error={Boolean(validationErrors.state)}
                  helperText={validationErrors.state}
                >
                  <MenuItem value="" disabled>
                    Select State
                  </MenuItem>
                  {states.map(({ state_id, state_name }) => (
                    <MenuItem key={state_id} value={state_id}>
                      {state_name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                margin="normal"
                fullWidth
                label="City"
                name="city"
                value={community.city || ''}
                onChange={handleChangeCommunity}
                error={Boolean(validationErrors.city)}
                helperText={validationErrors.city}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                margin="normal"
                fullWidth
                label={generateLabel('Zip')}
                name="zip"
                value={community.zip}
                onChange={handleChangeCommunity}
                error={Boolean(validationErrors.zip)}
                helperText={validationErrors.zip}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                select
                required
                fullWidth
                margin="normal"
                id="emergency_contacts"
                name="emergency_contacts"
                label="Point Person to relay concerns"
                value={community?.emergency_contacts || ''}
                onChange={handleChangeCommunity}
                error={Boolean(validationErrors.emergency_contacts)}
                helperText={validationErrors.emergency_contacts}
              >
                <MenuItem disabled>Available Users</MenuItem>
                {agents.map(
                  ({
                    username: agentsId,
                    firstname: FirstName,
                    lastname: LastName,
                  }) => (
                    <MenuItem key={agentsId} value={agentsId}>
                      {FirstName}
                      {' '}
                      {LastName}
                    </MenuItem>
                  ),
                )}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                onChange={handleChangeCommunity}
                margin="normal"
                required
                value={community?.sales_agent || ''}
                fullWidth
                label="Sales Contact #"
                name="sales_agent"
                error={Boolean(validationErrors.sales_agent)}
                helperText={validationErrors.sales_agent}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="normal"
                fullWidth
                label={generateLabel('External Community Id')}
                name="external_community_id"
                value={community.external_community_id}
                onChange={handleChangeCommunity}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                select
                margin="normal"
                fullWidth
                label={generateLabel('Community status')}
                name="status"
                value={community?.status || ''}
                onChange={handleChangeCommunity}
              >
                <MenuItem disabled value="">
                  Select Community status
                </MenuItem>
                {communityStatuses?.map(({ status_id, status }) => (
                  <MenuItem key={status_id} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                value={community.community_url ?? ''}
                placeholder="example: https://www.builder.com/community"
                margin="normal"
                id="community_url"
                name="community_url"
                label="Community URL link"
                onChange={handleChangeCommunity}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                value={community.floor_plan_url ?? ''}
                placeholder="example: https://www.builder.com/community"
                margin="normal"
                id="floor_plan_url"
                name="floor_plan_url"
                label="Floor Plan URL link"
                onChange={handleChangeCommunity}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                value={community.virtual_tour_url ?? ''}
                placeholder="example: https://www.builder.com/community"
                margin="normal"
                id="virtual_tour_url"
                name="virtual_tour_url"
                label="Virtual Tour URL link"
                onChange={handleChangeCommunity}
              />
            </Grid>

            {/* <Grid item xs={12} sm={12}>
              <TextField
                margin="normal"
                required
                placeholder="example: 989512345"
                value={community.sales_agent ?? ''}
                fullWidth
                label="Community phone number or OSC phone number"
                name="sales_agent"
                onChange={handleChangeCommunity}
              />
            </Grid> */}

            <Grid item xs={12} sm={12}>
              <TextField
                placeholder="Type here..."
                margin="normal"
                fullWidth
                multiline
                value={community.additional_notes ?? ''}
                rows={8}
                // rowsMax={8}
                label="Additional Notes"
                name="additional_notes"
                onChange={handleChangeCommunity}
                inputProps={{
                  style: {
                    paddingLeft: 12,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Grid item xs={12} style={{ marginRight: '20px' }}>
                <Typography className={classes.title}>Webflow URL:</Typography>
                <Typography className={classes.text}>{webflowUrl}</Typography>
              </Grid>
              <Grid item xs>
                <Button
                  type="button"
                  variant="contained"
                  onClick={handleCopyWebflowUrl}
                  style={{ marginTop: '16px', marginBottom: '8px' }}
                >
                  <FileCopyIcon style={{ marginRight: '10px' }} />
                  Copy
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.formActions}>
                <Button type="submit" variant="contained">
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default React.memo(CommunityDetails);
