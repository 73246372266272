import { connect } from 'react-redux';
import WebhookForm from './WebhookForm';

import {
  createWebhookRequest,
  webhookDetailsRequest,
  updateWebhookRequest,
  webhooksRequest,
} from '../../redux/actions/webhooks';
import { selectWebhook, selectWebhooks } from '../../redux/selectors/webhooks';
import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  webhooks: selectWebhooks(state),
  selectedWebhook: selectWebhook(state),
});

const mapDispatchToProps = {
  createWebhook: createWebhookRequest,
  updateWebhook: updateWebhookRequest,
  loadWebhooks: webhooksRequest,
  loadWebhookById: webhookDetailsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebhookForm);
