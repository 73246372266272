import React from 'react';
import { WithStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card/';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import styles from './styles';
import IntegrationDialog from '../../containers/IntegrationDialog';

type Props = WithStyles<typeof styles> & {
  icon?: React.ElementType;
  name: string;
  description: string;
};

const IntegrationButton: React.FC<Props> = props => {
  const {
    classes, name, description, icon: Icon,
  } = props;

  const [open, setOpen] = React.useState<boolean>(false);

  function handleOpen() {
    setOpen(!open);
  }

  return (
    <>
      <IntegrationDialog open={open} changeOpen={handleOpen} integrationName={''} />
      <Card elevation={3} className={classes.card} onClick={handleOpen}>
        <CardContent>
          {Icon && (
            <div className={classes.iconWrapper}>
              <Icon className={classes.icon} />
            </div>
          )}
          <Typography className={classes.primaryText}>{name}</Typography>
          <Typography className={classes.secondaryText}>{description}</Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default React.memo(IntegrationButton);
