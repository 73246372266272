import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  VISITS_REQUEST,
  VISITS_EXPORT_REQUEST,
  VISITS_EXPORT_AND_PRINT_REQUEST, VisitsExportRequestActionType, VISITS_REPORT_REQUEST, VisitsReportExportRequestActionType, VISITS_REPORT_EXPORT_AND_PRINT_REQUEST, VISITS_REPORT_EXPORT_REQUEST,
} from '../types/visits';

import {
  loadVisits, loadVisitsExport, loadVisitsReport, loadVisitsReportExport,
} from '../../services/visits';
import {
  visitsRequestSuccess,
  visitsRequestFailed,
  visitsExportSuccess,
  visitsExportFailed, visitsRequest, visitsReportRequest, visitsReportRequestSuccess, visitsReportRequestFailed, visitsReportExportSuccess, visitsReportExportFailed,
} from '../actions/visits';
import { openPrintDialog } from '../../services/print';
import { exportToFile } from '../../services/export';
import { createErrorSnackBar } from '../actions/snackbars';

function* loadVisitsSaga(action: ReturnType<typeof visitsRequest>) {
  try {
    const { data } = yield call(loadVisits, action.payload);

    yield put(visitsRequestSuccess(data));
  } catch (err) {
    yield put(createErrorSnackBar(err.response ? err.response.data.message : err.message));
    yield put(visitsRequestFailed(err));
  }
}

function* loadVisitsReportSaga(action: ReturnType<typeof visitsReportRequest>) {
  try {
    const { data } = yield call(loadVisitsReport, action.payload);

    yield put(visitsReportRequestSuccess(data));
  } catch (err) {
    yield put(createErrorSnackBar(err.response ? err.response.data.message : err.message));
    yield put(visitsReportRequestFailed(err));
  }
}

function* loadVisitsExportSaga(action: VisitsExportRequestActionType) {
  try {
    const { selected, mimeType } = action.payload;
    const { data } = yield call(loadVisitsExport, selected, mimeType);

    const fileType = { type: mimeType } as const;
    const blob = new Blob([data], fileType);

    if (blob && action.type === VISITS_EXPORT_AND_PRINT_REQUEST) {
      openPrintDialog(blob);
    } else {
      const fileName = 'visits-export.csv';

      yield call(exportToFile, blob, fileType, fileName);
    }

    yield put(visitsExportSuccess());
  } catch (err) {
    yield put(visitsExportFailed(err));
  }
}

function* loadVisitsReportExportSaga(action: VisitsReportExportRequestActionType) {
  try {
    const {
      selected, startDate, endDate, mimeType,
    } = action.payload;
    const { data } = yield call(loadVisitsReportExport, selected, startDate, endDate, mimeType);

    const fileType = { type: mimeType } as const;
    const blob = new Blob([data], fileType);

    if (blob && action.type === VISITS_REPORT_EXPORT_AND_PRINT_REQUEST) {
      openPrintDialog(blob);
    } else {
      const fileName = 'visits-report-export.csv';

      yield call(exportToFile, blob, fileType, fileName);
    }

    yield put(visitsReportExportSuccess());
  } catch (err) {
    yield put(visitsReportExportFailed(err));
  }
}

export default all([
  takeLatest<any>(VISITS_REQUEST, loadVisitsSaga),
  takeLatest<any>(VISITS_REPORT_REQUEST, loadVisitsReportSaga),
  takeLatest<any>(
    [VISITS_EXPORT_REQUEST, VISITS_EXPORT_AND_PRINT_REQUEST],
    loadVisitsExportSaga,
  ),
  takeLatest<any>(
    [VISITS_REPORT_EXPORT_REQUEST, VISITS_REPORT_EXPORT_AND_PRINT_REQUEST],
    loadVisitsReportExportSaga,
  ),
]);
