import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import styles from './styles';

export type LockInputType = 'code' | 'name';
type Props = {
  code: string
  label: string
  onChange: (value: string) => void
  disabled?: boolean
  type: LockInputType
} & WithStyles<typeof styles>;

const HubLockCodeInput: React.FC<Props> = (props: Props) => {
  const {
    classes, code, label, onChange, disabled, type,
  } = props;
  const [value, setValue] = useState(code);

  const onChangeHandler = (str: string) => {
    if (type === 'code') {
      onChange(str.replace(/[^0-9]/g, ''));
    } else {
      onChange(str);
    }
  };
  useEffect(() => {
    setValue(code);
  }, [code]);

  return (
    <div>
      <TextField
        margin="normal"
        label={label}
        inputProps={{ maxLength: type === 'code' ? 10 : 16 }}
        disabled={ disabled }
        value={value}
        fullWidth
        className={classes.input}
        onChange={(e) => onChangeHandler(e.target.value)}
      />
      {/* {isDirty && ( */}
      {/*  <CustomButton variant="orange" fullWidth disabled={!isValid || disabled} onClick={changeCode}> */}
      {/*    {code === '' ? 'Create' : 'Update'} */}
      {/*  </CustomButton> */}
      {/* )} */}
    </div>
  );
};

export default HubLockCodeInput;
