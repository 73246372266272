import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import { WithStyles } from '@material-ui/styles';
import styles from './styles';

type Props = { alignItems: 'center' | 'flex-start' } & WithStyles<typeof styles>;

const HubLockLogItem: React.FC<Props> = (props) => {
  const { children, alignItems } = props;
  return (
    <ListItem disableGutters component="li" alignItems={alignItems}>
      {children}
    </ListItem>
  );
};

export default React.memo(HubLockLogItem);
