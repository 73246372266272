import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Spinner from '@material-ui/core/CircularProgress';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { ArrowLeft } from '@material-ui/icons';
import { startOfDay } from 'date-fns';
import SearchBar from '../SearchBar';
import TablePagination from '../../components/CustomTablePagination';
import Table from '../../components/CustomTable';
import { ROWS_PER_PAGE_OPTIONS } from '../../constants/table';

import styles from './styles';
import { HeaderRowConfig } from '../../components/CustomTable/CustomTable';
import { Order } from '../../utils/table';
import { VisitReport } from '../../redux/types/visits';
import { Query } from '../../utils/query';
import useMarketReport from './hooks/useMarketReport.hook';
import { SetFilterPayload } from '../../redux/types/filter';
import { ExportAllParams } from '../../redux/types/exportAll';
import { MimeType } from '../../redux/types/mime';
import { MarketReport } from '../../redux/types/reports';
import { formatDate } from '../../utils/date';

type Props = {
  loading: boolean;
  exportAllLoading: boolean;
  marketReport: any[];
  marketReportTotal: number;
  loadMarketReport: (query?: Query) => void;
  exportMarketReport: (
    selection: Array<number | string>,
    startDate: Date,
    endDate: Date
  ) => void;
  exportAllMarketReport: (
    path: string,
    mimeType: MimeType,
    params: ExportAllParams,
    query?: Query
  ) => void;
  setFilterState: (payload: SetFilterPayload) => void;
} & WithStyles<typeof styles> &
RouteComponentProps;

const searchBarPlaceholder: string = 'Search all market report';

const headRows: HeaderRowConfig<MarketReport>[] = [
  {
    key: 'propertyId',
    align: 'center',
    disablePadding: false,
    label: 'Property ID',
  },
  {
    key: 'address',
    align: 'center',
    disablePadding: false,
    label: 'Address',
  },
  {
    key: 'lotNumber',
    align: 'center',
    disablePadding: false,
    label: 'Lot',
  },
  {
    key: 'communityName',
    align: 'center',
    disablePadding: false,
    label: 'Community',
  },
  {
    key: 'marketingName',
    align: 'center',
    disablePadding: false,
    label: 'Marketing Name',
  },
  {
    key: 'saleDate',
    align: 'center',
    disablePadding: false,
    label: 'Sale Date',
    prepareData: ({ saleDate }) => formatDate(saleDate)
  },
  {
    key: 'daysBeforeSale',
    align: 'center',
    disablePadding: false,
    label: 'Days Before Sale',
  },
  {
    key: 'visits',
    align: 'center',
    disablePadding: false,
    label: 'Visits',
  }
];

const MarketsReport: React.FC<Props> = (props) => {
  const {
    classes,
    marketReport,
    marketReportTotal,
    loadMarketReport,
    exportAllMarketReport,
    exportMarketReport,
    loading,
    history,
    exportAllLoading,
    setFilterState,
  } = props;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    builderId,
    startDate,
    endDate,
    setSearch,
    setOrder,
    setOrderBy,
    setBuilderId,
    setStartDate,
    setEndDate,
    setPage,
    setRowsPerPage,
    dispatchMarketReport,
  } = useMarketReport(loadMarketReport);

  const [selected, setSelected] = useState<VisitReport['community'][]>([]);
  const selectedLength = selected.length;

  useEffect(() => {
    setSelected([]);
  }, [setSelected, page, rowsPerPage]);

  useEffect(
    () => () => {
      const pageNameIndex = 1;
      const pathElements = history.location.pathname.split('/');
      const pathname = pathElements[pageNameIndex];

      if (pathElements.length === 3) {
        return;
      }

      if (pathname !== 'reportMarket') {
        dispatchMarketReport('RESET_STATE');
      }
    },
    [dispatchMarketReport, history.location.pathname],
  );

  useEffect(() => {
    if (!startDate || !endDate) {
      const newBuilderId = searchParams.get('builderId') ?? '';
      const newStartDate = startOfDay(new Date(searchParams.get('startDate') ?? ''));
      const newEndDate = new Date(searchParams.get('endDate') ?? '');
      setBuilderId(newBuilderId);
      setStartDate(newStartDate);
      setEndDate(newEndDate);
      setFilterState({
        type: 'reports',
        name: 'builderId',
        value: newBuilderId,
      });

      setFilterState({
        type: 'reports',
        name: 'startDate',
        value: newStartDate,
      });

      setFilterState({
        type: 'reports',
        name: 'endDate',
        value: newEndDate,
      });
    }
  }, [
    builderId,
    startDate,
    endDate,
    searchParams,
    setBuilderId,
    setEndDate,
    setStartDate,
    setFilterState,
  ]);

  const handleSelect = (newSelected: any[]) => {
    setSelected(newSelected);
  };

  const exportAll = () => {
    exportAllMarketReport(
      'properties/marketReport',
      'text/csv',
      {},
      { startDate, endDate },
    );
  };

  const handleExport = () => {
    if (selectedLength !== 0 && startDate && endDate) {
      exportMarketReport(selected, startDate, endDate);
    }
  };

  const handleChangePage = (pageNumber: number) => {
    setPage(pageNumber);
    dispatchMarketReport('CHANGE_MARKET_REPORT', { currentPage: pageNumber });
  };

  const handleChangeRowsPerPage = (rowsPerPageAmount: number) => {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
    dispatchMarketReport('CHANGE_MARKET_REPORT', {
      currentPage: 0,
      rowsPerPageAmount,
    });
  };

  const handleSearch = (value: string): void => {
    setSearch(value);
    setPage(0);
    dispatchMarketReport('CHANGE_MARKET_REPORT', {
      currentPage: 0,
      contextSearch: value,
    });
  };

  const handleChangeOrder = (
    newOrder: Order,
    newOrderBy: keyof MarketReport,
  ) => {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    setPage(0);
    dispatchMarketReport('CHANGE_MARKET_REPORT', {
      currentOrder: newOrder,
      currentOrderBy: newOrderBy,
      currentPage: 0,
    });
  };

  return (
    <>
      <Grid container justify="space-between" style={{ marginBottom: 25 }}>
        <Grid item>
          <Button variant="contained" onClick={() => history.push('/reports')}>
            <ArrowLeft />
            GoBack
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <SearchBar
            defaultValue={search}
            placeholder={searchBarPlaceholder}
            onChange={handleSearch}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <Typography variant="h5">
              Market Report
              {selectedLength > 0 ? ` ${selectedLength} selected.` : ''}
            </Typography>
          </div>
          <div className={classes.spacer} />
          {selectedLength > 0 && (
            <div className={classes.toolbarActions}>
              <span>Selected actions: </span>
              <Button
                id="fs-export-visit"
                size="small"
                disabled={selectedLength === 0}
                onClick={handleExport}
              >
                Export
              </Button>
            </div>
          )}
          {exportAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Button
              id="fs-export-visits"
              size="small"
              onClick={exportAll}
              disabled={marketReport && marketReport.length === 0}
            >
              Export All
            </Button>
          )}
        </Toolbar>
        <div>
        <div className={classes.tableWrapper}>
          {loading && (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
          <Table<MarketReport>
            className={classes.table}
            IDKey="propertyId"
            selected={selected}
            data={marketReport}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            header={headRows}
            onSelect={handleSelect}
            onChangeOrder={handleChangeOrder}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={marketReport && marketReport.length}
          rowsTotal={marketReportTotal}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        </div>
      </div>
    </>
  );
};

export default React.memo(MarketsReport);
