import { useEffect, useState, useContext } from 'react';
import { Query } from '../../../utils/query';
import { Order } from '../../../utils/table';
import { SearchSortContext } from '../../../context/SearchSortContext';
import {
  ChangeSearchPayload,
  PageSearchSortContextActionType,
} from '../../../context/types';
import parseUrlFilterSearch from '../../../hooks/parseUrlFilterSearch';
import useUrlFilterSearch from '../../../hooks/useUrlFilterSearch';
import { UrlParam } from '../../../redux/types/filter';
import { VisitsAfterTourHours } from '../../../redux/types/afterTourHours';

const useAfterTourHours = (
  loadVisitsAfterTourHours: (query: Query) => void,
) => {
  const urlParse = parseUrlFilterSearch<UrlParam>();
  const {
    dispatch,
    state: { afterTourHours },
  } = useContext(SearchSortContext);
  const {
    currentOrderBy,
    currentOrder,
    currentPage,
    rowsPerPageAmount,
    contextSearch,
  } = afterTourHours;

  const [page, setPage] = useState<number>(urlParse.page || currentPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    urlParse.limit || rowsPerPageAmount,
  );
  const [search, setSearch] = useState<string>(
    urlParse.search || contextSearch,
  );
  const [order, setOrder] = useState<Order>(urlParse.order || currentOrder);
  const [orderBy, setOrderBy] = useState<keyof VisitsAfterTourHours>(
    urlParse.orderBy || currentOrderBy,
  );

  useUrlFilterSearch<UrlParam>({
    search,
    order,
    orderBy,
    page,
    limit: rowsPerPage,
  });

  const dispatchAfterTourHours = (
    type: PageSearchSortContextActionType<'CHANGE_VISITS_AFTER_TOUR_HOURS'>,
    payload?: ChangeSearchPayload<VisitsAfterTourHours>,
  ) => {
    dispatch({ type, payload: { ...afterTourHours, ...payload } });
  };

  useEffect(() => {
    loadVisitsAfterTourHours({
      search,
      order,
      orderBy,
      page,
      limit: rowsPerPage,
    });
  }, [order, orderBy, page, rowsPerPage, search, loadVisitsAfterTourHours]);

  const searchRequest = () => loadVisitsAfterTourHours({
    search,
    order,
    orderBy,
    page,
    limit: rowsPerPage,
  });

  return {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    dispatchAfterTourHours,
    searchRequest,
  };
};

export default useAfterTourHours;
