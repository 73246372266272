import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Spinner from '@material-ui/core/CircularProgress';
import {
  Box,
  Divider,
  Drawer,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import { Edit } from '@material-ui/icons';
import styles from './styles';
import PropertyHubLockDeviceList from '../PropertyHubLockDeviceList';
import { ActionPayload } from '../../types/main';
import CustomButton from '../CustomButton';
import BatteryIcon from '../Icons/BatteryIcon';
import {
  updateSmartLockCodeRequest,
  deleteSmartLockCodeRequest,
  smartLockDetailsRequest,
  refreshSmartLockDetailsRequest,
} from '../../redux/actions/smartLocks';
import CloseIcon from '../Icons/CloseIcon';
import { ValidationErrors, beautifyErrors } from '../../utils/helpers';
import { SmartLock, SmartLockCode } from '../../redux/types/smartLocks';
import { smartLockCodeSchema } from './validation';
import {
  PropertyAccessSettings,
  PropertyDetailsInfo,
} from '../../redux/types/properties';

type Props = {
  loadSmartLockDetails: (
    smartLockSerial: ActionPayload<typeof smartLockDetailsRequest>
  ) => void;
  refreshSmartLockDetails: (
    smartLockSerial: ActionPayload<typeof refreshSmartLockDetailsRequest>
  ) => void;
  updateSmartLockCode: (
    smartLockCode: ActionPayload<typeof updateSmartLockCodeRequest>
  ) => void;
  deleteSmartLockCode: (
    smartLockCode: ActionPayload<typeof deleteSmartLockCodeRequest>
  ) => void;
  handleAccessSettings: (settings: PropertyAccessSettings) => void;
  handleSave: () => void;
  property: PropertyDetailsInfo;
  loading: boolean;
  refreshing: boolean;
  smartLockSerial: string;
  smartLockDetails: SmartLock | null;
} & WithStyles<typeof styles>;

const relockIntervalOptions = ['15 min', '20 min', '25 min', '30 min'];
const lockupOptions = ['8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM'];

const SmartLockPanel: React.FC<Props> = (props: Props) => {
  const {
    classes,
    loading,
    refreshing,
    loadSmartLockDetails,
    refreshSmartLockDetails,
    updateSmartLockCode,
    deleteSmartLockCode,
    smartLockSerial,
    smartLockDetails,
    property,
    handleAccessSettings,
    handleSave,
  } = props;

  const {
    access_settings: accessSettings,
    end_of_day_lockup: endOfDayLockup,
    relock_interval: interval,
  } = property;

  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [accessCodes, setAccessCodes] = useState<SmartLockCode[]>([]);
  const [newCode, setNewCode] = useState<SmartLockCode | null>(null);
  const [seconds, setSeconds] = useState(0);
  const [validationErrors, setValidationErrors] = useState<
  ValidationErrors<SmartLockCode>
  >({});
  const [checked, setChecked] = useState(Boolean(accessSettings) ?? false);
  const [relockInterval, setRelockInterval] = useState<string | null>(
    interval ?? null,
  );
  const [lockup, setLockup] = useState<string | null>(endOfDayLockup ?? null);
  const batteryLevel = (
    smartLockDetails?.battery_level && smartLockDetails?.battery_level * 100
  )?.toFixed();

  useEffect(() => {
    if (!checked) {
      setRelockInterval(null);
      setLockup(null);

      return;
    }

    setRelockInterval(interval ?? '15 min');
    setLockup(endOfDayLockup ?? '10:00 PM');
  }, [checked, endOfDayLockup, interval]);

  useEffect(() => {
    const settings = {
      propertyId: property.property_id,
      accessSettings: checked,
      relockInterval,
      endOfDayLockup: lockup,
    };

    handleAccessSettings(settings);
  }, [
    checked,
    lockup,
    relockInterval,
    handleAccessSettings,
    property.property_id,
  ]);

  useEffect(() => {
    if (!smartLockDetails?.accessCodes?.length) {
      return;
    }

    const filteredCodes = smartLockDetails?.accessCodes?.filter(
      (lockCode: SmartLockCode) => lockCode?.status !== 'removing'
    );

    setAccessCodes(filteredCodes);
  }, [smartLockDetails]);

  useEffect(() => {
    loadSmartLockDetails(smartLockSerial);
  }, [loadSmartLockDetails, smartLockSerial]);

  useEffect(() => {
    if (!isOpenSidebar) {
      return;
    }

    const timer = setTimeout(() => {
      if (seconds < 60) {
        setSeconds((prevState) => prevState + 10);
      }

      if (!refreshing) {
        refreshSmartLockDetails(smartLockSerial);
      }
    }, 10000);

    if (seconds >= 60) {
      clearTimeout(timer);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(timer);
    };
  }, [
    refreshSmartLockDetails,
    refreshing,
    seconds,
    isOpenSidebar,
    smartLockSerial,
  ]);

  const deleteCode = (accessCodeId: string) => {
    deleteSmartLockCode({ smartLockSerial, access_code_id: accessCodeId });
  };

  const handleNewCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (!newCode || (name === 'code' && Number.isNaN(Number(value)))) {
      return;
    }

    setNewCode({ ...newCode, [name]: value });
  };

  const saveNewCode = () => {
    try {
      if (!newCode) {
        return;
      }

      const isSupportedCodeLengths = smartLockDetails?.supported_code_lengths?.includes(
        newCode.code.length
      );

      if (!isSupportedCodeLengths) {
        setValidationErrors({ code: 'invalid code length' });

        return;
      }

      smartLockCodeSchema.validateSync(newCode, {
        abortEarly: false,
      });

      setValidationErrors({});

      const existedCode = accessCodes.find((code) => code.code === newCode.code);

      if (existedCode) {
        setValidationErrors({
          code: `code ${newCode.code} already exists as ${existedCode.name}`,
        });

        return;
      }

      updateSmartLockCode({
        device_id: smartLockSerial,
        code: newCode?.code,
        name: newCode?.name,
        access_code_id: newCode?.access_code_id,
      });

      setNewCode(null);
      setSeconds(0);
    } catch (errors) {
      setValidationErrors(beautifyErrors(errors));
    }
  };

  return (
    <>
      <Grid container alignItems="center" spacing={4}>
        <Grid item>
          <Switch
            checked={checked}
            onChange={() => {
              setChecked(!checked);
            }}
          />
        </Grid>

        <Grid item>
          <h2 className={`${classes.title} ${classes.primaryTitle}`}>
            Staffed Model Access Settings
          </h2>
        </Grid>
        <Grid item>
          <p
            className={`${classes.switchText} ${!checked && classes.disabled}`}
          >
            {checked ? 'Enabled' : 'Disabled'}
          </p>
        </Grid>
      </Grid>

      <Divider style={{ marginBottom: '20px' }} />

      <div className={`${!checked && classes.disabled}`}>
        <Grid container alignItems="center">
          <Grid item>
            <p className={`${classes.title} ${classes.secondaryTitle}`}>
              Relock Interval
            </p>
          </Grid>
          <Grid item>
            <Select
              disabled={!checked}
              className={classes.select}
              variant="outlined"
              value={relockInterval}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                const { value } = event.target;

                setRelockInterval(value as string);
              }}
            >
              {relockIntervalOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item>
            <p className={`${classes.title} ${classes.secondaryTitle}`}>
              End Of Day Lockup
            </p>
          </Grid>
          <Grid item>
            <Select
              disabled={!checked}
              className={classes.select}
              variant="outlined"
              value={lockup}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                const { value } = event.target;

                setLockup(value as string);
              }}
            >
              {lockupOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </div>

      <CustomButton
        onClick={handleSave}
        variant="orange"
        className={classes.btnStyle}
      >
        Save
      </CustomButton>
      <Divider style={{ marginTop: '20px' }} />

      <Grid item xs={12} sm={12}>
        {loading && !isOpenSidebar && (
          <div className={classes.progressWrapper}>
            <Spinner className={classes.progress} />
          </div>
        )}
        {!loading && !smartLockDetails && (
          <div className={classes.statusTitle}>No smart lock data</div>
        )}
        {smartLockDetails && (
          <>
            <p className={classes.statusTitle}>Status/Activity</p>
            <Grid container alignItems="center" className={classes.deviceTitle}>
              <span className={classes.deviceName}>Smart Lock</span>
            </Grid>
            <Grid
              container
              className={classes.card}
              direction="row"
              onClick={() => {
                setIsOpenSidebar(true);
              }}
            >
              <Grid item xs className={classes.cardTitle}>
                <span>{smartLockDetails?.name}</span>
              </Grid>
              <Grid item xs className={classes.cardStatus}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs>
                    <span>
                      {smartLockDetails?.locked ? 'Locked' : 'Unlocked'}
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs justify="flex-end">
                <Grid item className={classes.batteryCounter}>
                  {batteryLevel}
%
                </Grid>
                <Grid className={classes.iconWrapper}>
                  <BatteryIcon className={classes.batteryIcon} />
                </Grid>
              </Grid>
            </Grid>
            <Drawer
              anchor="right"
              open={isOpenSidebar}
              onClose={() => {
                setIsOpenSidebar(false);
                setSeconds(0);
              }}
            >
              <div className={classes.form}>
                <Grid
                  container
                  justify="space-between"
                  direction="column"
                  className={classes.content}
                >
                  <Grid item>
                    <div>
                      <p className={classes.smartLockTitle}>Smart Lock</p>
                      {smartLockDetails?.name && (
                        <p
                          className={clsx(classes.smartLockTitle)}
                          style={{ color: '#DB5C0E' }}
                        >
                          {smartLockDetails?.name}
                        </p>
                      )}
                    </div>
                    <div className={classes.lineBreak} />
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <div>
                          <div
                            className={classes.container}
                            style={{ display: 'block' }}
                          >
                            {!newCode && (
                              <span className={classes.codesTitle}>
                                {accessCodes.length < 1
                                  ? 'Smart lock has no codes'
                                  : 'Smart Lock Codes'}
                              </span>
                            )}
                            {accessCodes.length > 0
                              && !newCode
                              && accessCodes.map((lockCode) => {
                                const isCodeActive = lockCode.status === 'set';

                                return (
                                  <Grid
                                    container
                                    alignItems="center"
                                    spacing={3}
                                    justify="center"
                                    key={lockCode?.access_code_id}
                                  >
                                    <Grid item xs={2}>
                                      <span
                                        style={{
                                          fontSize: '16px',
                                          fontWeight: 700,
                                          color: isCodeActive
                                            ? '#04A777'
                                            : '#1E72C5',
                                        }}
                                      >
                                        {isCodeActive ? 'Active' : 'Pending'}
                                      </span>
                                    </Grid>
                                    <Grid item xs>
                                      <TextField
                                        margin="normal"
                                        label="Code"
                                        name="code"
                                        inputProps={{ maxLength: 8 }}
                                        disabled
                                        value={lockCode?.code}
                                        fullWidth
                                        className={classes.codeInput}
                                      />
                                    </Grid>
                                    <Grid item xs>
                                      <TextField
                                        margin="normal"
                                        label="Name"
                                        name="name"
                                        inputProps={{ maxLength: 16 }}
                                        disabled
                                        value={lockCode?.name}
                                        fullWidth
                                        className={classes.codeInput}
                                      />
                                    </Grid>
                                    <Grid
                                      container
                                      item
                                      xs={1}
                                      alignItems="center"
                                      onClick={() => {
                                        setNewCode({
                                          code: lockCode.code,
                                          name: lockCode.name,
                                          access_code_id:
                                            lockCode.access_code_id,
                                        });
                                      }}
                                      title="Edit code"
                                    >
                                      <Edit className={classes.deleteIcon} />
                                    </Grid>
                                    <Grid
                                      container
                                      item
                                      xs={1}
                                      alignItems="center"
                                      onClick={() => {
                                        if (!lockCode?.access_code_id) {
                                          return;
                                        }

                                        deleteCode(lockCode?.access_code_id);
                                      }}
                                      title="Delete code"
                                    >
                                      <CloseIcon
                                        className={classes.deleteIcon}
                                      />
                                    </Grid>
                                  </Grid>
                                );
                              })}

                            {newCode && (
                              <>
                                <Grid container spacing={3} justify="center">
                                  <Grid item xs={5}>
                                    <TextField
                                      margin="normal"
                                      label="Code"
                                      name="code"
                                      inputProps={{ maxLength: 8 }}
                                      value={newCode?.code}
                                      fullWidth
                                      onChange={handleNewCode}
                                      error={Boolean(validationErrors.code)}
                                      helperText={validationErrors.code}
                                      className={classes.codeInput}
                                    />
                                  </Grid>
                                  <Grid item xs={5}>
                                    <TextField
                                      margin="normal"
                                      label="Name"
                                      name="name"
                                      inputProps={{ maxLength: 16 }}
                                      value={newCode?.name}
                                      fullWidth
                                      onChange={handleNewCode}
                                      error={Boolean(validationErrors.name)}
                                      helperText={validationErrors.name}
                                      className={classes.codeInput}
                                    />
                                  </Grid>
                                </Grid>
                                <CustomButton
                                  variant="orange"
                                  fullWidth
                                  onClick={saveNewCode}
                                  style={{ margin: '20px 0' }}
                                >
                                  Save
                                </CustomButton>
                                <CustomButton
                                  variant="white"
                                  fullWidth
                                  onClick={() => setNewCode(null)}
                                >
                                  Cancel
                                </CustomButton>
                              </>
                            )}
                          </div>

                          <Box mt={1}>
                            {accessCodes.length < 8 && !newCode && (
                              <CustomButton
                                variant="orange"
                                fullWidth
                                disabled={loading}
                                onClick={() => {
                                  setNewCode({ code: '', name: '' });
                                }}
                              >
                                Add New Code
                              </CustomButton>
                            )}
                          </Box>
                          {loading && (
                            <div className={classes.progressWrapper}>
                              <Spinner className={classes.progress} />
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Drawer>
            <PropertyHubLockDeviceList hubId={smartLockSerial} />
          </>
        )}
      </Grid>
    </>
  );
};

export default SmartLockPanel;
