import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  selectVisitsRequestStatus,
} from '../../redux/selectors/visits';
import styles from './styles';
import { AppState } from '../../redux/store';
import { setFilter } from '../../redux/actions/filter';
import { exportAllRequest } from '../../redux/actions/exportAll';
import { selectExportAllLoadingState } from '../../redux/selectors/exportAll';
import ReportMarket from './ReportMarket';
import { marketReportExportRequest, marketReportRequest } from '../../redux/actions/reports';
import { selectMarketReport, selectTotalReports } from '../../redux/selectors/reports';

const mapStateToProps = (state: AppState) => ({
  marketReport: selectMarketReport(state),
  marketReportTotal: selectTotalReports(state),
  loading: selectVisitsRequestStatus(state),
  exportAllLoading: selectExportAllLoadingState(state),
});
const mapDispatchToProps = {
  loadMarketReport: marketReportRequest,
  exportMarketReport: marketReportExportRequest,
  exportAllMarketReport: exportAllRequest,
  setFilterState: setFilter,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ReportMarket),
);
