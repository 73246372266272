import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import { WithStyles } from '@material-ui/styles';
import { IntegrationsInfo, DataToSave } from '../../redux/types/properties';
import styles from './style';

type Props = {
  integrations: IntegrationsInfo;
  property_id: number;
  saveProjectId: (data: DataToSave) => void;
} & WithStyles<typeof styles>;


const IntegrationList: React.FC<Props> = (props) => {
  const {
    integrations, property_id: propertyId, saveProjectId, classes,
  } = props;
  const [integrationsInputsState, setIntegrationsInputsState] = React.useState(integrations);
  const handleChangeProjectId = (value: string, index: number) => {
    const reg = /^\d+\s*$/;
    if (value !== '' && !reg.test(value)) {
      return;
    }

    const projectId = value === '' ? null : value;
    const newArr: any = [...integrationsInputsState];
    newArr[index].project_id = projectId;
    setIntegrationsInputsState(newArr);
  };

  function saveIntegrations() {
    saveProjectId({ integrations: integrationsInputsState, property_id: propertyId });
  }

  return (
    <div>
      <List component="ul">
        {integrationsInputsState.map((item: any, index: number) => (!isNaN(Number(item.project_id)) ? (
          <ListItem component="li" key={item.name} className={classes.listItem}>
            <Typography variant="h6" className={classes.padTyphography}>{item.name}</Typography>
            <Grid item xs={12}>
              <TextField
                required
                margin="normal"
                id={`${item.name}_project_id`}
                name={item.name}
                label="Project id"
                value={item.project_id ?? ''}
                onChange={(e) => handleChangeProjectId(e.target.value, index)}
              />
            </Grid>
          </ListItem>
        ) : null
          // (
          //     <ListItem component="li" key={item} className={classes.listItem}>
          //       <Typography variant="h6" className={classes.padTyphography}>
          //         {item.name}
          //       </Typography>
          //       <Typography className={classes.padTyphography}>
          //         {item.project_id}
          //       </Typography>
          //     </ListItem>
          //   )
        ))}
      </List>
      <div className={classes.btnWrap}>
        <Button onClick={saveIntegrations} className={classes.btnStyle}>Save</Button>
      </div>

    </div>
  );
};

export default React.memo(IntegrationList);
