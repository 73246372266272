import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  container: {
    marginBottom: 26,
  },
  deviceTitle: {
    marginBottom: 20,
  },
  deviceName: {
    fontSize: 16,
    fontWeight: 700,
  },
  deviceIcon: {
    width: 18,
    height: 24,
    marginRight: 8,
    color: '#DB5C0E',
  },
  addIcon: {
    width: 16,
    height: 16,
    marginLeft: 12,
    cursor: 'pointer',
  },

  card: {
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    height: '100%',
  },
  iconWrapper: {
    textAlign: 'center',
    margin: '15px 0 31px',
    width: '100%',
  },
  icon: {
    height: 250,
    fontSize: 114,
    backgroundSize: 'contain',
    color: '#DB5C0E',
    display: 'block',
  },
  primaryText: {
    color: '#DB5C0E',
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: '28px',
    '& .deviceId': {
      fontWeight: 'bold',
      color: '#000',
    },
  },
  secondaryText: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#979797',
  },
  link: {
    color: theme.appPalette.orange,
  },
});
