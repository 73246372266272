import React from 'react';
import MatTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { Community } from '../../../redux/types/communities';
import TableHead from './TableHead';
import { Order, isSelected } from '../../../utils/table';
import { listFormatDate, formatDate } from '../../../utils/date';
import styles from './styles';

type Props = {
  selected: Community['id'][];
  communities: Community[];
  order: Order;
  orderBy: keyof Community;
  rowsPerPage: number;
  onSelect: (event: React.SyntheticEvent, id: Community['id']) => void;
  onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeOrder: (order: Order, orderBy: keyof Community) => void;
  onRowClick: (customerId: number) => void;
} & WithStyles<typeof styles>;

const CommunitiesTable: React.FC<Props> = (props) => {
  const {
    selected,
    communities,
    classes,
    order,
    orderBy,
    onSelect,
    onRowClick,
    onSelectAll,
    onChangeOrder,
  } = props;

  const handleRequestSort = (
    event: React.SyntheticEvent,
    property: keyof Community,
  ) => {
    event.preventDefault();

    const isDesc = orderBy === property && order === 'desc';

    onChangeOrder(isDesc ? 'asc' : 'desc', property);
  };

  return (
    <div className={classes.tableWrapper}>
      <MatTable className={classes.tableRoot}>
        <TableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          rowCount={communities.length}
          onRequestSort={handleRequestSort}
          onSelectAllClick={onSelectAll}
        />
        <TableBody>
          {communities.map((community: Community, index: number) => {
            const isItemSelected: boolean = isSelected(community.id, selected);
            const modifiedOn = community.modified_on
              ? listFormatDate(community?.modified_on)
              : listFormatDate(community?.created_on);
            const labelId: string = `enhanced-table-checkbox-${index}`;
            const created_on = formatDate(community?.created_on);

            return (
              <TableRow
                hover
                role="checkbox"
                classes={{ root: classes.rowRoot }}
                key={community.id}
                tabIndex={-1}
                aria-checked={isItemSelected}
                selected={isItemSelected}
                onClick={() => onRowClick(community.id)}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                    onClick={(event) => {
                      event.stopPropagation();
                      onSelect(event, community.id);
                    }}
                  />
                </TableCell>
                <TableCell align="center" id={labelId}>
                  {community.name}
                </TableCell>
                <TableCell align="center">{community.city}</TableCell>
                <TableCell align="center">{community.state}</TableCell>
                <TableCell align="center">{community.zip}</TableCell>
                <TableCell align="center">
                  {community.external_community_id}
                </TableCell>
                <TableCell align="center">{created_on}</TableCell>
                <TableCell align="center">{community.created_by}</TableCell>
                <TableCell align="center">{modifiedOn}</TableCell>
                <TableCell align="center">{community?.builderName}</TableCell>
                <TableCell align="center">{community?.status}</TableCell>

              </TableRow>
            );
          })}
        </TableBody>
      </MatTable>
    </div>
  );
};

export default withStyles(styles)(CommunitiesTable);
