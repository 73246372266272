import { createStyles } from '@material-ui/core';

export default () => createStyles({
  root: {
    '& > div': {
      marginLeft: 5,
    },
  },

  userGreeting: {
    marginRight: 30,
  },

  spacer: {
    flexGrow: 1,
  },

  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap-reverse',
  },

  chatIconBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
    marginLeft: '12px',
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'black',
  },

  notificationIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },

  logout: {
    marginRight: 16
  }
});
