import { useEffect, useState, useContext } from 'react';
import { Query } from '../../../utils/query';
import { Order } from '../../../utils/table';
import { SearchSortContext } from '../../../context/SearchSortContext';
import { ChangeSearchPayload, PageSearchSortContextActionType } from '../../../context/types';
import parseUrlFilterSearch from '../../../hooks/parseUrlFilterSearch';
import useUrlFilterSearch from '../../../hooks/useUrlFilterSearch';
import { UrlParam } from '../../../redux/types/filter';
import { Team } from '../../../redux/types/teams';

const useTeams = (
  loadTeams: (query: Query) => void,
) => {
  const urlParse = parseUrlFilterSearch<UrlParam>();
  const { dispatch, state: { teams } } = useContext(SearchSortContext);
  const {
    currentOrderBy, currentOrder, currentPage, rowsPerPageAmount, contextSearch,
  } = teams;

  const [search, setSearch] = useState<string>(urlParse.search || contextSearch);
  const [order, setOrder] = useState<Order>(urlParse.order || currentOrder);
  const [orderBy, setOrderBy] = useState<keyof Team>(urlParse.orderBy || currentOrderBy);
  const [page, setPage] = useState<number>(urlParse.page || currentPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(urlParse.limit || rowsPerPageAmount);

  useUrlFilterSearch<UrlParam>({
    search, order, orderBy, page: String(page), limit: rowsPerPage,
  });

  const dispatchTeams = (
    type: PageSearchSortContextActionType<'CHANGE_TEAM'>,
    payload?: ChangeSearchPayload<Team>,
  ) => dispatch({ type, payload: { ...teams, ...payload } });

  useEffect(() => {
    loadTeams({
      search, order, orderBy, page: String(page), limit: rowsPerPage,
    });
  }, [order, orderBy, page, rowsPerPage, search, loadTeams]);

  return {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    dispatchTeams,
  };
};

export default useTeams;
