import {
  AUTH_USER_SUCCESS,
  AUTH_USER_ERROR,
  AUTH_USER_REQUEST,
  AUTH_USER_LOGOUT,
  AUTH_ADMIN_SUCCESS,
  GET_OAUTH_CLIENTS_REQUEST,
  GET_OAUTH_CLIENTS_ERROR,
  GET_OAUTH_CLIENTS_SUCCESS,
  REMOVE_OAUTH_CLIENTS_REQUEST,
  REMOVE_OAUTH_CLIENTS_ERROR,
  REMOVE_OAUTH_CLIENTS_SUCCESS,
  CREATE_OAUTH_CLIENTS_REQUEST,
  CREATE_OAUTH_CLIENTS_ERROR,
  CREATE_OAUTH_CLIENTS_SUCCESS,
  AUTH_ENTERPRISE_USER_REQUEST,
  AUTH_PM_REQUEST,
  ClientDelete,
  AuthEnterpriseUserRequest,
  AuthRequest,
  AuthSuccess,
  OauthClient,
} from '../types/auth';
import { UPDATE_PROFILE_REQUEST_SUCCESS } from '../types/agents';
import { ErrorMessage } from '../../types/main';

export const authUserRequest = (payload: AuthRequest) => ({
  type: AUTH_USER_REQUEST,
  ...payload,
}) as const;

export const authPMRequest = (agentUsername: string) => ({
  type: AUTH_PM_REQUEST,
  payload: agentUsername,
}) as const;

export const authEnterpriseUserRequest = (payload: AuthEnterpriseUserRequest) => ({
  type: AUTH_ENTERPRISE_USER_REQUEST,
  payload,
}) as const;

export const authUserError = (payload: ErrorMessage) => ({
  type: AUTH_USER_ERROR,
  payload,
}) as const;

export const authUserSuccess = (payload: AuthSuccess) => ({
  type: AUTH_USER_SUCCESS,
  payload,
}) as const;

export const authUserLogout = () => ({
  type: AUTH_USER_LOGOUT,
  payload: undefined,
}) as const;

export const authAdminSuccess = () => ({
  type: AUTH_ADMIN_SUCCESS,
  payload: undefined,
}) as const;


export const getOauthClientsRequest = () => ({
  type: GET_OAUTH_CLIENTS_REQUEST,
}) as const;

export const getOauthClientsError = (payload: ErrorMessage) => ({
  type: GET_OAUTH_CLIENTS_ERROR,
  payload,
}) as const;

export const getOauthClientsSuccess = (payload: ApiResponse<OauthClient>) => ({
  type: GET_OAUTH_CLIENTS_SUCCESS,
  payload,
}) as const;

export const removeOauthClientsRequest = (clientId: string, clientSecret: string) => ({
  type: REMOVE_OAUTH_CLIENTS_REQUEST,
  payload: { clientId, clientSecret },
}) as const;

export const removeOauthClientsError = (payload: ErrorMessage) => ({
  type: REMOVE_OAUTH_CLIENTS_ERROR,
  payload,
}) as const;

export const updateProfileRequestSuccess = (userPayload: Record<string, any>) => ({
  type: UPDATE_PROFILE_REQUEST_SUCCESS,
  payload: userPayload,
} as const);

export const removeOauthClientsSuccess = ({ clientId, clientSecret }: ClientDelete) => ({
  type: REMOVE_OAUTH_CLIENTS_SUCCESS,
  payload: { clientId, clientSecret },
}) as const;

export const createOauthClientsRequest = () => ({
  type: CREATE_OAUTH_CLIENTS_REQUEST,
}) as const;

export const createOauthClientsError = (payload: ErrorMessage) => ({
  type: CREATE_OAUTH_CLIENTS_ERROR,
  payload,
}) as const;

export const createOauthClientsSuccess = (payload: OauthClient) => ({
  type: CREATE_OAUTH_CLIENTS_SUCCESS,
  payload,
}) as const;
