import FileSave from 'js-file-download';

export type ExportFileType = {
  type: 'application/pdf' | 'text/csv';
};

export type ExportData = Blob | string[];

// eslint-disable-next-line import/prefer-default-export
export const exportToFile = (
  exportData: ExportData,
  type: ExportFileType = {
    type: 'application/pdf',
  },
  fileName: string = 'export',
): void => {
  if (exportData instanceof Blob) {
    FileSave(exportData, fileName);
  } else {
    const blob = new Blob(exportData, type);
    FileSave(blob, fileName);
  }
};
