import {
  VISITS_REQUEST,
  VISITS_REQUEST_SUCCESS,
  VISITS_REQUEST_FAILED,
  VISITS_EXPORT_REQUEST,
  VISITS_EXPORT_AND_PRINT_REQUEST,
  VISITS_EXPORT_REQUEST_SUCCESS,
  VISITS_EXPORT_REQUEST_FAILED,
  VisitsRequestSuccess,
  VISITS_REPORT_REQUEST,
  VISITS_REPORT_REQUEST_FAILED,
  VISITS_REPORT_REQUEST_SUCCESS,
  VisitsReportsRequestSuccess,
  VISITS_REPORT_EXPORT_AND_PRINT_REQUEST,
  VISITS_REPORT_EXPORT_REQUEST,
  VISITS_REPORT_EXPORT_REQUEST_SUCCESS,
  VISITS_REPORT_EXPORT_REQUEST_FAILED,
} from '../types/visits';
import { Query } from '../../utils/query';
import { MimeType } from '../types/mime';
import { ErrorMessage } from '../../types/main';

export const visitsRequest = (query?: Query) => ({
  type: VISITS_REQUEST,
  payload: query,
} as const);

export const visitsRequestFailed = (payload: ErrorMessage) => ({
  type: VISITS_REQUEST_FAILED,
  payload,
} as const);

export const visitsRequestSuccess = (payload: VisitsRequestSuccess) => ({
  type: VISITS_REQUEST_SUCCESS,
  payload,
} as const);

export const visitsReportRequest = (query?: Query) => ({
  type: VISITS_REPORT_REQUEST,
  payload: query,
} as const);

export const visitsReportRequestFailed = (payload: ErrorMessage) => ({
  type: VISITS_REPORT_REQUEST_FAILED,
  payload,
} as const);

export const visitsReportRequestSuccess = (payload: VisitsReportsRequestSuccess) => ({
  type: VISITS_REPORT_REQUEST_SUCCESS,
  payload,
} as const);

export const visitsExportAndPrintRequest = (selected: Array<string | number>, mimeType: MimeType = 'application/pdf') => ({
  type: VISITS_EXPORT_AND_PRINT_REQUEST,
  payload: {
    selected,
    mimeType,
  },
} as const);

export const visitsExportRequest = (selected: Array<string | number>, mimeType: MimeType = 'text/csv') => ({
  type: VISITS_EXPORT_REQUEST,
  payload: {
    selected,
    mimeType,
  },
} as const);

export const visitsExportSuccess = () => ({
  type: VISITS_EXPORT_REQUEST_SUCCESS,
} as const);

export const visitsExportFailed = (payload: ErrorMessage) => ({
  type: VISITS_EXPORT_REQUEST_FAILED,
  payload,
} as const);

export const visitsReportExportAndPrintRequest = (selected: Array<string | number>, startDate: Date, endDate: Date, mimeType: MimeType = 'application/pdf') => ({
  type: VISITS_REPORT_EXPORT_AND_PRINT_REQUEST,
  payload: {
    selected,
    startDate,
    endDate,
    mimeType,
  },
} as const);

export const visitsReportExportRequest = (selected: Array<string | number>, startDate: Date, endDate: Date, mimeType: MimeType = 'text/csv') => ({
  type: VISITS_REPORT_EXPORT_REQUEST,
  payload: {
    selected,
    startDate,
    endDate,
    mimeType,
  },
} as const);


export const visitsReportExportSuccess = () => ({
  type: VISITS_REPORT_EXPORT_REQUEST_SUCCESS,
} as const);

export const visitsReportExportFailed = (payload: ErrorMessage) => ({
  type: VISITS_REPORT_EXPORT_REQUEST_FAILED,
  payload,
} as const);
