import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import UserToolbar from './UserToolbar';
import styles from './styles';
import { authUserLogout } from '../../redux/actions/auth';
import { AppState } from '../../redux/store';
import { selectUserData } from '../../redux/selectors/auth';

const mapStateToProps = (state: AppState) => ({
  user: selectUserData(state),
});

const mapDispatchToProps = {
  onLogOut: authUserLogout,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UserToolbar),
);
