import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({

  root: {
    background: '#FFFFFF',
    border: '1px solid #DBDDE3',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.25)',
    borderRadius: 5,
    "& > label": {
      height: 12,
      left: 0,
      top: 6,
    },
    "& > div > input": {
      fontFamily: 'Source Sans Pro',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: 24,
      display: 'flex',
      alignItems: 'flex-end',
      color: 'rgba(0, 0, 0, 0.87)',
      marginLeft: 0,
    },
    '&:focus-within': {
      background: 'rgba(219, 92, 14, 0.08)',
      mixBlendMode: 'normal',
      border: '1px solid #DB5C0E'
    },
  },

  container: {
    height: '100vh',
  },
  form: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  progressButtonWrapper: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});
