import {
  OFFERS_REQUEST,
  OFFERS_REQUEST_SUCCESS,
  OFFERS_REQUEST_FAILED,
  OFFERS_EXPORT_REQUEST,
  OFFERS_EXPORT_AND_PRINT_REQUEST,
  OFFERS_EXPORT_REQUEST_SUCCESS,
  OFFERS_EXPORT_REQUEST_FAILED,
  OFFER_DETAILS_REQUEST_SUCCESS,
  OFFER_DETAILS_REQUEST,
  OffersRequestSuccess,
  Offer,
} from '../types/offers';
import { Query } from '../../utils/query';
import { MimeType } from '../types/mime';
import { ErrorMessage } from '../../types/main';

export const offersRequest = (query?: Query) => ({
  type: OFFERS_REQUEST,
  payload: query,
} as const);

export const offersRequestFailed = (payload: ErrorMessage) => ({
  type: OFFERS_REQUEST_FAILED,
  payload,
} as const);

export const offersRequestSuccess = (payload: OffersRequestSuccess) => ({
  type: OFFERS_REQUEST_SUCCESS,
  payload,
} as const);

export const offersExportAndPrintRequest = (selected: Array<string | number>, mimeType: MimeType = 'application/pdf') => ({
  type: OFFERS_EXPORT_AND_PRINT_REQUEST,
  payload: {
    selected,
    mimeType,
  },
} as const);

export const offersExportRequest = (selected: Array<string | number>, mimeType: MimeType = 'text/csv') => ({
  type: OFFERS_EXPORT_REQUEST,
  payload: {
    selected,
    mimeType,
  },
} as const);


export const offersExportFailed = (payload: ErrorMessage) => ({
  type: OFFERS_EXPORT_REQUEST_FAILED,
  payload,
} as const);

export const offersExportSuccess = () => ({
  type: OFFERS_EXPORT_REQUEST_SUCCESS,
} as const);

export const offerDetailsRequest = (offerId: number) => ({
  type: OFFER_DETAILS_REQUEST,
  payload: offerId,
} as const);

export const offerDetailsRequestSuccess = (offer: Offer) => ({
  type: OFFER_DETAILS_REQUEST_SUCCESS,
  payload: offer,
} as const);
