import React from 'react';
import { Grid } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';

import styles from './styles';

type Props = {

} & WithStyles<typeof styles>;

const BrandHeader: React.FC<Props> = props => {
  const { classes } = props;
  return (
    <Grid container direction="column" className={classes.nternowHeader}>
      <p className={classes.nternowSubheader}>
          NterNow
      </p>
      <span>
            3870 High Green Dr.
      </span>
      <span>
            Marietta, GA 30068-2575
      </span>
    </Grid>
  );
};

export default BrandHeader;
