/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Spinner from '@material-ui/core/CircularProgress';
import SearchBar from '../SearchBar';
import Table from '../../components/CustomTable';
import { formatDate, formatToESTDate } from '../../utils/date';
import useCustomers from './hooks/useCustomers.hook';
import { ROWS_PER_PAGE_OPTIONS } from '../../constants/table';

import styles from './styles';
import { HeaderRowConfig } from '../../components/CustomTable/CustomTable';
import { Customer } from '../../redux/types/customers';
import { Order } from '../../utils/table';
import { Query } from '../../utils/query';
import TablePagination from '../../components/CustomTablePagination';

type Props = {
  customers: Customer[];
  customersTotal: number;
  loading: boolean;
  loadCustomers: (query: Query) => void;
  exportCustomers: (selection: Array<number | string>) => void;
  printCustomers: (selection: Array<number | string>) => void;
  exportAllCustomers: (path: string) => void;
  clearFilterState: () => void;
  exportAllLoading: boolean;
} & WithStyles<typeof styles> &
RouteComponentProps;

const headRows: HeaderRowConfig<Customer>[] = [
  {
    key: 'firstname',
    align: 'left',
    disablePadding: false,
    label: 'First Name',
  },
  {
    key: 'lastname',
    align: 'left',
    disablePadding: false,
    label: 'Last Name',
  },
  {
    key: 'visitor_contact_status_id_value',
    align: 'center',
    disablePadding: false,
    label: 'Customer Status',
  },
  {
    key: 'visitor_type_id_value',
    align: 'center',
    disablePadding: true,
    label: 'Visitor Type',
  },
  {
    key: 'visits_count',
    align: 'center',
    disablePadding: true,
    label: 'Visit Counter',
  },
  {
    key: 'last_visit_date',
    align: 'right',
    disablePadding: false,
    label: 'Last Use',
    prepareData: ({
      last_visit_date,
      timezoneAbbr,
    }) => formatToESTDate(last_visit_date, timezoneAbbr),
  },
  {
    key: 'created_on',
    align: 'center',
    disablePadding: true,
    label: 'Created',
    prepareData: ({ created_on }) => formatDate(created_on),
  },
  {
    key: 'last_visit_property_id',
    align: 'center',
    disablePadding: false,
    label: 'Last Property',
  },
  {
    key: 'visitor_status_id_value',
    align: 'center',
    disablePadding: false,
    label: 'Visit Status',
  },
];

const searchBarPlaceholder: string = 'Search all customers';

const Customers: React.FC<Props> = (props) => {
  const {
    classes,
    history,
    match,
    customers,
    customersTotal,
    loading,
    loadCustomers,
    exportCustomers,
    printCustomers,
    exportAllCustomers,
    exportAllLoading,
    clearFilterState,
  } = props;

  const {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    dispatchCustomers,
    searchRequest,
  } = useCustomers(loadCustomers);
  const [selected, setSelected] = useState<Customer['customer_id'][]>([]);
  const selectedLength = selected.length;

  useEffect(() => {
    setSelected([]);
  }, [setSelected, page, rowsPerPage]);

  useEffect(() => () => {
    const pageNameIndex = 1;
    const pathElements = history.location.pathname.split('/');
    const pathname = pathElements[pageNameIndex];
    if (pathElements.length === 3) {
      return;
    }
    if ((pathname !== 'customers')) {
      dispatchCustomers('RESET_STATE');
    }
  }, [dispatchCustomers, history.location.pathname]);

  useEffect(() => {
    return () => {
      clearFilterState();
    };
  }, [clearFilterState]);

  function handleChangeOrder(newOrder: Order, newOrderBy: keyof Customer) {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    setPage(0);
    dispatchCustomers('CHANGE_CUSTOMER', { currentOrder: newOrder, currentOrderBy: newOrderBy, currentPage: 0 });
  }

  function handleChangePage(newPage: number) {
    setPage(newPage);
    dispatchCustomers('CHANGE_CUSTOMER', { currentPage: newPage });
  }

  function handleChangeRowsPerPage(rowsPerPageAmount: number) {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
    dispatchCustomers('CHANGE_CUSTOMER', { rowsPerPageAmount, currentPage: 0 });
  }

  function handleSelect(newSelected: any[]) {
    setSelected(newSelected);
  }

  function handleCustomerSelect(customer: Customer) {
    history.push(`${match.url}/${customer.customer_id}`);
  }

  function handleCustomersExport() {
    if (selectedLength !== 0) {
      exportCustomers(selected);
    }
  }

  function handleCustomersPrint() {
    if (selectedLength !== 0) {
      printCustomers(selected);
    }
  }

  const handleSearch = (value: string): void => {
    setSearch(value);
    setPage(0);
    dispatchCustomers('CHANGE_CUSTOMER', { contextSearch: value, currentPage: 0 });
  };

  const exportAll = () => {
    exportAllCustomers(history.location.pathname.split('/')[1]);
  };

  return (
    <>
      <Grid container>
        <Grid item xs>
          <SearchBar
            defaultValue={search}
            placeholder={searchBarPlaceholder}
            onChange={handleSearch}
            searchRequest={searchRequest}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <Typography variant="h5">
              Customers
              {selectedLength > 0 ? ` ${selectedLength} selected.` : ''}
            </Typography>
          </div>
          <div className={classes.spacer} />
          {selectedLength > 0 && (
            <div className={classes.toolbarActions}>
              <span>Selected actions: </span>
              <Button
                id="fs-export-customer"
                size="small"
                disabled={selectedLength === 0}
                onClick={handleCustomersExport}
              >
                Export
              </Button>
              <Button
                size="small"
                disabled={selectedLength === 0}
                onClick={handleCustomersPrint}
              >
                Print
              </Button>
            </div>
          )}
          {exportAllLoading ? (<Spinner size={20} />) : (
            <Button
              id="fs-export-customers"
              size="small"
              onClick={exportAll}
              disabled={customers.length === 0}
            >
              Export All
            </Button>
          )}
        </Toolbar>
        <div className={classes.tableWrapper}>
          {loading && (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
          <Table<Customer>
            hover
            className={classes.table}
            IDKey="customer_id"
            selected={selected}
            data={customers}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            header={headRows}
            onSelect={handleSelect}
            onChangeOrder={handleChangeOrder}
            onRowClick={handleCustomerSelect}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={customers.length}
          rowsTotal={customersTotal}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default React.memo(Customers);
