import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import {
  weeklyVisitsRequest,
  yearlyVisitsRequest,
} from '../../redux/actions/stats';
import { visitsRequest } from '../../redux/actions/visits';
import {
  selectWeeklyChartData,
  selectYearlyChartData,
} from '../../redux/selectors/stats';
import { selectVisits } from '../../redux/selectors/visits';
import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  weeklyVisitsChartData: selectWeeklyChartData(state),
  yearlyVisitsChartData: selectYearlyChartData(state),
  recentVisits: selectVisits(state),
});

const mapDispatchToProps = {
  loadWeeklyVisits: weeklyVisitsRequest,
  loadYearlyVisits: yearlyVisitsRequest,
  loadRecentVisits: visitsRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
