import { Theme, createStyles } from '@material-ui/core';

const circleRadius = 16;
const circleDiameter = circleRadius * 2;
const lineWidth = 4;

export default (theme: Theme) => createStyles({
  container: {
    position: 'relative',
  },
  comment: {
    fontSize: 16,
  },
  activityDate: {
    color: theme.appPalette.orange,
  },
  circle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: circleDiameter,
    height: circleDiameter,
    borderRadius: '50%',
    backgroundColor: theme.appPalette.orange,
  },
  drawLineStyles: {
    marginLeft: circleRadius - lineWidth / 2,
    borderLeft: `${lineWidth}px solid ${theme.appPalette.orange}`,
  },
  content: {
    paddingLeft: circleDiameter,
    '& > div': {
      paddingBottom: 17,
    },
  },
  line: {
    minHeight: 58,
  },
});
