import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  TextField,
  Paper,
  Button,
  Checkbox,
  List,
  ListItem,
  Divider,
  MenuItem,
} from '@material-ui/core';
import ClearSharpIcon from '@material-ui/icons/ClearSharp';
import { ArrowLeft } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import CustomButton from '../CustomButton';
import { beautifyErrors, ValidationErrors } from '../../utils/helpers';
import { selectAvailableAgents } from '../../redux/selectors/agents';
import { availableAgentsRequest } from '../../redux/actions/agents';
import { teamSchema } from './TeamValidation';
import { TeamFormType, Team } from '../../redux/types/teams';
import { Agent } from '../../redux/types/agents';
import { ActionPayload } from '../../types/main';
import {
  addTeamAgentRequest,
  createTeamRequest,
  deleteTeamAgentRequest,
  teamDetailsRequest,
  updateTeamRequest,
} from '../../redux/actions/teams';
import { selectTeam } from '../../redux/selectors/teams';
import { Builder } from '../../redux/types/builders';

type Props = {
  builderId: number;
  enterpriseId: number | null;
  enterpriseBuilders: Builder[];
  createTeam: (team: ActionPayload<typeof createTeamRequest>) => void;
  updateTeam: (team: ActionPayload<typeof updateTeamRequest>) => void;
  loadTeamById: (team: ActionPayload<typeof teamDetailsRequest>) => void;
  addTeamAgent: (agent: ActionPayload<typeof addTeamAgentRequest>) => void;
  deleteTeamAgent: (
    agent: ActionPayload<typeof deleteTeamAgentRequest>
  ) => void;
  loadEnterpriseBuilders: (enterpriseId: number) => void;
  loadingEnterpriseBuilders: boolean;
};

const TeamForm: React.FC<Props> = (props) => {
  const {
    builderId,
    enterpriseId,
    enterpriseBuilders,
    loadingEnterpriseBuilders,
    loadEnterpriseBuilders,
    createTeam,
    updateTeam,
    loadTeamById,
    addTeamAgent,
    deleteTeamAgent,
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const allAgents = useSelector(selectAvailableAgents);
  const selectedTeam = useSelector(selectTeam);
  const { teamId } = useParams<{ teamId: string }>();

  const [availableAgents, setAvailableAgents] = useState<Agent[]>([]);
  const [selectedBuilder, setSelectedBuilder] = useState<number | null>(null);
  const [teamForm, setTeamForm] = useState<TeamFormType>({
    name: '',
    description: '',
    agents: [],
  });
  const [validationErrors, setValidationErrors] = useState<
  ValidationErrors<Team>
  >({});

  useEffect(() => {
    if (enterpriseId) {
      loadEnterpriseBuilders(enterpriseId);
    }
  }, [loadEnterpriseBuilders, enterpriseId]);

  useEffect(() => {
    const agents = allAgents.filter((agent) => {
      const isContractor = agent?.groups !== undefined
        && agent?.groups.find(({ GroupID }) => GroupID === 7);
      const isOtherStaff = agent?.groups !== undefined
        && agent?.groups.find(({ GroupID }) => GroupID === 1);
      return !isContractor && !isOtherStaff;
    });

    setAvailableAgents(agents);
  }, [allAgents]);

  useEffect(() => {
    if (!teamId) {
      setTeamForm({
        name: '',
        description: '',
        agents: [],
      });
      return;
    }

    loadTeamById(teamId);
  }, [teamId, loadTeamById]);

  useEffect(() => {
    if (!selectedTeam?.agents || !teamId) {
      return;
    }

    setTeamForm({
      name: selectedTeam.name,
      description: selectedTeam.description,
      agents: selectedTeam.agents,
    });
  }, [selectedTeam, teamId]);

  useEffect(() => {
    if (enterpriseId && selectedBuilder) {
      dispatch(availableAgentsRequest(selectedBuilder));

      return;
    }

    if (selectedTeam?.builder_id || builderId) {
      dispatch(availableAgentsRequest(selectedTeam?.builder_id ?? builderId));
    }
  }, [dispatch, selectedTeam, selectedBuilder, builderId, enterpriseId]);

  const handleChangeTeam = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setTeamForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const validateForm = () => {
    try {
      teamSchema.validateSync(teamForm, { abortEarly: false });
      setValidationErrors({});
      return true;
    } catch (error) {
      setValidationErrors(beautifyErrors<Team>(error));
      return false;
    }
  };

  const handleSubmit = () => {
    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    if (teamId) {
      updateTeam({ teamId, team: teamForm });
      return;
    }

    createTeam(teamForm);
  };

  const handleSelectBuilder = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedBuilderId = Number(e.target.value);
    const selected = enterpriseBuilders.find(
      ({ builder_id }) => builder_id === selectedBuilderId,
    );

    if (selected) {
      setSelectedBuilder(selectedBuilderId);
      setTeamForm((prevState) => ({
        ...prevState,
        agents: [],
        builderId: selectedBuilderId,
      }));
    }
  };

  return (
    <>
      <Grid container justify="space-between" style={{ marginBottom: 25 }}>
        <Grid item>
          <Button variant="contained" onClick={() => history.push('/teams')}>
            <ArrowLeft />
            {' GoBack'}
          </Button>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        <Grid container justify="space-between" style={{ marginBottom: 25 }}>
          <Grid item />
        </Grid>
        <div className={classes.headerText}>
          <span>{teamId ? 'Edit Team Details' : 'Add New Team Details'}</span>
        </div>
        <div className={classes.inputForm}>
          <Grid container>
            {enterpriseId && !teamId && (
              <Grid item xs>
                <TextField
                  select
                  required
                  fullWidth
                  margin="normal"
                  id="state"
                  name="state"
                  label="Select Builder"
                  value={selectedBuilder}
                  onChange={handleSelectBuilder}
                  disabled={
                    !enterpriseId
                    || (!enterpriseId && loadingEnterpriseBuilders)
                  }
                >
                  <MenuItem value="" disabled>
                    Select Builder
                  </MenuItem>
                  {enterpriseBuilders.map(({ builder_id, name }) => (
                    <MenuItem key={builder_id} value={builder_id}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              className={
                enterpriseId && !selectedBuilder && !teamId
                  ? classes.disabled
                  : ''
              }
            >
              <TextField
                autoComplete="off"
                onChange={handleChangeTeam}
                margin="normal"
                required
                value={teamForm.name}
                fullWidth
                label="Team Name"
                name="name"
                error={Boolean(validationErrors.name)}
                helperText={validationErrors.name}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              className={
                enterpriseId && !selectedBuilder && !teamId
                  ? classes.disabled
                  : ''
              }
            >
              <TextField
                autoComplete="off"
                onChange={handleChangeTeam}
                margin="normal"
                required
                value={teamForm.description}
                fullWidth
                label="Description"
                name="description"
                error={Boolean(validationErrors.description)}
                helperText={validationErrors.description}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              className={
                enterpriseId && !selectedBuilder && !teamId
                  ? classes.disabled
                  : ''
              }
            >
              <Autocomplete
                multiple
                options={availableAgents.sort(
                  (a: Agent, b: Agent) => -(
                    Number(a?.is_project_manager)
                      - Number(b?.is_project_manager)
                  ),
                )}
                disableCloseOnSelect
                disableClearable
                getOptionLabel={(agent) => agent.username}
                getOptionSelected={(option: any, value) => value.username === option.username}
                value={teamForm.agents}
                renderTags={() => null}
                noOptionsText="No record exist!"
                onChange={(e, agents) => {
                  setTeamForm((prevState) => ({ ...prevState, agents }));

                  if (!teamId) {
                    return;
                  }

                  if (teamForm.agents.length > agents.length) {
                    const agentToDelete = teamForm.agents.find(
                      (deletedAgent) => !agents.some(
                        (agent) => deletedAgent.username === agent.username,
                      ),
                    );

                    if (agentToDelete) {
                      deleteTeamAgent({
                        teamId,
                        agent: agentToDelete,
                      });
                    }
                  }

                  if (teamForm.agents.length < agents.length) {
                    const agentToAdd = agents.find(
                      (deletedAgent) => !teamForm.agents.some(
                        (agent) => deletedAgent.username === agent.username,
                      ),
                    );

                    if (agentToAdd) {
                      addTeamAgent({
                        teamId,
                        agent: agentToAdd,
                      });
                    }
                  }
                }}
                renderOption={(agent, { selected }) => (
                  <>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    <>
                      <span>{`${agent.firstname} ${agent.lastname}\u00A0-\u00A0`}</span>
                      <span style={{ color: '#DB5C0E' }}>
                        {agent.is_project_manager ? 'PM' : 'SS'}
                      </span>
                    </>
                  </>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    style={{ width: '100%' }}
                    variant="standard"
                    label="Search/Add New Agent *"
                    placeholder="Search agent"
                    error={Boolean(validationErrors.agents)}
                    helperText={validationErrors.agents}
                  />
                )}
              />
            </Grid>
          </Grid>
          <div />
          <div
            className={`${classes.agentsContainer} ${teamForm.agents.length
              < 1 && classes.hidden}`}
          >
            <p className={classes.label}>Selected Agents</p>
            <List className={classes.agentsList}>
              <Grid container>
                {teamForm.agents.map((agent) => (
                  <Grid item style={{ width: '152px' }} key={agent.username}>
                    <ListItem
                      className={classes.agentsItem}
                      onClick={() => {
                        setTeamForm((prevState) => ({
                          ...prevState,
                          agents: prevState.agents.filter(
                            ({ username }) => username !== agent.username,
                          ),
                        }));

                        if (teamId) {
                          deleteTeamAgent({ teamId, agent });
                        }
                      }}
                    >
                      <>
                        <span>
                          {`${agent.firstname} ${agent.lastname} - ${
                            agent.is_project_manager ? 'PM' : 'AG'
                          }`}
                        </span>
                      </>
                      <ClearSharpIcon
                        style={{
                          width: 18,
                          height: 18,
                          marginLeft: 'auto',
                        }}
                      />
                    </ListItem>
                  </Grid>
                ))}
              </Grid>
            </List>
          </div>
        </div>
        <Divider />
        <CustomButton
          className={classes.submitButton}
          variant="orange"
          onClick={handleSubmit}
        >
          {teamId ? 'Update' : 'Submit'}
        </CustomButton>
      </Paper>
    </>
  );
};

export default TeamForm;
