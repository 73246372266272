import { Query, buildQuery } from "../utils/query";
import axios from "./axios";

export const loadMarketReport = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/properties/marketReport${query}`);
};

export const loadMarketReportExport = (
  selection: Array<number | string>,
  startDate: Date,
  endDate: Date,
  accept: 'application/pdf' | 'text/csv' = 'application/pdf',
) => {
  const headers = {
    Accept: accept,
  };

  return axios.post(
    'properties/marketReport/export',
    {
      selection,
      startDate,
      endDate,
    },
    { headers, responseType: 'blob' },
  );
};