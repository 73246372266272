import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import styles from './styles';

type Props = {
  icon: React.ReactElement;
  title: string;
};

const PropertyHubDeviceList: React.FC<Props> = (props) => {
  const { title, icon } = props;
  const classes = makeStyles(styles)();

  return (
    <Grid container alignItems="center" className={classes.deviceTitle}>
      {React.cloneElement(icon, { className: classes.deviceIcon })}
      <span className={classes.deviceName}>{title}</span>
    </Grid>
  );
};

export default React.memo(PropertyHubDeviceList);
