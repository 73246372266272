import { createStyles, makeStyles } from '@material-ui/core';

const styles = () => createStyles({
  'NO-Contact': {
    backgroundColor: '#F23636',
    color: '#FEFEFE',
  },

  Contact: {
    color: '#119122',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
