import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  contentWrapper: {
    display: 'flex',
    '& > div': {
      marginTop: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  actionsBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button': {
      textTransform: 'capitalize',
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  actionButtonsWrapper: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  backToButton: {
    backgroundColor: '#fff',
    color: theme.appPalette.orange,
  },
  actionButton: {
    backgroundColor: theme.appPalette.orange,
    color: '#fff',
    '&:hover': {
      color: theme.appPalette.orange,
    },
  },
  rightSide: {
    minHeight: '100%',
    padding: '8px 30px 40px 30px',
    backgroundColor: '#fff',
    boxShadow: theme.shadows[3],
    borderRadius: 10,
  },
  tab: {
    minWidth: 'fit-content',
  },
  tabsContent: {
    height: '100%',
  },
  timestamp: {
    color: theme.appPalette.orange,
  },
  lockIcon: {
    color: theme.appPalette.orange,
  },
  hubLogItem: {
    marginLeft: '10px',
  },
  progressWrapper: {
    position: 'relative',
    height: '100%',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  slider: {
    color: '#DB5C0E',
  },
  btnStyle: {
    transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1)',
    backgroundColor: '#DB5C0E',
    color: 'white',
    '&:hover': { color: theme.appPalette.orange },
  },
  btnWrap: { display: 'flex', justifyContent: 'flex-end' },
  padTyphography: {
    paddingTop: '20px',
  },
  hubTab: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
    },
    '& svg': {
      width: 50,
      height: 26,
      marginRight: theme.spacing(1),
    },
    '&.MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
      marginBottom: 0,
    },
  },
  hidden: {
    display: 'none',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  groupLockTitle: {
    color: '#fb5700',
    margin: 0,
    fontSize: '16px',
    fontWeight: 400,
    backgroundColor: 'rgba(251,87,0,0.08)',
    padding: '5px 10px',
  },
  beta: {
    color: '#fb5700',
    fontSize: '10px',
    marginBottom: '15px',
    marginLeft: '2px',
    fontWeight: 'bold',
  },
});
