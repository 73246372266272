import { connect } from 'react-redux';
import ScheduledVisitDetails from './ScheduledVisitDetails';
import { AppState } from '../../redux/store';
import {
  scheduledVisitDetailsRequest,
} from '../../redux/actions/scheduledVisits';
import { getSelected } from '../../redux/selectors/scheduledVisits';


const mapStateToProps = (state: AppState) => ({
  scheduledVisit: getSelected(state),
});

const mapDispatchToProps = {
  scheduledVisitDetailsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledVisitDetails);
