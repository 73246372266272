import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { formatDate } from '../../utils/date';

import styles from './styles';
import { Lock } from '../../redux/types/locks';

type Props = {
  loading?: boolean;
  lockDetails: Lock | null;
  loadLockDetails: (propertyId: number | string) => void;
} & WithStyles<typeof styles> & RouteComponentProps<{ lockSerial: string }>;

const LockDetails: React.FC<Props> = (props) => {
  const {
    classes, loadLockDetails, match, lockDetails,
  } = props;

  useEffect(() => {
    loadLockDetails(match.params.lockSerial);
  }, [loadLockDetails, match.params.lockSerial]);

  if (!lockDetails || String(lockDetails.serial_number) !== match.params.lockSerial) {
    return null;
  }

  return (
    <>
      <div className={classes.actionsBar}>
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            component={Link}
            to="/inventory"
          >
            <ArrowLeftIcon />
              Inventory
          </Button>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <Grid container justify="center">
          <Grid item xs={12} md={10} xl={8}>
            <div className={classes.card}>
              <div className={classes.cardHeader}>
                <Typography variant="h5">
                  {`Locks, View record [ Lock ID: ${lockDetails.serial_number} ]`}
                </Typography>
              </div>
              <div className={classes.cardContent}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      component="p"
                      color="textSecondary"
                      variant="caption"
                    >
                      Lock location
                    </Typography>
                    <Typography paragraph>{`${lockDetails.house_num} ${lockDetails.address_1}`}</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Typography
                      gutterBottom
                      component="p"
                      color="textSecondary"
                      variant="caption"
                    >
                      Lock ID
                    </Typography>
                    <Typography paragraph>{lockDetails.serial_number}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      gutterBottom
                      component="p"
                      color="textSecondary"
                      variant="caption"
                    >
                      Lock Status
                    </Typography>
                    <Typography paragraph>{lockDetails.lock_status_value}</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      component="p"
                      color="textSecondary"
                      variant="caption"
                    >
                      Lock Version
                    </Typography>
                    <Typography paragraph>{lockDetails.version}</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      gutterBottom
                      component="p"
                      color="textSecondary"
                      variant="caption"
                    >
                      Manufacturer
                    </Typography>
                    <Typography paragraph>{lockDetails.manufacturer}</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Typography
                      gutterBottom
                      component="p"
                      color="textSecondary"
                      variant="caption"
                    >
                      License start date
                    </Typography>
                    <Typography paragraph>{formatDate(lockDetails.license_start_date)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      gutterBottom
                      component="p"
                      color="textSecondary"
                      variant="caption"
                    >
                      Licensee
                    </Typography>
                    <Typography paragraph>{lockDetails.licensee}</Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default React.memo(LockDetails);
