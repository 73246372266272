import { connect } from 'react-redux';
import Communities from './Communities';
import {
  getCommunities, communitiesExportRequest,
  communitiesDeleteRequest, communitiesDeletedReset,
} from '../../redux/actions/communities';
import { exportAllRequest } from '../../redux/actions/exportAll';
import { AppState } from '../../redux/store';
import {
  selectAllCommunities, selectCommunitiesRequestStatus, getTotalCommunities, selectCommunitiesDeleted
} from '../../redux/selectors/communities';
import { getBuilderId } from '../../redux/selectors/auth';


const mapStateToProps = (state: AppState) => ({
  deleted: selectCommunitiesDeleted(state),
  communities: selectAllCommunities(state),
  loading: selectCommunitiesRequestStatus(state),
  total: getTotalCommunities(state),
  builderId: getBuilderId(state),
  printCommunities: () => { },
});

const mapDispatchToProps = {
  loadCommunities: getCommunities,
  exportCommunities: communitiesExportRequest,
  communitiesDelete: communitiesDeleteRequest,
  communitiesDeletedReset,
  exportAllRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Communities);
