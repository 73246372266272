import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, Grid, TextareaAutosize, TextField, Typography,
} from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import useStyles, { CustomSlider } from './styles';
import useDebounce from '../../hooks/useDebounce';
import { selectBuilderSettings } from '../../redux/selectors/builders';

type Props = {
  builderName: string;
  onButtonSettingsChange(buttonSettings: string): void
};

type ButtonSettings = {
  text: string
  textColor: string;
  backgroundColor: string;
  borderRadius: number | number[];
  fontSize: number | number[];
};

const ButtonGenerator = ({ builderName, onButtonSettingsChange }: Props) => {
  const [buttonSettings, setButtonSettings] = useState<ButtonSettings>({
    text: 'Schedule a Tour',
    textColor: '#ffffff',
    backgroundColor: '#DB5C0E',
    borderRadius: 5,
    fontSize: 14,
  });
  const [isOpen, setIsOpen] = React.useState(false);
  const handleButtonSettingsChange = useCallback(onButtonSettingsChange, []);
  const debouncedButtonSettings = useDebounce(buttonSettings, 300);
  const builderSettings = useSelector(selectBuilderSettings);

  useEffect(() => {
    handleButtonSettingsChange(JSON.stringify(debouncedButtonSettings));
  }, [debouncedButtonSettings, handleButtonSettingsChange]);

  useEffect(() => {
    if (builderSettings?.button_settings) {
      setButtonSettings(JSON.parse(builderSettings?.button_settings));
    }
  }, [builderSettings]);

  const toggleIsOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleChangeText = (e: any) => {
    e.preventDefault();
    setButtonSettings({ ...buttonSettings, text: e.currentTarget.elements.editText.value });
    toggleIsOpen();
  };

  const classes = useStyles();

  return (
    <>
      <div style={{ padding: '20px 20%' }}>
        <h2 className={classes.title}>Website Marketing Button</h2>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                className={classes.colorPicker}
                type="color"
                value={buttonSettings.backgroundColor}
                onChange={(e) => {
                  setButtonSettings({ ...buttonSettings, backgroundColor: e.target.value });
                }}
              />
              <TextField
                fullWidth
                label="Background Color"
                type="text"
                name="backgroundColor"
                id="backgroundColor"
                value={buttonSettings.backgroundColor.toUpperCase()}
                onChange={(e) => {
                  setButtonSettings({ ...buttonSettings, backgroundColor: e.target.value });
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                className={classes.colorPicker}
                type="color"
                value={buttonSettings.textColor}
                onChange={(e) => {
                  setButtonSettings({ ...buttonSettings, textColor: e.target.value });
                }}
              />
              <TextField
                fullWidth
                label="Text Color"
                placeholder="example: FF2324"
                value={buttonSettings.textColor.toUpperCase()}
                onChange={(e) => {
                  setButtonSettings({ ...buttonSettings, textColor: e.target.value });
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.label}>Font Size</Typography>
            <CustomSlider
              value={buttonSettings.fontSize}
              min={8}
              max={40}
              onChange={(_, value) => {
                setButtonSettings({ ...buttonSettings, fontSize: value });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.label}>Border Radius</Typography>
            <CustomSlider
              value={buttonSettings.borderRadius}
              min={0}
              max={36}
              onChange={(_, value) => {
                setButtonSettings({ ...buttonSettings, borderRadius: value });
              }}
            />
          </Grid>
        </Grid>
      </div>
      <Grid container justify="space-between" style={{ padding: '20px 20%' }}>
        <Grid item xs={12}>
          <h2 className={classes.title}>Button Design Preview</h2>
        </Grid>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item>
            <Button
              disabled
              type="button"
              className={classes.previewButton}
              style={{
                color: buttonSettings?.textColor,
                backgroundColor: buttonSettings?.backgroundColor,
                fontSize: Number(buttonSettings?.fontSize),
                borderRadius: Number(buttonSettings?.borderRadius),
              }}
            >
              {buttonSettings.text}
            </Button>
          </Grid>
          <Grid item>
            <Button className={classes.editButton} variant="outlined" color="primary" onClick={toggleIsOpen}>
              <EditOutlinedIcon />
              Edit Text
            </Button>
          </Grid>
        </Grid>
        <Dialog open={isOpen} onClose={toggleIsOpen} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Edit Text</DialogTitle>
          <form onSubmit={handleChangeText}>
            <DialogContent>

              <TextField
                defaultValue={buttonSettings.text}
                autoFocus
                autoComplete="off"
                name="editText"
                type="text"
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleIsOpen}>
            Cancel
              </Button>
              <Button type="submit">
            Change
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Grid>
      <Grid container style={{ padding: '20px 20%' }}>
        <Grid item xs={12}>
          <h2 className={classes.title}>Auto-generated code snippet</h2>
        </Grid>
        <Grid item xs>
          <TextareaAutosize
            className={classes.textArea}
            readOnly
            value={`<a href="${encodeURI(`https://www.webflow.nternow.com/${builderName.toLowerCase()}`)}" style="color: ${buttonSettings.textColor}; background-color: ${buttonSettings.backgroundColor}; font-size: ${buttonSettings.fontSize}px; border-radius: ${buttonSettings.borderRadius}px; padding: 16px 32px; text-decoration: none; font-weight: 400; line-height: 1;">${buttonSettings.text}</a>`}
          />
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: '20px' }} />
    </>
  );
};

export default ButtonGenerator;
