import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from './styles';
import PropertyImportDialog from './PropertyImportDialog';
import { updateBuilderFeedSettingsRequest } from '../../redux/actions/builders';
import { AppState } from '../../redux/store';
import { selectBuilderFeedSettings } from '../../redux/selectors/builders';
import { getBuilderName } from '../../redux/selectors/auth';

const mapDispatchToProps = {
  saveFeedSettings: updateBuilderFeedSettingsRequest,
};

const mapStateToProps = (state: AppState) => ({
  builderFeedSettings: selectBuilderFeedSettings(state),
  builder: getBuilderName(state),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(PropertyImportDialog),
);
