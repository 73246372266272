import axios from './axios';
import { Query, buildQuery } from '../utils/query';
import { BuilderFeedSettings } from '../redux/types/builders';

export const loadBuilders = <T>(queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get<T>(`/builders${query}`);
};

export const loadEnterpriseUserBuilders = (username: string) => axios.get(`/builders/enterprise/${username}`);

export const loadEnterpriseBuilders = (enterpriseId: number) => axios.get(`/builders/enterprise/id/${enterpriseId}`);

export const saveSettings = (settings: FormData) => axios.post('/builders/settings', settings);

export const saveDisclosures = (disclosures: any) => axios.post('/builders/settings/disclosures', disclosures);

export const loadSettings = (id: number) => axios.get(`/builders/settings/${id}`);

export const loadPMs = (id: number) => axios.get(`/builders/${id}`);

export const getBuilderIntegrations = () => axios.get('/builders/integrations');

export const getBuilderTimezone = () => axios.get('/builders/timezone');

export const updateBuilderTimezone = (timezone: number | null) => axios.post('/builders/timezone', { timezone });

export const getBuilderFeedSettings = (builderId: number) => axios.get(`/builders/feedSettings/${builderId}`);

export const updateBuilderFeedSettings = (feedSettings: BuilderFeedSettings) => axios.post('/builders/feedSettings', feedSettings);
