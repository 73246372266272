import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  CREATE_HUB_LOCK_CODE_REQUEST,
  DELETE_HUB_LOCK_CODE_REQUEST,
  GET_HUB_DEVICES_REQUEST,
  GetHubDevicesResponse,
  REFRESH_HUB_DEVICES_REQUEST,
  UPDATE_HUB_LIGHT_REQUEST,
  UPDATE_HUB_LOCK_AUTO_LOCK_REQUEST,
  UPDATE_HUB_LOCK_CODE_REQUEST, UPDATE_HUB_LOCK_CODES_REQUEST,
  UPDATE_HUB_LOCK_STATUS_REQUEST,
  UPDATE_HUB_THERMOSTAT_REQUEST,
  GetHubInfoResponse,
  Hub,
  GET_HUBS_REQUEST,
  HUB_REBOOT_REQUEST,
  GET_HUB_LOCK_LOG_REQUEST,
  HubLockLogType,
} from '../types/hubs';
import {
  getHubDevicesFailed,
  getHubInfoFailed,
  getHubDevicesRequest,
  getHubInfoRequest,
  getHubDevicesSuccess,
  getHubInfoSuccess,
  updateHubThermostatFailed,
  updateHubThermostatRequest,
  updateHubThermostatSuccess,
  setCurrentHubFormName,
  getHubsSuccess,
  hubRebootRequest,
  hubRebootRequestSuccess,
  hubRebootRequestFailed,
  getHubsRequest,
  getHubLockLogSuccess,
  getHubLockLogRequest,
  getHubLockLogFailed,
} from '../actions/hubs/hubs';
import {
  createHubLockCode, deleteHubLockCode,
  getHubDevices,
  getHubInfo,
  getHubReboot,
  getHubsByBuilderId, getLockLogs,
  updateHubLight,
  updateHubLockAutoLock, updateHubLockCode, updateHubLockCodes,
  updateHubLockStatus,
  updateHubThermostat,
} from '../../services/hubs';
import {
  createHubLockMasterCodeFailed,
  createHubLockMasterCodeRequest,
  createHubLockMasterCodeSuccess,
  deleteHubLockCodeFailed,
  deleteHubLockCodeRequest,
  deleteHubLockCodeSuccess,
  updateHubLockAutoLockFailed,
  updateHubLockAutoLockRequest,
  updateHubLockAutoLockSuccess, updateHubLockCodesFailed, updateHubLockCodesRequest, updateHubLockCodesSuccess,
  updateHubLockMasterCodeFailed,
  updateHubLockMasterCodeRequest,
  updateHubLockMasterCodeSuccess,
  updateHubLockStatusFailed,
  updateHubLockStatusRequest,
  updateHubLockStatusSuccess,
} from '../actions/hubs/hubLocks';
import { createSuccessSnackBar } from '../actions/snackbars';
import { updateHubLightFailed, updateHubLightRequest, updateHubLightSuccess } from '../actions/hubs/hubLights';

function* updateHubLockStatusSaga({ payload }: ReturnType<typeof updateHubLockStatusRequest>) {
  try {
    yield call(updateHubLockStatus, payload);
    yield put(updateHubLockStatusSuccess(payload));
    yield put(createSuccessSnackBar(`Lock is ${payload.status}`));
  } catch (err) {
    yield put(updateHubLockStatusFailed());
  }
}

function* updateHubLockAutoLockSaga({ payload }: ReturnType<typeof updateHubLockAutoLockRequest>) {
  try {
    yield call(updateHubLockAutoLock, payload);
    yield put(updateHubLockAutoLockSuccess(payload));
    yield put(createSuccessSnackBar(`Lock's auto-lock is ${payload.autoLock ? 'enabled' : 'disabled'}`));
  } catch (err) {
    yield put(updateHubLockAutoLockFailed());
  }
}

function* createHubLockMasterCodeSaga({ payload }: ReturnType<typeof createHubLockMasterCodeRequest>) {
  try {
    yield call(createHubLockCode, payload);
    yield put(createHubLockMasterCodeSuccess(payload));
    yield put(createSuccessSnackBar('Code is created'));
  } catch (err) {
    yield put(createHubLockMasterCodeFailed());
  }
}

function* updateHubLockMasterCodeSaga({ payload }: ReturnType<typeof updateHubLockMasterCodeRequest>) {
  try {
    yield call(updateHubLockCode, payload);
    yield put(updateHubLockMasterCodeSuccess(payload));
    yield put(createSuccessSnackBar('Code is updated'));
  } catch (err) {
    yield put(updateHubLockMasterCodeFailed());
  }
}

function* updateHubLockCodesSaga({ payload }: ReturnType<typeof updateHubLockCodesRequest>) {
  try {
    yield call(updateHubLockCodes, payload);
    yield put(updateHubLockCodesSuccess(payload));
    yield put(createSuccessSnackBar('Codes are updated'));
  } catch (err) {
    yield put(updateHubLockCodesFailed());
  }
}

function* deleteHubLockCodeSaga({ payload }: ReturnType<typeof deleteHubLockCodeRequest>) {
  try {
    yield call(deleteHubLockCode, payload);
    yield put(deleteHubLockCodeSuccess(payload));
    yield put(createSuccessSnackBar('Code is deleted'));
  } catch (err) {
    yield put(deleteHubLockCodeFailed());
  }
}

function* updateHubLightSaga(action: ReturnType<typeof updateHubLightRequest>) {
  try {
    yield call(updateHubLight, action.payload);
    yield put(updateHubLightSuccess(action.payload));
    yield put(createSuccessSnackBar(`Light is ${action.payload.status === 'on' ? 'on' : 'off'}`));
  } catch (err) {
    yield put(updateHubLightFailed());
  }
}

function* updateHubThermostatSaga(action: ReturnType<typeof updateHubThermostatRequest>) {
  try {
    yield call(updateHubThermostat, action.payload);
    yield put(updateHubThermostatSuccess());
  } catch (err) {
    yield put(updateHubThermostatFailed());
  }
}

function* clearSelectedFormNameSaga() {
  yield put(setCurrentHubFormName(null));
}

function* hubReboot(action: ReturnType<typeof hubRebootRequest>) {
  try {
    yield call(getHubReboot, action.payload);
    yield put(hubRebootRequestSuccess());
  } catch (err) {
    yield put(hubRebootRequestFailed());
  }
}

function* getHubDevicesSaga(action: ReturnType<typeof getHubDevicesRequest>) {
  try {
    const response: AxiosResponse<GetHubDevicesResponse> = yield call(getHubDevices, action.payload);
    yield put(getHubDevicesSuccess(response.data));
  } catch (err) {
    yield put(getHubDevicesFailed());
  }
}

function* getHubsSaga(action: ReturnType<typeof getHubsRequest>) {
  try {
    const response: AxiosResponse<Hub[]> = yield call(getHubsByBuilderId, action.payload);
    yield put(getHubsSuccess(response.data));
  } catch (err) {
    yield put(getHubDevicesFailed());
  }
}

function* getHubInfoSaga(action: ReturnType<typeof getHubInfoRequest>) {
  try {
    const response: AxiosResponse<GetHubInfoResponse> = yield call(getHubInfo, action.payload);
    yield put(getHubInfoSuccess(response.data));
  } catch (err) {
    yield put(getHubInfoFailed());
  }
}

function* getHubLockLogSaga(action: ReturnType<typeof getHubLockLogRequest>) {
  try {
    const response: AxiosResponse<HubLockLogType[]> = yield call(getLockLogs, action.payload.hubId, action.payload.queryObject);
    yield put(getHubLockLogSuccess(response.data));
  } catch (err) {
    yield put(getHubLockLogFailed());
  }
}

export default all([
  takeLatest([GET_HUB_DEVICES_REQUEST, REFRESH_HUB_DEVICES_REQUEST], getHubDevicesSaga),
  takeLatest(GET_HUB_DEVICES_REQUEST, getHubInfoSaga),
  takeLatest(GET_HUB_LOCK_LOG_REQUEST, getHubLockLogSaga),
  takeLatest(GET_HUBS_REQUEST, getHubsSaga),
  takeLatest(UPDATE_HUB_LOCK_STATUS_REQUEST, updateHubLockStatusSaga),
  takeLatest(UPDATE_HUB_LOCK_AUTO_LOCK_REQUEST, updateHubLockAutoLockSaga),
  takeLatest(CREATE_HUB_LOCK_CODE_REQUEST, createHubLockMasterCodeSaga),
  takeLatest(UPDATE_HUB_LOCK_CODE_REQUEST, updateHubLockMasterCodeSaga),
  takeLatest(UPDATE_HUB_LOCK_CODES_REQUEST, updateHubLockCodesSaga),
  takeLatest(DELETE_HUB_LOCK_CODE_REQUEST, deleteHubLockCodeSaga),
  takeLatest(HUB_REBOOT_REQUEST, hubReboot),

  takeLatest(UPDATE_HUB_LIGHT_REQUEST, updateHubLightSaga),

  takeLatest(UPDATE_HUB_THERMOSTAT_REQUEST, updateHubThermostatSaga),
  takeLatest([], clearSelectedFormNameSaga),
]);
