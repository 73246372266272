export type Query = Record<string, string | number | Date | null | boolean>;

export function buildQuery(queryObject: Query | undefined, prefix = '?'): string {
  if (!queryObject) {
    return '';
  }

  const filteredQueryObject = Object
    .entries(queryObject)
    .filter(([, value]) => value !== null && value !== '');

  const query = filteredQueryObject
    .map(([key, value]) => (value ? `${key}=${value}` : ''))
    .join('&');

  return `${prefix}${query}`;
}
