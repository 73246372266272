import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    backgroundColor: '#EDECF2',
    '& button.MuiTab-root': {
      fontSize: 16,
      fontWeight: 'normal',
      textTransform: 'capitalize',
      color: '#8E8B99',
      '&.Mui-selected': {
        color: theme.appPalette.orange,
      },
    },
    '& span.MuiTabs-indicator': {
      backgroundColor: theme.appPalette.orange,
    },
  },

});
