import { useEffect, useState, useContext } from 'react';
import { Query } from '../../../utils/query';
import { Order } from '../../../utils/table';
import { Offer } from '../../../redux/types/offers';
import { SearchSortContext } from '../../../context/SearchSortContext';
import { ChangeSearchPayload, PageSearchSortContextActionType } from '../../../context/types';
import parseUrlFilterSearch from '../../../hooks/parseUrlFilterSearch';
import useUrlFilterSearch from '../../../hooks/useUrlFilterSearch';
import { UrlParam } from '../../../redux/types/filter';


const useOffers = (loadOffers: (query: Query) => void) => {
  const urlParse = parseUrlFilterSearch<UrlParam>();
  const { dispatch, state: { offers } } = useContext(SearchSortContext);
  const {
    currentOrderBy, currentOrder, currentPage, rowsPerPageAmount, contextSearch,
  } = offers;

  const [page, setPage] = useState<number>(urlParse.page || currentPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(urlParse.limit || rowsPerPageAmount);
  const [search, setSearch] = useState<string>(urlParse.search || contextSearch);
  const [order, setOrder] = useState<Order>(urlParse.order || currentOrder);
  const [orderBy, setOrderBy] = useState<keyof Offer>(urlParse.orderBy || currentOrderBy);

  useUrlFilterSearch<UrlParam>({
    search, order, orderBy, page, limit: rowsPerPage,
  });

  const dispatchOffers = (
    type: PageSearchSortContextActionType<'CHANGE_OFFER'>,
    payload?: ChangeSearchPayload<Offer>,
  ) => {
    dispatch({ type, payload: { ...offers, ...payload } });
  };
  useEffect(() => {
    loadOffers({
      search, order, orderBy, page, limit: rowsPerPage,
    });
  }, [order, orderBy, page, rowsPerPage, search, loadOffers]);

  return {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    // saveLocalState,
    dispatchOffers,
  };
};

export default useOffers;
