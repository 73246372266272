import { createSelector } from 'reselect';
import { AppState } from '../store';
import { SmartLocksState } from '../types/smartLocks';
import { LOCK_STATUS_RETURNED } from '../types/locks';

const selectSmartLocksState = (state: AppState) => state.smartLocks;
const selectItems = (state: SmartLocksState) => state.items;
const selectActiveItems = (state: SmartLocksState) => state.items.filter(
  (item) => item.smart_lock_status_value !== LOCK_STATUS_RETURNED,
);
const selectSelectedSmartLock = (state: SmartLocksState) => state.selected;
const selectLoading = (state: SmartLocksState) => state.loading;
const selectRefreshing = (state: SmartLocksState) => state.refreshing;

export const selectSmartLocksLoading = createSelector(
  selectSmartLocksState,
  selectLoading,
);

export const selectSmartLocksRefreshing = createSelector(
  selectSmartLocksState,
  selectRefreshing,
);

export const selectSmartLocks = createSelector(
  selectSmartLocksState,
  selectItems,
);

export const selectSmartLocksActive = createSelector(
  selectSmartLocksState,
  selectActiveItems,
);

export const selectSmartLocksRequestStatus = createSelector(
  selectSmartLocksState,
  selectLoading,
);

export const selectSmartLockDetails = createSelector(
  selectSmartLocksState,
  selectSelectedSmartLock,
);
