import React from 'react';
import { WithStyles } from '@material-ui/styles';
import ProgressBar from '../ProgressBar';
import Sidebar from '../../components/Sidebar';
import UserToolbar from '../UserToolbar';

import styles from './styles';

type Props = {
  children: React.ReactNode;
} & WithStyles<typeof styles>;

const MainLayout: React.FC<Props> = props => {
  const { classes, children } = props;
  return (
    <div className={classes.root}>
      <Sidebar />
      <main className={classes.content}>
        <ProgressBar />
        <UserToolbar />
        {children}
      </main>
    </div>
  );
};

export default React.memo(MainLayout);
