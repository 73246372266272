import { connect } from 'react-redux';
import { AppState } from '../../redux/store';
import Disclosures from './Disclosures';
import { saveBuilderDisclosures } from '../../redux/actions/builders';
import { getBuilderId } from '../../redux/selectors/auth';
import { selectBuilderSettingsLoading } from '../../redux/selectors/builders';

const mapStateToProps = (state: AppState) => ({
  builderId: getBuilderId(state),
  isLoading: selectBuilderSettingsLoading(state),
});

const mapDispatchToProps = {
  saveDisclosures: saveBuilderDisclosures,
};

export default connect(mapStateToProps, mapDispatchToProps)(Disclosures);
