import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { ReactComponent as HubSvg } from '../../assets/hubIcon.svg';

const HubIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <HubSvg />
  </SvgIcon>
);

export default React.memo(HubIcon);
