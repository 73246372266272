import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Spinner from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import SearchBar from '../SearchBar';
import useStyles from './styles';
import TablePagination from '../../components/CustomTablePagination';
import useTeams from './hooks/useTeams.hook';
import CustomButton from '../../components/CustomButton';
import Table from '../../components/CustomTable';
import ConfirmDialog from '../../components/ConfirmDialog';
import { Community } from '../../redux/types/communities';
import { Order } from '../../utils/table';
import { ROWS_PER_PAGE_OPTIONS } from '../../constants/table';
import { Query } from '../../utils/query';
import { ExportAllParams } from '../../redux/types/exportAll';
import { MimeType } from '../../redux/types/mime';
import { Team } from '../../redux/types/teams';
import { formatDate } from '../../utils/date';
import { HeaderRowConfig } from '../../components/CustomTable/CustomTable';
import addIcon from '../../assets/addIcon.svg';

type Props = {
  teams: Team[];
  teamsTotal: number;
  loading: boolean;
  loadTeams: (query: Query) => void;
  exportCommunities: (selection: Array<number | string>) => void;
  printCommunities: (selection: Array<number | string>) => void;
  deleteTeams: (selection: Array<number | string>) => void;
  communitiesDeletedReset: () => void;
  exportAllRequest: (path: string, mimeType: MimeType, params: ExportAllParams) => void
  deleted: Array<string | number>;
  total: number;
  builderId: number;
} & RouteComponentProps;

const searchBarPlaceholder: string = 'Search all teams';


const headRows: HeaderRowConfig<Team>[] = [
  {
    key: 'name',
    align: 'center',
    disablePadding: false,
    label: 'Team Name',
  },
  {
    key: 'description',
    align: 'center',
    disablePadding: false,
    label: 'Description',
  },
  {
    key: 'total_members',
    align: 'center',
    disablePadding: false,
    label: 'Total Members',
  },
  {
    key: 'created_on',
    align: 'center',
    disablePadding: true,
    label: 'Created On',
    prepareData: ({ created_on: createdOn }) => formatDate(createdOn),
  },
];

const Teams: React.FC<Props> = (props) => {
  const {
    match,
    history,
    loadTeams,
    deleteTeams,
    teams,
    teamsTotal,
    loading,
  } = props;
  const classes = useStyles();
  const {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    dispatchTeams,
  } = useTeams(loadTeams);


  const [selected, setSelected] = useState<Community['id'][]>([]);
  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);

  const selectedLength = selected.length || '';

  useEffect(() => {
    setSelected([]);
  }, [page, rowsPerPage]);

  const handleChangeOrder = (newOrder: Order, newOrderBy: keyof Team) => {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    setPage(0);
    dispatchTeams('CHANGE_TEAM', {
      currentOrder: newOrder,
      currentOrderBy: newOrderBy,
      currentPage: 0,
    });
  };

  function handleSelect(newSelected: any[]) {
    setSelected(newSelected);
  }

  const showConfirm = useCallback(() => {
    setOpenConfirm(true);
  }, []);

  const confirmDelete = () => {
    deleteTeams(selected);
    setSelected([]);
    setOpenConfirm(false);
  };

  const closeConfirm = (): void => {
    setOpenConfirm(false);
  };

  const handleTeamSelect = (team: Team) => {
    history.push(`${match.url}/${team.id}`);
  };

  function handleChangePage(newPage: number) {
    setPage(newPage);
    dispatchTeams('CHANGE_TEAM', { currentPage: newPage });
  }

  function handleChangeRowsPerPage(rowsPerPageAmount: number) {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
    dispatchTeams('CHANGE_TEAM', {
      currentPage: 0,
      rowsPerPageAmount,
    });
  }


  const handleSearch = (value: string) => {
    setPage(0);
    setSearch(value);
    dispatchTeams('CHANGE_TEAM', {
      contextSearch: value,
      currentPage: 0,
    });
  };

  const searchResuest = () => {
    dispatchTeams('CHANGE_TEAM');
  };

  return (
    <>
      <ConfirmDialog
        isOpen={openConfirm}
        onClose={closeConfirm}
        onDelete={confirmDelete}
      />

      <Grid container spacing={8}>
        <Grid item className={classes.controlButton}>
          <CustomButton
            variant="orange"
            color="primary"
            onClick={() => history.push(`${match.path}/add`)}
          >
            <img src={addIcon} alt="add icon" style={{ marginRight: 10 }} />
            Add New Team
          </CustomButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <SearchBar
            defaultValue={search}
            placeholder={searchBarPlaceholder}
            onChange={handleSearch}
            searchRequest={searchResuest}
          />
        </Grid>
      </Grid>

      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <Typography variant="h5">
              Teams List
              {selectedLength > 0 ? ` ${selectedLength} selected.` : ''}
            </Typography>
          </div>
          <div className={classes.spacer} />
          {selectedLength && (
            <div className={classes.toolbarActions}>
              <span>Selected actions: </span>
              <Button size="small" onClick={showConfirm}>
                Delete
              </Button>
            </div>
          )}
        </Toolbar>
        <div className={classes.tableWrapper}>
          {loading && (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
          <Table<Team>
            hover
            className={classes.table}
            IDKey="id"
            selected={selected}
            data={teams}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            header={headRows}
            onSelect={handleSelect}
            onChangeOrder={handleChangeOrder}
            onRowClick={handleTeamSelect}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={teams?.length}
          rowsTotal={teamsTotal}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default React.memo(Teams);
