import { createStyles } from '@material-ui/core';

export default () => createStyles({
  badgeBase: {
    display: 'inline-block',
    padding: '10px 25px',
    borderRadius: 5,
    fontSize: 18,
    color: '#fff',
    textTransform: 'uppercase',
    backgroundColor: '#040620',
  },
});
