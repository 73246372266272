/* eslint-disable import/prefer-default-export */
import {
  EXPORT_ALL_REQUEST,
  EXPORT_ALL_REQUEST_SUCCESS,
  EXPORT_ALL_REQUEST_FAILED,
} from '../types/visits';
import { MimeType } from '../types/mime';
import { ExportAllParams } from '../types/exportAll';
import { Query } from '../../utils/query';

export const exportAllRequest = (
  path: string,
  mimeType: MimeType = 'text/csv',
  params: ExportAllParams = {},
  query: Query = {},
) => ({
  type: EXPORT_ALL_REQUEST,
  payload: { path, mimeType, params, query },
} as const);

export const exportAllSuccess = () => ({
  type: EXPORT_ALL_REQUEST_SUCCESS,
} as const);

export const exportAllFailed = () => ({
  type: EXPORT_ALL_REQUEST_FAILED,
} as const);
