import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  saveButton: {
    color: '#FFFFFF',
    minWidth: 100,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    backgroundColor: '#DB5C0E',
  },
  paper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: 25,
  },
  progressWrapper: {
    position: 'relative',
  },

  navButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '44px 10% 60px 10%',

    '& button:first-child': {
      marginRight: '15px',
    },
  },

  headerText: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '50px',
  },

  note: {
    textAlign: 'center',
    fontSize: '14px',
    color: `${theme.palette.text.secondary}`,
    '& > span': {
      color: 'red',
    },
  },

  disclaimer: {
    textAlign: 'center',
    fontSize: '14px',
    paddingTop: '10px',
    marginBottom: '20px',
    fontWeight: 'bold',
    '& > span': {
      color: 'red',
    },
  },

  stepper: {
    margin: '0 50px 50px 50px',
  },

  stepIcon: {
    color: `${theme.appPalette.orange} !important`,
  },

  connectorCompleted: {
    borderTopWidth: '4px !important',
    borderTopColor: 'black !important',
  },

  connectorLineDisabled: {
    borderTopWidth: '3px !important',
    borderTopColor: `${theme.appPalette.orange}`,
    // borderTopColor: `red !important`,
  },

  connector: {
    alternativeLabel: { top: 9 },
    active: { '& $line': { borderColor: 'blue' } },
    completed: { '& $line': { borderColor: 'green' } },
    line: {
      borderTopWidth: 2,
    },
    vertical: {
      marginLeft: 9,
    },
    lineVertical: {
      borderLeftWidth: 2,
    },
  },
  inputForm: {
    borderTop: '1px solid rgba(142, 139, 153, 0.25)',
    borderBottom: '1px solid rgba(142, 139, 153, 0.25)',
    padding: '32px 10% 32px 10%',
    minHeight: '544px',
  },
});


const useStyles = makeStyles(styles);

export default useStyles;
