import { ErrorMessage, ValueOf } from '../../types/main';
import * as LocksActions from '../actions/locks';

export const LOCKS_REQUEST = 'LOCKS_REQUEST';
export const LOCKS_REQUEST_SUCCESS = 'LOCKS_REQUEST_SUCCESS';
export const LOCKS_REQUEST_FAILED = 'LOCKS_REQUEST_FAILED';
export const LOCK_DETAILS_REQUEST = 'LOCK_DETAILS_REQUEST';
export const LOCK_DETAILS_REQUEST_SUCCESS = 'LOCK_DETAILS_REQUEST_SUCCESS';
export const LOCK_DETAILS_REQUEST_FAILED = 'LOCK_DETAILS_REQUEST_FAILED';
export const LOCK_STATUS_ACTIVE_VALUE = 'Active';
export const LOCK_STATUS_ACTIVE_ID = 116;
export const LOCK_STATUS_DOWN_VALUE = 'Down';
export const LOCK_STATUS_RETURNED = 'To be returned';
export const LOCK_STATUS_DOWN_ID = 117;
export const LOCK_STATUS_OTHER_VALUE = 'Other';
export const LOCK_STATUS_OTHER_ID = 118;

export type LocksActionsTypes = ReturnType<ValueOf<typeof LocksActions>>;

export interface Lock {
  builder_id: number;
  property_id: number
  address_1: string
  house_num: string
  lock_status_value: string
  serial_number: string
  battery_change: string | null
  purchase: string
  comments: string
  lock_status: number
  additional_info_1: string
  additional_info_2: string
  version: string
  licensee: string
  manufacturer: string
  license_start_date: string | null
  email_1: string
  created_on: string
  modified_on: string
  lock_shipment_status?: string
  license_date: string
  community_name?: string
}

export type LocksRequestSuccess = Lock[];
export type LockDetailsRequestSuccess = Lock;

export type LocksState = {
  items: Lock[];
  loading: boolean;
  error: ErrorMessage['message'];
  selected: Lock | null;
};
