import React from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

const SmallLogoIcon: React.FC<SvgIconProps> = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="47px"
    height="47px"
    viewBox="0 0 50 50"
    {...props}
  >
    <g id="surface1">
      <path
        style={{
          stroke: "none",
          fillRule: "evenodd",
          fill: "rgb(98.431373%,34.117647%,0%)",
          fillOpacity: 1,
        }}
        d="M 40.917969 17.269531 L 32.046875 15.582031 L 23.671875 27.285156 L 18.601562 34.621094 L 7.125 28.363281 L 7.125 32.429688 L 23.523438 41.996094 L 28.074219 39.355469 L 28.097656 28.6875 L 33.441406 21.34375 L 33.441406 36.242188 L 40.960938 31.886719 Z M 40.917969 17.269531 "
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "rgb(99.215686%,73.72549%,60%)",
          fillOpacity: 1,
        }}
        d="M 30.941406 9.535156 L 13.589844 0.00390625 L 27.570312 2.394531 L 44.921875 11.921875 Z M 30.941406 9.535156 "
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "rgb(99.607843%,84.313725%,76.078431%)",
          fillOpacity: 1,
        }}
        d="M 30.945312 9.535156 L 44.925781 11.921875 L 43.921875 14.296875 L 31.105469 11.945312 L 18.421875 29.636719 L 17.351562 28.488281 L 30.941406 9.53125 Z M 30.945312 9.535156 "
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "rgb(99.215686%,73.72549%,60%)",
          fillOpacity: 1,
        }}
        d="M 18.421875 29.636719 L 1.070312 20.101562 L 0 18.957031 L 17.351562 28.492188 Z M 18.421875 29.636719 "
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: "rgb(98.431373%,34.117647%,0%)",
          fillOpacity: 1,
        }}
        d="M 17.351562 28.492188 L 0 18.960938 L 13.589844 0.00390625 L 30.941406 9.535156 Z M 17.351562 28.492188 "
      />
    </g>
  </svg>
);

export default React.memo(SmallLogoIcon);
