import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import MatTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { Order } from '../../../utils/table';
import { HubActivityLog } from '../../../redux/types/hubs';

type Props = {
  order: Order;
  orderBy: keyof HubActivityLog;
  onRequestSort: (
    event: React.SyntheticEvent,
    property: keyof HubActivityLog
  ) => void;
};

type HeadRow = {
  id: keyof HubActivityLog;
  align: 'left' | 'center' | 'right';
  disablePadding: boolean;
  label: string;
};

const headRows: HeadRow[] = [
  {
    id: 'id',
    align: 'center',
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'hub_type',
    align: 'center',
    disablePadding: true,
    label: 'Hub Type',
  },
  {
    id: 'lock_type',
    align: 'center',
    disablePadding: true,
    label: 'Lock Type',
  },
  {
    id: 'serial_number',
    align: 'center',
    disablePadding: true,
    label: 'Serial number',
  },
  {
    id: 'property_id',
    align: 'center',
    disablePadding: true,
    label: 'Property ID',
  },
  {
    id: 'action',
    align: 'center',
    disablePadding: true,
    label: 'Action',
  },
  {
    id: 'created_on',
    align: 'center',
    disablePadding: true,
    label: 'Timestamp',
  },
  {
    id: 'created_by',
    align: 'center',
    disablePadding: true,
    label: 'User',
  },
  {
    id: 'users_email',
    align: 'center',
    disablePadding: true,
    label: 'User email',
  },
];

const TableHead: React.FC<Props> = (props) => {
  const {
    onRequestSort,
    orderBy,
    order,
  } = props;

  const createSortHandler = (property: keyof HubActivityLog) => (
    event: React.SyntheticEvent,
  ): void => {
    onRequestSort(event, property);
  };

  return (
    <MatTableHead>
      <TableRow>
        {headRows.map(({
          id, align, disablePadding, label,
        }) => (
          <TableCell
            key={id}
            align={align}
            padding={disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === id ? order : false}
            onClick={createSortHandler(id)}
          >
            {label}
            <TableSortLabel
              active={orderBy === id}
              direction={order}
              IconComponent={ArrowIcon}
            />
          </TableCell>
        ))}
      </TableRow>
    </MatTableHead>
  );
};

export default React.memo(TableHead);
