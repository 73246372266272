import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  card: {
    height: '100%',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  content: {
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: 27,
    '& > div': {
      '&:first-of-type': {
        marginBottom: 22,
        paddingRight: 16,
        flexBasis: '55%',
        flexGrow: 1,
      },
    },
  },
  icon: {
    fontSize: 50,
    marginRight: 25,
  },
  titleWrapper: {
    color: theme.appPalette.orange,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  description: {
    marginTop: 25,
    fontSize: 22,
  },
  imgWrapper: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg': {
      fontSize: 113,
      color: theme.appPalette.orange,
    },
  },
  cardImg: {
    margin: 'auto',
    '& > img': {
      display: 'block',
    },
  },
});
