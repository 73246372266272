import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  icon: {
    color: '#fff',
  },
  avatar: {
    backgroundColor: theme.appPalette.orange,
  },
  link: {
    color: theme.appPalette.orange,
  },
});
