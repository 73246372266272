import { connect } from 'react-redux';
import SnackBars from './SnackBars';
import { selectSnackbars } from '../../redux/selectors/snackbars';
import { deleteSnackBar } from '../../redux/actions/snackbars';

import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  snackbars: selectSnackbars(state),
});

const mapDispatchToProps = {
  removeSnackBar: deleteSnackBar,
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackBars);
