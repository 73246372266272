import React, { useCallback } from 'react';
import PropertyHubDeviceList from '../PropertyHubDeviceList';
import LockIcon from '../Icons/LockIcon';
import HubLockForm from '../HubLockForm';
import { HubLock } from '../../redux/types/hubs';
import HubLockTitleWithStatus from './components/HubLockTitleWithStatus';

type Props = {
  hubId: string
  hubLocks: HubLock[]
  selectedHubLock: HubLock | null
  setHubSelectedLock: (record: HubLock) => void
};

const PropertyHubLockDeviceList: React.FC<Props> = (props) => {
  const {
    setHubSelectedLock, hubLocks, selectedHubLock, hubId,
  } = props;

  const form = selectedHubLock && <HubLockForm hubId={hubId} record={selectedHubLock} />;

  const recordSelectHandler = useCallback((index: number) => {
    setHubSelectedLock(hubLocks[index]);
  }, [setHubSelectedLock, hubLocks]);

  const rowRecords = hubLocks
    .map(({ name, properties: { status, battery } }) => ({
      name,
      status: <HubLockTitleWithStatus status={status} battery={battery} />,
    }));

  if (!rowRecords.length) {
    return null;
  }

  return (
    <PropertyHubDeviceList
      title="Lock"
      icon={<LockIcon />}
      hubFormName="lock-form"
      hubForm={form}
      records={rowRecords}
      recordSelectHandler={recordSelectHandler}
    />
  );
};

export default React.memo(PropertyHubLockDeviceList);
