import { useQuery } from 'react-query';
import { getHubActivityLogs } from '../services/hubs';
import { Order } from '../utils/table';

export const useGetHubActivityLogs = ({
  search, page, limit, orderBy, order,
}: {
  search: string, page: number, limit: number,
  order: Order, orderBy: string,
}) => useQuery(
  ['hubActivityLogs', search, page, limit, order, orderBy],
  () => getHubActivityLogs({
    search, page, limit, orderBy, order
  }),
  {
    refetchOnWindowFocus: false,
  },
);
