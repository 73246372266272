import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CustomButton from '../CustomButton';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
};

const ConfirmDialog: React.FC<Props> = ({
  isOpen, onClose, onDelete,
}) => (
  <Dialog
    open={isOpen}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      <Typography variant="h5" color="secondary">
          Are you sure you want to delete?
      </Typography>

    </DialogTitle>

    <DialogActions>
      <CustomButton
        onClick={onClose}
        variant="white"
      >
        Cancel
      </CustomButton>
      <CustomButton
        onClick={onDelete}
        variant="orange"
      >
        Remove
      </CustomButton>
    </DialogActions>
  </Dialog>
);

export default React.memo(ConfirmDialog);
