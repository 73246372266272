import React from 'react';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import { WithStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import StatusBadge from '../StatusBadge';
import TypeBadge from '../TypeBadge';
import { getInitials } from '../../utils/helpers';

import styles from './styles';
import { CustomerInfo, UpdateCustomer } from '../../redux/types/customers';

type Props = {
  customerInfo: CustomerInfo;
  updateCustomerRequest: (payload: UpdateCustomer) => void;
} & WithStyles<typeof styles>;

function getFullAddress({
  street_address: streetAddress,
  city,
  state,
  zipcode,
}: CustomerInfo): string {
  return [streetAddress, city, `${state} ${zipcode}`]
    .filter(Boolean)
    .join(', ');
}

const CustomerInfoCard: React.FC<Props> = (props) => {
  const { classes, customerInfo, updateCustomerRequest } = props;
  const visitorStatus = customerInfo.visitor_status_id_value || '';
  const visitorType = customerInfo.visitor_type_id_value || '';
  const match = useRouteMatch<{ customerId: string }>();

  return (
    <Grid className={classes.content}>
      <Grid>
        <Avatar component="div" classes={{ root: classes.rootAvatar }}>
          {getInitials(customerInfo.firstname, customerInfo.lastname)}
        </Avatar>
      </Grid>
      <Grid className={classes.row}>
        <Typography className={classes.customerName}>
          {`${customerInfo.firstname} ${customerInfo.lastname}`}
        </Typography>
      </Grid>
      <Grid className={classnames(classes.row, classes.statusAndType)}>
        <Grid>
          <Typography color="textSecondary">Recent status</Typography>
          <StatusBadge status={visitorStatus} />
        </Grid>
        <Grid>
          <Typography color="textSecondary">Type</Typography>
          <TypeBadge type={visitorType} />
        </Grid>
      </Grid>
      <Grid className={classes.row}>
        <Divider />
      </Grid>
      <Grid className={classes.row}>
        <Typography className={classes.title}>Contact Information</Typography>
        <Grid spacing={4} container wrap="wrap">
          {customerInfo.email_1 && (
            <Grid item xs={12}>
              <Typography color="textSecondary">Email</Typography>
              <Typography className={classes.email}>
                {customerInfo.email_1}
              </Typography>
            </Grid>
          )}

          {customerInfo.cell_phone_1 && (
            <Grid item xs={12}>
              <Typography color="textSecondary">Phone</Typography>
              <Typography noWrap>{customerInfo.cell_phone_1}</Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography color="textSecondary">Address</Typography>
            <Typography>{getFullAddress(customerInfo)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color="textSecondary">Contact Status</Typography>
            <Typography
              className={classes[customerInfo.visitor_contact_status_id_value]}
              noWrap
            >
              {customerInfo.visitor_contact_status_id_value}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              labelPlacement="start"
              className={classes.blacklist}
              label="Blacklist"
              control={(
                <Checkbox
                  checked={customerInfo.visitor_status_id === 155}
                  onChange={(e, checked) => {
                    if (checked) {
                      updateCustomerRequest({
                        customer_id: Number(match.params.customerId),
                        visitor_status_id: 155,
                      });
                      return;
                    }

                    updateCustomerRequest({
                      customer_id: Number(match.params.customerId),
                      visitor_status_id: 154,
                    });
                  }}
                  name="blacklist"
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(CustomerInfoCard);
