import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { WithStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { SEARCH_INPUT_DELAY } from '../../constants/input';

import styles from './styles';
import SearchFilterElement from '../../components/SearchFilterElement';
import useUrlLocation from '../../hooks/useUrlLocation';
import { getEnterpriseId } from '../../redux/selectors/auth';

function noOP(): void {}

type Props = {
  onChange?: (search: string) => void;
  placeholder?: string;
  defaultValue?: string;
  searchRequest?: () => void;
} & WithStyles<typeof styles>;

const SearchBar: React.FC<Props> = (props) => {
  const {
    classes, onChange = noOP, placeholder, defaultValue,
  } = props;
  const enterpriseId = useSelector(getEnterpriseId);
  const debounced = useCallback(debounce(onChange, SEARCH_INPUT_DELAY), [
    onChange,
  ]);
  const searchBarRef = React.useRef<HTMLDivElement | null>(null);
  const pathes = ['customers', 'properties', 'visits', 'inventory'];
  const enterprisePathes = ['communities', 'agents'];
  const [
    pathForRenderingSearchFilter,
    setPathForRenderingSearchFilter,
  ] = useState(pathes);
  const currentPath = useUrlLocation();

  useEffect(() => {
    const containsEnterprisePathes = enterprisePathes.some((path) => pathForRenderingSearchFilter.includes(path));

    if (enterpriseId && !containsEnterprisePathes) {
      setPathForRenderingSearchFilter([...pathes, ...enterprisePathes]);
    }
  }, [enterpriseId, pathes, enterprisePathes, pathForRenderingSearchFilter]);

  function handleChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    debounced(e.target.value);
  }

  return (
    <OutlinedInput
      ref={searchBarRef}
      fullWidth
      labelWidth={0}
      name="searchBar"
      autoComplete="off"
      defaultValue={defaultValue}
      placeholder={placeholder}
      classes={{ root: classes.searchBarRoot }}
      onChange={handleChange}
      startAdornment={(
        <InputAdornment position="start">
          <SearchIcon className={classes.searchBarIcon} />
        </InputAdornment>
      )}
      endAdornment={(
        <>
          {pathForRenderingSearchFilter.map(
            (path) => path === currentPath && (
            <SearchFilterElement key={path} searchBarRef={searchBarRef} />
            ),
          )}
        </>
      )}
    />
  );
};

SearchBar.defaultProps = {
  placeholder: 'Search...',
};

export default React.memo(SearchBar);
