import React from 'react';

type PageNumbersProps = {
  current: number;
  total: number;
  step?: number;
  onPageClick: (pageNumber: number) => void;
};

const PageNumbers: React.FC<PageNumbersProps> = props => {
  const {
    current, total, step = 4, onPageClick,
  } = props;
  const ellipse = <span className="ellipse">...</span>;
  const renderNumber = (page: number) => (
    <span
      key={page}
      className={current === page ? 'selected' : ''}
      onClick={() => onPageClick(page - 1)}
      role="presentation"
    >
      {page}
    </span>
  );

  const all = Array.from({ length: total }).map((_, idx) => idx + 1);

  if (total <= step * 2) {
    return <>{all.map(renderNumber)}</>;
  }

  const first = all.slice(0, step);

  if (first.includes(current + 1)) {
    return (
      <>
        {first.map(renderNumber)}
        {ellipse}
        <span role="presentation" onClick={() => onPageClick(total - 1)}>{total}</span>
      </>
    );
  }

  const last = all.slice(total - step);

  if (last.includes(current)) {
    return (
      <>
        <span role="presentation" onClick={() => onPageClick(0)}>1</span>
        {ellipse}
        {last.map(renderNumber)}
      </>
    );
  }

  const initial = current - Math.ceil(step / 2);
  const res = Array.from({ length: step }).map((_, idx) => initial + idx);

  return (
    <>
      {step < current && (
        <>
          <span role="presentation" onClick={() => onPageClick(0)}>1</span>
          {ellipse}
        </>
      )}
      {res.map(renderNumber)}
      {ellipse}
      <span role="presentation" onClick={() => onPageClick(total - 1)}>{total}</span>
    </>
  );
};

export default React.memo(PageNumbers);
