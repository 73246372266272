import axios from 'axios';
import appConfig from '../config';

const RESET_PASSWORD_URL = `${appConfig.API_ROOT}/password_reset`;
const REFRESH_TOKEN_URL = `${appConfig.API_ROOT}/password_reset`;

export const resetRequest = (payload: { username: string, email: string }) => axios.get(`${RESET_PASSWORD_URL}?${new URLSearchParams(payload).toString()}`);

export const validateTokenRequest = (token: string) => axios.post(RESET_PASSWORD_URL, { token });

export const refreshTokenRequest = (refreshToken: string): any => axios.patch(REFRESH_TOKEN_URL, { refreshToken });

export const passwordUpdateRequest = ({
  token,
  password,
}: {token: string | null, password: string}) => axios.put(RESET_PASSWORD_URL, { token, password });
