import { connect } from 'react-redux';
import ScheduledVisits from './ScheduledVisits';
import { AppState } from '../../redux/store';

import { selectScheduledVisits, selectTotalScheduledVisits } from '../../redux/selectors/scheduledVisits';
import { selectExportAllLoadingState } from '../../redux/selectors/exportAll';
import {
  scheduledVisitsRequest,
  deleteScheduledVisits,
  exportScheduledVisits,
} from '../../redux/actions/scheduledVisits';
import { exportAllRequest } from '../../redux/actions/exportAll';


const mapStateToProps = (state: AppState) => ({
  scheduledVisits: selectScheduledVisits(state),
  totalVisits: selectTotalScheduledVisits(state),
  exportAllLoading: selectExportAllLoadingState(state),
});

const mapDispatchToProps = {
  scheduledVisitsRequest,
  exportAllScheduledVisits: exportAllRequest,
  deleteVisits: deleteScheduledVisits,
  exportVisits: exportScheduledVisits,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledVisits);
