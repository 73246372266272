import React, { useState } from 'react';
import { WithStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card/';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import styles from './styles';
import PropertyImportDialog from './../../containers/PropertyImportDialog';

type Props = WithStyles<typeof styles> & {
  imgSource: string;
  name: string;
  description: string;
};

const PropertyImportWidget: React.FC<Props> = props => {
  const [open, setOpen] = useState<boolean>(false);
  const {
    classes, imgSource, name, description,
  } = props;
  
  function handleOpen() {
    setOpen(!open);
  }

  return (
    <>
      <PropertyImportDialog open={open} changeOpen={handleOpen} integrationName='Property Import' />

      <Card elevation={3} className={classes.card}>
        <CardContent>
          <div className={classes.logoWrapper}>
            <img style={{ maxHeight: '150px' }} src={imgSource} alt="" className={classes.image} />
          </div>

          <Typography className={classes.apiName}>{name}</Typography>
          <Typography className={classes.apiDescription}>
            {description}
          </Typography>
          <Typography className={classes.apiAction}>
            <Button
              variant="contained"
              className={classes.controlButton}
              size='large'
              onClick={handleOpen}
            >
              <b>Start Sync</b>
            </Button>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default React.memo(PropertyImportWidget);
