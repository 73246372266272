import React, { FC, memo } from 'react';
import {
  Table, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import SurveyResult from '../redux/types/survey';

type Props = {
  results: SurveyResult[]
};

const SurveyInfo: FC<Props> = ({ results }) => (
  <>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="center">Question</TableCell>
          <TableCell align="center">Answer</TableCell>
        </TableRow>
      </TableHead>
      { results.map(result => (
        <TableRow key={result.question}>
          <TableCell align="center">{result.question}</TableCell>
          <TableCell align="center">{result.answer}</TableCell>
        </TableRow>
      )) }
    </Table>
  </>
);

export default memo(SurveyInfo);
