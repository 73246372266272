import {
  INTEGRATIONS_REQUEST,
  INTEGRATIONS_REQUEST_SUCCESS,
  INTEGRATIONS_REQUEST_FAILED,
  GET_INTEGRATIONS_REQUEST,
  GET_INTEGRATIONS_REQUEST_SUCCESS, Integration,
} from '../types/integrations';

export const sendIntegrtation = (payload: { message: string, name: string }) => ({
  type: INTEGRATIONS_REQUEST,
  payload,
} as const);

export const sendIntegrtationSuccess = () => ({
  type: INTEGRATIONS_REQUEST_SUCCESS,
} as const);

export const sendIntegrtationFailed = () => ({
  type: INTEGRATIONS_REQUEST_FAILED,
} as const);

export const getIntegrations = () => ({
  type: GET_INTEGRATIONS_REQUEST,
} as const);

export const getIntegrationsSuccess = (payload: { integrations: Integration[] }) => ({
  type: GET_INTEGRATIONS_REQUEST_SUCCESS,
  payload,
} as const);
