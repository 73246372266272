import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  customerDetailsClear,
  customerDetailsRequest,
} from '../../redux/actions/customers';
import {
  selectCustomerSelected,
  selectCustomersRequestStatus,
} from '../../redux/selectors/customers';
import CustomerDetails from './CustomerDetails';
import styles from './styles';
import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  customerDetails: selectCustomerSelected(state),
  loading: selectCustomersRequestStatus(state),
});

const mapDispatchToProps = {
  clearSelected: customerDetailsClear,
  loadCustomerDetails: customerDetailsRequest,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CustomerDetails),
);
