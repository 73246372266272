import {
  BUILDERS_PMS_REQUEST,
  BUILDERS_PMS_REQUEST_FAILED,
  BUILDERS_PMS_REQUEST_SUCCESS,
  BuildersPMsRequestSuccess,
} from '../types/builders';
import { ErrorMessage } from '../../types/main';

export const buildersPMsRequest = (id: number) => ({
  type: BUILDERS_PMS_REQUEST,
  payload: id,
} as const);

export const buildersPMsRequestFailed = (payload: ErrorMessage) => ({
  type: BUILDERS_PMS_REQUEST_FAILED,
  payload,
} as const);

export const buildersPMsRequestSuccess = (payload: BuildersPMsRequestSuccess) => ({
  type: BUILDERS_PMS_REQUEST_SUCCESS,
  payload,
} as const);
