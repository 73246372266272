import axios from './axios';
import { Query, buildQuery } from '../utils/query';

export const loadLocks = <T>(queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get<T>(`/locks${query}`);
};

export const loadLocksCommunity = () => axios.get<string[]>('/locks/community');

export const loadLockDetails = <T>(lockSerial: number | string) => axios.get<T>(`/locks/${lockSerial}`);
