import { ErrorMessage } from '../../types/main';
import { Query } from '../../utils/query';
import {
  WEBHOOKS_REQUEST,
  WEBHOOKS_REQUEST_FAILED,
  WEBHOOKS_REQUEST_SUCCESS,
  DELETE_WEBHOOKS_REQUEST,
  DELETE_WEBHOOKS_SUCCESS,
  DELETE_WEBHOOKS_FAILED,
  WebhookFormType,
  WebhooksRequestSuccess,
  WEBHOOKS_DETAILS_REQUEST,
  WEBHOOKS_DETAILS_REQUEST_SUCCESS,
  WEBHOOKS_DETAILS_REQUEST_FAILED,
  Webhook,
  CREATE_WEBHOOKS_REQUEST,
  CREATE_WEBHOOKS_SUCCESS,
  CREATE_WEBHOOKS_FAILED,
  UPDATE_WEBHOOKS_REQUEST,
  UPDATE_WEBHOOKS_SUCCESS,
  UPDATE_WEBHOOKS_FAILED,
} from '../types/webhooks';

export const webhooksRequest = (query?: Query) => ({
  type: WEBHOOKS_REQUEST,
  payload: query,
} as const);

export const webhooksRequestSuccess = (payload: WebhooksRequestSuccess) => ({
  type: WEBHOOKS_REQUEST_SUCCESS,
  payload,
} as const);

export const webhooksRequestFailed = (payload: ErrorMessage) => ({
  type: WEBHOOKS_REQUEST_FAILED,
  payload,
} as const);

export const webhookDetailsRequest = (payload: number | string) => ({
  type: WEBHOOKS_DETAILS_REQUEST,
  payload,
} as const);

export const webhookDetailsRequestSuccess = (payload: Webhook) => ({
  type: WEBHOOKS_DETAILS_REQUEST_SUCCESS,
  payload,
} as const);

export const webhookDetailsRequestFailed = (payload: ErrorMessage) => ({
  type: WEBHOOKS_DETAILS_REQUEST_FAILED,
  payload,
} as const);

export const deleteWebhooksRequest = (payload: Array<number>) => ({
  type: DELETE_WEBHOOKS_REQUEST,
  payload,
} as const);

export const deleteWebhooksSuccess = (payload: any) => ({
  type: DELETE_WEBHOOKS_SUCCESS,
  payload,
} as const);

export const deleteWebhooksFailed = (payload: ErrorMessage) => ({
  type: DELETE_WEBHOOKS_FAILED,
  payload,
} as const);

export const createWebhookRequest = (webhook: WebhookFormType) => ({
  type: CREATE_WEBHOOKS_REQUEST,
  payload: webhook,
} as const);

export const createWebhookSuccess = (webhook: WebhookFormType) => ({
  type: CREATE_WEBHOOKS_SUCCESS,
  payload: webhook,
} as const);

export const createWebhookFailed = (webhook: WebhookFormType) => ({
  type: CREATE_WEBHOOKS_FAILED,
  payload: webhook,
} as const);

export const updateWebhookRequest = (payload: {
  webhookId: number | string;
  webhook: WebhookFormType;
}) => ({
  type: UPDATE_WEBHOOKS_REQUEST,
  payload,
} as const);

export const updateWebhookSuccess = (webhook: WebhookFormType) => ({
  type: UPDATE_WEBHOOKS_SUCCESS,
  payload: webhook,
} as const);

export const updateWebhookFailed = (webhook: WebhookFormType) => ({
  type: UPDATE_WEBHOOKS_FAILED,
  payload: webhook,
} as const);
