import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    position: 'relative',
    '& > li': {
      paddingTop: 16,
    },
    '&:before': {
      position: 'absolute',
      width: 4,
      left: 18,
      top: theme.spacing(4),
      content: '""',
      display: 'block',
      backgroundColor: theme.appPalette.orange,
      bottom: 0,
    },
  },
});
