import { useEffect, useRef } from 'react';
import qs from 'qs';
import useUrlLocation from './useUrlLocation';
import history from '../browserHsitory';
import filterEmptyFields from '../utils/filter';

export default function useUrlFilterSearch<T>(obj: T): void {
  const currentPath = useUrlLocation();
  const firstRender = useRef(true);

  const urlString = qs.stringify(filterEmptyFields(obj), {
    addQueryPrefix: true,
    skipNulls: true,
  });
  const newUrl = (search: string) => history.push({ pathname: currentPath, search });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      newUrl(history.location.search);

      return;
    }

    newUrl(urlString);
    // eslint-disable-next-line
  }, [urlString]);
}
