import React from 'react';
import { TextField, MenuItem, WithStyles } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import styles from './styles';

type Props = {
  defaultTimezone?: number | null;
  timezones: any;
} & TextFieldProps & WithStyles<typeof styles>;

const TimezoneSelect: React.FC<Props> = (props) => {
  const {
    defaultTimezone, timezones, classes, ...textFieldProps
  } = props;

  return (
    <TextField
      fullWidth
      {...textFieldProps}
      value={defaultTimezone || ''}
      className={classes.select}
      select
      inputProps={{ classes: { root: classes.input, disabled: classes.disabledInput } }}
    >
      <MenuItem value="" disabled>
        Choose a timezone
      </MenuItem>
      {timezones.map(({ catalog_id: catalogId, value }: any) => (
        <MenuItem
          key={catalogId}
          value={catalogId}
        >
          {value}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default React.memo(TimezoneSelect);
