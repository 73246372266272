import React from 'react';
import { Grid, WithStyles } from '@material-ui/core';
import styles from './styles';
import Switch from '../Switch';
import HubForm from '../HubForm';
import { HubLock, HubLockUserCodeUpdate } from '../../redux/types/hubs';
import {
  createHubLockMasterCodeRequest,
  deleteHubLockCodeRequest,
  updateHubLockAutoLockRequest,
  updateHubLockCodesRequest,
  updateHubLockMasterCodeRequest,
  updateHubLockStatusRequest,
} from '../../redux/actions/hubs/hubLocks';
import { ActionPayload } from '../../types/main';
import LockIcon from '../Icons/LockIcon';
import HubLockUserCodesForm from '../HubLockUserCodesForm';

type Props = {
  hubId: string
  record: HubLock
  updating: boolean
  refreshLoading: boolean
  updateHubLockStatus: (payload: ActionPayload<typeof updateHubLockStatusRequest>) => void
  updateHubLockAutoLock: (payload: ActionPayload<typeof updateHubLockAutoLockRequest>) => void
  createHubLockMasterCode: (payload: ActionPayload<typeof createHubLockMasterCodeRequest>) => void
  updateHubLockMasterCode: (payload: ActionPayload<typeof updateHubLockMasterCodeRequest>) => void
  updateHubLockCodes: (payload: ActionPayload<typeof updateHubLockCodesRequest>) => void
  deleteHubLockCode: (payload: ActionPayload<typeof deleteHubLockCodeRequest>) => void
} & WithStyles<typeof styles>;

const HubLockForm: React.FC<Props> = (props: Props) => {
  const {
    hubId, record, updating, refreshLoading,
    updateHubLockStatus, /* updateHubLockAutoLock, */
    deleteHubLockCode, updateHubLockCodes,
  } = props;
  const { id: deviceId, properties: { userCodes, status: lockStatus /* lockTimeoutEnabled */ } } = record;

  // const autoLock = lockTimeoutEnabled === undefined ? true : lockTimeoutEnabled;

  const handleLockStatus = () => {
    const status = lockStatus === 'locked' ? 'unlocked' : 'locked';
    updateHubLockStatus({ hubId, status, deviceId });
  };

  // const handleAutoLock = () => {
  //   updateHubLockAutoLock({ hubId, autoLock: !autoLock });
  // };

  const updateHubLockCodesHandler = (codes: HubLockUserCodeUpdate[]) => {
    updateHubLockCodes({ hubId, codes });
  };

  const deleteHubLockCodeHandler = (codeKey: string) => {
    deleteHubLockCode({ hubId, codeKey });
  };

  return (
    <HubForm
      title={{
        title: 'Lock',
        recordName: record.name,
        icon: <LockIcon />,
      }}
    >
      <Grid item xs={6}>
        <Switch
          title="Status"
          valueName="Unlock"
          secondValueName="Lock"
          value={lockStatus === 'locked'}
          setValue={handleLockStatus}
          disabled={updating || refreshLoading}
        />
      </Grid>
      {/* <Grid item xs={6}>
        <Switch
          title="Auto-Lock"
          valueName="Off"
          secondValueName="On"
          value={Boolean(autoLock)}
          setValue={handleAutoLock}
          disabled={updating || refreshLoading}
        />
      </Grid> */}
      <Grid item xs={12}>
        <HubLockUserCodesForm
          userCodes={userCodes}
          deleteHubLockCode={deleteHubLockCodeHandler}
          updateHubLockCodes={updateHubLockCodesHandler}
          updating={updating}
        />
      </Grid>
    </HubForm>
  );
};

export default HubLockForm;
