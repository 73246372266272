import React, { useState } from 'react';
import { WithStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card/';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import styles from './styles';
import IntegrationDialog from '../../containers/IntegrationDialog';

type Props = WithStyles<typeof styles> & {
  imgSource: string;
  name: string;
  description: string;
};

const IntegrationWidget: React.FC<Props> = props => {
  const [open, setOpen] = useState<boolean>(false);
  const {
    classes, imgSource, name, description,
  } = props;
  const [switchChecked, handleChecked] = useState<boolean>(false);
  const handleSwitch = () => {
    handleChecked(!switchChecked);
    setOpen(!open);
  };
  function handleOpen() {
    setOpen(!open);
  }

  return (
    <>
      <IntegrationDialog open={open} handleChecked={handleSwitch} changeOpen={handleOpen} integrationName={name} />

      <Card elevation={3} className={classes.card}>
        <CardContent>
          <div className={classes.switchWrapper}>
            <Switch checked={switchChecked} onChange={handleSwitch} />
          </div>

          <div className={classes.logoWrapper}>
            <img style={{ maxHeight: '150px' }} src={imgSource} alt="" className={classes.image} />
          </div>

          <Typography className={classes.apiName}>{name}</Typography>
          <Typography className={classes.apiDescription}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default React.memo(IntegrationWidget);
