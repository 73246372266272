import {
  ADD_AGENT_REQUEST,
  ADD_AGENT_REQUEST_FAILED,
  ADD_AGENT_REQUEST_SUCCESS,
  AGENT_DETAILS_REQUEST,
  AGENT_DETAILS_REQUEST_FAILED,
  AGENT_DETAILS_REQUEST_SUCCESS,
  AGENTS_REQUEST,
  AGENTS_REQUEST_FAILED,
  AGENTS_REQUEST_SUCCESS,
  DELETE_AGENTS_REQUEST,
  UPDATE_AGENT_REQUEST,
  UPDATE_AGENT_REQUEST_FAILED,
  UPDATE_AGENT_REQUEST_SUCCESS,
  UPDATE_SUGGESTED_USERNAME,
  RESET_SUGGESTED_USERNAME,
  RESEND_EMAIL_REQUEST,
  RESEND_EMAIL_FAILED,
  RESEND_EMAIL_SUCCESS,
  AgentsState,
  AgentsActionTypes,
  AVAILABLE_AGENTS_REQUEST,
  AVAILABLE_AGENTS_REQUEST_SUCCESS,
} from '../types/agents';

const initialState: AgentsState = {
  items: [],
  total: 0,
  error: '',
  availableAgents: [],
  loading: false,
  selected: null,
  loadingBtnEmailResend: false,
  suggestedUsername: '',
};

export default function AgentReducer(
  state = initialState,
  action: AgentsActionTypes,
): AgentsState {
  switch (action.type) {
    case AGENTS_REQUEST:
    case AVAILABLE_AGENTS_REQUEST:
    case ADD_AGENT_REQUEST:
    case DELETE_AGENTS_REQUEST:
    case UPDATE_AGENT_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case RESEND_EMAIL_REQUEST: {
      return {
        ...state,
        error: '',
        loadingBtnEmailResend: true,
      };
    }

    case ADD_AGENT_REQUEST_SUCCESS:
    case UPDATE_AGENT_REQUEST_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case AGENTS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total,
        error: '',
        loading: false,
      };
    }

    case AVAILABLE_AGENTS_REQUEST_SUCCESS: {
      return {
        ...state,
        availableAgents: action.payload.items,
        error: '',
        loading: false,
      };
    }

    case AGENTS_REQUEST_FAILED:
    case AGENT_DETAILS_REQUEST_FAILED:
    case ADD_AGENT_REQUEST_FAILED:
    case UPDATE_AGENT_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case RESEND_EMAIL_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loadingBtnEmailResend: false,
      };
    }

    case AGENT_DETAILS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case AGENT_DETAILS_REQUEST_SUCCESS: {
      return {
        ...state,
        error: '',
        selected: action.payload,
        loading: false,
      };
    }

    case RESEND_EMAIL_SUCCESS: {
      return {
        ...state,
        error: '',
        loadingBtnEmailResend: false,
      };
    }

    case UPDATE_SUGGESTED_USERNAME: {
      return {
        ...state,
        suggestedUsername: action.payload,
      };
    }

    case RESET_SUGGESTED_USERNAME: {
      return {
        ...state,
        suggestedUsername: '',
      };
    }

    default: {
      return state;
    }
  }
}
