import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const LockIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 95 113" {...props}>
    <path d="M49.8192 8.92198V3.33087C49.8192 0.713749 46.9724 -0.83273 44.7186 0.475829L25.74 12.0149C23.6049 13.3235 23.6049 16.4165 25.74 17.725L44.7186 29.2641C46.9724 30.5727 49.8192 29.0262 49.8192 26.4091V19.9853C68.7978 21.1748 83.8621 36.8776 83.8621 56.268C83.8621 64.8331 80.8967 73.0414 75.4403 79.5842C74.7286 80.4169 74.8472 81.6065 75.5589 82.3202L80.8967 87.3166C81.727 88.0303 83.0318 88.0303 83.7435 87.1976C91.0977 78.6325 95.0121 67.6882 95.0121 56.268C95.1307 30.8106 75.0845 10.1116 49.8192 8.92198Z" />
    <path d="M50.2934 83.6288C48.0397 82.3203 45.1929 83.8667 45.1929 86.4839V92.9077C26.2143 91.7181 11.15 76.0154 11.15 56.6249C11.15 48.0598 14.1154 39.8516 19.5717 33.3088C20.2834 32.4761 20.1648 31.2865 19.4531 30.5727L14.1154 25.5764C13.2851 24.8626 11.9803 24.8626 11.2686 25.6954C3.91435 34.2605 0 45.2048 0 56.6249C0 82.2013 20.1648 102.9 45.3115 104.09V109.681C45.3115 112.298 48.1583 113.845 50.412 112.536L69.3907 100.997C71.5258 99.6884 71.5258 96.5955 69.3907 95.2869L50.2934 83.6288Z" />
  </SvgIcon>
);

export default React.memo(LockIcon);
