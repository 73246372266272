import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WithStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { Grid } from '@material-ui/core';
import CustomerInfoCard from '../../components/CustomerInfoCard';
import CustomerInfoWorkbench from '../../components/CustomerInfoWorkbench';
import { CustomerDetails } from '../../redux/types/customers';
import { CUSTOMERS, VISITS } from '../../constants/sortState';
import { clearStorage } from '../../services/storage';

import styles from './styles';

type Props = {
  clearSelected: () => void;
  loadCustomerDetails: (customerId: number) => void;
  customerDetails: CustomerDetails | null;
  loading?: boolean;
} & WithStyles<typeof styles> & RouteComponentProps<{ customerId: string }>;

const CustomerDetailsPage: React.FC<Props> = (props) => {
  const {
    classes,
    loadCustomerDetails,
    clearSelected,
    customerDetails,
    match,
    history,
  } = props;
  const pageNameIndex = 1;
  const pathname = history.location.pathname.split('/')[pageNameIndex];

  function goBack() {
    history.goBack();
  }

  useEffect(() => () => {
    const newPathName = history.location.pathname.split('/')[pageNameIndex];

    if (newPathName !== pathname) {
      clearStorage(CUSTOMERS)();
    }

    if (newPathName !== 'visits') {
      clearStorage(VISITS)();
    }
  }, [pathname, history.location.pathname]);

  useEffect(() => {
    loadCustomerDetails(Number(match.params.customerId));
    return clearSelected;
  }, [loadCustomerDetails, clearSelected, match.params.customerId]);

  return (
    <>
      <Grid className={classes.actionsBar}>
        <Grid container className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            onClick={goBack}
          >
            <ArrowLeftIcon />
            Back
          </Button>
        </Grid>
      </Grid>
      <Grid>
        {customerDetails && (
          <Grid container spacing={4}>
            <Grid item xs={12} sm={5}>
              <CustomerInfoCard customerInfo={customerDetails.info} />
            </Grid>
            <Grid item xs={12} sm={7}>
              <CustomerInfoWorkbench
                customerActivity={{
                  visits: customerDetails.visits,
                  surveyResults: customerDetails.surveyResults,
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default React.memo(CustomerDetailsPage);
