import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { RouteComponentProps } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import CustomButton from '../../components/CustomButton';
import addIcon from '../../assets/addIcon.svg';
import Table from '../../components/CustomTable';
import { HeaderRowConfig } from '../../components/CustomTable/CustomTable';
import TablePagination from '../../components/CustomTablePagination';
import { formatDate } from '../../utils/date';
import { Order } from '../../utils/table';
import { ROWS_PER_PAGE_OPTIONS } from '../../constants/table';
import { Webhook } from '../../redux/types/webhooks';
import useStyles from './styles';
import ConfirmDialog from '../../components/ConfirmDialog';
import { Query } from '../../utils/query';
import useWebhooks from './hooks/useWebhooks.hook';

type Props = {
  webhooks: Webhook[];
  loadWebhooks: (query: Query) => void;
  deleteWebhooks: (webhookIds: number[]) => void;
} & RouteComponentProps;

const headRows: HeaderRowConfig<Webhook>[] = [
  {
    key: 'payload_url',
    align: 'center',
    disablePadding: false,
    label: 'Payload URL',
  },
  {
    key: 'event_type',
    align: 'center',
    disablePadding: false,
    label: 'Event Type',
  },
  {
    key: 'created_on',
    align: 'center',
    disablePadding: true,
    label: 'Created at',
    prepareData: ({ created_on: createdOn }) => formatDate(createdOn),
  },
];

const Webhooks: React.FC<Props> = (props) => {
  const {
    history, match, loadWebhooks, deleteWebhooks, webhooks,
  } = props;
  const classes = useStyles();
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    dispatchWebhooks,
  } = useWebhooks(loadWebhooks);

  const [selected, setSelected] = useState<number[]>([]);
  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);

  const selectedLength = selected.length;

  function handleSelect(newSelected: number[]) {
    setSelected(newSelected);
  }

  const handleWebhookSelect = (hook: Webhook) => {
    history.push(`${match.url}/${hook.id}`);
  };

  const handleChangeOrder = (newOrder: Order, newOrderBy: keyof Webhook) => {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    setPage(0);
    dispatchWebhooks('CHANGE_WEBHOOK', {
      currentOrder: newOrder,
      currentOrderBy: newOrderBy,
      currentPage: 0,
    });
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rowsPerPageAmount: number) => {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
  };

  const showConfirm = () => {
    setOpenConfirm(true);
  };

  const closeConfirm = (): void => {
    setOpenConfirm(false);
  };

  const confirmDelete = () => {
    deleteWebhooks(selected);
    setOpenConfirm(false);
  };

  return (
    <>
      <ConfirmDialog
        isOpen={openConfirm}
        onClose={closeConfirm}
        onDelete={confirmDelete}
      />
      <Grid container spacing={8}>
        <Grid item className={classes.controlButton}>
          <CustomButton
            variant="orange"
            color="primary"
            onClick={() => history.push(`${match.path}/add`)}
          >
            <img src={addIcon} alt="add webhook" style={{ marginRight: 10 }} />
            Add New Webhook
          </CustomButton>
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <Typography variant="h5">
              Webhooks
              {selectedLength > 0 ? ` ${selectedLength} selected.` : ''}
            </Typography>
          </div>
          <div className={classes.spacer} />
          {selectedLength > 0 && (
            <div className={classes.toolbarActions}>
              <span>Selected actions: </span>
              <Button size="small" onClick={showConfirm}>
                Delete
              </Button>
            </div>
          )}
        </Toolbar>
        <div className={classes.tableWrapper}>
          <Table<Webhook>
            hover
            className={classes.table}
            IDKey="id"
            selected={selected}
            data={webhooks}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            onChangeOrder={handleChangeOrder}
            header={headRows}
            onSelect={handleSelect}
            onRowClick={handleWebhookSelect}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={webhooks.length}
          rowsTotal={webhooks.length}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default Webhooks;
