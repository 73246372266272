import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  headerText: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '40px',
    marginBottom: '20px',
  },
  pickerText: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginRight: '20px',
  },
  container: {
    textAlign: 'center',
    padding: '50px 10% 50px 10%',
    borderTop: '1px solid rgba(142, 139, 153, 0.25)',
    borderBottom: '1px solid rgba(142, 139, 153, 0.25)',
  },
  datePicker: {
    margin: '0 0 20px 10%',
    '& label': {
      top: '6px',
    },
    '&::after': {
      borderBottom: 'none',
    },
  },
  formControl: {
    display: 'flex',
    width: '200px',
    padding: '20px',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  cardsGrid: {
    // display: 'grid',
    // gridTemplateColumns: 'repeat(4, minmax(0, 1fr))'
    '& .MuiGrid-item': {
      flexBasis: 0,
      flexGrow: 0,
      minWidth: '300px',
    }
  },
  card: {
    position: 'relative',
    borderRadius: 10,
    height: '100%',
    padding: '50px 0 50px 0',
    overflow: 'visible',
    cursor: 'pointer',
  },
  cardTitle: {
    color: '#DB5C0E',
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: '28px',
  },
  cardDescription: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#979797',
  },
  cardIcon: {
    width: '40px',
    height: '40px',
    padding: '5px',
    borderRadius: '50%',
    fill: '#8E8B99',
    border: '1px solid #8E8B99',
    backgroundColor: '#fff',
    position: 'absolute',
    top: -20,
    left: 20,
  },
  comingSoon: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    borderRadius: '50%',
    fill: '#8E8B99',
    position: 'absolute',
    top: 5,
    right: 5,

    '& span': {
      fontSize: '18px',
      lineHeight: '20px',
      color: '#8E8B99',
    },
  },
  comingSoonIcon: {
    width: '40px',
    height: '40px',
    padding: '5px',
    borderRadius: '50%',
    fill: '#8E8B99',
  },
  disabled: {
    filter: 'grayscale(1)',
    opacity: '0.5',
    pointerEvents: 'none',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
