import {all, call,put, takeLatest} from 'redux-saga/effects'
import { loadMarketReport, loadMarketReportExport } from "../../services/reports";
import { 
  marketReportExportSuccess,
  marketReportRequest, 
  marketReportRequestFailed, 
  marketReportRequestSuccess 
} from "../actions/reports";
import { createErrorSnackBar } from "../actions/snackbars";
import { MARKET_REPORT_EXPORT_REQUEST, MARKET_REPORT_REQUEST, MarketReportExportRequestActionType } from '../types/reports';
import { exportToFile } from '../../services/export';

function* loadMarketReportSaga(action: ReturnType<typeof marketReportRequest>) {
  try {
    const { data } = yield call(loadMarketReport, action.payload);

    yield put(marketReportRequestSuccess(data));
  } catch (err) {
    yield put(createErrorSnackBar(err.response ? err.response.data.message : err.message));
    yield put(marketReportRequestFailed(err));
  }
}

function* loadMarketReportExportSaga(action: MarketReportExportRequestActionType) {
  try {
    const {
      selected, startDate, endDate, mimeType,
    } = action.payload;
    const { data } = yield call(loadMarketReportExport, selected, startDate, endDate, mimeType);

    const fileType = { type: mimeType } as const;
    const blob = new Blob([data], fileType);

    const fileName = 'market-report-export.csv';

    yield call(exportToFile, blob, fileType, fileName);
  

    yield put(marketReportExportSuccess());
  } catch (err) {
    yield put(marketReportRequestFailed(err));
  }
}

export default all([
  takeLatest<any>(MARKET_REPORT_REQUEST, loadMarketReportSaga),
  takeLatest<any>(
    [MARKET_REPORT_EXPORT_REQUEST],
    loadMarketReportExportSaga,
  ),
]);