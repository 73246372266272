import { connect } from 'react-redux';
import SelectBuilder from './SelectBuilder';
import { selectEnterpriseStatus, selectUsername } from '../../redux/selectors/auth';
import { selectBuilders, selectBuildersRequestStatus } from '../../redux/selectors/builders';
import { AppState } from '../../redux/store';
import { enterpriseUserBuildersRequest } from '../../redux/actions/builders';
import { authEnterpriseUserRequest } from '../../redux/actions/auth';

const mapStateToProps = (state: AppState) => ({
  builders: selectBuilders(state),
  loadingBuilders: selectBuildersRequestStatus(state),
  isEnterprise: selectEnterpriseStatus(state.auth),
  username: selectUsername(state.auth),
});

const mapDispatchToProps = {
  loadBuilders: enterpriseUserBuildersRequest,
  auth: authEnterpriseUserRequest,
};

export default
connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectBuilder);
