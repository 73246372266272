import { createStyles, Theme, makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  white: {
    color: '#DB5C0E',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    minWidth: 100,
  },
  orange: {
    color: '#FFFFFF',
    backgroundColor: '#DB5C0E',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    minWidth: 100,
    '&:hover': {
      backgroundColor: fade('#DB5C0E', 0.7),
    },
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
