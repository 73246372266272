import { createSelector } from 'reselect';
import { AppState } from '../store';
import {
  LocksState,
  LOCK_STATUS_ACTIVE_VALUE,
  LOCK_STATUS_DOWN_VALUE,
  LOCK_STATUS_RETURNED
} from '../types/locks';

const selectLocksState = (state: AppState) => state.locks;
const selectItems = (state: LocksState) => state.items;
const selectSelectedLock = (state: LocksState) => state.selected;
const selectLoading = (state: LocksState) => state.loading;
const selectActiveItems = (state: LocksState) => state.items.filter(
  item => item.lock_status_value !== LOCK_STATUS_RETURNED,
);
const selectReturnedItems = (state: LocksState) => state.items.filter(
  item => item.lock_shipment_status === LOCK_STATUS_RETURNED,
);
const selectOtherItems = (state: LocksState) => state.items.filter(
  item => item.lock_status_value !== LOCK_STATUS_ACTIVE_VALUE && item.lock_status_value !== LOCK_STATUS_DOWN_VALUE,
);

export const selectLocks = createSelector(
  selectLocksState,
  selectItems,
);

export const selectLocksActive = createSelector(
  selectLocksState,
  selectActiveItems,
);

export const selectLocksReturned = createSelector(
  selectLocksState,
  selectReturnedItems,
);

export const selectLocksOther = createSelector(
  selectLocksState,
  selectOtherItems,
);

export const selectLocksRequestStatus = createSelector(
  selectLocksState,
  selectLoading,
);

export const selectLockDetails = createSelector(
  selectLocksState,
  selectSelectedLock,
);
