import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  logo: {
    height: 100,
    position: 'absolute',
    left: '50%',
    top: '10%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.down('md')]: {
      height: 75,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: 50,
    },
    [theme.breakpoints.down('sm')]: {
      height: 35,
    },
  },
  welcomeMessage: {
    fontSize: 30,
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'oblique',
  },
  blurb: {
    position: 'absolute',
    left: '50%',
    top: '20%',
    transform: 'translate(-50%, -50%)',
    fontSize: 30,
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'oblique',
    [theme.breakpoints.down('md')]: {
      fontSize: 25,
      webkitTextSizeAdjust: 25,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 20,
      webkitTextSizeAdjust: 20,
      top: '18%',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      webkitTextSizeAdjust: 16,
      top: '18%',
    },
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
