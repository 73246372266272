import { createStyles } from '@material-ui/core';

export default () => createStyles({
  root: {
    position: 'relative',
    '& > li': {
      paddingTop: 16,
    },
  },
  hubLockWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  flex: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },
});
