import React from 'react';
import MatTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/styles';
import Spinner from '@material-ui/core/CircularProgress';
import { defaultDateTimeFormat, formatDate } from '../../../utils/date';
import styles from './styles';
import { HubActivityLog } from '../../../redux/types/hubs';
import TableHead from './TableHead';
import { Order } from '../../../utils/table';

const ActionMapping: any = {
  remove: 'Removed, not sold',
  activate: 'Activated',
  mask_as_sold: 'Removed, marked as sold',
};

type Props = {
  isLoading: boolean;
  order: Order;
  orderBy: keyof HubActivityLog;
  histories?: HubActivityLog[];
  rowsPerPage: number;
  onChangeOrder: (order: Order, orderBy: keyof HubActivityLog) => void;
} & WithStyles<typeof styles>;

const InventoryHistoryTable: React.FC<Props> = (props) => {
  const {
    isLoading,
    histories,
    classes,
    onChangeOrder,
    order,
    orderBy,
  } = props;

  const handleRequestSort = (
    event: React.SyntheticEvent,
    property: keyof HubActivityLog,
  ) => {
    event.preventDefault();

    const isDesc = orderBy === property && order === 'desc';

    onChangeOrder(isDesc ? 'asc' : 'desc', property);
  };

  return (
    <div className={classes.tableWrapper}>
      {isLoading && (
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
      )}
      <MatTable className={classes.tableRoot}>
        <TableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {histories?.map((community, index: number) => {
            const labelId: string = `enhanced-table-checkbox-${index}`;
            const created_on = formatDate(community?.created_on, defaultDateTimeFormat);

            return (
              <TableRow
                hover
                role="checkbox"
                classes={{ root: classes.rowRoot }}
                key={community.id}
                tabIndex={-1}
              >
                <TableCell align="center" id={labelId}>
                  {community.id}
                </TableCell>
                <TableCell align="center">{community.hub_type}</TableCell>
                <TableCell align="center">{community.lock_type}</TableCell>
                <TableCell align="center">{community.serial_number}</TableCell>
                <TableCell align="center">{community.property_id}</TableCell>
                <TableCell align="center">{ActionMapping[community.action] || community.action}</TableCell>
                <TableCell align="center">
                  {created_on}
                </TableCell>
                <TableCell align="center">{community.created_by}</TableCell>
                <TableCell align="center">{community.users_email}</TableCell>

              </TableRow>
            );
          })}
        </TableBody>
      </MatTable>
    </div>
  );
};

export default withStyles(styles)(InventoryHistoryTable);
