import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  input: {
    marginTop: 0,
    marginBottom: 0,

    '& > div > input': {
      marginLeft: 0,
      fontSize: 28,
      fontWeight: 700,
      textAlign: 'center',
    },

    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'none',
    },

    '& label': {
      width: '100%',
      left: 0,
      fontSize: 28,
      textAlign: 'center',
    },
    '& label.Mui-focused, & label.MuiFormLabel-filled': {
      textAlign: 'left',
      left: 12,
      fontSize: 16,
    },
  },
});
