/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Spinner from '@material-ui/core/CircularProgress';
import { RouteComponentProps } from 'react-router-dom';
import SearchBar from '../SearchBar';
import TablePagination from '../../components/CustomTablePagination';
import Table from '../../components/CustomTable';
import { ROWS_PER_PAGE_OPTIONS } from '../../constants/table';
import useOffers from './hooks/useOffers.hook';

import styles from './styles';
import { HeaderRowConfig } from '../../components/CustomTable/CustomTable';
import { Order } from '../../utils/table';
import { Offer } from '../../redux/types/offers';
import { Query } from '../../utils/query';

type Props = {
  isRental: boolean;
  loading: boolean;
  exportAllLoading: boolean;
  offers: Offer[];
  offersTotal: number;
  loadOffers: (query?: Query) => void;
  exportOffers: (selection: Array<number | string>) => void;
  exportAllOffers: (path: string) => void;
  printOffers: (selection: Array<number | string>) => void;
} & WithStyles<typeof styles> & RouteComponentProps;

const searchBarPlaceholder: string = 'Search all offers';

const headRows: HeaderRowConfig<Offer>[] = [
  {
    key: 'property_id',
    align: 'center',
    disablePadding: false,
    label: 'Property ID',
  },
  {
    key: 'floor_plan',
    align: 'center',
    disablePadding: false,
    label: 'Floor plan',
  },
  {
    key: 'prequalified',
    align: 'center',
    disablePadding: false,
    label: 'Prequalified',
    prepareData: ({ prequalified }) => {
      if (!prequalified) return 'NO';
      return 'YES';
    },
  },
  {
    key: 'comments',
    align: 'center',
    disablePadding: false,
    label: 'Comments',
  },
  {
    key: 'preferred_contact',
    align: 'center',
    disablePadding: false,
    label: 'Preferred Contact',
  },
  {
    key: 'firstname',
    align: 'center',
    disablePadding: false,
    label: 'First Name',
  },
  {
    key: 'lastname',
    align: 'center',
    disablePadding: false,
    label: 'Last Name',
  },
  {
    key: 'phone',
    align: 'center',
    disablePadding: false,
    label: 'Customer Contact',
    prepareData: ({ preferred_contact, email, phone }) => {
      if (preferred_contact === 'email') return email;
      return phone;
    },
  },
];

const Offers: React.FC<Props> = (props) => {
  const {
    classes, offers, offersTotal, loadOffers, loading, exportOffers, printOffers,
    history,
    isRental,
    exportAllOffers, exportAllLoading,
  } = props;
  const {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    dispatchOffers,
  } = useOffers(loadOffers);
  const [selected, setSelected] = useState<Offer['id'][]>([]);
  const selectedLength = selected.length;

  useEffect(() => {
    setSelected([]);
  }, [setSelected, page, rowsPerPage]);

  useEffect(() => () => {
    const pageNameIndex = 1;
    const pathElements = history.location.pathname.split('/');
    const pathname = pathElements[pageNameIndex];
    if (pathElements.length === 3) {
      return;
    }
    if ((pathname !== 'offers')) {
      dispatchOffers('RESET_STATE');
    }
  }, [dispatchOffers, history.location.pathname]);

  function handleOfferSelect(offers: Offer) {
    history.push(`/offers/${offers.id}`);
  }

  function handleExport() {
    if (selectedLength !== 0) {
      exportOffers(selected);
    }
  }

  const exportAll = () => {
    exportAllOffers(history.location.pathname.split('/')[1]);
  };

  function handlePrint() {
    if (selectedLength !== 0) {
      printOffers(selected);
    }
  }

  function handleChangeOrder(newOrder: Order, newOrderBy: keyof Offer) {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    setPage(0);
    dispatchOffers('CHANGE_OFFER', { currentOrder: newOrder, currentOrderBy: newOrderBy, currentPage: 0 });
  }

  function handleChangePage(pageNumber: number) {
    setPage(pageNumber);
    // saveLocalState({ page: pageNumber });
    dispatchOffers('CHANGE_OFFER', { currentPage: pageNumber });
  }

  function handleChangeRowsPerPage(rowsPerPageAmount: number) {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
    dispatchOffers('CHANGE_OFFER', { currentPage: 0, rowsPerPageAmount });
  }

  const handleSearch = (value: string): void => {
    setSearch(value);
    setPage(0);
    dispatchOffers('CHANGE_OFFER', { currentPage: 0, contextSearch: value });
  };

  function handleSelect(newSelected: any[]) {
    setSelected(newSelected);
  }

  return (
    <>
      <Grid container>
        <Grid item xs>
          <SearchBar
            defaultValue={search}
            placeholder={searchBarPlaceholder}
            onChange={handleSearch}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <Typography variant="h5">
              {isRental ? 'Ready to Rent' : 'Ready to Buy'}
              {selectedLength > 0 ? ` ${selectedLength} selected.` : ''}
            </Typography>
          </div>
          <div className={classes.spacer} />
          {selectedLength > 0 && (
            <div className={classes.toolbarActions}>
              <span>Selected actions: </span>
              <Button
                id="fs-export-offer"
                size="small"
                disabled={selectedLength === 0}
                onClick={handleExport}
              >
                Export
              </Button>
              <Button
                size="small"
                disabled={selectedLength === 0}
                onClick={handlePrint}
              >
                Print
              </Button>
            </div>
          )}
          {exportAllLoading ? (<Spinner size={20} />) : (
            <Button
              id="fs-export-offer"
              size="small"
              onClick={exportAll}
              disabled={offers.length === 0}
            >
              Export All
            </Button>
          )}
        </Toolbar>
        <div className={classes.tableWrapper}>
          {loading && (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
          <Table<Offer>
            className={classes.table}
            IDKey="id"
            selected={selected}
            data={offers}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            header={headRows}
            onSelect={handleSelect}
            onChangeOrder={handleChangeOrder}
            onRowClick={handleOfferSelect}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={offers.length}
          rowsTotal={offersTotal}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default React.memo(Offers);
