import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';
import CallMadeIcon from '@material-ui/icons/CallMade';
import InputBase from '@material-ui/core/InputBase';
import { WithStyles } from '@material-ui/styles';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import styles from './styles';

type Props = WithStyles<typeof styles>;

const BillingToolBar: React.FC<Props> = props => {
  const { classes } = props;

  return (
    <Toolbar classes={{ root: classes.root }}>
      <div className={classes.itemsContainer}>
        <Button variant="outlined" classes={{ root: classes.buttonRoot }}>

          <FilterListIcon />
      Filter
        </Button>
        <Paper classes={{
          root: classes.inputRoot,
        }}
        >
          <SearchIcon className={classes.searchButton} />
          <InputBase
            placeholder="Search…"
          />
        </Paper>
      </div>
      <Button variant="outlined" classes={{ root: classes.buttonRoot }}>

        <CallMadeIcon />
      Export
      </Button>
    </Toolbar>
  );
};

export default BillingToolBar;
