import { createSelector } from 'reselect';
import { AppState } from '../store';
import { ExportAllState } from '../types/exportAll';


const selectExportAllState = (state: AppState) => state.exportAll;
const selectLoading = (state: ExportAllState) => state.loading;

export const selectExportAllLoadingState = createSelector(
  selectExportAllState,
  selectLoading,
);
