import { createSelector } from 'reselect';
import { AppState } from '../store';
import { IntegrationsState } from '../types/integrations';


export const selectIntegrationsState = (state: AppState) => state.integrations;
const selectItems = (state: IntegrationsState) => state.integrations;


export const getIntegrationsData = createSelector(
  selectIntegrationsState,
  selectItems,
);
