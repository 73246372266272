import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  navRow: {
    marginBottom: 22,
  },
  button: {
    padding: '10px 16px',
    textTransform: 'capitalize',
    fontSize: 16,
    lineHeight: '20px',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  buttonGroup: {
    borderRadius: 0,
    boxShadow: 'none',
    '&:first-of-type': {
      borderRadius: '10px 0 0 10px',
    },
    '&:last-of-type': {
      borderRadius: '0 10px 10px 0',
    },
  },
  saveButton: {
    padding: '10px 40px',
  },
  cardWrapper: {
    width: '100%',
  },
  card: {
    borderRadius: 10,
    boxShadow: theme.shadows[3],
    overflow: 'hidden',
  },
  tabs: {
    padding: '15px 45px 0 45px',
    background: '#E9ECF2',
    borderRadius: '10px 10px 0px 0px',
  },
  tabRoot: {
    minWidth: 50,
    padding: '0',
    marginRight: theme.spacing(1),
    textTransform: 'capitalize',
    fontSize: 16,
    '&.Mui-selected': {
      color: theme.appPalette.orange,
    },
  },
  tabIndicator: {
    backgroundColor: theme.appPalette.orange,
  },
  cardContent: {
    paddingLeft: 45,
    paddingRight: 45,
    '&:last-child': {
      paddingBottom: 57,
    },
  },
  formTitle: {
    marginBottom: 22,
  },
  cardActions: {
    marginTop: 45,
  },
});
