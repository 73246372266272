import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { MenuItem, TextField } from '@material-ui/core';
import { State } from '../../../redux/types/states';
import { Agent } from '../../../redux/types/agents';
import useStyles from './styles';
import CustomButton from '../../CustomButton';
import { Community } from '../../../redux/types/communities';
import { beautifyErrors, ValidationErrors } from '../../../utils/helpers';
import { communityInputSchema } from '../CommunityInputValidation';
import { Builder } from '../../../redux/types/builders';

type Props = {
  defaultCommunity: Community;
  states: State[];
  agents: Agent[];
  enterpriseBuilders: Builder[];
  enterpriseId: number | null;
  builderId: number | null;
  builderName: string
  loadingEnterpriseBuilders: boolean;
  isEnterprise: boolean;
  selectedBuilder: number | null
  handleChanges: (community: Community) => void;
  handleSelectBuilder: (builderId: number) => void
};

const CommunityInfo: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  const {
    states,
    handleChanges,
    defaultCommunity,
    agents,
    enterpriseId,
    isEnterprise,
    builderName,
    builderId,
    enterpriseBuilders,
    handleSelectBuilder,
    selectedBuilder,
  } = props;

  const [validationErrors, setValidationErrors] = useState<ValidationErrors<Community>>({});
  const [community, setCommunity] = useState<Community>(defaultCommunity);

  useEffect(() => {
    if (!enterpriseId) {
      setCommunity((prevState) => ({
        ...prevState,
        builder_id: builderId,
        builderName,
      }));
    }
  }, [enterpriseId, builderId, builderName]);

  const handleChangeCommunity = (
    { target: { name, value } }: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (name) {
      setCommunity((prevCommunity) => ({
        ...prevCommunity,
        [name]: value,
      }));
    }
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCommunity((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const saveCommunityInfo = () => {
    try {
      const validCommunity = communityInputSchema
        .validateSync(community, { abortEarly: false }) as Community;
      setValidationErrors({});
      handleChanges(validCommunity);
    } catch (errors) {
      setValidationErrors(beautifyErrors<Community>(errors));
    }
  };

  return (
    <>
      <div className={classes.headerText}>
        <span>Community Info</span>
      </div>
      <div className={classes.inputForm}>
        <Grid container spacing={4}>
          {isEnterprise && (
          <Grid
            item
            xs
            className={!enterpriseId ? classes.disabled : ''}
          >
            <TextField
              select
              required
              fullWidth
              margin="normal"
              id="state"
              name="state"
              label="Select Builder"
              value={enterpriseId ? selectedBuilder ?? '' : builderId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const { value } = e.target;

                if (!value) {
                  return;
                }

                handleSelectBuilder(Number(value));
                setCommunity((prevState) => ({
                  ...prevState,
                  builder_id: Number(value),
                }));
              }}
              error={Boolean(validationErrors.builder_id)}
              helperText={Boolean(validationErrors.builder_id) && 'Builder is required field'}
            >
              <MenuItem value="" disabled>
                      Select Builder
              </MenuItem>
              {enterpriseId ? (
                enterpriseBuilders.map(
                  ({ builder_id, name }, index) => (
                    <MenuItem key={builder_id} value={builder_id}>
                      {name}
                    </MenuItem>
                  ),
                )
              ) : (
                <MenuItem key={builderId ?? ''} value={builderId ?? ''}>
                  {community.builderName}
                </MenuItem>
              )}
            </TextField>
          </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <TextField
              onChange={handleChangeCommunity}
              placeholder="example: Crystal Falls"
              margin="normal"
              required
              value={community.name}
              fullWidth
              label="Community Name"
              name="name"
              error={Boolean(validationErrors.name)}
              helperText={validationErrors.name}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              select
              required
              fullWidth
              margin="normal"
              id="state"
              name="state"
              label="State/Province"
              value={community.state}
              onChange={handleStateChange}
              error={Boolean(validationErrors.state)}
              helperText={validationErrors.state}
            >
              <MenuItem disabled>Select State</MenuItem>
              {states.map(({ state_id: stateId, state_name: stateName }) => (
                <MenuItem
                  key={stateId}
                  value={stateId}
                >
                  {stateName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              value={community.city}
              onChange={handleChangeCommunity}
              placeholder="example: New York"
              margin="normal"
              id="city"
              name="city"
              label="City"
              error={Boolean(validationErrors.city)}
              helperText={validationErrors.city}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              value={community.zip}
              onChange={handleChangeCommunity}
              placeholder="example: 00000"
              required
              margin="normal"
              id="zip"
              name="zip"
              label="Zip Code"
              error={Boolean(validationErrors.zip)}
              helperText={validationErrors.zip}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            className={enterpriseId && !selectedBuilder ? classes.disabled : ''}
          >
            <TextField
              select
              required
              fullWidth
              margin="normal"
              id="emergency_contacts"
              name="emergency_contacts"
              label="Point Person to relay concerns"
              value={community.emergency_contacts ?? ''}
              onChange={handleChangeCommunity}
              error={Boolean(validationErrors.emergency_contacts)}
              helperText={validationErrors.emergency_contacts}
            >
              <MenuItem disabled>Available Users</MenuItem>
              {agents.map(({ username: agentsId, firstname: FirstName, lastname: LastName }) => (
                <MenuItem
                  key={agentsId}
                  value={agentsId}
                >
                  {FirstName}
                  {' '}
                  {LastName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              onChange={handleChangeCommunity}
              margin="normal"
              required
              value={community.sales_agent}
              fullWidth
              label="Sales Contact #"
              name="sales_agent"
              error={Boolean(validationErrors.sales_agent)}
              helperText={validationErrors.sales_agent}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              value={community.external_community_id}
              onChange={handleChangeCommunity}
              placeholder="example: 0001"
              margin="normal"
              id="external_community_id"
              name="external_community_id"
              label="External ID"
              error={Boolean(validationErrors.external_community_id)}
              helperText={validationErrors.external_community_id}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              value={community.community_url}
              placeholder="example: https://www.builder.com/community"
              margin="normal"
              id="community_url"
              name="community_url"
              label="Community URL link"
              onChange={handleChangeCommunity}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              value={community.floor_plan_url}
              placeholder="example: https://www.builder.com/community"
              margin="normal"
              id="floor_plan_url"
              name="floor_plan_url"
              label="Floor Plan URL link"
              onChange={handleChangeCommunity}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              value={community.virtual_tour_url}
              placeholder="example: https://www.builder.com/community"
              margin="normal"
              id="virtual_tour_url"
              name="virtual_tour_url"
              label="Virtual Tour URL link"
              onChange={handleChangeCommunity}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <TextField
              margin="normal"
              required
              placeholder="example: 989512345"
              value={community.sales_agent}
              fullWidth
              label="Community phone number or OSC phone number"
              name="sales_agent"
              onChange={handleChangeCommunity}
              error={Boolean(validationErrors.sales_agent)}
              helperText={validationErrors.sales_agent}
            />
          </Grid> */}

          <Grid item xs={12} sm={12}>
            <TextField
              placeholder="Type here..."
              margin="normal"
              fullWidth
              multiline
              value={community.additional_notes}
              rows={8}
              // rowsMax={8}
              label="Additional Notes"
              name="additional_notes"
              onChange={handleChangeCommunity}
              inputProps={{
                style: {
                  paddingLeft: 12,
                },
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.navButtons}>
        <CustomButton variant="orange" onClick={() => saveCommunityInfo()}>
          Next
        </CustomButton>
      </div>
    </>
  );
};


export default React.memo(CommunityInfo);
