import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({

  headerText: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
  },

  integratinsContainer: {
    borderTop: '1px solid rgba(142, 139, 153, 0.25)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '50px',
    paddingTop: '50px'
  },

  navButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  integrations: {
    textAlign: 'center',
    paddingTop: '30px',
    width: '129px',
    height: '129px',
    marginRight: '30px',
    background: '#F0F8FF',
    borderRadius: '10px',
  }

});

const useStyles = makeStyles(styles);

export default useStyles;
