import {
  all, takeLatest, call, put,
} from 'redux-saga/effects';
import {
  EXPORT_ALL_REQUEST,
} from '../types/visits';

import { loadExportAll } from '../../services/visits';
import {
  exportAllSuccess,
  exportAllFailed, exportAllRequest,
} from '../actions/exportAll';
import { exportToFile } from '../../services/export';


function* loadVisitsExportAllSaga(action: ReturnType<typeof exportAllRequest>) {
  try {
    const { mimeType, path, params, query } = action.payload;
    const { data } = yield call(loadExportAll, path, mimeType, params, query);

    const fileType = { type: mimeType } as const;
    const blob = new Blob([data], fileType);
    const fileName = `${path}-export.csv`;

    yield call(exportToFile, blob, fileType, fileName);
    yield put(exportAllSuccess());
  } catch (err) {
    yield put(exportAllFailed());
  }
}

export default all([
  takeLatest<any>(EXPORT_ALL_REQUEST, loadVisitsExportAllSaga),
]);
