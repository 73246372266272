import { ErrorMessage, ValueOf } from '../../types/main';
import * as VisitsAfterTourHoursActions from '../actions/afterTourHours';

export const VISITS_AFTER_TOUR_HOURS_REQUEST = 'VISITS_AFTER_TOUR_HOURS_REQUEST';
export const VISITS_AFTER_TOUR_HOURS_REQUEST_SUCCESS = 'VISITS_AFTER_TOUR_HOURS_REQUEST_SUCCESS';
export const VISITS_AFTER_TOUR_HOURS_REQUEST_FAILED = 'VISITS_AFTER_TOUR_HOURS_REQUEST_FAILED';
export const VISITS_AFTER_TOUR_HOURS_EXPORT_REQUEST = 'VISITS_AFTER_TOUR_HOURS_EXPORT_REQUEST';
export const VISITS_AFTER_TOUR_HOURS_EXPORT_REQUEST_SUCCESS = 'VISITS_AFTER_TOUR_HOURS_EXPORT_REQUEST_SUCCESS';
export const VISITS_AFTER_TOUR_HOURS_EXPORT_REQUEST_FAILED = 'VISITS_AFTER_TOUR_HOURS_EXPORT_REQUEST_FAILED';
export const VISITS_AFTER_TOUR_HOURS_EXPORT_AND_PRINT_REQUEST = 'VISITS_AFTER_TOUR_HOURS_EXPORT_AND_PRINT_REQUEST';

export interface VisitsAfterTourHours {
  _id: number;
  property_id: number;
  community: string;
  tour_hours_window: string;
  modified_on: string;
}

export interface VisitsAfterTourHoursState {
  items: VisitsAfterTourHours[];
  total: number;
  loading: boolean;
  error: ErrorMessage['message'];
}

export interface VisitsAfterTourHoursRequestSuccess {
  items: VisitsAfterTourHours[];
  total: number;
}

export type VisitsAfterTourHoursExportRequestActionType = ReturnType<
| typeof VisitsAfterTourHoursActions.visitsAfterTourHoursExportRequest
| typeof VisitsAfterTourHoursActions.visitsAfterTourHoursExportAndPrintRequest
>;

export type VisitsAfterTourHoursActionsTypes = ReturnType<
ValueOf<typeof VisitsAfterTourHoursActions>
>;
