import {
  AGENT_DETAILS_REQUEST,
  AGENT_DETAILS_REQUEST_FAILED,
  AGENT_DETAILS_REQUEST_SUCCESS,
  AGENTS_REQUEST,
  AGENTS_REQUEST_SUCCESS,
  AGENTS_REQUEST_FAILED,
  ADD_AGENT_REQUEST,
  ADD_AGENT_REQUEST_SUCCESS,
  ADD_AGENT_REQUEST_FAILED,
  UPDATE_AGENT_REQUEST,
  UPDATE_AGENT_REQUEST_SUCCESS,
  UPDATE_AGENT_REQUEST_FAILED,
  UPDATE_PROFILE_REQUEST,
  DELETE_AGENTS_REQUEST,
  UPDATE_SUGGESTED_USERNAME,
  RESET_SUGGESTED_USERNAME,
  RESEND_EMAIL_REQUEST,
  RESEND_EMAIL_FAILED,
  RESEND_EMAIL_SUCCESS,
  AgentsRequestSuccess,
  AgentDetailsRequestSuccess,
  AVAILABLE_AGENTS_REQUEST,
  AVAILABLE_AGENTS_REQUEST_SUCCESS,
  AVAILABLE_AGENTS_REQUEST_FAILED,
} from '../types/agents';
import { Query } from '../../utils/query';
import { ErrorMessage } from '../../types/main';

export const agentsRequest = (query?: Query) => ({
  type: AGENTS_REQUEST,
  payload: query,
} as const);

export const availableAgentsRequest = (payload?: number) => ({
  type: AVAILABLE_AGENTS_REQUEST,
  payload,
} as const);

export const availableAgentsRequestSuccess = (payload: AgentsRequestSuccess) => ({
  type: AVAILABLE_AGENTS_REQUEST_SUCCESS,
  payload,
} as const);

export const availableAgentsRequestFailed = (payload: ErrorMessage) => ({
  type: AVAILABLE_AGENTS_REQUEST_FAILED,
  payload,
} as const);

export const deleteAgentsRequest = (payload: string[]) => ({
  type: DELETE_AGENTS_REQUEST,
  payload,
} as const);

export const agentsRequestFailed = (payload: ErrorMessage) => ({
  type: AGENTS_REQUEST_FAILED,
  payload,
} as const);

export const agentsRequestSuccess = (payload: AgentsRequestSuccess) => ({
  type: AGENTS_REQUEST_SUCCESS,
  payload,
} as const);

export const agentDetailsRequest = (agentID: number | string) => ({
  type: AGENT_DETAILS_REQUEST,
  payload: agentID,
} as const);

export const resendEmailRequest = (payload: {username: string, email: string} | null) => ({
  type: RESEND_EMAIL_REQUEST,
  payload,
} as const);

export const resendEmailFailed = (payload: ErrorMessage) => ({
  type: RESEND_EMAIL_FAILED,
  payload,
} as const);

export const resendEmailSuccess = () => ({
  type: RESEND_EMAIL_SUCCESS,
} as const);

export const agentDetailsFailed = (payload: ErrorMessage) => ({
  type: AGENT_DETAILS_REQUEST_FAILED,
  payload,
} as const);

export const agentDetailsSuccess = (payload: AgentDetailsRequestSuccess) => ({
  type: AGENT_DETAILS_REQUEST_SUCCESS,
  payload,
} as const);

export const addAgentRequest = (agentPayload: Record<string, any>) => ({
  type: ADD_AGENT_REQUEST,
  payload: agentPayload,
} as const);

export const addAgentFailed = (payload: ErrorMessage) => ({
  type: ADD_AGENT_REQUEST_FAILED,
  payload,
} as const);

export const addAgentSuccess = () => ({
  type: ADD_AGENT_REQUEST_SUCCESS,
} as const);

export const updateAgentRequest = (agentPayload: Record<string, any>) => ({
  type: UPDATE_AGENT_REQUEST,
  payload: agentPayload,
} as const);

export const updateProfileRequest = (userPayload: Record<string, any>) => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: userPayload,
} as const);


export const updateAgentFailed = (payload: ErrorMessage) => ({
  type: UPDATE_AGENT_REQUEST_FAILED,
  payload,
} as const);

export const updateAgentSuccess = () => ({
  type: UPDATE_AGENT_REQUEST_SUCCESS,
} as const);

export const insertSuggestedUsername = (payload: string) => ({
  type: UPDATE_SUGGESTED_USERNAME,
  payload,
} as const);

export const resetSuggestedUsername = () => ({
  type: RESET_SUGGESTED_USERNAME,
} as const);
