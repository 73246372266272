import {
  SMART_LOCKS_REQUEST,
  SMART_LOCKS_REQUEST_SUCCESS,
  SMART_LOCKS_REQUEST_FAILED,
  SMART_LOCK_DETAILS_REQUEST,
  SMART_LOCK_DETAILS_REQUEST_SUCCESS,
  SMART_LOCK_DETAILS_REQUEST_FAILED,
  SmartLocksRequestSuccess,
  SmartLockDetailsRequestSuccess,
  SmartLockCode,
  UPDATE_SMART_LOCK_CODE_REQUEST,
  UPDATE_SMART_LOCK_CODE_REQUEST_SUCCESS,
  UPDATE_SMART_LOCK_CODE_REQUEST_FAILED,
  DELETE_SMART_LOCK_CODE_REQUEST,
  DELETE_SMART_LOCK_CODE_REQUEST_SUCCESS,
  DELETE_SMART_LOCK_CODE_REQUEST_FAILED,
  DeleteSmartLockCode,
  REFRESH_SMART_LOCK_DETAILS_REQUEST,
} from '../types/smartLocks';
import { Query } from '../../utils/query';
import { ErrorMessage } from '../../types/main';

export const smartLocksRequest = (query?: Query) => ({
  type: SMART_LOCKS_REQUEST,
  payload: query,
} as const);

export const smartLocksRequestFailed = (payload: ErrorMessage) => ({
  type: SMART_LOCKS_REQUEST_FAILED,
  payload,
} as const);

export const smartLocksRequestSuccess = (payload: SmartLocksRequestSuccess) => ({
  type: SMART_LOCKS_REQUEST_SUCCESS,
  payload,
} as const);

export const smartLockDetailsRequest = (smartLockSerial: string | number) => ({
  type: SMART_LOCK_DETAILS_REQUEST,
  payload: smartLockSerial,
} as const);

export const refreshSmartLockDetailsRequest = (payload: string) => ({
  type: REFRESH_SMART_LOCK_DETAILS_REQUEST,
  payload,
} as const);

export const smartLockDetailsRequestFailed = (payload: ErrorMessage) => ({
  type: SMART_LOCK_DETAILS_REQUEST_FAILED,
  payload,
} as const);

export const smartLockDetailsRequestSuccess = (payload: SmartLockDetailsRequestSuccess) => ({
  type: SMART_LOCK_DETAILS_REQUEST_SUCCESS,
  payload,
} as const);

export const updateSmartLockCodeRequest = (payload: SmartLockCode) => ({
  type: UPDATE_SMART_LOCK_CODE_REQUEST,
  payload,
} as const);

export const updateSmartLockCodeRequestSuccess = (payload: SmartLockDetailsRequestSuccess) => ({
  type: UPDATE_SMART_LOCK_CODE_REQUEST_SUCCESS,
  payload,
} as const);

export const updateSmartLockCodeRequestFailed = (payload: ErrorMessage) => ({
  type: UPDATE_SMART_LOCK_CODE_REQUEST_FAILED,
  payload,
} as const);

export const deleteSmartLockCodeRequest = (payload: DeleteSmartLockCode) => ({
  type: DELETE_SMART_LOCK_CODE_REQUEST,
  payload,
} as const);

export const deleteSmartLockCodeRequestSuccess = (payload: SmartLockDetailsRequestSuccess) => ({
  type: DELETE_SMART_LOCK_CODE_REQUEST_SUCCESS,
  payload,
} as const);

export const deleteSmartLockCodeRequestFailed = (payload: ErrorMessage) => ({
  type: DELETE_SMART_LOCK_CODE_REQUEST_FAILED,
  payload,
} as const);
