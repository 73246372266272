import { createStyles } from '@material-ui/core';

export default () => createStyles({
  cardActions: {
    marginTop: 45,
  },
  beta: {
    color: '#fb5700',
    fontSize: 10,
    fontWeight: 'bold',
    marginLeft: '4px',
    marginBottom: '15px',
  },
  // password: {
  //   '& > label': {
  //     color: 'red',
  //     top: -5,
  //     ':focus': {
  //       top: 2
  //     }
  //   }
  // }
});
