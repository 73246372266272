import axios from './axios';
import { buildQuery, Query } from '../utils/query';

export const loadVisitsAfterTourHours = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/visits/afterTourHours${query}`);
};

export const loadVisitsAfterTourHoursExport = (
  selection: Array<number | string>,
  accept: 'application/pdf' | 'text/csv' = 'application/pdf',
) => {
  const headers = {
    Accept: accept,
  };

  return axios.post(
    '/visits/afterTourHours/export',
    {
      selection,
    },
    { headers, responseType: 'blob' },
  );
};
