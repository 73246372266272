import {
  createStyles, makeStyles, Slider,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import theme from '../../theme';

const styles = () => createStyles({
  title: {
    display: 'block',
    fontSize: '14px',
    textTransform: 'uppercase',
    color: theme.appPalette.orange,
    marginBottom: '20px',
  },

  label: {
    fontSize: '12px',
    fontWeight: 700,
    color: theme.palette.text.secondary,
  },

  colorPicker: {
    WebkitAppearance: 'none',
    padding: 0,
    width: '40px',
    height: '40px',
    border: 'none',
    borderRadius: '5px',
    marginRight: '10px',
  },

  previewButton: {
    cursor: 'default',
    border: 'none',
    padding: '16px 32px',
    textDecoration: 'none',
    fontWeight: 400,
    lineHeight: 1,
  },

  editButton: {
    border: 'none',
    color: '#fff',
    backgroundColor: '#8E8B99',
    '& svg': {
      width: '14px',
      height: '14px',
      marginRight: '5px',
    },
    '&:hover': {
      border: 'none',
      backgroundColor: theme.appPalette.orange,
    },
  },

  textArea: {
    padding: '15px',
    width: '60%',
    resize: 'none',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: '#e3e3e3',
  },
});

export const CustomSlider = withStyles({
  root: {
    color: '#DB5C0E',
    height: 10,
  },
  thumb: {
    height: 24,
    width: 24,
    border: '2px solid',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 10,
    borderRadius: 5,
    backgroundColor: 'transparent',
  },
  rail: {
    height: 10,
    borderRadius: 5,
    backgroundColor: theme.palette.text.secondary,
  },
})(Slider);

const useStyles = makeStyles(styles);

export default useStyles;
