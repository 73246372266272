import { createSelector } from 'reselect';
import { AppState } from '../store';
import { PropertiesState } from '../types/properties';

const selectPropertiesState = (state: AppState) => state.properties;
const selectItems = (state: PropertiesState) => state.items;
const selectLoading = (state: PropertiesState) => state.loading;
const selectUpdating = (state: PropertiesState) => state.updating;
const selectTotal = (state: PropertiesState) => state.total;
const selectSelected = (state: PropertiesState) => state.selected;
const selectPropertyTours = (state: PropertiesState) => state.propertyTours;
const selectSelectedHubId = (state: PropertiesState): string|null => state.selected?.info.hub_id || null;

export const selectPropertySelected = createSelector(
  selectPropertiesState,
  selectSelected,
);

export const selectProperties = createSelector(
  selectPropertiesState,
  selectItems,
);

export const selectTotalProperties = createSelector(
  selectPropertiesState,
  selectTotal,
);

export const selectPropertiesRequestStatus = createSelector(
  selectPropertiesState,
  selectLoading,
);

export const selectPropertiesUpdateStatus = createSelector(
  selectPropertiesState,
  selectUpdating,
);

export const selectPropertySelectedHubId = createSelector(
  selectPropertiesState,
  selectSelectedHubId,
);

export const selectPropertyToursReport = createSelector(
  selectPropertiesState,
  selectPropertyTours,
);
