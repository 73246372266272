import { combineReducers } from 'redux';
import {
  ALL_PROPERTIES_STATS_REQUEST,
  ALL_PROPERTIES_STATS_REQUEST_FAILED,
  ALL_PROPERTIES_SOLD_STATS_REQUEST_SUCCESS,
  ALL_PROPERTIES_ACTIVE_STATS_REQUEST_SUCCESS,
  ALL_VISITS_REQUEST,
  ALL_VISITS_REQUEST_FAILED,
  ALL_VISITS_REQUEST_SUCCESS,
  MONTHLY_VISITS_REQUEST,
  MONTHLY_VISITS_REQUEST_FAILED,
  MONTHLY_VISITS_REQUEST_SUCCESS,
  WEEKLY_VISITS_REQUEST,
  WEEKLY_VISITS_REQUEST_FAILED,
  WEEKLY_VISITS_REQUEST_SUCCESS,
  YEARLY_VISITS_REQUEST,
  YEARLY_VISITS_REQUEST_FAILED,
  YEARLY_VISITS_REQUEST_SUCCESS,
  PropertiesStatsState,
  AllVisitsState,
  MonthlyVisitsState,
  WeeklyVisitsState,
  YearlyVisitsState,
  ContractorsState,
  CONTRACTORS_SERVED_COUNT_REQUEST,
  CONTRACTORS_SERVED_COUNT_REQUEST_SUCCESS,
  CONTRACTORS_SERVED_COUNT_REQUEST_FAILED,
  SOLD_PROPERTIES_REQUEST_SUCCESS,
  SOLD_PROPERTIES_REQUEST_FAILED,
  PropertiesStatsActionsTypes,
  WeeklyVisitsStatsActionsTypes,
  MonthlyVisitsStatsActionsTypes,
  YearlyVisitsStatsActionsTypes,
  AllVisitsStatsActionsTypes,
  ContractorsActionsTypes,
} from '../types/stats';

const propertiesInitialState: PropertiesStatsState = {
  propertiesActive: [],
  propertiesSold: [],
  error: '',
  loading: false,
};

const properties = (
  state = propertiesInitialState,
  action: PropertiesStatsActionsTypes,
): PropertiesStatsState => {
  switch (action.type) {
    case ALL_PROPERTIES_STATS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case ALL_PROPERTIES_SOLD_STATS_REQUEST_SUCCESS: {
      return {
        ...state,
        propertiesSold: action.payload,
        error: '',
        loading: false,
      };
    }

    case ALL_PROPERTIES_ACTIVE_STATS_REQUEST_SUCCESS: {
      return {
        ...state,
        propertiesActive: action.payload,
        error: '',
        loading: false,
      };
    }

    case ALL_PROPERTIES_STATS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case SOLD_PROPERTIES_REQUEST_SUCCESS: {
      return {
        ...state,
        propertiesSold: action.payload,
      };
    }

    case SOLD_PROPERTIES_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

const weeklyVisitsInitialState: WeeklyVisitsState = {
  items: [],
  error: '',
  loading: false,
};

const weeklyVisits = (
  state = weeklyVisitsInitialState,
  action: WeeklyVisitsStatsActionsTypes,
): WeeklyVisitsState => {
  switch (action.type) {
    case WEEKLY_VISITS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case WEEKLY_VISITS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        error: '',
        loading: false,
      };
    }

    case WEEKLY_VISITS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

const monthlyVisitsInitialState: MonthlyVisitsState = {
  items: [],
  error: '',
  loading: false,
};

const monthlyVisits = (
  state = monthlyVisitsInitialState,
  action: MonthlyVisitsStatsActionsTypes,
): MonthlyVisitsState => {
  switch (action.type) {
    case MONTHLY_VISITS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case MONTHLY_VISITS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        error: '',
        loading: false,
      };
    }

    case MONTHLY_VISITS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

const yearlyVisitsInitialState: YearlyVisitsState = {
  items: [],
  error: '',
  loading: false,
};

const yearlyVisits = (
  state = yearlyVisitsInitialState,
  action: YearlyVisitsStatsActionsTypes,
): YearlyVisitsState => {
  switch (action.type) {
    case YEARLY_VISITS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case YEARLY_VISITS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        error: '',
        loading: false,
      };
    }

    case YEARLY_VISITS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

const allVisitsInitialState: AllVisitsState = {
  items: [],
  error: '',
  loading: false,
};

const allVisits = (
  state = allVisitsInitialState,
  action: AllVisitsStatsActionsTypes,
): AllVisitsState => {
  switch (action.type) {
    case ALL_VISITS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case ALL_VISITS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        error: '',
        loading: false,
      };
    }

    case ALL_VISITS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

const contractorsInitialState: ContractorsState = {
  served: 0,
  error: '',
  loading: false,
};

const contractors = (
  state = contractorsInitialState,
  action: ContractorsActionsTypes,
): ContractorsState => {
  switch (action.type) {
    case CONTRACTORS_SERVED_COUNT_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case CONTRACTORS_SERVED_COUNT_REQUEST_SUCCESS: {
      return {
        ...state,
        served: action.payload,
        error: '',
        loading: false,
      };
    }

    case CONTRACTORS_SERVED_COUNT_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default combineReducers({
  properties,
  weeklyVisits,
  monthlyVisits,
  yearlyVisits,
  allVisits,
  contractors,
});
