/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { AppState } from '../store';
import { BillingStateType } from '../reducer/billing';
import { convertToUsdCurrencyFormat } from '../../utils/price';

const selectInvoicesState = (state: AppState) => state.billing;
const selectInvoicesInfo = (state: BillingStateType) => state.invoices
  .map(invoice => {
    const paidAmountInUSD = Number(invoice.total) / 100;

    return { ...invoice, amount_paid: convertToUsdCurrencyFormat(paidAmountInUSD) };
  });
const paidInvoices = (state: BillingStateType) => state.invoices.filter(invoice => invoice.status === 'paid').map(invoice => {
  const paidAmountInUSD = Number(invoice.amount_paid) / 100;

  return { ...invoice, amount_paid: convertToUsdCurrencyFormat(paidAmountInUSD) };
});
const paidInvoicesAmount = (state: BillingStateType) => state.invoices.filter(invoice => invoice.status === 'paid').length;


const selectInvoicesLoadState = (state: BillingStateType) => state.loading;
const selectCurrentInvoice = (state: BillingStateType) => state.selected;
const selectTotal = (state: BillingStateType) => state.total;
const getPastDueInvoices = (state: BillingStateType) => state.invoices.filter(invoice => invoice.status === 'not_paid')
  .map(invoice => {
    const paidAmountInUSD = Number(invoice.amount_paid) / 100;

    return { ...invoice, amount_paid: convertToUsdCurrencyFormat(paidAmountInUSD) };
  });
const getPastDueInvoicesAmount = (state: BillingStateType) => state.invoices.filter(invoice => invoice.status === 'not_paid').length;


export const selectInvoicesDetails = createSelector(selectInvoicesState, selectInvoicesInfo);
export const selectPaidInvoices = createSelector(selectInvoicesState, paidInvoices);
export const selectInvoicesRequestStatus = createSelector(
  selectInvoicesState,
  selectInvoicesLoadState,
);
export const selectInvoiceById = createSelector(selectInvoicesState, selectCurrentInvoice);
export const selectTotalInvoicesAmount = createSelector(selectInvoicesState, selectTotal);
export const selectPaidInvoicesAmount = createSelector(selectInvoicesState, paidInvoicesAmount);

export const selectPastDueInvoices = createSelector(selectInvoicesState, getPastDueInvoices);
export const selectPastDueInvoicesAmount = createSelector(
  selectInvoicesState, getPastDueInvoicesAmount,
);
