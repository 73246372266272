import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Query } from '../../../utils/query';
import { selectInventoryFilters } from '../../../redux/selectors/filter';
import parseUrlFilterSearch from '../../../hooks/parseUrlFilterSearch';
import useUrlFilterSearch from '../../../hooks/useUrlFilterSearch';

type UrlParamProperties = {
  [key: string]: string
};

const useInventory = (loadInventory: (query: Query) => void) => {
  const urlParse = parseUrlFilterSearch<UrlParamProperties>();
  const { lockStatus, shipmentStatus, community } = useSelector(selectInventoryFilters);
  const [search, setSearch] = useState<string>(urlParse.search || '');

  useUrlFilterSearch<UrlParamProperties>({
    search,
    lockStatus,
    shipmentStatus,
    community,
  });

  useEffect(() => {
    loadInventory({
      lockStatus, shipmentStatus, search, community,
    });
  }, [loadInventory, lockStatus, shipmentStatus, search, community]);

  const searchRequest = () => {
    loadInventory({
      lockStatus, shipmentStatus, community,
    });
  };

  return {
    searchRequest,
    search,
    setSearch,
  };
};

export default useInventory;
