import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0,
  },

  buttonRoot: {
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[3],
  },

  inputRoot: {
    height: 36,
    marginLeft: 15,
    width: 260,
    paddingLeft: 10,
    paddingTop: 2,
    boxShadow: theme.shadows[3],
    display: 'flex',
    alignItems: 'center',
  },

  itemsContainer: {
    display: 'flex',
  },

  searchButton: {
    color: theme.palette.text.secondary,
  },
});
