import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropertyInfoCard from './PropertyInfoCard';
import styles from './styles';
import { catalogRequest } from '../../redux/actions/catalog';
import { statesRequest } from '../../redux/actions/states';
import { saveProperty } from '../../redux/actions/properties';

import { AppState } from '../../redux/store';
import { propertyDispositionCatalog, lockStatusCatalog, selectCatalogRequestStatus } from '../../redux/selectors/catalog';
import { selectStates, selectStatesRequestStatus } from '../../redux/selectors/states';
import { selectIsRental } from '../../redux/selectors/auth';

const mapStateToProps = (state: AppState) => ({
  loadingCatalog: selectCatalogRequestStatus(state),
  dispositionCatalog: propertyDispositionCatalog(state),
  lockStatusCatalog: lockStatusCatalog(state),
  states: selectStates(state),
  loadingStates: selectStatesRequestStatus(state),
  isRental: selectIsRental(state.auth),
});

const mapDispatchToProps = {
  loadCatalog: catalogRequest,
  loadStates: statesRequest,
  saveProperty,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PropertyInfoCard));
