import {
  WebhooksState,
  WEBHOOKS_REQUEST,
  WEBHOOKS_REQUEST_SUCCESS,
  WEBHOOKS_REQUEST_FAILED,
  DELETE_WEBHOOKS_REQUEST,
  DELETE_WEBHOOKS_SUCCESS,
  DELETE_WEBHOOKS_FAILED,
  WEBHOOKS_DETAILS_REQUEST,
  WEBHOOKS_DETAILS_REQUEST_SUCCESS,
  WEBHOOKS_DETAILS_REQUEST_FAILED,
  WebhooksActionsTypes,
} from '../types/webhooks';

const initialState: WebhooksState = {
  items: [],
  total: 0,
  error: '',
  loading: false,
  updating: false,
  selected: null,
};

export default function WebhooksReducer(
  state = initialState,
  action: WebhooksActionsTypes,
): WebhooksState {
  switch (action.type) {
    case WEBHOOKS_DETAILS_REQUEST:
    case WEBHOOKS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case WEBHOOKS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total,
        error: '',
        loading: false,
      };
    }

    case WEBHOOKS_DETAILS_REQUEST_SUCCESS: {
      return {
        ...state,
        error: '',
        selected: action.payload,
        loading: false,
      };
    }

    case WEBHOOKS_DETAILS_REQUEST_FAILED:
    case WEBHOOKS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case DELETE_WEBHOOKS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case DELETE_WEBHOOKS_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
      };
    }

    case DELETE_WEBHOOKS_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
