import React, { Fragment } from 'react';
import { TableRow, TableCell, Checkbox } from '@material-ui/core';
import { isSelected } from '../../utils/table';

import useStyles from './styles';

type Props<T extends object> = {
  hover?: boolean;
  rowData: any;
  IDKey: any;
  onSelect?: (selected: any[]) => void;
  selected: any[];
  labelId: string;
  header: any[];
  handleRowClick: (rowData: any) => (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => void;
  handleSelectClick: (id: number) => (event: any) => void;
};

const CustomTableRow = <T extends object>(props: Props<T>) => {
  const {
    hover, rowData, handleRowClick, IDKey, handleSelectClick, onSelect, selected, labelId, header,
  } = props;
  const classes = useStyles();

  return (
    <TableRow
      hover={hover}
      key={String(rowData[IDKey])}
      onClick={handleRowClick(rowData)}
    >
      {
            onSelect && (
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isSelected(rowData[IDKey], selected)}
                  inputProps={{ 'aria-labelledby': labelId }}
                  onClick={handleSelectClick(rowData[IDKey])}
                />
              </TableCell>
            )
          }
      {
            header.map(({
              key, prepareData, render, align,
            }: {key: string, align: any, prepareData: any, render: any}) => {
              if (render) {
                return (<Fragment key={String(key)}>{render(rowData)}</Fragment>);
              }
              if (key === 'visitor_contact_status_id_value') {
                return (
                  <TableCell
                    align={align}
                    key={String(key)}
                    className={String(rowData[key]) === 'Contact' ? classes.Contact : classes['NO-Contact']}
                  >
                    {rowData[key]}
                  </TableCell>
                );
              }

              return (
                <TableCell
                  align={align}
                  key={String(key)}
                  className={String(rowData[key])}
                >
                  {prepareData ? prepareData(rowData) : rowData[key]}
                </TableCell>
              );
            })
          }
    </TableRow>
  );
};

export default React.memo(CustomTableRow);
