import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import CustomerInfoCard from './CustomerInfoCard';
import styles from './styles';
import { updateCustomerRequest } from '../../redux/actions/customers';

const mapDispatchToProps = {
  updateCustomerRequest,
};


export default withStyles(styles)(connect(null, mapDispatchToProps)(CustomerInfoCard));
