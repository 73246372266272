import React from 'react';
import { WithStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Spinner from '@material-ui/core/CircularProgress';
import CustomButton from '../CustomButton';
import styles from './styles';
import { ActionPayload } from '../../types/main';
import { refreshHubDevicesRequest } from '../../redux/actions/hubs/hubs';

type Props = {
  refreshHubDevices: (hubId: ActionPayload<typeof refreshHubDevicesRequest>) => void
  hubId: string | null
  updating: boolean
  refreshLoading: boolean
} & WithStyles<typeof styles>;

const HubFormControls: React.FC<Props> = (props: Props) => {
  const {
    refreshHubDevices, hubId, updating, refreshLoading, classes,
  } = props;

  const refreshDevice = () => {
    if (hubId) {
      refreshHubDevices(hubId);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <CustomButton
          variant="orange"
          onClick={refreshDevice}
          fullWidth
          disabled={updating || refreshLoading}
        >
          Refresh Device
          {(updating || refreshLoading) && <Spinner className={classes.spinner} />}

        </CustomButton>
      </Grid>
    </Grid>
  );
};

export default HubFormControls;
