import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  nternowHeader: {
    fontSize: 18,
    fontWeight: 'normal',
    textTransform: 'capitalize',
    fontFamily: 'Source Sans Pro, Roboto, Helvetica, Arial, sans-serif',

    marginLeft: 10,
  },

  nternowSubheader: {
    marginTop: 0,
    marginBottom: 10,
  },

  headers: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Source Sans Pro, Roboto, Helvetica, Arial, sans-serif',
    color: 'rgba(151, 151, 151, 1)',
    paddingLeft: 25,
    paddingRight: 85,
  },

  smallLogo: {
    display: 'none',
    width: 'auto',
    height: 'auto',
    fontSize: 'initial',
    [theme.breakpoints.between('xs', 'md')]: {
      display: 'block',
    },
  },
  content: {
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    backgroundColor: '#fff',
    padding: '40px',
    width: '80%',
    margin: '0 auto',
  },

  cardHeader: {
    margin: '0 auto',
    width: 226,
    height: 50,
    padding: '0px 10px',
    marginBottom: 30,
    fontSize: 28,
    textTransform: 'uppercase',
    fontFamily: 'Source Sans Pro, "Roboto", "Helvetica", "Arial", sans-serif',
  },

  itemsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0, 0, 0, 0.54)',
  },

  itemName: {
    fontSize: 20,
    marginLeft: 30,
  },

  itemValue: {
    fontSize: 20,
    textAlign: 'center',
    width: 200,
  },

  invoiceHeader: {
    textAlign: 'right',
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: 0,
  },

  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});
