import { eventChannel } from 'redux-saga';
import {
  call,
  take,
  put,
} from 'redux-saga/effects';
import { createClient } from '../../split';
import { featuresRequestSuccess, featuresRequestFailed } from '../actions/split';

const createChannel = (splitClient: ReturnType<typeof createClient>) => eventChannel(emit => {
  const handler = () => {
    const treatment = splitClient.getTreatments(['BILLING_UI']);
    emit(treatment);
  };

  const errorHandler = () => {
    emit(new Error('sdk time out'));
  };

  splitClient.on(splitClient.Event.SDK_READY, handler);
  splitClient.on(splitClient.Event.SDK_UPDATE, handler);
  splitClient.on(splitClient.Event.SDK_READY_TIMED_OUT, errorHandler);

  return () => splitClient.destroy();
});

function* splitSaga() {
  const client = yield call(createClient);
  const channel = yield call(createChannel, client);

  while (true) {
    try {
      const treatment = yield take(channel);
      yield put(featuresRequestSuccess(treatment));
    } catch (e) {
      yield put(featuresRequestFailed('split error'));
      channel.close();
    }
  }
}

export default splitSaga;
