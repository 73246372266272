import { useEffect } from 'react';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import history from '../browserHsitory';
import { setEntireFilter } from '../redux/actions/filter';

const initialState: any = {
  customers: {
    customerStatus: '',
    visitorType: '',
    lastStatus: '',
  },
  properties: {
    lockStatus: '',
    lockDisposition: '',
    builder: '',
  },
  communities: {
    builder: '',
  },
  agents: {
    builder: '',
  },
  visits: {
    visitorType: '',
    lastStatus: '',
    startDate: null,
    endDate: null,
  },
  inventory: {
    lockStatus: '',
    shipmentStatus: '',
    community: '',
  },
};


export default function ParseUrlFilterSearch<T>(): T {
  const parseUrl: any = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(parseUrl).length) {
      const path = history.location.pathname.replace('/', '');
      if (path in initialState) {
        Object.keys(initialState[path]).forEach((key) => {
          initialState[path][key] = parseUrl[key] || '';
        });

        dispatch(setEntireFilter({ [path]: { ...initialState[path] } }));
      }
    }
    // eslint-disable-next-line
  }, []);

  return parseUrl;
}
