import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { AppState } from '../../redux/store';
import Integrations from './Integrations';
import { getIntegrations } from '../../redux/actions/integrations';
import { getIntegrationsData } from '../../redux/selectors/integrations';
import { builderFeedSettingsRequest } from '../../redux/actions/builders';

import styles from './styles';


const mapStateToProps = (state: AppState) => ({
  integrations: getIntegrationsData(state),
});

const mapDispatchToProps = {
  getIntegrations,
  getBuilderFeedSettings: builderFeedSettingsRequest,
};


export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Integrations));
