import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  removeBtn: {
    borderRadius: 10,
    boxShadow: 'none',
  },
  hidden: {
    display: 'none',
  },
  spinner: {
    width: '16px !important',
    height: '16px !important',
    marginLeft: 8,
    color: '#FFF',
  },
});
