import {
  BUILDERS_PMS_REQUEST_SUCCESS,
  BUILDERS_PMS_REQUEST_FAILED,
  BUILDERS_PMS_REQUEST,
  BuildersPMsState,
} from '../types/builders';
import { BuildersPMsActionsTypes } from '../types/builderPMs';

const initialState: BuildersPMsState = {
  items: [],
  error: '',
  loading: false,
  selected: null,
};

export default function BuilderPMsReducer(
  state = initialState,
  action: BuildersPMsActionsTypes,
): BuildersPMsState {
  switch (action.type) {
    case BUILDERS_PMS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case BUILDERS_PMS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        error: '',
        loading: false,
      };
    }

    case BUILDERS_PMS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
