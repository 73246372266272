import React from 'react';
import { WithStyles } from '@material-ui/styles';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import classNames from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styles from './styles';
import newIcon from '../../../assets/img/new.png';
import betaIcon from '../../../assets/beta.svg';

type Props = {
  icon: React.ElementType;
  newFeature?: boolean;
  id?: string;
  path: string;
  label: string;
  beta?: boolean;
} & WithStyles<typeof styles> & RouteComponentProps;



const MenuItem: React.FC<Props> = ({
  classes, icon: Icon, path, label, newFeature, beta, history, location, id
}) => {
  const isSelected = location.pathname.includes(path);
  const NewIconComponent: React.FC = () => {
    return (
      <img className={classes.newIcon} src={newIcon} alt="New" />
    )
  }

  const BetaIconComponent: React.FC = () => {
    return (
      <img className={classes.betaIcon} src={betaIcon} alt="Beta" />
    )
  }

  return (
    <>
      <ListItem id={id} className={classes.menuItem} button onClick={() => history.push(path)}>
        <ListItemIcon><Icon className={isSelected ? classes.selectedSvgLink : 'none'} /></ListItemIcon>
        <ListItemText classes={{ primary: classNames(classes.menuItemText, isSelected ? classes.selectedLink : '') }} primary={label} />
        {newFeature && (<NewIconComponent />)}
        {beta && (<BetaIconComponent />)}
      </ListItem>
    </>
  );
};
export default withRouter<Props, any>(MenuItem);
