import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  contentWrapper: {
    display: 'flex',
    marginRight: '-17px',
    '& > div': {
      padding: '0 17px',
      marginLeft: '-17px',
      marginTop: '33px',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  actionsBar: {
    marginBottom: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button': {
      textTransform: 'capitalize',
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  actionButtonsWrapper: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  // infoWrapper: {
  //   flexGrow: 1,
  //   flexShrink: 0,
  //   width: '348px',
  // },
  // workbenchWrapper: {
  //   flexGrow: 2,
  // },
});
