import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  content: {
    height: '100%',
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    backgroundColor: '#fff',
    padding: '40px 30px',
  },
  row: {
    marginTop: 21,
  },
  rootAvatar: {
    margin: '0 auto',
    width: 81,
    height: 81,
    fontSize: 32,
    textTransform: 'uppercase',
    backgroundColor: theme.appPalette.orange,
  },
  customerName: {
    fontSize: 32,
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  title: {
    fontSize: 20,
    marginBottom: 16,
  },
  email: {
    color: theme.appPalette.orange,
    overflowWrap: 'break-word',
  },
  badgeBase: {
    display: 'inline-block',
    padding: '10px 25px',
    borderRadius: 5,
    fontSize: 18,
    color: '#fff',
  },
  customerStatus: {
    backgroundColor: '#04A777',
  },
  customerType: {
    backgroundColor: '#040620',
    textTransform: 'uppercase',
  },
  statusAndType: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },

  Contact: {
    backgroundColor: '#04A777',
    color: 'white',
    display: 'inline-block',
    padding: '5px 22px',
  },

  blacklist: {
    margin: 0,

    '& span': {
      color: 'red',
      fontWeight: 'bold',
      letterSpacing: '1px',
      fontSize: '20px',
    },
  },

  'NO-Contact': {
    backgroundColor: '#F61B22',
    color: 'white',
    display: 'inline-block',
    padding: '5px 13px',
  },
});
