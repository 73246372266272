import { createStyles } from '@material-ui/core/styles';

export default () => createStyles({
  feedbackButton: {
    marginRight: 4,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  feedbackIcon: {
    marginRight: 4,
  },
});
