import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import { propertyTourExportAndPrintRequest, propertyToursReportExportRequest, propertyToursReportRequest } from '../../redux/actions/properties';
import { selectPropertiesRequestStatus, selectPropertyToursReport, selectTotalProperties } from '../../redux/selectors/properties';
import { AppState } from '../../redux/store';
import ReportPropertyVisit from './ReportPropertyVisit';
import { setFilter } from '../../redux/actions/filter';

import styles from './styles';
import { selectExportAllLoadingState } from '../../redux/selectors/exportAll';
import { exportAllRequest } from '../../redux/actions/exportAll';

const mapStateToProps = (state: AppState) => ({
  propertyVisitsReport: selectPropertyToursReport(state),
  propertyVisitsReportTotal: selectTotalProperties(state),
  loading: selectPropertiesRequestStatus(state),
  exportAllLoading: selectExportAllLoadingState(state),
});
const mapDispatchToProps = {
  loadPropertyTourReport: propertyToursReportRequest,
  setFilterState: setFilter,
  exportPropertyVisitsReport: propertyToursReportExportRequest,
  printPropertyVisitsReport: propertyTourExportAndPrintRequest,
  exportAllPropertyVisitsReport: exportAllRequest,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ReportPropertyVisit),
);
