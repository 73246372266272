import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  headerText: {
    paddingBottom: '12px',
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
  },

  inputForm: {
    borderTop: '1px solid rgba(142, 139, 153, 0.25)',
    padding: '20px 20% 50px 20%',
  },

  submitButton: {
    display: 'block',
    marginTop: '52px',
    marginLeft: 'auto',
    marginRight: '20%',
  },

  paper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: 25,
  },

  label: {
    margin: 0,
    fontSize: '12px',
    lineHeight: 1,
    color: 'rgba(0, 0, 0, 0.54)',
  },

  agentsContainer: {
    height: '145px',
    overflowY: 'scroll',
    padding: '12px',
    marginTop: '16px',
    border: '1px solid #DBDDE3',
    borderRadius: '5px',
    boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.25)',
    transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1)',

    '&:hover': {
      backgroundColor: 'rgba(219, 92, 14, 0.08)',
      borderColor: '#DB5C0E',
    },
  },

  hidden: {
    opacity: 0,
    visibility: 'hidden',
    pointerEvents: 'none',
  },

  agentsList: {
    minWidth: '152px',
  },

  agentsItem: {
    alignItems: 'flex-start',
    transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1)',

    '&:hover': {
      color: '#EB5757',
      cursor: 'pointer',
    },
  },
  disabled: {
    filter: 'grayscale(1)',
    opacity: '0.5',
    pointerEvents: 'none',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
