import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import {
  propertyDetailsRequest,
  propertyAddAgentRequest,
  propertyRemoveAgentRequest,
  saveProperty,
} from '../../redux/actions/properties';
import {
  selectPropertySelected,
  selectPropertiesRequestStatus,
  selectPropertiesUpdateStatus,
} from '../../redux/selectors/properties';
import { selectAgents, selectAgentsRequestStatus, selectAvailableAgents } from '../../redux/selectors/agents';
import PropertyDetails from './PropertyDetails';
import styles from './styles';
import { AppState } from '../../redux/store';
import { agentsRequest, availableAgentsRequest } from '../../redux/actions/agents';
import { selectEnterpriseStatus, selectIsRental, selectUserData } from '../../redux/selectors/auth';
import { selectHubInfo, selectHubLogs, selectHubLogLoading } from '../../redux/selectors/hubs';
import { selectTeams } from '../../redux/selectors/teams';
import { teamsByBuilderRequest, teamsRequest } from '../../redux/actions/teams';
import { getHubDevicesRequest, getHubInfoRequest, getHubLockLogRequest } from '../../redux/actions/hubs/hubs';

const mapStateToProps = (state: AppState) => ({
  propertyDetails: selectPropertySelected(state),
  loading: selectPropertiesRequestStatus(state),
  logsLoading: selectHubLogLoading(state),
  updatingProperties: selectPropertiesUpdateStatus(state),
  agents: selectAgents(state),
  availableAgents: selectAvailableAgents(state),
  loadingAgents: selectAgentsRequestStatus(state),
  isPM: selectUserData(state).isPM,
  hubInfo: selectHubInfo(state),
  lockLogs: selectHubLogs(state),
  teams: selectTeams(state),
  isRental: selectIsRental(state.auth),
  isEnterprise: selectEnterpriseStatus(state.auth),
});

const mapDispatchToProps = {
  loadPropertyDetails: propertyDetailsRequest,
  loadAgents: agentsRequest,
  loadAvailableAgents: availableAgentsRequest,
  addAgent: propertyAddAgentRequest,
  removeAgent: propertyRemoveAgentRequest,
  saveProperty,
  loadHubDevices: getHubDevicesRequest,
  loadHubInfo: getHubInfoRequest,
  loadTeams: teamsRequest,
  loadTeamsByBuilder: teamsByBuilderRequest,
  loadHubLockLog: getHubLockLogRequest,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PropertyDetails));
