/* eslint-disable comma-dangle */
/* eslint-disable quotes */
import { createStyles, makeStyles } from "@material-ui/core";

const styles = () => createStyles({
  customCheckbox: {
    userSelect: "none",
    webkitTapHighlightColor: "transparent",
    color: "rgba(0, 0, 0, 0.87)",
    border: "none",
    width: '100%',
    cursor: "default",
    height: 32,
    display: "inline-flex",
    outline: 0,
    padding: 0,
    fontSize: "0.8125rem",
    boxSizing: "border-box",
    transition:
        "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    alignItems: "left",
    fontFamily:
        '"Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif',
    whiteSpace: "nowrap",
    borderRadius: 16,
    verticalAlign: "middle",
    justifyContent: "left",
    textDecoration: "none",
    // backgroundColor: "#3CB371",
    paddingRight: 20
    // marginBottom: 20
  }
});

const useStyles = makeStyles(styles);

export default useStyles;
