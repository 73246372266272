import { createSelector } from 'reselect';
import { AppState } from '../store';
import { StatesState } from '../types/states';

const selectStatesState = (state: AppState) => state.appResources.states;
const selectItems = (state: StatesState) => state.items;
const selectLoading = (state: StatesState) => state.loading;

export const selectStates = createSelector(
  selectStatesState,
  selectItems,
);

export const selectStatesRequestStatus = createSelector(
  selectStatesState,
  selectLoading,
);
