import { ErrorMessage, ValueOf } from '../../types/main';
import * as CatalogsActions from '../actions/catalog';

export const CATALOG_REQUEST = 'CATALOG_REQUEST';
export const CATALOG_REQUEST_SUCCESS = 'CATALOG_REQUEST_SUCCESS';
export const CATALOG_REQUEST_FAILED = 'CATALOG_REQUEST_FAILED';

export type CatalogActionsTypes = ReturnType<ValueOf<typeof CatalogsActions>>;


export interface CatalogEntity {
  catalog_id: number
  catalog_type_id: number
  name: string
  value: string
}

export interface CatalogState {
  items: CatalogEntity[]
  loading: boolean
  error: ErrorMessage['message']
}

export type CatalogRequestSuccess = CatalogEntity[];
