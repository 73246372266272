import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  contentWrapper: {
    display: 'flex',
    '& > div': {
      marginTop: '8px',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    width: '85%',
    margin: '0 auto',
  },
  actionsBar: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    width: '85%',
    justifyContent: 'space-between',
    '& button': {
      textTransform: 'capitalize',
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  actionButtonsWrapper: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  backToButton: {
    backgroundColor: '#fff',
    color: theme.appPalette.orange,
  },
  actionButton: {
    backgroundColor: theme.appPalette.orange,
    color: '#fff',
    '&:hover': {
      color: theme.appPalette.orange,
    },
  },
  disabled: {
    filter: 'grayscale(1)',
    opacity: '0.5',
    pointerEvents: 'none',
  },
  rightSide: {
    minHeight: '100%',
    padding: '8px 30px 40px 30px',
    backgroundColor: '#fff',
    boxShadow: theme.shadows[3],
    borderRadius: 10,
  },
  tabsContent: {
    height: '100%',
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  timestamp: {
    color: theme.appPalette.orange,
  },
  paper: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: 15,
    paddingBottom: 5,
  },
  propertyId: {
    fontSize: 25,
  },

  headerText: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px',
  },

  note: {
    textAlign: 'center',
    fontSize: '14px',
    color: `${theme.palette.text.secondary}`,
    '& > span': {
      color: 'red',
    },
  },

  stepIcon: {
    color: `${theme.appPalette.orange} !important`,
  },

  stepper: {
    margin: '0 50px 50px 50px',
  },

  isModel: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '1.38',
    color: '#212B36',
    marginTop: '12px',
  },

  inputForm: {
    borderTop: '1px solid rgba(142, 139, 153, 0.25)',
    borderBottom: '1px solid rgba(142, 139, 153, 0.25)',
    padding: '32px 10% 32px 10%',
    minHeight: '544px',
  },

  navButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '44px 10% 60px 10%',

    '& button:first-child': {
      marginRight: '15px',
    },
  },

  alarmContainer: {
    width: '100%',
    border: '2px solid #DBDDE3',
    borderRadius: '4px',
    padding: '0 10px',
  },
});
