import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Divider, TextField } from '@material-ui/core';
import Spinner from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import CustomButton from '../CustomButton';
import { BuilderContentSettingsRequest, CONTENT_SETTINGS_DEFAULT } from '../../redux/types/builders';
import { builderSettingsCreateSchema } from './BuilderSettingsInputValidation';
import useStyles from './styles';
import { selectContentBuilderSettings } from '../../redux/selectors/builders';
import { convertKeyToLabel } from '../../utils/helpers';

type Props = {
  handleChanges: () => void;
  saveSettings: (settings: FormData) => void;
  builderSettingsRequest: (builderId: number) => void;
  builderId: number;
  isLoading: boolean;
};

const BuilderContentSettingsForm: React.FC<Props> = (props) => {
  const {
    saveSettings,
    builderSettingsRequest,
    isLoading,
    builderId,
  } = props;

  useEffect(() => {
    if (builderId) {
      builderSettingsRequest(builderId);
    }
  }, [builderSettingsRequest, builderId]);

  const classes = useStyles();

  const builderSettings = useSelector(selectContentBuilderSettings);

  const [settings, setSettings] = useState<BuilderContentSettingsRequest>(builderSettings as any);

  useEffect(() => {
    if (builderSettings) {
      setSettings(builderSettings as any);
    }
  }, [builderSettings]);

  const handleChangeSettings = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (name) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        [name]: value,
      }));
    }
  };

  const handleSaveSettings = async () => {
    try {
      const validSettings = builderSettingsCreateSchema.validateSync(settings, {
        abortEarly: false,
      }) as BuilderContentSettingsRequest;

      const formData = new FormData();
      formData.append('content_settings', JSON.stringify(validSettings));
      saveSettings(formData);
    } catch (errors) {
      console.log(errors);
    }
  };

  return (
    <>
      <Divider />
      {isLoading ? (
        <Grid container justify="center">
          <div>
            <Spinner />
          </div>
        </Grid>
      ) : (
        <>
          <div className={classes.headerText}>
            <span>Content Settings</span>
          </div>
          <div className={classes.inputForm}>
            <Grid container spacing={2}>
              {(Object.keys(CONTENT_SETTINGS_DEFAULT) as (keyof BuilderContentSettingsRequest)[])
                .map((key) => (
                  <Grid item xs={6} sm={6} key={key}>
                    <TextField
                      margin="normal"
                      fullWidth
                      label={convertKeyToLabel(key)}
                      name={key}
                      value={settings[key] ?? CONTENT_SETTINGS_DEFAULT[key]}
                      onChange={handleChangeSettings}
                    />
                  </Grid>
                ))}
            </Grid>
          </div>
          <Divider style={{ marginBottom: '20px' }} />
          <div className={classes.navButtons}>
            <CustomButton variant="orange" onClick={() => handleSaveSettings()}> Submit </CustomButton>
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(BuilderContentSettingsForm);
