import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { WithStyles } from '@material-ui/styles';
import { Box, Button, IconButton } from '@material-ui/core';
import DeviceWidget from '../../components/DeviceWidget';
import SearchBar from '../SearchBar';
import AddForm from './AddForm';
import styles from './styles';
import { Lock } from '../../redux/types/locks';
import { SmartLock } from '../../redux/types/smartLocks';
import { Query } from '../../utils/query';
import useInventory from './hooks/useInventory.hook';
import { Hub } from '../../redux/types/hubs';
import HubWidget from '../../components/HubWidget/index';
import SmartLockWidget from '../../components/SmartLockWidget/index';

type Props = {
  locksActive: Lock[];
  smartLocksActive: SmartLock[];
  locksReturned: Lock[];
  loadSmartLocks: (query?: Query) => void;
  loadLocks: (query?: Query) => void;
  loadHubs: (query?: Query) => void;
  smartLocks:SmartLock[];
  hubs: Hub[];
  clearFilterState: () => void;
  exportAllProperties: (path: string) => void;

} & WithStyles<typeof styles>;

const searchBarPlaceholder = 'Search inventory by device ID';

const Inventory: React.FC<Props> = (props) => {
  const {
    classes,
    locksActive,
    smartLocksActive,
    locksReturned,
    loadLocks,
    loadSmartLocks,
    loadHubs,
    smartLocks,
    hubs,
    clearFilterState,
    exportAllProperties,
  } = props;
  const { searchRequest, setSearch, search } = useInventory(loadLocks);

  const [open, setOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>('Current Inventory');

  useEffect(() => () => {
    clearFilterState();
  }, [clearFilterState]);

  function closeForm(): void {
    setOpen(false);
  }

  const handleSearch = (value: string) => {
    loadLocks({ search: value });
    loadSmartLocks({ search: value });
    loadHubs({ search: value });
    setSearch(value);
  };

  useEffect(() => {
    loadSmartLocks();
    loadHubs();
  }, [loadHubs, loadSmartLocks]);

  function renderLocksList(locks: Lock[], heading: string) {
    const exportAll = () => {
      exportAllProperties('locks');
    };

    return (
      <ExpansionPanel
        classes={{ root: classes.expandRoot }}
        expanded={expanded === heading}
      >
        <ExpansionPanelSummary>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Typography color="textSecondary">{heading}</Typography>
            <Button
              id="fs-export-properties"
              size="small"
              onClick={exportAll}
              disabled={locksActive.length === 0 && smartLocksActive.length === 0}
            >
              Export All
            </Button>
          </Box>
          <IconButton onClick={() => setExpanded(!expanded ? heading : false)}>
            <ExpandMoreIcon classes={expanded ? { root: '' } : { root: classes.inventoryRotateIcon }} />
          </IconButton>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={2}>
            {hubs.length > 0 && hubs.map((hub) => (
              <Grid
                key={hub.serial_number}
                item
                xs={2}
                sm={2}
              >
                <HubWidget hub={hub} />
              </Grid>
            ))}
            {smartLocks.length > 0 && smartLocks.map((smartLock) => (
              <Grid
                key={`${smartLock.serial_number}`}
                item
                xs={2}
                sm={2}
              >
                <SmartLockWidget smartLock={smartLock} />
              </Grid>
            ))}
            {locks.length > 0 && locks.map((lock) => (
              // if lock serial not unique for different versions
              <Grid
                key={`${lock.version}-${lock.serial_number}-${lock.property_id}`}
                item
                xs={2}
                sm={2}
              >
                <DeviceWidget device={lock} />
              </Grid>
            ))}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>


    );
  }

  return open ? (
    <Grid container>
      <AddForm closeForm={closeForm} />
    </Grid>
  ) : (
    <>
      <Grid container>
        <Grid item xs>
          <SearchBar
            onChange={handleSearch}
            placeholder={searchBarPlaceholder}
            searchRequest={searchRequest}
            defaultValue={search}
          />
        </Grid>
      </Grid>

      <div className={classes.widgetsWrapper}>
        {renderLocksList(locksActive, 'Current Inventory')}
        {locksReturned.length > 0 && renderLocksList(locksReturned, 'Returned Locks')}
      </div>
    </>
  );
};

export default React.memo(Inventory);
