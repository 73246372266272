import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';

import LockDetails from './LockDetails';
import styles from './styles';
import { lockDetailsRequest } from '../../redux/actions/locks';
import { selectLockDetails, selectLocksRequestStatus } from '../../redux/selectors/locks';

import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  loading: selectLocksRequestStatus(state),
  lockDetails: selectLockDetails(state),
});

const mapDispatchToProps = {
  loadLockDetails: lockDetailsRequest,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LockDetails),
);
