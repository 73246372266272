import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import ResetPassword from './ResetPassword';
import styles from './styles';
import { authUserRequest } from '../../redux/actions/auth';

const mapStateToProps = null;
const mapDispatchToProps = {
  authUserRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(ResetPassword));
