import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  cardIcon: {
    '&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader': {
      borderRadius: '3px',
      backgroundColor: '#999',
      padding: '15px',
      marginTop: '-20px',
      marginRight: '15px',
      float: 'left',
    },
  },
  warningCardHeader: {
    color: '#FFFFFF',
    '&:not($cardHeaderText):not($cardHeaderIcon)': {
      background: 'linear-gradient(60deg, #ffa726, #fb8c00)',
      boxShadow:
    '0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2)',
    },
  },
  successCardHeader: {
    color: '#FFFFFF',
    '&:not($cardHeaderText):not($cardHeaderIcon)': {
      background: 'linear-gradient(60deg, #66bb6a, #43a047)',
      boxShadow:
      '0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(76, 175, 80, 0.2)',
    },
  },
  dangerCardHeader: {
    color: '#FFFFFF',
    '&:not($cardHeaderText):not($cardHeaderIcon)': {
      background: 'linear-gradient(60deg, #ea6361, #e53935)',
      boxShadow:
      '0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(244, 67, 54, 0.2)',
    },
  },
  infoCardHeader: {
    color: '#FFFFFF',
    '&:not($cardHeaderText):not($cardHeaderIcon)': {
      background: 'linear-gradient(60deg, #26c6da,#0097a7)',
      boxShadow:
      theme.shadows[3],
    },
  },
  primaryCardHeader: {
    color: '#FFFFFF',
    '&:not($cardHeaderText):not($cardHeaderIcon)': {
      background: 'linear-gradient(60deg, #ab47bc, #8e24aa)',
      boxShadow:
      '0 12px 20px -10px rgba(156, 39, 176, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(156, 39, 176, 0.2)',

    },
  },
  roseCardHeader: {
    color: '#FFFFFF',
    '&:not($cardHeaderText):not($cardHeaderIcon)': {
      background: 'linear-gradient(60deg, #ec407a, #d81b60)',
      boxShadow:
    '0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4)',
    },
  },
});
