import { useEffect, useState, useContext } from 'react';
import { Query } from '../../utils/query';
import { Order } from '../../utils/table';
import { SearchSortContext } from '../../context/SearchSortContext';
import { ScheduledVisit } from '../../redux/types/scheduledVisit';
import { ChangeSearchPayload, PageSearchSortContextActionType } from '../../context/types';
import ParseUrlFilterSearch from '../../hooks/parseUrlFilterSearch';
import useUrlFilterSearch from '../../hooks/useUrlFilterSearch';
import { UrlParam } from '../../redux/types/filter';


const useScheduledVisits = (loadScheduledVisits: (query: Query) => void) => {
  const urlParse = ParseUrlFilterSearch<UrlParam>();
  const { dispatch, state: { scheduledVisits } } = useContext(SearchSortContext);
  const {
    currentOrderBy, currentOrder, currentPage, rowsPerPageAmount, contextSearch,
  } = scheduledVisits;

  const [page, setPage] = useState<number>(urlParse.page || currentPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(urlParse.limit || rowsPerPageAmount);
  const [search, setSearch] = useState<string>(urlParse.search || contextSearch);
  const [order, setOrder] = useState<Order>(urlParse.order || currentOrder);
  const [orderBy, setOrderBy] = useState<keyof ScheduledVisit>(urlParse.orderBy || currentOrderBy);

  useUrlFilterSearch<UrlParam>({
    search, order, orderBy, page, limit: rowsPerPage,
  });

  const dispatchVisits = (
    type: PageSearchSortContextActionType<'CHANGE_SCHEDULED_VISIT'>,
    payload?: ChangeSearchPayload<ScheduledVisit>,
  ) => dispatch({ type, payload: { ...scheduledVisits, ...payload } });

  useEffect(() => {
    loadScheduledVisits({
      search, order, orderBy, page, limit: rowsPerPage,
    });
  }, [order, orderBy, page, rowsPerPage, search, loadScheduledVisits]);

  return {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    dispatchVisits,
  };
};

export default useScheduledVisits;
