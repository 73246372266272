import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import EditProfile from './EditProfile';
import styles from './styles';
import { AppState } from '../../redux/store';
import { selectUserData } from '../../redux/selectors/auth';
import {agentDetailsRequest, updateProfileRequest} from '../../redux/actions/agents';
import { selectAgentSelected } from '../../redux/selectors/agents';

const mapStateToProps = (state: AppState) => ({
  user: selectUserData(state),
  agentSelected: selectAgentSelected(state),
});

const mapDispatchToProps = {
  loadAgentById: agentDetailsRequest,
  updateAgentById: updateProfileRequest,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EditProfile),
);
