import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import styles from './styles';

import {
  customersRequest,
  customersExportRequest,
  customersExportAndPrintRequest,
} from '../../redux/actions/customers';
import { exportAllRequest } from '../../redux/actions/exportAll';
import { clearFilter } from '../../redux/actions/filter';

import {
  selectCustomers,
  selectTotalCustomers,
  selectCustomersRequestStatus,
} from '../../redux/selectors/customers';
import { selectExportAllLoadingState } from '../../redux/selectors/exportAll';
import { AppState } from '../../redux/store';

import Customers from './Customers';


const mapStateToProps = (state: AppState) => ({
  customers: selectCustomers(state),
  customersTotal: selectTotalCustomers(state),
  loading: selectCustomersRequestStatus(state),
  exportAllLoading: selectExportAllLoadingState(state),
});

const mapDispatchToProps = {
  loadCustomers: customersRequest,
  exportCustomers: customersExportRequest,
  printCustomers: customersExportAndPrintRequest,
  exportAllCustomers: exportAllRequest,
  clearFilterState: clearFilter,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Customers),
);
