import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const ConnectionIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 127 101" {...props}>
    <path
      d="M5.59471 27.3659H121.405V95.3575H5.59471V27.3659ZM5.59471 5.64246H121.405V21.7235H5.59471V5.64246ZM0 27.3659V101H127V27.3659V0H0V27.3659Z"
    />
    <path
      d="M17.9031 13.824C17.9031 20.2282 8.39209 20.2282 8.39209 13.824C8.39209 7.41983 17.9031 7.41983 17.9031 13.824Z"
    />
    <path
      d="M32.4495 13.824C32.4495 20.2282 22.9385 20.2282 22.9385 13.824C22.9385 7.41983 32.4495 7.41983 32.4495 13.824Z"
    />
    <path
      d="M46.8557 13.824C46.8557 20.2282 37.3447 20.2282 37.3447 13.824C37.3447 7.41983 46.8557 7.41983 46.8557 13.824Z"
    />
    <path
      d="M50.6322 72.7877H47.2753C40.5617 72.7877 35.1068 67.2863 35.1068 60.5154C35.1068 53.7444 40.5617 48.243 47.2753 48.243H50.6322V72.7877ZM53.4295 42.6006H47.2753C38.4637 42.6006 31.0507 49.0894 29.7919 57.6941H18.043C16.5044 57.6941 15.2456 58.9637 15.2456 60.5154C15.2456 62.067 16.5044 63.3366 18.043 63.3366H29.7919C31.1905 71.9413 38.4637 78.4302 47.2753 78.4302H53.4295C54.9681 78.4302 56.2269 77.1606 56.2269 75.6089V45.4218C56.2269 43.8701 54.9681 42.6006 53.4295 42.6006Z"
    />
    <path
      d="M79.7248 72.7877H76.368V48.243H79.7248C86.4385 48.243 91.8933 53.7444 91.8933 60.5154C92.0332 67.2863 86.4385 72.7877 79.7248 72.7877ZM109.097 57.6941H97.3482C95.9495 49.0894 88.6764 42.6006 79.8647 42.6006H73.7105C72.1719 42.6006 70.9131 43.8701 70.9131 45.4218V50.0768H64.3393C62.8008 50.0768 61.542 51.3464 61.542 52.898C61.542 54.4497 62.8008 55.7193 64.3393 55.7193H70.9131V65.4525H64.3393C62.8008 65.4525 61.542 66.7221 61.542 68.2737C61.542 69.8254 62.8008 71.095 64.3393 71.095H70.9131V75.75C70.9131 77.3017 72.1719 78.5712 73.7105 78.5712H79.8647C88.6764 78.5712 96.0893 72.0824 97.3482 63.4776H109.097C110.636 63.4776 111.894 62.2081 111.894 60.6564C111.894 59.1047 110.636 57.6941 109.097 57.6941Z"
    />
  </SvgIcon>
);

export default React.memo(ConnectionIcon);
