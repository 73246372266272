import React, { ChangeEvent } from 'react';
import { convertToUsdCurrencyFormat } from '../../utils/price';

type InputPriceWrapperProps = {
  children: {
    props: React.HTMLProps<HTMLInputElement>
  }
};

const InputPriceWrapper: React.FC<InputPriceWrapperProps> = ({ children }) => {
  const input = React.Children.only(children);
  const [parsedValue, setParsedValue] = React.useState<string | number>(
    convertToUsdCurrencyFormat(input.props.value),
  );

  const onFocus = () => {
    setParsedValue(input.props.value || '');

    if (typeof input.props.onFocus === 'function') {
      input.props.onFocus();
    }
  };

  const onBlur = () => {
    setParsedValue(convertToUsdCurrencyFormat(input.props.value));

    if (typeof input.props.onBlur === 'function') {
      input.props.onBlur();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const regex = /^[0-9]*$/;

    if (!regex.test(value)) {
      return;
    }

    setParsedValue(value);
    input.props.onChange(e);
  };

  return React.cloneElement(input as React.ReactElement<React.HTMLProps<HTMLInputElement>>, {
    onFocus, onBlur, onChange, value: parsedValue,
  });
};

export default InputPriceWrapper;
