import { connect } from 'react-redux';
import TeamForm from './TeamForm';
import {
  addTeamAgentRequest,
  createTeamRequest,
  deleteTeamAgentRequest,
  teamDetailsRequest,
  updateTeamRequest,
} from '../../redux/actions/teams';
import { getBuilderId, getEnterpriseId } from '../../redux/selectors/auth';
import { selectBuilders, selectBuildersRequestStatus } from '../../redux/selectors/builders';
import { enterpriseBuildersRequest } from '../../redux/actions/builders';
import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  builderId: getBuilderId(state),
  // isEnterprise: selectEnterpriseStatus(state.auth),
  enterpriseId: getEnterpriseId(state),
  enterpriseBuilders: selectBuilders(state),
  loadingEnterpriseBuilders: selectBuildersRequestStatus(state),
});

const mapDispatchToProps = {
  createTeam: createTeamRequest,
  updateTeam: updateTeamRequest,
  loadTeamById: teamDetailsRequest,
  addTeamAgent: addTeamAgentRequest,
  deleteTeamAgent: deleteTeamAgentRequest,
  loadEnterpriseBuilders: enterpriseBuildersRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamForm);
