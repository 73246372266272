import React from 'react';

type Props = {
  children: (isOn: boolean, isPM: boolean) => React.ReactElement | null,
  name : string,
  isPM: boolean,
  features: Record<string, 'on' | 'off'>
};

const FeatureWrapper: React.FC<Props> = ({
  children, name, features, isPM,
}) => {
  const isOn = features[name] === 'on';

  return children(isOn, isPM);
};

export default FeatureWrapper;
