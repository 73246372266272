import React from 'react';
import { Grid } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useGetHubActivityLogs } from '../../hooks/useGetHubActivityLogs';
import useStyles from './styles';
import SearchBar from '../SearchBar';
import TablePagination from '../../components/CustomTablePagination';
import { ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS } from '../../constants/table';
import InventoryHistoryTable from './InventoryHistoryTable/InventoryHistoryTable';
import { Order } from '../../utils/table';
import { HubActivityLog } from '../../redux/types/hubs';
import { exportHubActivityLogs } from './helpers/useExportHubActivityLogs';

export const InventoryHistory = () => {
  const classes = useStyles();
  const [search, setSearch] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(ROWS_PER_PAGE);
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof HubActivityLog>('id');
  const { data: hubActivity, isFetching } = useGetHubActivityLogs({
    search, page, limit: rowsPerPage, order, orderBy,
  });

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows);
    setPage(0);
  };

  const handleChangeOrder = (newOrder: Order, newOrderBy: keyof HubActivityLog) => {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    setPage(0);
  };

  const exportAll = async () => {
    await exportHubActivityLogs(search);
  };

  return (
    <>
      <Grid container>
        <Grid item xs>
          <SearchBar
            defaultValue={search}
            placeholder="Search by serial number, property id, author or action"
            onChange={(event) => setSearch(event)}
          />
        </Grid>
      </Grid>

      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <Typography variant="h5">
              Hardware History
            </Typography>
          </div>
          <div className={classes.spacer} />
          <Button
            id="fs-export-communities"
            size="small"
            onClick={() => exportAll()}
          >
            Export All
          </Button>
        </Toolbar>
        <InventoryHistoryTable
          histories={hubActivity?.data}
          rowsPerPage={rowsPerPage}
          isLoading={isFetching}
          orderBy={orderBy}
          order={order}
          onChangeOrder={handleChangeOrder}
        />
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={hubActivity?.data?.length || 0}
          rowsTotal={hubActivity?.total || 0}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default React.memo(InventoryHistory);
