import React from 'react';
import classNames from 'classnames';
import { WithStyles } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import styles from './styles';
import CardHeader from '../CardHeader';
import CardIcon from '../CardIcon';
import { IconColorType } from '../CardIcon/CardIcon';
import CardFooter from '../CardFooter';

type Props = {
  iconColor: IconColorType,
  icon: string,
  header: string,
  content: string | number,
  footerContent: string,
}& WithStyles<typeof styles>;

const Card: React.FC<Props> = props => {
  const {
    iconColor,
    icon,
    classes,
    header,
    content,
    footerContent,
    children,
    ...rest
  } = props;

  const cardClasses = classNames({
    [classes.card]: true,
  });


  return (
    <div className={cardClasses} {...rest}>
      <CardHeader stats icon>
        <CardIcon color={iconColor}>
          <Icon>{icon}</Icon>
        </CardIcon>
        <p className={classes.cardCategory}>{header}</p>
        <h3 className={classes.cardTitle}>
          {content}
        </h3>
      </CardHeader>
      <CardFooter stats>
        <div className={classes.stats}>
          {footerContent}
        </div>
      </CardFooter>
    </div>
  );
};

export default React.memo(Card);
