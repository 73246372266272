import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const builderSettingsCreateSchema = yup.object().shape({
  self_tour_scheduling_title: yup.string().nullable(),
  self_tour_scheduling_subtitle: yup.string().nullable(),
  self_tour_scheduling_description: yup.string().nullable(),
  how_it_works_title: yup.string().nullable(),
  step_1_title: yup.string().nullable(),
  step_1_description: yup.string().nullable(),
  step_2_title: yup.string().nullable(),
  step_2_description: yup.string().nullable(),
  step_3_title: yup.string().nullable(),
  step_3_description: yup.string().nullable(),
  step_4_title: yup.string().nullable(),
  step_4_description: yup.string().nullable(),
  start_touring_button: yup.string().nullable(),
  self_tours_own_time_title: yup.string().nullable(),
  tour_now_title: yup.string().nullable(),
  tour_now_description: yup.string().nullable(),
  tour_now_button: yup.string().nullable(),
  schedule_tour_title: yup.string().nullable(),
  schedule_tour_description: yup.string().nullable(),
  schedule_tour_button: yup.string().nullable(),
});
