import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  title: {
    fontWeight: 600,
    lineHeight: 1.25,
  },
  contentWrapper: {
    padding: '20px',
    marginTop: '33px',
    minHeight: '790px',
    boxShadow: theme.shadows[3],
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  tableWrapper: {
    overflowX: 'auto',
    position: 'relative',
    borderRadius: '10px',
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 999,
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  tableHead: {
    backgroundColor: theme.appPalette.orange,
    border: `1px solid ${theme.appPalette.orange}`,

    '& th': {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: 1.2,
      color: '#FFF',
    },
  },
  tableRow: {
    '&:first-child td': {},
    '& td:first-child': {
      borderLeftStyle: 'solid',
      color: '#8E8B99',
    },
    '&:last-child td:first-child': {
      borderBottomLeftRadius: '10px',
    },
    '&:last-child td:last-child': {
      borderBottomRightRadius: '10px',
    },
  },
  tableCell: {
    padding: '0 10px',
    border: '1px solid #8E8B9933',
    borderStyle: 'none solid solid none',
  },
  addButton: { color: theme.appPalette.orange },
  actionButton: {
    width: '16px',
    height: '16px',
    marginRight: '8px',
  },
  radio: {
    color: `${theme.appPalette.orange} !important`,
  },
  question: {
    marginTop: '20px',
    marginBottom: '20px',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: 1.27,
  },
  addLabelButton: {
    fontSize: '16px',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
