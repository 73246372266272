import {
  createStyles, fade, makeStyles, Theme,
} from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  icon: {
    transform: 'translateY(7px)',
  },

  mainList: {
    alignItems: 'baseline',
  },

  addButton: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '1.38',
    textDecoration: 'underline',
    color: theme.appPalette.orange,
    cursor: 'pointer',
    transition: 'all 200ms cubic-bezier(0.0, 0, 0.2, 1)',

    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
      color: fade('#DB5C0E', 0.7),
    },
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
