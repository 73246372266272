import {
  EXPORT_ALL_REQUEST,
  EXPORT_ALL_REQUEST_SUCCESS,
  EXPORT_ALL_REQUEST_FAILED,
} from '../types/visits';
import { ExportAllActionType, ExportAllState } from '../types/exportAll';


const initialState: ExportAllState = {
  loading: false,
};

export default function CommunitiesReducer(
  state = initialState,
  action: ExportAllActionType,
): ExportAllState {
  switch (action.type) {
    case EXPORT_ALL_REQUEST: {
      return {
        loading: true,
      };
    }

    case EXPORT_ALL_REQUEST_SUCCESS: {
      return {
        loading: false,
      };
    }

    case EXPORT_ALL_REQUEST_FAILED: {
      return {
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
