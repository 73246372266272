import React from 'react';
import { WithStyles } from '@material-ui/core';
import classNames from 'classnames';
import styles from './styles';


type Props = {
  stats?: boolean,
  icon?: boolean,
} & WithStyles<typeof styles>;

const CardHeader: React.FC<Props> = props => {
  const {
    classes,
    children,
    stats,
    icon,
    ...rest
  } = props;

  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes.cardHeaderStats]: stats,
    [classes.cardHeaderIcon]: icon,
  });


  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  );
};

export default React.memo(CardHeader);
