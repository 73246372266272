import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { navTo } from '../../browserHsitory';
import {
  loadWebhooks,
  loadWebhookById,
  updateWebhookData,
  createWebhookData,
  deleteWebhooks,
} from '../../services/webhooks';
import {
  createWebhookFailed,
  createWebhookRequest,
  createWebhookSuccess,
  updateWebhookFailed,
  updateWebhookRequest,
  updateWebhookSuccess,
  webhookDetailsRequestFailed,
  webhookDetailsRequestSuccess,
  webhookDetailsRequest,
  webhooksRequestFailed,
  webhooksRequestSuccess,
  deleteWebhooksRequest,
  deleteWebhooksFailed,
  webhooksRequest,
} from '../actions/webhooks';

import {
  WEBHOOKS_REQUEST,
  CREATE_WEBHOOKS_REQUEST,
  UPDATE_WEBHOOKS_REQUEST,
  WEBHOOKS_DETAILS_REQUEST,
  DELETE_WEBHOOKS_REQUEST,
} from '../types/webhooks';

function* loadWebhooksSaga(action: ReturnType<typeof webhooksRequest>) {
  try {
    const { data } = yield call(loadWebhooks, action.payload);
    yield put(webhooksRequestSuccess(data));
  } catch (err) {
    yield put(webhooksRequestFailed(err));
  }
}

function* loadWebhookDetailsSaga(
  action: ReturnType<typeof webhookDetailsRequest>,
) {
  try {
    const { data } = yield call(loadWebhookById, action.payload);
    yield put(webhookDetailsRequestSuccess(data));
  } catch (err) {
    yield put(webhookDetailsRequestFailed(err));
  }
}

function* createWebhookSaga(action: ReturnType<typeof createWebhookRequest>) {
  try {
    const { data } = yield call(createWebhookData, action.payload);
    yield put(createWebhookSuccess(data.message));
    yield call(navTo, `/webhooks/${data.id}`);
  } catch (err) {
    yield put(createWebhookFailed(err));
  }
}

function* updateWebhookSaga(action: ReturnType<typeof updateWebhookRequest>) {
  try {
    const { data } = yield call(updateWebhookData, action.payload);
    yield put(updateWebhookSuccess(data.message));
    yield call(navTo, '/webhooks');
  } catch (err) {
    yield put(updateWebhookFailed(err));
  }
}

function* deleteWebhookSaga(action: ReturnType<typeof deleteWebhooksRequest>) {
  try {
    yield call(deleteWebhooks, action.payload);
    const { data } = yield call(loadWebhooks);
    yield put(webhooksRequestSuccess(data));
  } catch (err) {
    yield put(deleteWebhooksFailed(err));
  }
}

export default all([
  takeLatest<any>(WEBHOOKS_REQUEST, loadWebhooksSaga),
  takeLatest<any>(WEBHOOKS_DETAILS_REQUEST, loadWebhookDetailsSaga),
  takeLatest<any>(CREATE_WEBHOOKS_REQUEST, createWebhookSaga),
  takeLatest<any>(UPDATE_WEBHOOKS_REQUEST, updateWebhookSaga),
  takeLatest<any>(DELETE_WEBHOOKS_REQUEST, deleteWebhookSaga),
]);
