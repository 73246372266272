import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  button: {
    padding: '10px 16px',
    textTransform: 'capitalize',
    fontSize: 16,
    lineHeight: '20px',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  saveButton: {
    padding: '10px 40px',
  },
  logOutButton: {
    float: 'right',
    padding: '10px 40px',
  },
  cardActions: {
    marginTop: 45,
  },
  progressWrapper: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-24px',
    marginLeft: '-24px',
  },
});
