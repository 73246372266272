import { createSelector } from 'reselect';
import { AppState } from '../store';
import { CustomersState } from '../types/customers';

const selectCustomersState = (state: AppState) => state.customers;
const selectItems = (state: CustomersState) => state.items;
const selectTotal = (state: CustomersState) => state.total;
const selectSelected = (state: CustomersState) => state.selected;
const selectLoading = (state: CustomersState) => state.loading;

export const selectCustomers = createSelector(
  selectCustomersState,
  selectItems,
);

export const selectTotalCustomers = createSelector(
  selectCustomersState,
  selectTotal,
);

export const selectCustomerSelected = createSelector(
  selectCustomersState,
  selectSelected,
);

export const selectCustomersRequestStatus = createSelector(
  selectCustomersState,
  selectLoading,
);
