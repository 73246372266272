import * as yup from 'yup';

export const QuestionSchema = yup.object().shape({
  text: yup.string().required(),
  type: yup.string().required(),
});

export const AnswerSchema = yup.object().shape({
  type: yup.number().required(),
  choices: yup
    .array()
    .of(yup.string().required())
    .required(),
});
