/* eslint-disable comma-dangle */
/* eslint-disable quotes */
import { createStyles, Theme } from "@material-ui/core";

export default (theme: Theme) =>
  createStyles({
    container: {
      overflow: "hidden",
      boxShadow: theme.shadows[3],
      borderRadius: 10,
      backgroundColor: "#fff",
      "& > div": {
        paddingLeft: 43,
        paddingRight: 43,
        "&:last-child": {
          paddingBottom: theme.spacing(4)
        }
      }
    },
    header: {
      paddingTop: theme.spacing(8),
      backgroundColor: "#E9ECF2"
    },
    titleWrapper: {
      margin: "8px 0"
    },
    title: {
      fontSize: 28
    },
    content: {},
    button: {
      padding: "10px 40px",
      textTransform: "capitalize",
      fontSize: 16,
      boxShadow: theme.shadows[3]
    },
    cardActions: {
      marginTop: 45,
      display: 'flex',
      justifyContent: 'flex-start'
    },
    buttonWrapper: {
      marginTop: 5,
      marginLeft: 10,
      position: "relative"
    },
    buttonProgress: {
      color: theme.appPalette.orange,
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    },
    chip: {
      margin: theme.spacing(0.5)
    },
    avatar: {
      color: "white"
    },
    customCheckbox: {
      userSelect: "none",
      webkitTapHighlightColor: "transparent",
      color: "rgba(0, 0, 0, 0.87)",
      border: "none",
      cursor: "default",
      height: 32,
      display: "inline-flex",
      outline: 0,
      padding: 0,
      fontSize: "0.8125rem",
      boxSizing: "border-box",
      transition:
        "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      alignItems: "center",
      fontFamily:
        '"Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif',
      whiteSpace: "nowrap",
      borderRadius: 16,
      verticalAlign: "middle",
      justifyContent: "center",
      textDecoration: "none",
      // backgroundColor: "#3CB371",
      paddingRight: 20
      // marginBottom: 20
    },
    // input: {
    //   background: 'rgba(219, 92, 14, 0.08)',
    //   mixBlendMode: 'normal',
    //   border: ' 1px solid #DB5C0E',
    //   boxSizing: 'border-box',
    //   borderRadius: 5,
    // },
    input: {
      background: '#FFFFFF',
      border: '1px solid #DBDDE3',
      boxSizing: 'border-box',
      boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.25)',
      borderRadius: 5,
      "& > label": {
        // width: 45,
        height: 12,
        left: 12,
        top: 6,
      },
      "& > div > input": {
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 16,
        lineHeight: 24,
        display: 'flex',
        alignItems: 'flex-end',
        color: 'rgba(0, 0, 0, 0.87)',
        marginLeft: 12,
      }
    },
    username: {
      "& > label": {
        left: 6,
        top: 6,
      }
    },
    buttonText: {
      textTransform: 'none',
    },
  });
