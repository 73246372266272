/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import history from './browserHsitory';
import theme from './theme';
import store from './redux/store';
import App from './App';
import appConfig from './config';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: appConfig.PUBLIC_DSN });
}


ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);
