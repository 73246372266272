import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Zoom from '@material-ui/core/Zoom';
import subDays from 'date-fns/subDays';

import { PropertiesStatsQuery } from '../../redux/types/stats';
import { Query } from '../../utils/query';
import Card from '../../components/Card';

type Props = {
  loadPropertiesStats: (query: PropertiesStatsQuery) => void;
  loadSoldPropertiesStats: (builderId: number) => void;
  loadAllVisits: (query: Query) => void;
  loadContractorsCount: () => void;
  builderId: number;
  propertiesActiveCount: number;
  propertiesSoldCount: number;
  allVisitsCount: number;
  contractorsServed: number;
  loadingStats: boolean;
  isRental: boolean;
};

const Stats: React.FC<Props> = props => {
  const {
    loadPropertiesStats,
    loadSoldPropertiesStats,
    loadAllVisits,
    builderId,
    propertiesActiveCount,
    propertiesSoldCount,
    allVisitsCount,
    contractorsServed,
    loadingStats,
    loadContractorsCount,
    isRental
  } = props;

  useEffect(() => {
    if (builderId !== 0) {
      const now = new Date();
      const from = subDays(now, 30).toISOString();
      const to = now.toISOString();

      loadPropertiesStats({ status: 'Accessible', builderId });
      loadSoldPropertiesStats(builderId);
      loadAllVisits({ builderId, from, to });
      loadContractorsCount();
    }
  }, [
    loadPropertiesStats,
    loadSoldPropertiesStats,
    loadAllVisits,
    loadContractorsCount,
    builderId,
  ]);

  return (
    <Zoom in={!loadingStats}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            iconColor="successCardHeader"
            icon="lock_open"
            header="Company Inventory"
            content={`${propertiesActiveCount}`}
            footerContent="Active Locks"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            iconColor="infoCardHeader"
            icon="person"
            header="Visitors Served"
            content={allVisitsCount}
            footerContent="Last 30 days"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            iconColor="dangerCardHeader"
            icon="home"
            header={isRental ? "Company Homes Rented" : "Company Homes Sold"}
            content={propertiesSoldCount}
            footerContent="Last 30 days"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            iconColor="warningCardHeader"
            icon="build"
            header="Contractors Served"
            content={contractorsServed}
            footerContent="Last 30 days"
          />
        </Grid>
      </Grid>
    </Zoom>
  );
};

export default React.memo(Stats);
