import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  GET_INTEGRATIONS_REQUEST,
  INTEGRATIONS_REQUEST,
} from '../types/integrations';
import { createSuccessSnackBar, createErrorSnackBar } from '../actions/snackbars';

import {
  sendIntegrtation, getIntegrationsSuccess,
} from '../actions/integrations';
import { sendIntergationsRequest, getIntegrationsRequest } from '../../services/intergations';


function* sendIntergationsSaga(action: ReturnType<typeof sendIntegrtation>) {
  try {
    const result = yield call(sendIntergationsRequest, action.payload);
    yield put(createSuccessSnackBar(result.data.message));
  } catch (err) {
    yield put(createErrorSnackBar(err.response ? err.response.data.message : err.message));
  }
}

function* getIntegrationsSaga() {
  try {
    const { data } = yield call(getIntegrationsRequest);
    yield put(getIntegrationsSuccess(data));
  } catch (err) {
    yield put(createErrorSnackBar(err.response ? err.response.data.message : err.message));
  }
}

export default all([
  takeLatest(INTEGRATIONS_REQUEST, sendIntergationsSaga),
  takeLatest(GET_INTEGRATIONS_REQUEST, getIntegrationsSaga),
]);
