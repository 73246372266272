import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import styles from './styles';

import { selectLocksActive, selectLocksReturned } from '../../redux/selectors/locks';
import { selectSmartLocks, selectSmartLocksActive } from '../../redux/selectors/smartLocks';

import { clearFilter } from '../../redux/actions/filter';
import { locksRequest } from '../../redux/actions/locks';
import { smartLocksRequest } from '../../redux/actions/smartLocks';
import { exportAllRequest } from '../../redux/actions/exportAll';

import { AppState } from '../../redux/store';
import { getHubsRequest } from '../../redux/actions/hubs/hubs';
import { selectHubs } from '../../redux/selectors/hubs';

import Inventory from './Inventory';

const mapStateToProps = (state: AppState) => ({
  locksActive: selectLocksActive(state),
  smartLocksActive: selectSmartLocksActive(state),
  locksReturned: selectLocksReturned(state),
  smartLocks: selectSmartLocks(state),
  hubs: selectHubs(state),
});

const mapDispatchToProps = {
  loadLocks: locksRequest,
  loadSmartLocks: smartLocksRequest,
  loadHubs: getHubsRequest,
  clearFilterState: clearFilter,
  exportAllProperties: exportAllRequest,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Inventory),
);
