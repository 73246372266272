import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const LockIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 80 114" {...props}>
    <path
      fill="currentColor"
      d="M68.5636 45.2054V28.2568C68.5636 12.6776 55.7559 0 40 0C24.2441 0 11.4364 12.6776 11.4364 28.2568V45.2054C5.12856 45.2054 0 50.2629 0 56.5V101.692C0 107.929 5.11484 113 11.4364 113H68.5636C74.8714 113 80 107.943 80 101.692V56.5C80 50.2629 74.8714 45.2054 68.5636 45.2054ZM45.7182 83.1704V96.0378H34.2818V83.1704C30.881 81.2043 28.5773 77.5977 28.5773 73.4351C28.5773 67.198 33.6922 62.1405 40 62.1405C46.3078 62.1405 51.4227 67.198 51.4227 73.4351C51.4227 77.6112 49.1052 81.2179 45.7182 83.1704ZM57.1409 45.2054H22.8591V28.2568C22.8591 18.9147 30.5519 11.3081 40 11.3081C49.4481 11.3081 57.1409 18.9147 57.1409 28.2568V45.2054Z"
    />
  </SvgIcon>
);

export default React.memo(LockIcon);
