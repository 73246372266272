import { createStyles } from '@material-ui/core';

export default () => createStyles({
  headerCell: {
    border: '1px solid #040620',
    color: '#040620',
    fontSize: 14,
    textAlign: 'center',
  },

  mainTableCell: {
    borderRight: '1px solid #040620',
    borderLeft: '1px solid #040620',
    padding: '0 5px 0 5px',
  },

  lastTableCell: {
    borderRight: '1px solid #040620',
    borderLeft: '1px solid #040620',
    borderBottom: '1px solid #040620',
    padding: '0 5px 0 5px',
  },

  phoneNumber: {
    alignSelf: 'flex-start',
  },

  leftBorder: {
    borderLeft: '1px solid #040620',
  },

  bottomBorder: {
    borderBottom: '1px solid #040620',
    padding: '10px 10px 10px 10px',
  },

  rightBorder: {
    borderRight: '1px solid #040620',
  },

  capitalText: {
    fontSize: 24,
    fontWeight: 'bold',
  },

  paymentsText: {
    fontSize: 20,
    fontWeight: 'bold',
  },

  hideBottom: {
    borderBottom: 0,
  },

  hideLeft: {
    borderLeft: 0,
  },
});
