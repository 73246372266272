import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Query } from '../../../utils/query';
import { Order } from '../../../utils/table';
import { Community } from '../../../redux/types/communities';
import { SearchSortContext } from '../../../context/SearchSortContext';
import {
  ChangeSearchPayload,
  PageSearchSortContextActionType,
} from '../../../context/types';
import parseUrlFilterSearch from '../../../hooks/parseUrlFilterSearch';
import useUrlFilterSearch from '../../../hooks/useUrlFilterSearch';
import { UrlParam } from '../../../redux/types/filter';
import { selectCommunityFilters } from '../../../redux/selectors/filter';

interface UrlParamProperties extends UrlParam {
  builderId?: number;
  filterByBuilder?: number | null;
}

const useCommunities = (
  loadCommunities: (query: Query & { builderId: number }) => void,
  builderId: number,
) => {
  const urlParse = parseUrlFilterSearch<UrlParamProperties>();
  const { builder: filterByBuilder } = useSelector(selectCommunityFilters);
  const {
    dispatch,
    state: { communities },
  } = useContext(SearchSortContext);
  const {
    currentOrderBy,
    currentOrder,
    currentPage,
    rowsPerPageAmount,
    contextSearch,
  } = communities;

  const [search, setSearch] = useState<string>(
    urlParse.search || contextSearch,
  );
  const [order, setOrder] = useState<Order>(urlParse.order || currentOrder);
  const [orderBy, setOrderBy] = useState<keyof Community>(
    urlParse.orderBy || currentOrderBy,
  );
  const [page, setPage] = useState<number>(urlParse.page || currentPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    urlParse.limit || rowsPerPageAmount,
  );
  const [showAll, setShowAll] = useState<boolean>(urlParse?.showAll || false);

  useUrlFilterSearch<UrlParamProperties>({
    search,
    order,
    orderBy,
    page: String(page),
    limit: rowsPerPage,
    builderId,
    filterByBuilder,
  });

  const dispatchCommunities = (
    type: PageSearchSortContextActionType<'CHANGE_COMMUNITY'>,
    payload?: ChangeSearchPayload<Community>,
  ) => dispatch({ type, payload: { ...communities, ...payload } });

  useEffect(() => {
    loadCommunities({
      search,
      order,
      orderBy,
      page: String(page),
      limit: rowsPerPage,
      builderId,
      filterByBuilder,
      showAll,
    });
  }, [
    order,
    orderBy,
    page,
    rowsPerPage,
    search,
    loadCommunities,
    builderId,
    filterByBuilder,
    showAll,
  ]);

  return {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    setShowAll,
    // saveLocalState,
    dispatchCommunities,
  };
};

export default useCommunities;
