import {
  all, takeEvery, put, call,
} from 'redux-saga/effects';
import { AxiosResponse, AxiosError } from 'axios';
import {
  AUTH_USER_REQUEST,
  AUTH_USER_LOGOUT,
  AuthRequest,
  OauthClient,
  GET_OAUTH_CLIENTS_REQUEST,
  REMOVE_OAUTH_CLIENTS_REQUEST,
  CREATE_OAUTH_CLIENTS_REQUEST,
  AUTH_PM_REQUEST,
  AUTH_ENTERPRISE_USER_REQUEST,
  AuthEnterpriseUserRequest,
} from '../types/auth';
import { navTo } from '../../browserHsitory';

import {
  login,
  logOut,
  getOauthClients,
  removeOauthClient,
  createOauthClient,
  loginAdminAsPM,
  loginEnterpriseUser,
} from '../../services/auth';
import {
  authUserError,
  authUserSuccess,
  authAdminSuccess,
  getOauthClientsSuccess,
  removeOauthClientsSuccess,
  getOauthClientsError,
  removeOauthClientsError,
  removeOauthClientsRequest,
  createOauthClientsSuccess,
  createOauthClientsError,
  authEnterpriseUserRequest,
} from '../actions/auth';
import { createErrorSnackBar } from '../actions/snackbars';

const getErrorMessage = ({ response }: AxiosError): string => {
  if (!response) {
    return 'Something went wrong';
  }

  switch (response.status) {
    case 404:
      return 'User not found';
    case 403:
      return 'Invalid usersname or password';
    default:
      return response.data;
  }
};

function* loginSaga(loginData: AuthRequest) {
  try {
    const { data } = yield call(login, loginData);
    yield put(authUserSuccess(data));
    if (data.isAdmin) {
      yield call(navTo, '/choose_PM');
      yield put(authAdminSuccess());
      return;
    }

    if (data.isEnterprise) {
      if (data.enterpriseId) {
        yield put(
          authEnterpriseUserRequest({
            username: data.username,
            builder_id: data.builderId,
          }),
        );

        return;
      }

      yield call(navTo, '/choose_builder');
      return;
    }
    yield call(navTo, '/');
  } catch (err) {
    if (err.isAxiosError) {
      yield put(createErrorSnackBar(getErrorMessage(err)));
    }

    yield put(authUserError(err));
  }
}

function* loginAdminAsPMSaga(loginData: string) {
  try {
    const { data } = yield call(loginAdminAsPM, loginData);
    yield put(authUserSuccess(data));
    yield call(navTo, '/');
  } catch (err) {
    if (err.isAxiosError) {
      yield put(createErrorSnackBar(getErrorMessage(err)));
    }

    yield put(authUserError(err));
  }
}

function* loginEnterpriseUserSaga(payload: AuthEnterpriseUserRequest) {
  try {
    const { data } = yield call(loginEnterpriseUser, payload);
    yield put(authUserSuccess(data));
    yield call(navTo, '/');
  } catch (err) {
    if (err.isAxiosError) {
      yield put(createErrorSnackBar(getErrorMessage(err)));
    }

    yield put(authUserError(err));
  }
}

function* logOutSaga(): IterableIterator<any> {
  yield call(logOut);
  yield call(navTo, '/');
}

function* getOauthClientsSaga() {
  try {
    const { data }: AxiosResponse<ApiResponse<OauthClient>> = yield call(
      getOauthClients,
    );
    yield put(getOauthClientsSuccess(data));
  } catch (err) {
    yield put(getOauthClientsError(err));
  }
}

function* removeOauthClientsSaga({
  payload,
}: ReturnType<typeof removeOauthClientsRequest>) {
  try {
    yield call(removeOauthClient, payload);
    yield put(removeOauthClientsSuccess(payload));
  } catch (err) {
    yield put(removeOauthClientsError(err));
  }
}

function* createOauthClientsSaga() {
  try {
    const { data }: AxiosResponse<OauthClient> = yield call(
      createOauthClient,
      {},
    );
    yield put(createOauthClientsSuccess(data));
  } catch (err) {
    yield put(createOauthClientsError(err));
  }
}

const authSaga = all([
  takeEvery<any>(AUTH_USER_REQUEST, loginSaga),
  takeEvery<any>(AUTH_USER_LOGOUT, logOutSaga),
  takeEvery<any>(GET_OAUTH_CLIENTS_REQUEST, getOauthClientsSaga),
  takeEvery<any>(REMOVE_OAUTH_CLIENTS_REQUEST, removeOauthClientsSaga),
  takeEvery<any>(CREATE_OAUTH_CLIENTS_REQUEST, createOauthClientsSaga),
  takeEvery<any>(AUTH_PM_REQUEST, loginAdminAsPMSaga),
  takeEvery<any>(AUTH_ENTERPRISE_USER_REQUEST, loginEnterpriseUserSaga),
]);

export default authSaga;
