import axios from './axios';
import { WebhookFormType } from '../redux/types/webhooks';
import { Query, buildQuery } from '../utils/query';

export const loadWebhooks = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/webhooks${query}`);
};

export const loadWebhookById = (id: number | string) => axios.get(`/webhooks/${id}`);

export const createWebhookData = (webhook: WebhookFormType) => axios.post('/webhooks/add', webhook);

export const deleteWebhooks = (webhookIds: number[]) => axios.delete('/webhooks', { data: webhookIds });

export const updateWebhookData = ({
  webhookId,
  webhook,
}: {
  webhookId: number | string;
  webhook: WebhookFormType;
}) => axios.post(`/webhooks/${webhookId}`, webhook);
