import React, { useEffect, useState } from 'react';
import {
  RouteComponentProps,
} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, TextField } from '@material-ui/core';
import useStyles from './styles';
import { Builder } from '../../redux/types/builders';
import CustomButton from '../../components/CustomButton';
import { AuthEnterpriseUserRequest } from '../../redux/types/auth';

type Props = {
  loadingBuilders?: boolean;
  isAdmin: boolean;
  isEnterprise: boolean;
  builders: Builder[];
  loadBuilders: (username: string) => void;
  auth: ({ username, builder_id }: AuthEnterpriseUserRequest) => void;
  username: string;
} & RouteComponentProps;


const SelectBuilder: React.FC<Props> = (props) => {
  const {
    loadingBuilders,
    isEnterprise,
    builders,
    loadBuilders,
    auth,
    username,
  } = props;
  const [builder, setBuilder] = useState<string>('');
  const classes = useStyles();

  useEffect(() => {
    loadBuilders(username);
  }, [loadBuilders, username]);


  const handleBuilderChange = (e: any, chosenSelect: any | null) => {
    setBuilder(chosenSelect);
  };

  const signIn = () => {
    const selected = builders.find((b) => b.name === builder);
    selected && auth({ username, builder_id: selected.builder_id });
  };

  const listBuilders = builders.map(({ name }) => name);
  return (
    <>
      {isEnterprise && (
        <Grid container justify="center" alignItems="center" direction="column" className={classes.content}>
          <Grid item container xs={12} sm={4} justify="center" alignContent="flex-start" className={classes.container}>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                options={listBuilders}
                id="multiple-limit-tags"
                getOptionLabel={(option: any) => option}
                noOptionsText="No builders were found"
                onChange={handleBuilderChange}
                className={classes.input}
                loading={loadingBuilders}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label="Select builder"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingBuilders ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}

                  />
                )}
              />
            </Grid>
            {
              builder && !loadingBuilders && (
                <Grid container item justify="center" alignItems="center" direction="column" className={classes.buttonContainer}>
                  <Grid item xs={12} sm={12}>
                    <CustomButton variant="orange" onClick={signIn}>
                      Sign in as PM for
                      {' '}
                      {builder}
                    </CustomButton>
                  </Grid>
                </Grid>

              )
            }

          </Grid>
        </Grid>
      )}

    </>
  );
};

export default React.memo(SelectBuilder);
