import React from 'react';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { WithStyles } from '@material-ui/styles';
import {
  Switch, Route, Redirect, RouteComponentProps,
} from 'react-router-dom';
import Tabs from '../../components/CustomTabs';
import EditProfile from '../EditProfile';
import NotFound from '../../pages/NotFound';
import FeatureWrapper from '../FeatureWrapper';

import styles from './styles';
import ApiKeys from '../../components/ApiKeys';
import Billing from '../Billing';

type Props = WithStyles<typeof styles> & RouteComponentProps;

const defaultTab = 'edit-profile';

const tabs: Record<string, string> = {
  'edit-profile': 'Edit profile',
  'test-texting': 'Test texting',
  subscriptions: 'Subscriptions',
  billing: 'Billing',
  'api-keys': 'API Keys',
};

const renderBilling = (props: RouteComponentProps<any>) => (
  <FeatureWrapper name="BILLING_UI">
    {
      (isOn: boolean, isPM: boolean) => ((isOn && isPM)
        ? (
          <Billing {...props} />
        )
        : (<Redirect to={`/account/${defaultTab}`} />))
    }
  </FeatureWrapper>
);

const Account: React.FC<Props> = (props) => {
  const {
    classes,
    location,
    history,
    match,
  } = props;

  function toggleTab(key: string): () => void {
    return () => {
      history.push(`/account/${key}`);
    };
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <FeatureWrapper name="BILLING_UI">
          {(isOn: boolean, isPM: boolean) => (
            <Tabs value={location.pathname.split('/')[2] || defaultTab}>
              <Tab value="edit-profile" label="Edit profile" onClick={toggleTab('edit-profile')} />
              {
                isOn && isPM ? (
                  <Tab
                    value="billing"
                    label="Billing"
                    onClick={toggleTab('billing')}
                  />
                ) : null
              }
              <Tab value="api-keys" label="API Keys" onClick={toggleTab('api-keys')} />
            </Tabs>
          )}
        </FeatureWrapper>
      </div>
      <div className={classes.titleWrapper}>
        <Typography className={classes.title}>{tabs[location.pathname.split('/')[2]]}</Typography>
      </div>
      <div className={classes.content}>
        <Switch>
          <Route path="/account" exact component={() => <Redirect to={`${match.path}/${defaultTab}`} />} />
          <Route path={`${match.path}/edit-profile`} exact component={EditProfile} />
          <Route path={`${match.path}/api-keys`} exact component={ApiKeys} />
          <Route path={`${match.path}/billing`} render={renderBilling} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </div>
  );
};

export default React.memo(Account);
