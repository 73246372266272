import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({

  test: {
    top: 0,
    right: 0,
  },

  formControl: {
    display: 'flex',
    width: '200px',
    margin: theme.spacing(1),
    minWidth: 120,
  },

  '@global': {
    '.MuiFormControl-root': {
      border: 'none',
      'box-shadow': 'none',
    },

    '.MuiButton-containedPrimary': {
      display: 'block',
      margin: '0 auto',
    },

    'MuiMenu-paper': {
      margin: '21px 0px 0px -44px',
    },
  },
});
