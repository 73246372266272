import { combineReducers } from 'redux';
import auth from './auth';
import billing from './billing';
import properties from './properties';
import customers from './customers';
import visits from './visits';
import locks from './locks';
import smartLocks from './smartLocks';
import offers from './offers';
import progressBarStatus from './progressBarStatus';
import stats from './stats';
import agents from './agents';
import snackbars from './snackbars';
import appResources from './appResources';
import split from './split';
import communities from './communities';
import exportAll from './exportAll';
import scheduledVisits from './scheduledVisits';
import integrations from './integration';
import builders from './builders';
import builderSettings from './builderSettings';
import builderPMs from './builderPMs';
import filter from './filter';
import sort from './sort';
import hubs from './hubs';
import teams from './teams';
import survey from './survey';
import visitsAfterTourHours from './afterTourHours';
import webhooks from './webhooks';
import reports from './reports'

export default combineReducers({
  auth,
  billing,
  locks,
  smartLocks,
  visits,
  offers,
  customers,
  properties,
  progressBarStatus,
  stats,
  agents,
  snackbars,
  appResources,
  split,
  communities,
  exportAll,
  scheduledVisits,
  integrations,
  builders,
  builderPMs,
  builderSettings,
  filter,
  sort,
  hubs,
  teams,
  survey,
  visitsAfterTourHours,
  webhooks,
  reports
});
