/* eslint-disable import/prefer-default-export */
import axios from './axios';
// import { Query, buildQuery } from '../utils/query';

export const getAllInvoices = <T>() => axios.get<T>('/invoices', { baseURL: process.env.REACT_APP_BILLING_API_ROOT });

export const getInvoiceById = <T>(id: string) => axios.get<T>(`/invoices/${id}`,
  {
    baseURL: process.env.REACT_APP_BILLING_API_ROOT,
  });

export const getFakePdf = () => axios.get('/invoices/fake-pdf', {
  baseURL: process.env.REACT_APP_BILLING_API_ROOT,
});
