import * as SplitActions from '../actions/split';
import { ValueOf } from '../../types/main';

export const SPLIT_FEATURES_REQUEST = 'SPLIT_FEATURES_REQUEST';
export const SPLIT_FEATURES_REQUEST_SUCCESS = 'SPLIT_FEATURES_REQUEST_SUCCESS';
export const SPLIT_FEATURES_REQUEST_FAILED = 'SPLIT_FEATURES_REQUEST_FAILED';


export type SplitActionsTypes = ReturnType<ValueOf<typeof SplitActions>>;


export interface SplitState {
  loaded: boolean
  features: Record<string, 'on' | 'off'>
}
