import React, { useEffect, useState } from 'react';
import {
  Button, Grid, Typography, Avatar,
} from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { Link, RouteComponentProps } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import useStyles from './styles';
import { ScheduledVisit } from '../../redux/types/scheduledVisit';
import { formatToESTDate } from '../../utils/date';
import { getInitials } from '../../utils/helpers';

const initialVisit = {
  id: 0,
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  visit_date: '',
  comments: '',
  source: '',
  status: '',
  external_id: '',
  property_id: 0,
  timezoneAbbr: 0,
  timezoneValue: '',
  house_num: 0,
  address_1: '',
  builderName: '',
  propertyAgents: [],
};

type Props = {
  scheduledVisitDetailsRequest: (visitId: number) => void;
  scheduledVisit: ScheduledVisit | null;
} & RouteComponentProps<{ scheduledVisitId: string }>;

const ScheduledVisitDetails: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { scheduledVisitDetailsRequest, match, scheduledVisit } = props;
  const [visit, setVisit] = useState<ScheduledVisit>(initialVisit);
  const [formatedDate, time] = formatToESTDate(visit.visit_date, visit.timezoneAbbr, visit.timezoneValue || '')
    .split(',');
  const propertyAddress = [visit.house_num, visit.address_1].filter(Boolean).join(' ');

  useEffect(() => {
    scheduledVisitDetailsRequest(Number(match.params.scheduledVisitId));
  }, [scheduledVisitDetailsRequest, match.params.scheduledVisitId]);

  useEffect(() => {
    if (scheduledVisit) {
      setVisit(scheduledVisit);
    }
  }, [scheduledVisit]);

  return (
    <>
      <div className={classes.actionsBar}>
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            className={classes.backToButton}
            component={Link}
            to="/schedule-visit"
          >
            <ArrowLeftIcon />
            Scheduled Visits
          </Button>
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} sm={4} className={classes.content}>
          <Grid>
            <Avatar component="div" classes={{ root: classes.rootAvatar }}>
              {getInitials(visit.first_name, visit.last_name)}
            </Avatar>
          </Grid>
          <Grid className={classes.row}>
            <Typography className={classes.customerName}>
              {`${
                visit?.first_name
              } ${visit.last_name}`}
            </Typography>
          </Grid>
          <Grid className={classes.row}>
            <Divider />
          </Grid>
          <Grid className={classes.row}>
            <Typography className={classes.title}>Contact Information</Typography>
            <Grid spacing={4} container wrap="wrap">
              {visit.email && (
                <Grid item xs={12}>
                  <Typography color="textSecondary">Email</Typography>
                  <Typography className={classes.email} noWrap>
                    {visit.email}
                  </Typography>
                </Grid>
              )}
              {visit.phone && (
                <Grid item xs={12}>
                  <Typography color="textSecondary">Phone</Typography>
                  <Typography noWrap>{visit.phone}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={8}>
          <Grid className={classes.container}>
            <Grid className={classes.titleWrapper}>
              <Typography className={classes.title}>Visit</Typography>
            </Grid>
            <Grid className={classes.content}>
              <div className={classes.visitContainer}>
                <Grid container>
                  <Grid item xs={12} sm={2}>
                    <Typography className={classes.activityDate}>
                      {formatedDate}
                    </Typography>
                    <Typography className={classes.activityDate}>
                      {time}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <Typography color="textSecondary">Address</Typography>
                    <Typography>{propertyAddress}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Typography color="textSecondary">Client</Typography>
                    <Typography>{visit.builderName}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography color="textSecondary">Agent</Typography>
                    <Typography>{visit.propertyAgents.join(', ')}</Typography>
                  </Grid>
                </Grid>
                <Typography className={classes.comment}>{visit.comments}</Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(ScheduledVisitDetails);
