import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import InvoiceDetails from './InvoiceDetails';
import { AppState } from '../../redux/store';
import { getInvoiceDetails } from '../../redux/actions/billing';
import { selectInvoiceById } from '../../redux/selectors/invoices';
import styles from './styles';

const mapStateToProps = (state: AppState) => ({
  invoiceDetails: selectInvoiceById(state),
});

const mapDispatchToProps = {
  loadInvoiceDetails: getInvoiceDetails,
};

export default withStyles(styles)(connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoiceDetails));
