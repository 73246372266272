import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
  Typography, CardMedia, WithStyles,
} from '@material-ui/core';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import silverLock from '../../assets/img/silver_lock.jpg';
import bronzeLock from '../../assets/img/bronze_lock.jpg';
import blackLock from '../../assets/img/black_lock.jpg';
import lockBox from '../../assets/img/lockbox.jpg';
import LockStatus from '../LockStatus';
import styles from './styles';
import { Lock } from '../../redux/types/locks';
import { getBuilderId } from '../../redux/selectors/auth';

type Props = {
  device: Lock;
} & WithStyles<typeof styles>;


const DeviceWidget: React.FC<Props> = (props) => {
  const { classes, device } = props;

  const builderId = useSelector(getBuilderId);

  const lockImage: { [key: string]: any } = {
    lockbox: lockBox,
    Jorge: bronzeLock,
    Lockly: silverLock,
    Igloohome: blackLock,
  };

  const lockShipmentStatusesToDisplay = ['Shipped', 'Received'];


  return (
    <Card
      role="presentation"
      className={classes.card}
      // onClick={() => navTo(`/inventory/${device.serial_number}`)}
    >
      <div className={classes.iconWrapper}>
        <CardMedia
          className={classes.icon}
          image={lockImage[device.manufacturer
            .charAt(0)
            .toUpperCase() + device.manufacturer
            .slice(1)]}
        />
      </div>
      <CardContent>
        {device.serial_number
          && (
            <Typography className={classes.primaryText}>
              Device ID:
              &nbsp;
              <span className="deviceId">{device.serial_number}</span>
            </Typography>
          ) }

        {(device.builder_id === builderId) && (device.house_num || device.address_1)
          ? (
            <Typography className={classes.secondaryText}>
              Location:
              &nbsp;
              {`${device.house_num} ${device.address_1}`}
            </Typography>
          ) : null}

        {device.property_id && device.builder_id === builderId
          && (
            <Typography className={classes.secondaryText}>
              Property ID:
              &nbsp;
              <Link className={classes.link} onClick={(e) => e.stopPropagation()} to={`/properties/${device.property_id}`}>{device.property_id}</Link>
            </Typography>
          ) }

        {device.lock_status_value && <LockStatus lockStatusValue={device.lock_status_value} />}

        {
          device.lock_shipment_status && lockShipmentStatusesToDisplay
            .find((element) => element === device.lock_shipment_status)
            ? (
              <Typography className={classes.secondaryText}>
              Shipment Status:
              &nbsp;
                {device.lock_shipment_status}
              </Typography>
            ) : null
        }
        { device.license_date
          && (
            <Typography className={classes.secondaryText}>
              License Date:
              &nbsp;
              {device.license_date}
            </Typography>
          )}
        { device.community_name
          && (
            <Typography className={classes.secondaryText}>
              Community name:
              &nbsp;
              {device.community_name}
            </Typography>
          )}
      </CardContent>
    </Card>
  );
};

export default React.memo(DeviceWidget);
