import React, {
  Fragment,
} from 'react';
import useStyles from './styles';
import { ReactComponent as HomeIcon } from '../../../assets/homeCommunity.svg';
import { ReactComponent as SyncIcon } from '../../../assets/syncIntegrate.svg';
import CustomButton from '../../CustomButton';

type Props = {
  previousPage: () => void; 
  saveCommunity: () => void;
}

const CommunityIntegrations: React.FC<Props> = (props: Props) => {

  const {previousPage, saveCommunity} = props;

  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.headerText}>
        <span>Integrations</span>
      </div>
      <div className={classes.integratinsContainer}>
        <div className={classes.integrations}>
          <HomeIcon />
          <h4> Manually Create a Property </h4>
        </div>
        <div className={classes.integrations}>
          <SyncIcon />
          <h4> Sync &#38; Integrate </h4>
        </div>
      </div>
      <div className={classes.navButtons}>
        <CustomButton style={{ marginRight: 30 }} variant="white" onClick={ ()=> previousPage()}>
          Previous
        </CustomButton>
        <CustomButton variant="orange" onClick={ ()=> saveCommunity()}>
          Submit
        </CustomButton>
      </div>
    </Fragment>
  )
};


export default React.memo(CommunityIntegrations);