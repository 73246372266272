import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import Spinner from '@material-ui/core/CircularProgress';
import {
  AddCircleOutline,
  Edit,
  MoreVert,
  DeleteOutline,
  HighlightOff,
} from '@material-ui/icons';
import useStyles from './styles';
import CustomButton from '../../components/CustomButton';
import { beautifyErrors, ValidationErrors } from '../../utils/helpers';
import { QuestionSchema, AnswerSchema } from './SurveyValidation';
import { SurveyQuestion } from '../../redux/types/survey';

const defaultQuestions = [
  {
    text: 'Do you currently Own, Rent, Live with family or other?',
    type: 2,
    choices: ['Own', 'Rent', 'Live /w Fam', 'Other'],
  },
  {
    text: 'Do you have a buyers/leasing agent?',
    type: 3,
    choices: ['Yes', 'No'],
  },
  {
    text: 'How long until you buy/rent a home?',
    type: 2,
    choices: ['Now', '1-3 Month', '3-6 Month', 'Not sure'],
  },
];

type Props = {
  loadSurveyQuestions: () => void;
  updateQuestions: (questions: SurveyQuestion[]) => void;
  surveyQuestions: SurveyQuestion[];
  loading: boolean;
  updating: boolean;
};

const SurveyQuestions: React.FC<Props> = (props) => {
  const {
    loadSurveyQuestions,
    updateQuestions,
    surveyQuestions,
    loading,
    updating,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [questions, setQuestions] = useState<SurveyQuestion[]>(
    defaultQuestions,
  );
  const [selectedQuestion, setSelectedQuestion] = useState<SurveyQuestion>({});
  const [action, setAction] = useState<string | null>(null);
  const [step, setStep] = useState(1);
  const [validationErrors, setValidationErrors] = useState<
  ValidationErrors<SurveyQuestion>
  >({});

  const classes = useStyles();
  const open = Boolean(anchorEl);

  useEffect(() => {
    loadSurveyQuestions();
  }, [loadSurveyQuestions]);

  useEffect(() => {
    const customQuestions = surveyQuestions.map(
      ({
        questionId, type, text, choices,
      }: SurveyQuestion) => ({
        questionId,
        text,
        choices,
        type,
      }),
    );

    if (customQuestions.length > 0) {
      setQuestions(surveyQuestions);
    }
  }, [surveyQuestions]);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    question: SurveyQuestion,
  ) => {
    setAnchorEl(event.currentTarget);
    setStep(1);
    setAction(null);
    setSelectedQuestion(question);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setStep(1);
    setAction('edit');
    setAnchorEl(null);
  };

  const handleAddQuestion = () => {
    setSelectedQuestion({});
    setStep(1);
    setAction('add');
    setAnchorEl(null);
  };

  const handleCancel = () => {
    setSelectedQuestion({});
    setStep(1);
    setAction(null);
  };

  const handleUpdate = () => {
    try {
      switch (step) {
        case 1:
          QuestionSchema.validateSync(selectedQuestion, {
            abortEarly: false,
          });

          if (selectedQuestion.type === 2) {
            setStep(2);
            return;
          }

          break;

        case 2:
          AnswerSchema.validateSync(selectedQuestion, {
            abortEarly: false,
          });

          break;

        default:
          break;
      }

      setValidationErrors({});

      const updatedQuestions = questions?.map((question, index) => (index === selectedQuestion?.index ? selectedQuestion : question)
      );

      if (action === 'edit') {
        setQuestions(updatedQuestions);
        updateQuestions(updatedQuestions);
      }

      if (action === 'add') {
        setQuestions([...updatedQuestions, selectedQuestion]);
        updateQuestions([...updatedQuestions, selectedQuestion]);
      }

      setAction(null);
      setStep(1);
    } catch (e) {
      setValidationErrors(beautifyErrors<SurveyQuestion>(e));
    }
  };

  const handleDelete = () => {
    const updatedQuestions = questions.filter(
      (_, index) => index !== selectedQuestion?.index,
    );
    setQuestions(updatedQuestions);
    updateQuestions(updatedQuestions);
    setAnchorEl(null);
  };

  const handleChangeAnswers = (
    e: React.ChangeEvent<HTMLInputElement>,
    choices: string[],
    index: number,
  ) => {
    const newChoices = choices.map((a, idx) => (idx === index ? e.target.value : a));

    setSelectedQuestion({
      ...selectedQuestion,
      choices: newChoices,
    });
  };

  const handleAddLabel = () => {
    if (!selectedQuestion.choices) {
      return;
    }

    setSelectedQuestion({
      ...selectedQuestion,
      choices: [...selectedQuestion.choices, ''],
    });
  };

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedQuestion({
      ...selectedQuestion,
      text: e.target.value,
    });
  };

  const handleAnswerTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const type = Number(e.target.value);

    setSelectedQuestion({
      ...selectedQuestion,
      type,
      choices: type === 3 ? ['Yes', 'No'] : new Array(4).fill(''),
    });
  };

  // const renderBooleanAnswer = () => (
  //   <Grid container>
  //     <Grid item>
  //       <FormControlLabel
  //         value="true"
  //         control={<Radio className={classes.radio} />}
  //         label="Yes"
  //         checked={selectedQuestion?.answer === true}
  //         onChange={() => {
  //           setSelectedQuestion({ ...selectedQuestion, answer: true });
  //         }}
  //       />
  //     </Grid>
  //     <Grid item>
  //       <FormControlLabel
  //         value="false"
  //         control={<Radio className={classes.radio} />}
  //         label="No"
  //         checked={selectedQuestion?.answer === false}
  //         onChange={() => {
  //           setSelectedQuestion({ ...selectedQuestion, answer: false });
  //         }}
  //       />
  //     </Grid>
  //   </Grid>
  // );

  // const renderTextAnswer = () => (
  //   <TextField
  //     fullWidth
  //     id="text_stream"
  //     name="text_stream"
  //     label="30 characters max..."
  //     value={selectedQuestion?.answer}
  //     inputProps={{ maxLength: 30 }}
  //     onChange={(e) => {
  //       setSelectedQuestion({
  //         ...selectedQuestion,
  //         answer: e.target.value,
  //       });
  //     }}
  //     helperText={validationErrors.answer && 'Field is required'}
  //     error={Boolean(validationErrors.answer)}
  //   />
  // );

  // const renderNumberAnswer = () => (
  //   <TextField
  //     fullWidth
  //     id="numbers"
  //     name="numbers"
  //     label="Enter Numbers..."
  //     value={selectedQuestion?.answer}
  //     onChange={(e: any) => {
  //       if (Number(e.target.value) || !e.target.value.length) {
  //         setSelectedQuestion({
  //           ...selectedQuestion,
  //           answer: e.target.value,
  //         });
  //       }
  //     }}
  //     helperText={validationErrors.answer && 'Field is required'}
  //     error={Boolean(validationErrors.answer)}
  //   />
  // );

  const renderMultipleAnswer = () => {
    const { choices } = selectedQuestion;

    return (
      <Grid container spacing={4}>
        {Array.isArray(choices)
          && choices.map((choice, index) => (
            <Fragment key={index}>
              <Grid item container xs={6} spacing={1} alignItems="center">
                <Grid item xs>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    id={`label_${index + 1}`}
                    name={`label_${index + 1}`}
                    label={`Enter Label ${index + 1}...`}
                    value={choice}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeAnswers(e, choices, index)}
                    helperText={
                      (validationErrors[
                        `choices[${index}]` as keyof typeof validationErrors
                      ]
                        || validationErrors.choices)
                      && 'Fill or delete this field'
                    }
                    error={
                      Boolean(
                        validationErrors[
                          `choices[${index}]` as keyof typeof validationErrors
                        ],
                      ) || Boolean(validationErrors.choices)
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    disabled={choices.length < 2}
                    onClick={() => {
                      if (choices.length < 2) {
                        return;
                      }

                      const filteredChoices = choices.filter(
                        (el, idx) => index !== idx,
                      );

                      setSelectedQuestion({
                        ...selectedQuestion,
                        choices: filteredChoices,
                      });
                    }}
                  >
                    <HighlightOff
                      color={choices.length < 2 ? 'disabled' : 'error'}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Fragment>
          ))}
        {Array.isArray(choices) && choices.length < 4 && (
          <Grid item container xs={6} spacing={1} alignItems="center">
            <Grid item xs>
              <Button
                className={classes.addLabelButton}
                onClick={handleAddLabel}
              >
                Add Label
                <AddCircleOutline
                  className={classes.addButton}
                  style={{ marginLeft: '10px' }}
                />
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };
  const renderAnswers = () => {
    if (action && step === 2 && selectedQuestion?.type === 2) {
      return renderMultipleAnswer();
    }

    // if (
    //   (action)
    //   && step === 2
    //   && selectedQuestion?.type === 3
    // ) {
    //   return renderBooleanAnswer();
    // }

    // if (
    //   (action)
    //   && step === 2
    //   && selectedQuestion?.type === 4
    // ) {
    //   return renderTextAnswer();
    // }

    // if (
    //   (action)
    //   && step === 2
    //   && selectedQuestion?.type === 5
    // ) {
    //   return renderNumberAnswer();
    // }
  };

  return (
    <div className={classes.contentWrapper}>
      <p className={classes.title} style={{ fontSize: '24px' }}>
        Select Survey Question (up to 5)
      </p>

      {loading ? (
        <LinearProgress style={{ margin: '20px 0', height: '2px' }} />
      ) : (
        <Divider style={{ margin: '20px 0' }} />
      )}

      <div className={classes.tableWrapper}>
        {updating && (
          <div className={classes.spinnerWrapper}>
            <Spinner />
          </div>
        )}
        <Table className={classes.table} aria-label="customized table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell style={{ width: '44px' }} />
              <TableCell>Survey Questions</TableCell>
              <TableCell style={{ width: '150px' }}>Answer Type</TableCell>
              <TableCell align="right" style={{ width: '80px' }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!loading
              && questions.map(({
                questionId, text, type, choices,
              }, index) => (
                <TableRow className={classes.tableRow} key={index}>
                  <TableCell align="center" className={classes.tableCell}>
                    {index + 1}
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    {text}
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell}>
                    {type === 2 && 'Multiple Choice'}
                    {type === 3 && 'Yes/No'}
                    {type === 4 && 'Text Stream'}
                    {type === 5 && 'Numbers'}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <div>
                      <IconButton
                        disabled={index === 0}
                        onClick={(e) => {
                          if (index === 0) {
                            return;
                          }

                          handleClick(e, {
                            text,
                            type,
                            index,
                            choices,
                            questionId,
                          });
                        }}
                      >
                        <MoreVert />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={handleEdit}>
                          <Edit
                            color="primary"
                            className={classes.actionButton}
                          />
                          <Typography>Edit</Typography>
                        </MenuItem>
                        <MenuItem onClick={handleDelete}>
                          <DeleteOutline
                            color="error"
                            className={classes.actionButton}
                          />
                          <Typography>Delete</Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>

      {questions.length < 5 && (
        <p
          className={classes.title}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '20px',
            marginTop: '20px',
          }}
        >
          Add Custom Questions
          <IconButton onClick={handleAddQuestion}>
            <AddCircleOutline className={classes.addButton} />
          </IconButton>
        </p>
      )}

      {action && step === 1 && (
        <Grid container spacing={2}>
          <Grid item xs>
            <TextField
              fullWidth
              required
              margin="normal"
              id="survey_question"
              name="survey_question"
              label="Survey Question"
              value={selectedQuestion?.text ?? ''}
              onChange={handleQuestionChange}
              helperText={validationErrors.text && 'Question field is required'}
              error={Boolean(validationErrors.text)}
            />
          </Grid>

          <Grid item xs>
            <TextField
              select
              fullWidth
              required
              margin="normal"
              id="answer_field_type"
              name="answer_field_type"
              label="Answer Field Type"
              value={selectedQuestion?.type ?? ''}
              onChange={handleAnswerTypeChange}
              helperText={
                validationErrors.type && 'Answer type field is required'
              }
              error={Boolean(validationErrors.type)}
            >
              <MenuItem value={5}>Numbers</MenuItem>
              <MenuItem value={3}>Yes/No</MenuItem>
              <MenuItem value={4}>Text Stream (Limit 30 characters)</MenuItem>
              <MenuItem value={2}>Multiple Choice</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      )}

      {action && step === 2 && (
        <>
          <p className={classes.question}>{selectedQuestion.text}</p>
          {renderAnswers()}
        </>
      )}

      <Divider
        style={{
          marginTop: '20px',
          marginBottom: '40px',
        }}
      />

      {action && (
        <Grid
          container
          justify="flex-end"
          spacing={2}
          style={{ marginTop: '32px' }}
        >
          <Grid item>
            <CustomButton variant="white" onClick={handleCancel}>
              Cancel
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              variant="orange"
              onClick={() => {
                handleUpdate();
              }}
            >
              {selectedQuestion.type === 2
                && action === 'edit'
                && step === 1
                && 'Next'}
              {selectedQuestion.type !== 2
                && action === 'edit'
                && step === 1
                && 'Update'}
              {selectedQuestion.type === 2
                && action === 'add'
                && step === 1
                && 'Next'}
              {selectedQuestion.type !== 2
                && action === 'add'
                && step === 1
                && 'Add'}
              {action === 'edit' && step === 2 && 'Update'}
              {action === 'add' && step === 2 && 'Add'}
            </CustomButton>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default React.memo(SurveyQuestions);
