import {
  Grid,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  WithStyles,
  Typography,
  Switch,
  Select,
  MenuItem,
  Divider,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './styles';
import {
  compareTime,
  convertTime12to24,
  convertTime24To12,
} from '../../utils/date';
import { TourHoursType, WeeklyTourHours } from '../../redux/types/communities';
import { createErrorSnackBar } from '../../redux/actions/snackbars';

type Props = {
  handlePreviousPage?: () => void;
  handleNextPage?: () => void;
  handleChanges?: (tourHours: number[]) => void;
  handleTourHours: (tourHours: WeeklyTourHours[] | null) => void;
  propertyHours?: TourHoursType[] | null;
  communityHours?: TourHoursType[] | null;
  variant?: 'property' | 'community';
} & WithStyles<typeof styles>;

const hours: { value: number; label: string }[] = [];

for (let i = 5; i < 24; i += 1) {
  hours.push({
    value: i,
    label: i < 12 ? `${i}am` : `${i === 12 ? i : i - 12}pm`,
  });
}

const getTourHours = (start: number | null, end: number | null) => {
  if (!start || !end) {
    return;
  }

  const hours: string[] = [];

  for (let i = start; i <= end; i += 1) {
    const hour = i < 12 ? `${i}:00 AM` : `${i === 12 ? i : i - 12}:00 PM`;
    const halfHour = i < 12 ? `${i}:30 AM` : `${i === 12 ? i : i - 12}:30 PM`;

    i === end ? hours.push(hour) : hours.push(hour, halfHour);
  }

  return hours;
};

const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const getTourHoursOptions = (tourHours: TourHoursType[]) => tourHours.map((day) => ({
  day: weekDays[day.dayOfWeek],
  checked: day.isToursAvailable,
  tourHoursStart: 7,
  tourHoursEnd: 23,
  startTime: day.tourHoursStart
    ? convertTime12to24(day?.tourHoursStart)
    : null,
  endTime: day.tourHoursEnd ? convertTime12to24(day?.tourHoursEnd) : null,
  secondWindowStart: day?.secondWindowStart
    ? convertTime12to24(day?.secondWindowStart)
    : null,
  secondWindowEnd: day.secondWindowEnd
    ? convertTime12to24(day?.secondWindowEnd)
    : null,
  isSecondWindowEnabled: day.isSecondWindowEnabled,
}));

const defaultWeeklyTourHours = [
  {
    day: 'Monday',
    checked: true,
    tourHoursStart: 7,
    tourHoursEnd: 23,
    isSecondWindowEnabled: false,
    secondWindowStart: null,
    secondWindowEnd: null,
    startTime: { hour: 7, minute: 0 },
    endTime: { hour: 21, minute: 0 },
  },
  {
    day: 'Tuesday',
    checked: true,
    tourHoursStart: 7,
    tourHoursEnd: 23,
    isSecondWindowEnabled: false,
    secondWindowStart: null,
    secondWindowEnd: null,
    startTime: { hour: 7, minute: 0 },
    endTime: { hour: 21, minute: 0 },
  },
  {
    day: 'Wednesday',
    checked: true,
    tourHoursStart: 7,
    tourHoursEnd: 23,
    isSecondWindowEnabled: false,
    secondWindowStart: null,
    secondWindowEnd: null,
    startTime: { hour: 7, minute: 0 },
    endTime: { hour: 21, minute: 0 },
  },
  {
    day: 'Thursday',
    checked: true,
    tourHoursStart: 7,
    tourHoursEnd: 23,
    isSecondWindowEnabled: false,
    secondWindowStart: null,
    secondWindowEnd: null,
    startTime: { hour: 7, minute: 0 },
    endTime: { hour: 21, minute: 0 },
  },
  {
    day: 'Friday',
    checked: true,
    tourHoursStart: 7,
    tourHoursEnd: 23,
    isSecondWindowEnabled: false,
    secondWindowStart: null,
    secondWindowEnd: null,
    startTime: { hour: 7, minute: 0 },
    endTime: { hour: 21, minute: 0 },
  },
  {
    day: 'Saturday',
    checked: true,
    tourHoursStart: 7,
    tourHoursEnd: 23,
    isSecondWindowEnabled: false,
    secondWindowStart: null,
    secondWindowEnd: null,
    startTime: { hour: 7, minute: 0 },
    endTime: { hour: 21, minute: 0 },
  },
  {
    day: 'Sunday',
    checked: true,
    tourHoursStart: 7,
    tourHoursEnd: 23,
    isSecondWindowEnabled: false,
    secondWindowStart: null,
    secondWindowEnd: null,
    startTime: { hour: 7, minute: 0 },
    endTime: { hour: 21, minute: 0 },
  },
];

const TourHours = (props: Props) => {
  const {
    classes,
    handleTourHours,
    propertyHours,
    communityHours,
    variant,
  } = props;

  const [isDefaultHours, setIsDefaultHours] = useState<boolean>(
    !propertyHours && !communityHours,
  );
  const [weeklyTourHours, setWeeklyTourHours] = useState<
  WeeklyTourHours[] | null
  >(defaultWeeklyTourHours);

  const dispatch = useDispatch();

  useEffect(() => {
    handleTourHours(isDefaultHours ? null : weeklyTourHours);
  }, [weeklyTourHours, isDefaultHours, handleTourHours]);

  useEffect(() => {
    if (isDefaultHours && variant === 'community') {
      setWeeklyTourHours(defaultWeeklyTourHours);
      return;
    }

    if (propertyHours) {
      setWeeklyTourHours(getTourHoursOptions(propertyHours));
      return;
    }

    if (communityHours) {
      setWeeklyTourHours(getTourHoursOptions(communityHours));
    }
  }, [isDefaultHours, propertyHours, communityHours, variant]);

  useEffect(() => {
    if (propertyHours && variant === 'property') {
      setWeeklyTourHours(getTourHoursOptions(propertyHours));
      setIsDefaultHours(false);
      return;
    }

    if (communityHours && variant === 'community') {
      setWeeklyTourHours(getTourHoursOptions(communityHours));
      setIsDefaultHours(false);
      return;
    }

    setIsDefaultHours(true);
  }, [communityHours, propertyHours, variant]);

  const handleTourHoursChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    el: string,
  ) => {
    const { name, value } = event.target;

    const newState = weeklyTourHours?.map((day) => {
      if (name === day.day) {
        switch (el) {
          case 'start': {
            const startTime = convertTime12to24(value);

            return { ...day, startTime };
          }

          case 'end': {
            const endTime = convertTime12to24(value);

            if (day?.isSecondWindowEnabled) {
              let secondWindowStart = null;

              secondWindowStart = day?.secondWindowStart
                  && day?.secondWindowStart?.hour >= endTime?.hour
                ? {
                  hour: day?.secondWindowStart?.hour,
                  minute: day?.secondWindowStart?.minute,
                }
                : {
                  hour: endTime.hour,
                  minute: endTime.minute,
                };

              return {
                ...day,
                endTime,
                secondWindowStart,
              };
            }
            return { ...day, endTime };
          }

          case 'secondWindowStart': {
            const secondWindowStart = convertTime12to24(value);

            return { ...day, secondWindowStart };
          }

          case 'secondWindowEnd': {
            const secondWindowEnd = convertTime12to24(value);

            return { ...day, secondWindowEnd };
          }

          default:
            return day;
        }
      }

      return day;
    }) ?? null;

    setWeeklyTourHours(newState);
  };

  const handleAdjustableTourHours = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsDefaultHours(e.target.checked);
  };

  const handleDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, name, checked } = event.target;

    const newState = weeklyTourHours?.map((day) => {
      if (name === day.day) {
        const defaultDay = defaultWeeklyTourHours.find(
          (weekDay) => weekDay.day === day.day,
        );
        const secondWindowStart = checked && day.endTime
          ? {
            hour: day.endTime.hour,
            minute: day.endTime.minute,
          }
          : null;
        const isSecondWindowStartValid = day?.endTime?.hour && secondWindowStart?.hour
          ? secondWindowStart?.hour >= day?.endTime?.hour
                && compareTime(
                  {
                    hour: day?.endTime?.hour,
                    minute: day?.endTime?.minute,
                  },
                  {
                    hour: 22,
                    minute: 30,
                  },
                  true,
                )
          : true;

        if (!isSecondWindowStartValid) {
          dispatch(createErrorSnackBar('No available time slots!'));
          return day;
        }

        const defaultState = { ...day, ...defaultDay };
        const updatedState = {
          ...day,
          checked: id === 'day' ? checked : day.checked,
          isSecondWindowEnabled: checked,
          startTime: id === 'day' ? null : day.startTime,
          endTime: id === 'day' ? null : day.endTime,
          secondWindowStart,
          secondWindowEnd: checked
            ? { hour: day.tourHoursEnd, minute: 0 }
            : null,
        };

        return day.checked ? updatedState : defaultState;
      }

      return day;
    }) ?? null;

    setWeeklyTourHours(newState);
  };

  return (
    <>
      <Grid item xs={12}>
        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={isDefaultHours}
                onChange={handleAdjustableTourHours}
              />
            )}
            label={variant === 'property' ? 'Default' : 'Default - 7am to 9pm'}
          />
          {variant === 'property' && (
            <FormHelperText>Community Settings</FormHelperText>
          )}
        </FormControl>

        <Grid
          container
          direction="column"
          className={`${isDefaultHours && classes.disabled}`}
        >
          <Divider style={{ width: '100%', margin: '10px 0 20px 0' }} />

          {weeklyTourHours?.map((day) => {
            const tourHoursStartOptions = getTourHours(7, day.tourHoursEnd);
            const tourHoursEndOptions = getTourHours(
              day.tourHoursStart,
              day.tourHoursEnd,
            );

            const startTime = !day.checked
              ? null
              : convertTime24To12(day?.startTime?.hour, day?.startTime?.minute);
            const endTime = !day.checked
              ? null
              : convertTime24To12(day?.endTime?.hour, day?.endTime?.minute);
            const secondWindowStartTime = !day.secondWindowStart?.hour
              ? null
              : convertTime24To12(
                  day?.secondWindowStart?.hour,
                  day?.secondWindowStart?.minute,
              );
            const secondWindowEndTime = !day.secondWindowEnd?.hour
              ? null
              : convertTime24To12(
                  day?.secondWindowEnd?.hour,
                  day?.secondWindowEnd?.minute,
              );

            return (
              <Grid
                key={day.day}
                item
                container
                xs
                alignItems="center"
                spacing={2}
                style={{ marginBottom: '20px' }}
              >
                <Grid item style={{ width: '150px' }}>
                  <Typography style={{ fontWeight: 700, fontSize: '20px' }}>
                    {day.day}
                  </Typography>
                </Grid>
                <Grid item style={{ width: '150px' }}>
                  <Switch
                    id="day"
                    checked={Boolean(day.checked)}
                    onChange={handleDayChange}
                    name={day.day}
                  />
                </Grid>
                {day.checked ? (
                  <Grid item xs container spacing={6}>
                    <Grid item>
                      <Typography
                        style={{
                          fontWeight: 700,
                          padding: '10px 0',
                        }}
                      >
                        First Window
                      </Typography>
                      <Grid item>
                        <Select
                          id="start"
                          name={day.day}
                          variant="outlined"
                          value={startTime ?? ''}
                          onChange={(e) => handleTourHoursChange(e, 'start')}
                          style={{ minWidth: '120px' }}
                        >
                          {tourHoursStartOptions?.map((hour) => {
                            const {
                              hour: tourHour,
                              minute: tourMinute,
                            } = convertTime12to24(hour);
                            const disabled = day?.endTime?.hour
                              ? compareTime(
                                {
                                  hour: day?.endTime?.hour,
                                  minute: day?.endTime?.minute,
                                },
                                {
                                  hour: tourHour,
                                  minute: tourMinute,
                                },
                                true,
                              )
                              : false;

                            if (disabled) {
                              return null;
                            }

                            return (
                              <MenuItem
                                key={hour}
                                value={hour}
                                disabled={disabled}
                              >
                                {hour}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{
                            textAlign: 'center',
                            margin: '10px 0',
                            fontWeight: 700,
                            color: '#8E8B99',
                          }}
                        >
                          To
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Select
                          name={day.day}
                          variant="outlined"
                          value={endTime ?? ''}
                          onChange={(e) => handleTourHoursChange(e, 'end')}
                          style={{ minWidth: '120px' }}
                        >
                          {tourHoursEndOptions?.map((hour) => {
                            const {
                              hour: tourHour,
                              minute: tourMinute,
                            } = convertTime12to24(hour);
                            const isFirstWindowValid = day?.startTime?.hour
                              ? compareTime(
                                {
                                  hour: day?.startTime?.hour,
                                  minute: day?.startTime?.minute,
                                },
                                {
                                  hour: tourHour,
                                  minute: tourMinute,
                                },
                                false,
                              )
                              : false;
                            const isSecondWindowValid = day?.isSecondWindowEnabled
                              && day?.secondWindowStart
                              ? compareTime(
                                {
                                  hour: tourHour,
                                  minute: tourMinute,
                                },
                                {
                                  hour: day?.secondWindowStart?.hour,
                                  minute: day?.secondWindowStart?.minute,
                                },
                                true,
                              )
                              : true;
                            const disabled = !isFirstWindowValid || !isSecondWindowValid;

                            if (disabled) {
                              return null;
                            }

                            return (
                              <MenuItem
                                key={hour}
                                value={hour}
                                disabled={disabled}
                              >
                                {hour}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center">
                        <Typography
                          style={{
                            color: day.isSecondWindowEnabled
                              ? '#000'
                              : '#8E8B99',
                            fontWeight: 700,
                            padding: '10px 0',
                          }}
                        >
                          Second Window
                        </Typography>
                        <Checkbox
                          id="secondWindow"
                          style={{ marginLeft: '5px' }}
                          checked={day.isSecondWindowEnabled ?? false}
                          name={day.day}
                          onChange={handleDayChange}
                        />
                      </Grid>
                      <div
                        className={`${!day.isSecondWindowEnabled
                          && classes.disabled}`}
                      >
                        <Grid item>
                          <Select
                            id="start"
                            name={day.day}
                            variant="outlined"
                            value={secondWindowStartTime ?? ''}
                            onChange={(e) => handleTourHoursChange(e, 'secondWindowStart')}
                            style={{ minWidth: '120px' }}
                          >
                            {tourHoursStartOptions?.map((hour) => {
                              const {
                                hour: tourHour,
                                minute: tourMinute,
                              } = convertTime12to24(hour);
                              const isSecondWindowStartValid = day?.endTime?.hour && day?.secondWindowEnd?.hour
                                ? compareTime(
                                  {
                                    hour: day?.endTime?.hour,
                                    minute: day?.endTime?.minute,
                                  },
                                  {
                                    hour: tourHour,
                                    minute: tourMinute,
                                  },
                                  true,
                                )
                                    && compareTime(
                                      {
                                        hour: tourHour,
                                        minute: tourMinute,
                                      },
                                      {
                                        hour: day?.secondWindowEnd?.hour,
                                        minute: day?.secondWindowEnd?.minute,
                                      },
                                      false,
                                    )
                                : false;
                              const disabled = !isSecondWindowStartValid;

                              if (disabled) {
                                return null;
                              }

                              return (
                                <MenuItem
                                  key={hour}
                                  value={hour}
                                  disabled={disabled}
                                >
                                  {hour}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              margin: '10px 0 10px 50px',
                              fontWeight: 700,
                              color: '#8E8B99',
                            }}
                          >
                            To
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Select
                            name={day.day}
                            variant="outlined"
                            value={secondWindowEndTime ?? ''}
                            onChange={(e) => handleTourHoursChange(e, 'secondWindowEnd')}
                            style={{ minWidth: '120px' }}
                          >
                            {tourHoursEndOptions?.map((hour) => {
                              const {
                                hour: tourHour,
                                minute: tourMinute,
                              } = convertTime12to24(hour);
                              const disabled = day?.secondWindowStart?.hour
                                ? compareTime(
                                  {
                                    hour: tourHour,
                                    minute: tourMinute,
                                  },
                                  {
                                    hour: day?.secondWindowStart?.hour,
                                    minute: day?.secondWindowStart?.minute,
                                  },
                                  true,
                                ) || !day.secondWindowStart
                                : false;

                              if (disabled) {
                                return null;
                              }

                              return (
                                <MenuItem
                                  key={hour}
                                  value={hour}
                                  disabled={disabled}
                                >
                                  {hour}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item>
                    <Typography>No Tours</Typography>
                  </Grid>
                )}
                <Divider style={{ width: '100%', margin: '20px 0 10px 0' }} />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default TourHours;
