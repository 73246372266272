import {
  CATALOG_REQUEST,
  CATALOG_REQUEST_FAILED,
  CATALOG_REQUEST_SUCCESS, CatalogActionsTypes,
  CatalogState,
} from '../../types/catalog';

const initialState: CatalogState = {
  items: [],
  error: '',
  loading: false,
};

export default function CatalogReducer(
  state = initialState,
  action: CatalogActionsTypes,
): CatalogState {
  switch (action.type) {
    case CATALOG_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case CATALOG_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        error: '',
        loading: false,
      };
    }

    case CATALOG_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
