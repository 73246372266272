import React, { useEffect, useMemo, useState } from 'react';
import { WithStyles } from '@material-ui/styles';
import { Box, Grid } from '@material-ui/core';
import clonedeep from 'lodash.clonedeep';
import uniq from 'lodash.uniq';
import styles from './styles';
import HubLockCodeInput from '../HubLockCodeInput';
import { HubLockDevicePropertyUserCodes, HubLockUserCodeUpdate } from '../../redux/types/hubs';
import CloseIcon from '../Icons/CloseIcon';
import { LockInputType } from '../HubLockCodeInput/HubLockCodeInput';
import CustomButton from '../CustomButton';
import { getFreeLockCodeKey, getHubLockUserCodesForUpdate } from './utils';

type Props = {
  userCodes: HubLockDevicePropertyUserCodes | null
  updating: boolean
  deleteHubLockCode: (code: string) => void
  updateHubLockCodes: (payload: HubLockUserCodeUpdate[]) => void
} & WithStyles<typeof styles>;


const HubLockUserCodesForm: React.FC<Props> = (props: Props) => {
  const {
    classes, userCodes, deleteHubLockCode, updating, updateHubLockCodes,
  } = props;

  const userCodesArrInitial = useMemo<HubLockUserCodeUpdate[]>(() => (
    userCodes
      ? getHubLockUserCodesForUpdate(userCodes)
      : []),
  [userCodes]);

  const [userCodesArr, setUserCodesArr] = useState<HubLockUserCodeUpdate[]>(clonedeep(userCodesArrInitial));
  const [newCodeKey, setNewCodeKey] = useState('');

  const isDirtyForm = useMemo<boolean>(() => {
    if (userCodesArr.length !== userCodesArrInitial.length) {
      return true;
    }

    let isDirty = false;

    userCodesArr.forEach((lockCode, index) => {
      const searchedCode = userCodesArrInitial.find((c) => c.code === lockCode.code);

      if (searchedCode) {
        if (JSON.stringify(searchedCode) !== JSON.stringify(userCodesArr[index])) {
          isDirty = true;
        }
      } else {
        isDirty = true;
      }
    });

    return isDirty;
  }, [userCodesArrInitial, userCodesArr]);

  const isValidForm = !userCodesArr.find((lockCode) => (lockCode.code === '0' || !lockCode.code || lockCode.code.length > 10 || !lockCode.name.length));

  const formWithDuplicates = useMemo<boolean>(() => userCodesArr
    .length !== uniq(userCodesArr.map((lockCode) => lockCode.code)).length,
  [userCodesArr]);

  const onChangeInput = (inputType: LockInputType, index: number) => (val: string) => {
    const newArr: HubLockUserCodeUpdate[] = clonedeep(userCodesArr);
    newArr[index][inputType] = val;
    setUserCodesArr(newArr);
  };


  const addEmptyCode = () => {
    const key = getFreeLockCodeKey(userCodesArr);
    setNewCodeKey(key);
    setUserCodesArr([...clonedeep(userCodesArr), { code: '', name: '', key }]);
  };

  const deleteCode = (codeKey: string) => {
    deleteHubLockCode(codeKey);
  };

  const updateCodes = () => {
    updateHubLockCodes(userCodesArr);
    setNewCodeKey('');
  };

  useEffect(() => {
    setUserCodesArr(userCodesArrInitial);
  }, [userCodesArrInitial]);

  return (
    <div>
      <Box className={classes.title}>Lock Codes</Box>
      {Boolean(!isValidForm && isDirtyForm) && (
      <Box mb={1}>Some codes are not valid</Box>
      )}
      {Boolean(formWithDuplicates && isDirtyForm) && (
      <Box mb={1}>Codes cannot be same</Box>
      )}

      <div className={classes.container} style={{display: 'block'}}>
        {!!userCodesArr.length && userCodesArr.map((lockCode, index) => (
          <Grid style={{opacity: (newCodeKey === '') ? '1': (lockCode.key === newCodeKey ? '1' : '0.3'), marginTop: (lockCode.key === newCodeKey) ? '50px': '0px'}} container spacing={3} key={index}>
            <Grid item xs={5}>
              <HubLockCodeInput
                code={lockCode.code}
                label="Code"
                onChange={onChangeInput('code', index)}
                disabled={index < userCodesArrInitial.length}
                type="code"
              />
            </Grid>
            <Grid item xs={5}>
              <HubLockCodeInput
                code={lockCode.name}
                label="Name"
                onChange={onChangeInput('name', index)}
                disabled = {index < userCodesArrInitial.length}
                type="name"
              />
            </Grid>
            {(lockCode.key !== newCodeKey) && (
              <Grid container item xs={2} alignItems="center">
                <div title="Delete code" onClick={() => deleteCode(lockCode.key)}>
                  <CloseIcon className={classes.deleteIcon} />
                </div>
              </Grid>
            )}
          </Grid>
        ))}
      </div>
      <Box mt={1}>
        {isDirtyForm && (
        <CustomButton variant="orange" fullWidth disabled={!isValidForm || formWithDuplicates || updating} onClick={updateCodes}>
          Save New Code
        </CustomButton>
        )}
      </Box>
      <Box mt={1}>
        {userCodesArr.length < 8 && !isDirtyForm &&(
          <CustomButton variant="orange" fullWidth disabled={updating} onClick={addEmptyCode}>
            Add New Code
          </CustomButton>
        )}
      </Box>
    </div>
  );
};

export default HubLockUserCodesForm;
