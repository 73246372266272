import React from 'react';
import { WithStyles } from '@material-ui/core';
import MaterialSwitch from '@material-ui/core/Switch';
import styles from './styles';

type Props = {
  title?: string
  valueName: string;
  secondValueName?: string;
  value: boolean
  setValue: () => void;
  disabled?: boolean
} & WithStyles<typeof styles>;


const Switch = (props: Props) => {
  const {
    classes, title, valueName, secondValueName, value, setValue, disabled = false,
  } = props;

  return (
    <div className={classes.container}>
      {title && <div className={classes.title}>{title}</div>}
      <div className={classes.switchWrapper}>
        <span className={classes.valueName}>{valueName}</span>
        <MaterialSwitch
          checked={value}
          onChange={() => setValue()}
          classes={{
            root: classes.switchRoot,
            checked: classes.switchChecked,
          }}
          disabled={disabled}
        />
        <span className={classes.valueName}>{secondValueName}</span>
      </div>
    </div>
  );
};

export default Switch;
