import { createSelector } from 'reselect';
import { AppState } from '../store';

export const selectReportsState = (state: AppState) => state.reports;
const selectReports = (state: any) => state.marketReport;
const selectTotal = (state: any) => state.total;

export const selectMarketReport = createSelector(
  selectReportsState,
  selectReports,
);

export const selectTotalReports = createSelector(
  selectReportsState,
  selectTotal,
);
