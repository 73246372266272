import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Query } from '../../../utils/query';
import { Order } from '../../../utils/table';
import { Agent } from '../../../redux/types/agents';
import { SearchSortContext } from '../../../context/SearchSortContext';
import {
  ChangeSearchPayload,
  PageSearchSortContextActionType,
} from '../../../context/types';
import parseUrlFilterSearch from '../../../hooks/parseUrlFilterSearch';
import useUrlFilterSearch from '../../../hooks/useUrlFilterSearch';
import { UrlParam } from '../../../redux/types/filter';
import { selectAgentsFilters } from '../../../redux/selectors/filter';

const useAgents = (loadAgents: (query: Query) => void) => {
  const urlParse = parseUrlFilterSearch<UrlParam>();
  const { builder: filterByBuilder } = useSelector(selectAgentsFilters);
  const {
    dispatch,
    state: { agents },
  } = useContext(SearchSortContext);
  const {
    currentOrderBy,
    currentOrder,
    currentPage,
    rowsPerPageAmount,
    contextSearch,
  } = agents;

  const [search, setSearch] = useState<string>(
    urlParse.search || contextSearch,
  );
  const [order, setOrder] = useState<Order>(urlParse.order || currentOrder);
  const [orderBy, setOrderBy] = useState<keyof Agent>(
    urlParse.orderBy || currentOrderBy,
  );
  const [page, setPage] = useState<number>(urlParse.page || currentPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    urlParse.limit || rowsPerPageAmount,
  );

  useUrlFilterSearch<UrlParam>({
    search,
    order,
    orderBy,
    page,
    limit: rowsPerPage,
    filterByBuilder,
  });

  const dispatchAgents = (
    type: PageSearchSortContextActionType<'CHANGE_AGENT'>,
    payload?: ChangeSearchPayload<Agent>,
  ) => dispatch({ type, payload: { ...agents, ...payload } });

  useEffect(() => {
    loadAgents({
      search,
      order,
      orderBy,
      page,
      limit: rowsPerPage,
      filterByBuilder,
    });
  }, [order, orderBy, page, rowsPerPage, search, filterByBuilder, loadAgents]);

  return {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    dispatchAgents,
    filterByBuilder,
  };
};

export default useAgents;
