import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import { WithStyles } from '@material-ui/core/styles';
import Tabs from '../CustomTabs';
import ActivityFeedItem from '../ActivityFeedItem';
import newIcon from '../../assets/img/new.png';
import styles from './styles';
import { CustomerVisits } from '../../redux/types/customers';
import SurveyResult from '../../redux/types/survey';
import SurveyInfo from '../SurveyInfo';

enum TabIndex {
  FirstTab,
  SecondTab
}

interface CustomerActivity {
  visits: CustomerVisits[],
  surveyResults: SurveyResult[] | null,
}

type Props = {
  customerActivity: CustomerActivity;
} & WithStyles<typeof styles>;

const CustomerInfoWorkbench: React.FC<Props> = props => {
  const { classes, customerActivity } = props;
  const [tabIndex, setTabIndex] = useState<TabIndex>(0);

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newTabIndex: number,
  ) => {
    setTabIndex(newTabIndex);
  };

  const NewIconComponent: React.FC = () => {
    return (
      <img className={classes.icon} src={newIcon} alt="New" />
    )
  }

  return (
    <Grid className={classes.container}>
      <Grid className={classes.header}>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Activity" />
          {customerActivity.surveyResults && (
            <Tab id="fs-survey-tab" className={classes.tab} label="Survey" icon={<NewIconComponent />} />
          )
          }
        </Tabs>
      </Grid>
      {tabIndex === TabIndex.FirstTab && (
        <>
          <Grid className={classes.titleWrapper}>
            <Typography className={classes.title}>Activity</Typography>
          </Grid>
          <Grid className={classes.content}>
            {customerActivity.visits.map(activity => (
              <ActivityFeedItem key={activity.customer_visit_id} activity={activity} />
            ))}
          </Grid>
        </>
      )}
      {customerActivity.surveyResults
        && tabIndex === TabIndex.SecondTab
        && (
          <Grid className={classes.content}>
            <SurveyInfo results={customerActivity.surveyResults} />
          </Grid>
        )}
    </Grid>
  );
};

export default React.memo(CustomerInfoWorkbench);
