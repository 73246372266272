import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

type Props = {
  component: React.ElementType;
  layout: React.ElementType;
  roles?: string[];
  isAuthorized: boolean;
  userRole: string;
} & RouteProps;

const PrivateRoute: React.FC<Props> = ({
  component: Component,
  layout: Layout,
  roles,
  isAuthorized,
  userRole,
  ...rest
}) => {
  const isAuthenticated = !roles || roles.includes(userRole);

  return (
    <Route
      {...rest}
      exact
      render={(props) => (isAuthorized && isAuthenticated ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      ))}
    />
  );
};

export default React.memo(PrivateRoute);
