import React from 'react';
import { WithStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import styles from './styles';
import HubFormTitle from '../HubFormTitle';
import HubFormControls from '../HubFormControls';

type Props = {
  children: React.ReactNode
  title: {
    title: string
    recordName: string
    icon: JSX.Element
  },
} & WithStyles<typeof styles>;

const HubForm: React.FC<Props> = (props: Props) => {
  const { classes, children, title } = props;
  return (
    <div className={classes.form}>
      <Grid container justify="space-between" direction="column" className={classes.content}>
        <Grid item>
          <Grid item>
            <HubFormTitle {...title} />
          </Grid>
          <Grid container spacing={1}>
            {children}
          </Grid>
        </Grid>
        <Grid item>
          <HubFormControls />
        </Grid>
      </Grid>
    </div>
  );
};

export default HubForm;
