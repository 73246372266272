import React from 'react';
import classnames from 'classnames';
import MatSnackBar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import MatSnackBarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';

import { WithStyles } from '@material-ui/styles';
import styles from './styles';
import { SnackbarType } from '../../redux/types/snackbars';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const snackBarPosition: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

export type SnackBarProps = {
  open: boolean;
  snackbar: SnackbarType;
  index: number;
  onClose: (id: string | number) => void;
} & WithStyles<typeof styles>;

const SnackBar: React.FC<SnackBarProps> = props => {
  const {
    classes, open, snackbar, index, onClose,
  } = props;
  const Icon = variantIcon[snackbar.variant];
  const snackBarLabel = `snackbar-${snackbar.id}`;
  const handleClose = () => {
    onClose(snackbar.id);
  };

  return (
    <MatSnackBar
      open={open}
      autoHideDuration={4000}
      transitionDuration={300}
      anchorOrigin={snackBarPosition}
      style={{ bottom: index * 75 }}
      onClose={handleClose}
    >
      <MatSnackBarContent
        aria-describedby={snackBarLabel}
        className={classes[snackbar.variant]}
        message={(
          <span id={snackBarLabel} className={classes.message}>
            <Icon className={classnames(classes.icon, classes.iconVariant)} />
            {snackbar.message}
          </span>
        )}
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={handleClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </MatSnackBar>
  );
};

export default React.memo(SnackBar);
