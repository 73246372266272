import { 
  MARKET_REPORT_REQUEST, 
  MARKET_REPORT_REQUEST_FAILED, 
  MARKET_REPORT_REQUEST_SUCCESS, 
  MARKET_REPORT_EXPORT_REQUEST,
  MARKET_REPORT_EXPORT_REQUEST_SUCCESS,
  MARKET_REPORT_EXPORT_REQUEST_FAILED,
  MarketReportsRequestSuccess 
} from "../types/reports";
import { Query } from '../../utils/query';
import { ErrorMessage } from '../../types/main';
import { MimeType } from '../types/mime';
export const marketReportRequest = (query?: Query) => ({
  type: MARKET_REPORT_REQUEST,
  payload: query,
} as const);

export const marketReportRequestFailed = (payload: ErrorMessage) => ({
  type: MARKET_REPORT_REQUEST_FAILED,
  payload,
} as const);

export const marketReportRequestSuccess = (payload: MarketReportsRequestSuccess) => ({
  type: MARKET_REPORT_REQUEST_SUCCESS,
  payload,
} as const);

export const marketReportExportRequest = (selected: Array<string | number>, startDate: Date, endDate: Date, mimeType: MimeType = 'text/csv') => ({
  type: MARKET_REPORT_EXPORT_REQUEST,
  payload: {
    selected,
    startDate,
    endDate,
    mimeType,
  },
} as const);

export const marketReportExportSuccess = () => ({
  type: MARKET_REPORT_EXPORT_REQUEST_SUCCESS,
} as const);

export const marketReportExportFailed = (payload: ErrorMessage) => ({
  type: MARKET_REPORT_EXPORT_REQUEST_FAILED,
  payload,
} as const);