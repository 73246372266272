import * as IntegrationsActions from '../actions/integrations';
import { ValueOf } from '../../types/main';

export const INTEGRATIONS_REQUEST = 'INTEGRATIONS_REQUEST';
export const INTEGRATIONS_REQUEST_SUCCESS = 'INTEGRATIONS_REQUEST_SUCCESS';
export const INTEGRATIONS_REQUEST_FAILED = 'INTEGRATIONS_REQUEST_FAILED';
export const GET_INTEGRATIONS_REQUEST = 'GET_INTEGRATIONS_REQUEST';
export const GET_INTEGRATIONS_REQUEST_SUCCESS = 'GET_INTEGRATIONS_REQUEST_SUCCESS';

export interface Integration {
  id: number
  name: string
  description: string
  created_on: string
  modified_on: string
}

export interface IntegrationsState {
  integrations: Integration[]
  total: number
  error: string
  loading: boolean
}

export type IntegrationsActionsTypes = ReturnType<ValueOf<typeof IntegrationsActions>>;
