import { ErrorMessage, ValueOf } from '../../types/main';
import * as ReportActions from '../actions/reports';

export const MARKET_REPORT_REQUEST = 'MARKET_REPORT_REQUEST';
export const MARKET_REPORT_REQUEST_SUCCESS = 'MARKET_REPORT_REQUEST_SUCCESS';
export const MARKET_REPORT_REQUEST_FAILED = 'MARKET_REPORT_REQUEST_FAILED';

export const MARKET_REPORT_EXPORT_REQUEST = 'MARKET_REPORT_EXPORT_REQUEST';
export const MARKET_REPORT_EXPORT_REQUEST_SUCCESS = 'MARKET_REPORT_EXPORT_REQUEST_SUCCESS';
export const MARKET_REPORT_EXPORT_REQUEST_FAILED = 'MARKET_REPORT_EXPORT_REQUEST_FAILED';

export type ReportsActionsTypes = ReturnType<ValueOf<typeof ReportActions>>;

export type MarketReportExportRequestActionType =
  | ReturnType<typeof ReportActions.marketReportExportRequest>;

export interface MarketReportsRequestSuccess {
  marketReport: any[]
  total: number
}

export interface ReportsState {
  marketReport: any[]
  total: number
  loading: boolean
  error: ErrorMessage['message']
}

export interface MarketReport {
  lotNumber: string
  communityName: string
  daysBeforeSale: number
  marketingName: string
  propertyId: number
  saleDate: Date
  address: string
  visits: number
}