const filterEmptyFields = (obj: any) => {
  const filtredObj: any = {};
  if (obj) {
    Object.entries(obj).forEach(([k, v]) => {
      if (v !== '') {
        filtredObj[k] = v;
      }
    });
  }
  return filtredObj;
};
export default filterEmptyFields;
