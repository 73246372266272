import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  headerText: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px',
  },

  inputForm: {
    borderTop: '1px solid rgba(142, 139, 153, 0.25)',
    borderBottom: '1px solid rgba(142, 139, 153, 0.25)',
    padding: '74px 10% 136px 10%',
  },

  slider: {
    color: '#DB5C0E',
  },

  navButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '44px 10% 60px 10%',

    '& button:first-child': {
      marginRight: '15px',
    },
  },

  formControl: {
    width: '100%',
    backgroundColor: 'rgba(219, 92, 14, 0.08)',
    padding: '12px 20px',
    marginBottom: '44px',
    border: 'none',
    borderRadius: '10px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '1.5',
    color: '#040620',

    '& label > span': {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '1.5',
      color: '#040620',
    },

    '& p': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '1.57',
      color: '#040620',
      marginLeft: '32px',
    },
  },

  disabled: {
    filter: 'grayscale(1)',
    opacity: '0.5',
    pointerEvents: 'none',
  },
});
