import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import AgentForm from './AgentForm';
import styles from './styles';
import {
  addAgentRequest,
  agentDetailsRequest,
  updateAgentRequest,
  resetSuggestedUsername,
  resendEmailRequest,
} from '../../redux/actions/agents';
import {
  selectAgentsRequestStatus,
  selectAgentSelected,
  selectSuggestedUsername,
  selectEmailResendStatus,
} from '../../redux/selectors/agents';
import { createErrorSnackBar } from '../../redux/actions/snackbars';

import { AppState } from '../../redux/store';
import { getBuilderId, getEnterpriseId, selectEnterpriseStatus } from '../../redux/selectors/auth';
import { selectBuilders, selectBuildersRequestStatus } from '../../redux/selectors/builders';
import { enterpriseBuildersRequest } from '../../redux/actions/builders';

const mapStateToProps = (state: AppState) => ({
  loading: selectAgentsRequestStatus(state),
  agentDetails: selectAgentSelected(state),
  suggestedUsername: selectSuggestedUsername(state),
  loadingBtnEmailResend: selectEmailResendStatus(state),
  builderId: getBuilderId(state),
  enterpriseId: getEnterpriseId(state),
  isEnterprise: selectEnterpriseStatus(state.auth),
  enterpriseBuilders: selectBuilders(state),
  loadingEnterpriseBuilders: selectBuildersRequestStatus(state),


});

const mapDispatchToProps = {
  loadAgentDetails: agentDetailsRequest,
  addAgent: addAgentRequest,
  resendEmail: resendEmailRequest,
  updateAgentById: updateAgentRequest,
  resetSuggestedUsername,
  createErrorSnackBar,
  loadEnterpriseBuilders: enterpriseBuildersRequest,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AgentForm),
);
