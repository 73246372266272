/* eslint-disable no-nested-ternary */
import React from 'react';
import { WithStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import { SimpleTableHeaderProp } from '../SimpleTable/SimpleTable';
import { formatUnixDate } from '../../utils/unix-date';
import { Invoice } from '../../redux/types/billing';

import styles from './styles';

type Props = {
  invoices: Invoice[],
  downloadFakePdf: () => void,
  handleSingleInvoice?: (invoice: Invoice) => void,
} & WithStyles<typeof styles>;

const invoicesHeaders: SimpleTableHeaderProp<Invoice> = [
  { key: 'amount_paid', label: 'AMOUNT' },
  { key: 'id', label: 'INVOICE NUMBER' },
  { key: 'customer_id', label: 'CUSTOMER' },
  { key: 'status', label: 'DUE' },
  { key: 'date', label: 'CREATED', date: true },
];

const InvoicesTable: React.FC<Props> = (props) => {
  const {
    invoices, classes, downloadFakePdf, handleSingleInvoice,
  } = props;

  const onRowClick = (invoice: Invoice) => (
    event: React.MouseEvent<HTMLTableRowElement,
    MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (handleSingleInvoice) {
      handleSingleInvoice(invoice);
    }
  };

  return (
    <div className={classes.invoiceTableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            {invoicesHeaders.map((head, idx: number) => (
              <TableCell
                key={String(head.key)}
                align={idx !== 0 ? 'right' : undefined}
              >
                {head.label}
              </TableCell>
            ))}
            <TableCell>
              PDF
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((item, idx) => (
          // eslint-disable-next-line react/no-array-index-key
            <TableRow className={classes.tableRow} key={idx} hover onClick={onRowClick(item)}>
              {/* eslint-disable-next-line no-shadow */}
              {invoicesHeaders.map(({ key, date }, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell key={idx} align={idx !== 0 ? 'right' : undefined}>
                  {date ? formatUnixDate(Number(item[key]))
                    : key === 'customer_id' && item && item.billing_address
                      ? `${item.billing_address.company}`
                      : item[key]}
                </TableCell>
              ))}
              <TableCell>
                <PictureAsPdf onClick={downloadFakePdf} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default InvoicesTable;
