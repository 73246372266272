import { createStyles } from '@material-ui/core';

export default () => createStyles({
  nternowHeader: {
    fontSize: 18,
    fontWeight: 'normal',
    textTransform: 'capitalize',
    fontFamily: 'Source Sans Pro, Roboto, Helvetica, Arial, sans-serif',

    marginLeft: 10,
    marginBottom: 70,
  },

  nternowSubheader: {
    marginTop: 0,
    marginBottom: 10,
  },
});
