import { ErrorMessage } from '../../types/main';

export const APP_INIT = 'APP_INIT';
export const APP_INIT_SUCCESS = 'APP_INIT_SUCCESS';
export const APP_INIT_FAILED = 'APP_INIT_FAILED';

export interface AppInitState {
  initialized: boolean
  error: ErrorMessage['message']
}
