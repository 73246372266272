import { HubLockDevicePropertyUserCodes, HubLockUserCodeUpdate } from '../../redux/types/hubs';

export const getHubLockUserCodesForUpdate = (userCodes: HubLockDevicePropertyUserCodes): HubLockUserCodeUpdate[] => {
  const arr: HubLockUserCodeUpdate[] = [];
  for (const [key, value] of Object.entries(userCodes)) {
    arr.push({ ...value, key });
  }
  return arr;
};

export const getFreeLockCodeKey = (lockCodes: HubLockUserCodeUpdate[]): string => {
  const reservedKeys = ['6', '7', '8', '9', '10'];
  const keys = lockCodes.map((c) => c.key);
  let key = 2;

  while (keys.includes(key.toString()) || reservedKeys.includes(key.toString())) {
    key += 1;
  }

  return key.toString();
};
