import React, { useState, useEffect, useCallback } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Spinner from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import SearchBar from '../SearchBar';
import Table from '../../components/CustomTable';
import useAgents from './hooks/useAgents.hook';
import ConfirmDialog from '../../components/ConfirmDialog';

import styles from './styles';
import { HeaderRowConfig } from '../../components/CustomTable/CustomTable';
import { Agent } from '../../redux/types/agents';
import { Order } from '../../utils/table';
import { Query } from '../../utils/query';
import { ROWS_PER_PAGE_OPTIONS } from '../../constants/table';
import TablePagination from '../../components/CustomTablePagination';

type Props = {
  agents: Agent[]
  agentsTotal: number
  deleteAgents: (selection: string[]) => void
  loading: boolean
  loadAgents: (query: Query) => void
} & WithStyles<typeof styles> & RouteComponentProps;

const searchBarPlaceholder: string = 'Search all agents';

const headRows: HeaderRowConfig<Agent>[] = [
  {
    key: 'username',
    align: 'right',
    disablePadding: false,
    label: 'User name',
  },
  {
    key: 'firstname',
    align: 'right',
    disablePadding: false,
    label: 'First Name',
  },
  {
    key: 'lastname',
    align: 'right',
    disablePadding: false,
    label: 'Last Name',
  },
  {
    key: 'userType',
    align: 'right',
    disablePadding: false,
    label: 'User Type',
  },
  {
    key: 'builder',
    align: 'right',
    disablePadding: false,
    label: 'Builder',
  },
  {
    key: 'email',
    align: 'right',
    disablePadding: false,
    label: 'Email',
  },
  {
    key: 'phone',
    align: 'right',
    disablePadding: false,
    label: 'Cell',
  },

];

const Agents: React.FC<Props> = (props) => {
  const {
    classes,
    agents,
    agentsTotal,
    loading,
    deleteAgents,
    history,
    match,
    loadAgents,
  } = props;

  const [selected, setSelected] = useState<Agent['username'][]>([]);
  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);

  const selectedLength = selected.length;

  const {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    dispatchAgents,
  } = useAgents(loadAgents);
  const handleChangeOrder = (newOrder: Order, newOrderBy: keyof Agent) => {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    setPage(0);
    dispatchAgents('CHANGE_AGENT', { currentOrder: newOrder, currentOrderBy: newOrderBy, currentPage: 0 });
  };

  function handleChangePage(newPage: number) {
    setPage(newPage);
    // saveLocalState({ page: newPage });
    dispatchAgents('CHANGE_AGENT', { currentPage: newPage });
  }
  function handleSelect(newSelected: any[]) {
    setSelected(newSelected);
  }
  const handleAgentsDelete = useCallback(() => {
    deleteAgents(selected);
    setSelected([]);
  }, [deleteAgents, selected]);

  const handleAgentSelect = (agent: Agent) => {
    history.push(`${match.url}/${agent.username}`);
  };

  function handleChangeRowsPerPage(rowsPerPageAmount: number) {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
    dispatchAgents('CHANGE_AGENT', { currentPage: 0, rowsPerPageAmount });
  }

  const handleSearch = (value: string): void => {
    setSearch(value);
    setPage(0);
    dispatchAgents('CHANGE_AGENT', { contextSearch: value, currentPage: 0 });
  };

  const searchRequest = (): void => {
    dispatchAgents('CHANGE_AGENT');
  };
  useEffect(() => () => {
    const pageNameIndex = 1;
    const pathElements = history.location.pathname.split('/');
    const pathname = pathElements[pageNameIndex];
    if (pathElements.length === 3) {
      return;
    }
    if ((pathname !== 'agents')) {
      dispatchAgents('RESET_STATE');
    }
  }, [dispatchAgents, history.location.pathname]);

  const showConfirm = useCallback(() => {
    setOpenConfirm(true);
  }, []);

  const confirmDelete = useCallback(() => {
    handleAgentsDelete();
    setOpenConfirm(false);
  }, [handleAgentsDelete]);

  const closeConfirm = useCallback((): void => {
    setOpenConfirm(false);
  }, []);

  return (
    <>
      <ConfirmDialog
        isOpen={openConfirm}
        onClose={closeConfirm}
        onDelete={confirmDelete}
      />
      <div style={{ marginBottom: '25px' }}>
        <Button
          variant="contained"
          className={classes.controlButton}
          component={Link}
          to="/agents/add"
        >
          <AddIcon />
          Add Staff
        </Button>
      </div>
      <Grid container>
        <Grid item xs>
          <SearchBar
            defaultValue={search}
            placeholder={searchBarPlaceholder}
            onChange={handleSearch}
            searchRequest={searchRequest}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <div>
              <Typography variant="h5">
                Staff
                {selectedLength > 0 ? ` ${selectedLength} selected.` : ''}
              </Typography>
            </div>
            <div className={classes.spacer} />
            {selectedLength > 0 && (
              <div className={classes.toolbarActions}>
                <span>Selected actions: </span>
                <Button size="small" onClick={showConfirm}>
                  Delete
                </Button>
              </div>
            )}
          </div>
          <div className={classes.spacer} />
        </Toolbar>
        <div className={classes.tableWrapper}>
          {loading && (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
          <Table<Agent>
            hover
            className={classes.table}
            IDKey="username"
            data={agents}
            selected={selected}
            onSelect={handleSelect}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            header={headRows}
            onChangeOrder={handleChangeOrder}
            onRowClick={handleAgentSelect}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={agents.length}
          rowsTotal={agentsTotal}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default React.memo(Agents);
