/* eslint-disable arrow-parens */
import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import SearchBar from '../SearchBar';
import useStyles from './styles';
import { Community } from '../../redux/types/communities';
import { Order } from '../../utils/table';
import CommunityTable from './CommunitiesTable';
import { ROWS_PER_PAGE_OPTIONS } from '../../constants/table';
import { Query } from '../../utils/query';
import TablePagination from '../../components/CustomTablePagination';
// import { COMMUNITIES } from '../../constants/sortState';
import useCommunities from './hooks/useCommunities.hook';
import CustomButton from '../../components/CustomButton';
import { ExportAllParams } from '../../redux/types/exportAll';
import { MimeType } from '../../redux/types/mime';
import { selectCommunityFilters } from '../../redux/selectors/filter';

type Props = {
  communities: Community[];
  loadCommunities: (query: Query & { builderId: number }) => void;
  exportCommunities: (selection: Array<number | string>) => void;
  printCommunities: (selection: Array<number | string>) => void;
  // communitiesDelete: (selection: Array<number | string>) => void;
  communitiesDeletedReset: () => void;
  exportAllRequest: (
    path: string,
    mimeType: MimeType,
    params: ExportAllParams
  ) => void;
  deleted: Array<string | number>;
  total: number;
  builderId: number;
} & RouteComponentProps;

const searchBarPlaceholder: string = 'Search all communities';

const Communities: React.FC<Props> = props => {
  const {
    match,
    history,
    loadCommunities,
    communities,
    total,
    exportCommunities,
    printCommunities,
    // communitiesDelete,
    communitiesDeletedReset,
    exportAllRequest,
    builderId,
    deleted,
  } = props;
  const classes = useStyles();
  const { builder } = useSelector(selectCommunityFilters);

  const [selected, setSelected] = useState<Community['id'][]>([]);
  const [builderCommunities, setCommunities] = useState<Community[]>(
    communities,
  );
  const [isShowAll, setIsShowAll] = useState(false);

  const {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    // saveLocalState,
    dispatchCommunities,
    setShowAll,
  } = useCommunities(loadCommunities, builder ?? builderId);

  const selectedLength = selected.length || '';
  const handleChangeOrder = (newOrder: Order, newOrderBy: keyof Community) => {
    /* saveLocalState({
      order: newOrder,
      orderBy: newOrderBy,
    }); */
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    setPage(0);
    dispatchCommunities('CHANGE_COMMUNITY', {
      currentOrder: newOrder,
      currentOrderBy: newOrderBy,
      currentPage: 0,
    });
  };

  const handleClick = (event: React.SyntheticEvent, id: Community['id']) => {
    event.preventDefault();

    const selectedIndex = selected.indexOf(id);
    let newSelected: Community['id'][] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = communities && communities.map((community: Community) => community.id);
      setSelected(newSelected ?? []);
      return;
    }
    setSelected([]);
  };

  const handleCommunitySelect = (id: number) => {
    history.push(`${match.url}/${id}`);
  };

  function handleChangePage(newPage: number) {
    setPage(newPage);
    // saveLocalState({ page: newPage });
    dispatchCommunities('CHANGE_COMMUNITY', { currentPage: newPage });
  }

  function handleChangeRowsPerPage(rowsPerPageAmount: number) {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
    // saveLocalState({ page: 0, rowsPerPageAmount });
    dispatchCommunities('CHANGE_COMMUNITY', {
      currentPage: 0,
      rowsPerPageAmount,
    });
  }

  function handleCommunitiesExport() {
    if (selectedLength !== 0) {
      exportCommunities(selected);
    }
  }

  function handleCommunitiesPrint() {
    if (selectedLength !== 0) {
      printCommunities(selected);
    }
  }

  // const handleCommunitiesDelete = () => {
  //   communitiesDelete(selected);
  //   setSelected([]);
  // };

  const handleExportAll = () => {
    exportAllRequest('communities', 'text/csv', { builderId });
  };

  const handleSearch = (value: string) => {
    // saveLocalState({ search: value });
    setPage(0);
    setSearch(value);
    dispatchCommunities('CHANGE_COMMUNITY', {
      contextSearch: value,
      currentPage: 0,
    });
  };

  const searchResuest = () => {
    dispatchCommunities('CHANGE_COMMUNITY');
  };

  const handleShowAll = () => {
    setIsShowAll(!isShowAll);
    setShowAll(!isShowAll);
    setSelected([]);
  };

  useEffect(() => {
    setCommunities(communities);
  }, [communities, setCommunities]);

  useEffect(() => {
    if (deleted.length) {
      setCommunities(
        builderCommunities.filter(
          (comm: Community) => !deleted.includes(comm.id),
        ),
      );
      communitiesDeletedReset();
    }
  }, [deleted, builderCommunities, communitiesDeletedReset]);

  useEffect(
    () => () => {
      const pageNameIndex = 1;
      const pathElements = history.location.pathname.split('/');
      const pathname = pathElements[pageNameIndex];
      if (pathElements.length === 3) {
        return;
      }
      if (pathname !== 'communities') {
        dispatchCommunities('RESET_STATE');
      }
    },
    [dispatchCommunities, history.location.pathname],
  );

  return (
    <>
      <Grid container spacing={8}>
        <Grid item className={classes.controlButton}>
          <CustomButton
            variant="orange"
            color="primary"
            onClick={() => history.push(`${match.path}/add`)}
          >
            Add Community
          </CustomButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <SearchBar
            /* listName={COMMUNITIES} */ defaultValue={search}
            placeholder={searchBarPlaceholder}
            onChange={handleSearch}
            searchRequest={searchResuest}
          />
        </Grid>
      </Grid>

      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <Typography variant="h5">
              Communities
              {selectedLength > 0 ? ` ${selectedLength} selected.` : ''}
            </Typography>
          </div>
          <div className={classes.spacer} />
          <Button
            style={{ marginRight: 20 }}
            id="show-active-communities"
            size="small"
            onClick={handleShowAll}
          >
            {`${isShowAll ? 'Hide' : 'Show'} All`}
          </Button>
          {selectedLength && (
            <div className={classes.toolbarActions}>
              <span>Selected actions: </span>
              {/* <Button size="small" onClick={handleCommunitiesDelete}>
                Delete
              </Button> */}
              <Button
                id="fs-export-community"
                size="small"
                disabled={!selectedLength}
                onClick={handleCommunitiesExport}
              >
                Export
              </Button>
              <Button
                size="small"
                disabled={!selectedLength}
                onClick={handleCommunitiesPrint}
              >
                Print
              </Button>
            </div>
          )}
          <Button
            id="fs-export-communities"
            size="small"
            onClick={handleExportAll}
          >
            Export All
          </Button>
        </Toolbar>
        <CommunityTable
          selected={selected}
          communities={builderCommunities}
          order={order}
          orderBy={orderBy}
          rowsPerPage={rowsPerPage}
          onChangeOrder={handleChangeOrder}
          onSelect={handleClick}
          onSelectAll={handleSelectAllClick}
          onRowClick={handleCommunitySelect}
        />
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={communities.length}
          rowsTotal={total}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default Communities;
