import { Theme, createStyles, makeStyles } from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  contentWrapper: {
    marginTop: '10px',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  tableWrapper: {
    overflowX: 'auto',
    position: 'relative'
  },
  table: {
    minWidth: 1230,
    '& tr': {
      '&:hover > td': {
        color: theme.appPalette.orange,
        cursor: 'pointer',
      },
    },
  },
  spacer: {
    flexGrow: 1
  },
  controlButton: {
    marginBottom: '25px',
  },
    
  toolbarActions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
      color: theme.appPalette.orange,
      textTransform: 'capitalize',
    },
  }
  
});

const useStyles = makeStyles(styles);

export default useStyles;