import { createSelector } from 'reselect';
import { AppState } from '../store';
import { AgentsState } from '../types/agents';

const selectAgentsState = (state: AppState) => state.agents;
const selectItems = (state: AgentsState) => state.items;
const selectAvailableAgentsState = (state: AgentsState) => state.availableAgents;
const selectTotal = (state: AgentsState) => state.total;
const selectLoading = (state: AgentsState) => state.loading;
const selectLoadingBtnResendEmail = (state: AgentsState) => state.loadingBtnEmailResend;
const selectAgent = (state: AgentsState) => state.selected;
const selectSuggested = (state: AgentsState) => state.suggestedUsername;

export const selectAgents = createSelector(
  selectAgentsState,
  selectItems,
);

export const selectAvailableAgents = createSelector(
  selectAgentsState,
  selectAvailableAgentsState,
);

export const selectAgentSelected = createSelector(
  selectAgentsState,
  selectAgent,
);

export const selectTotalAgents = createSelector(
  selectAgentsState,
  selectTotal,
);

export const selectAgentsRequestStatus = createSelector(
  selectAgentsState,
  selectLoading,
);

export const selectEmailResendStatus = createSelector(
  selectAgentsState,
  selectLoadingBtnResendEmail,
);

export const selectSuggestedUsername = createSelector(
  selectAgentsState,
  selectSuggested,
);
