import React from 'react';
import {
  Typography, Box, WithStyles,
} from '@material-ui/core';

import styles from './styles';

type Props = {
  lockStatusValue: string;
} & WithStyles<typeof styles>;

const LockStatus: React.FC<Props> = (props) => {
  const { lockStatusValue, classes } = props;
  const lockStatusDown = 'Down';

  return (
    <Typography className={classes.secondaryText}>
      Lock status:
        &nbsp;
      {lockStatusValue === lockStatusDown
        ? (
          <Box component="span" display="inline" fontWeight="fontWeightBold" color="error.main">
            {lockStatusValue}
          </Box>
        )
        : (
          <>
            {lockStatusValue}
          </>
        )}

    </Typography>
  );
};

export default React.memo(LockStatus);
