import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  batteryIcon: {
    width: 54,
    height: 22,
    color: '#04A777',
  },
  batteryCounter: {
    marginRight: 4,
    fontSize: 16,
    fontWeight: 600,
    color: '#04A777',
  },
});
