import { connect } from 'react-redux';
import CommunityAddForm from './CommunityAddForm';
import {
  saveCommunity,
  cancelSuccessfullySave,
} from '../../redux/actions/communities';
import { AppState } from '../../redux/store';
import {
  selectSavedStatus,
} from '../../redux/selectors/communities';
import {
  selectIsPM, getBuilderId, selectEnterpriseStatus, getEnterpriseId,
  getBuilderName,
} from '../../redux/selectors/auth';
import { selectTeams } from '../../redux/selectors/teams';
import { teamsByBuilderRequest, teamsRequest } from '../../redux/actions/teams';
import { availableAgentsRequest } from '../../redux/actions/agents';
import { selectBuilders } from '../../redux/selectors/builders';
import { enterpriseBuildersRequest } from '../../redux/actions/builders';

const mapStateToProps = (state: AppState) => ({
  successfullySaved: selectSavedStatus(state),
  isPM: selectIsPM(state),
  builderName: getBuilderName(state),
  builderId: getBuilderId(state),
  teams: selectTeams(state),
  isEnterprise: selectEnterpriseStatus(state.auth),
  enterpriseId: getEnterpriseId(state),
  enterpriseBuilders: selectBuilders(state),
});

const mapDispatchToProps = {
  communitySave: saveCommunity,
  cancelSuccessfullySave,
  loadTeams: teamsRequest,
  loadTeamsByBuilder: teamsByBuilderRequest,
  loadEnterpriseBuilders: enterpriseBuildersRequest,
  loadAvailableAgents: availableAgentsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunityAddForm);
