import {
  all, takeEvery, put, call, takeLatest,
} from 'redux-saga/effects';
// import FileSave from 'js-file-download';
import {
  allInvoicesSuccess,
  allInvoicesFailed,
  invoiceDetailsSuccess,
  invoiceDetailsFailed,
} from '../actions/billing';
import {
  BILLING_REQUEST,
  PAID_INVOICES,
  DOWNLOAD_FAKE_PDF,
  INVOICE_DETAILS,
} from '../types/billing';
import {
  getAllInvoices,
  getFakePdf,
  getInvoiceById,
} from '../../services/billing';

function* allInvoicesSaga() {
  try {
    const { data } = yield call(getAllInvoices);

    yield put(allInvoicesSuccess(data));
  } catch (error) {
    yield put(allInvoicesFailed(error));
  }
}

function* getInvoiceDeatailsSaga(action: {type: 'INVOICE_DETAILS', payload: {invoiceId: string}}) {
  const { invoiceId } = action.payload;

  try {
    const { data } = yield call(getInvoiceById, invoiceId);

    yield put(invoiceDetailsSuccess(data));
  } catch (error) {
    yield put(invoiceDetailsFailed(error));
  }
}

function* downloadPdf() {
  const { data } = yield call(getFakePdf);
  const byteCharacters = atob(data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const pdf = new Blob([byteArray], { type: 'application/pdf' });
  const fileURL = URL.createObjectURL(pdf);

  // Open the URL on new Window
  window.open(fileURL);
}

const billingSaga = all([
  takeEvery<any>(BILLING_REQUEST, allInvoicesSaga),
  takeEvery<any>(PAID_INVOICES, allInvoicesSaga),
  takeEvery<any>(DOWNLOAD_FAKE_PDF, downloadPdf),
  takeLatest<any>(INVOICE_DETAILS, getInvoiceDeatailsSaga),
]);

export default billingSaga;
