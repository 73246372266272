import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const LockIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 50 50" {...props}>
    <path d="M24.9998 11.2816C17.3951 11.2816 11.209 17.4352 11.209 25C11.209 29.9967 14.0463 34.7473 18.558 37.0528V39.4897C18.558 40.2609 19.0694 40.7696 19.8447 40.7696C20.6201 40.7696 21.1315 40.2609 21.1315 39.4897V36.2816C21.1315 35.7729 20.8758 35.3873 20.3561 35.1247C16.364 33.3279 13.7824 29.3567 13.7824 25C13.7824 18.8464 18.8055 13.8497 24.9916 13.8497C31.1776 13.8497 36.2007 18.8464 36.2007 25C36.2007 29.3567 33.6273 33.3361 29.627 35.1247C29.1239 35.256 28.8682 35.7647 28.8682 36.2816V40.9009L19.5891 42.9521C18.9457 43.0834 18.4261 43.8464 18.558 44.4946C18.69 45.1346 19.2014 45.5202 19.8447 45.5202H20.1004L30.4106 43.2146H30.5425C30.6745 43.2146 30.6745 43.2146 30.7982 43.0834C30.9302 43.0834 30.9302 42.9521 31.0539 42.9521L31.1859 42.8208C31.1859 42.8208 31.3179 42.6895 31.3179 42.5665C31.3179 42.4434 31.4498 42.4352 31.4498 42.3121V42.0578V41.9265V37.0528C35.9615 34.7473 38.7989 30.0049 38.7989 25C38.7906 17.4352 32.6046 11.2816 24.9998 11.2816Z" />
    <path d="M32.9925 22.6944C33.5039 22.1857 33.6358 21.4145 33.1244 20.8976C32.6131 20.3807 31.8377 20.2576 31.3181 20.7663L23.3257 27.6912L20.1007 24.36C19.5893 23.8513 18.814 23.8513 18.2944 24.36C17.7747 24.8687 17.783 25.64 18.2944 26.1569L22.4184 30.3905C22.6741 30.6449 23.0618 30.7762 23.3174 30.7762C23.5731 30.7762 23.9608 30.6449 24.2165 30.5218L32.9925 22.6944Z" />
    <path d="M29.8994 45.1264L19.5892 47.4319C18.9459 47.5632 18.4263 48.3262 18.5582 48.9744C18.6902 49.6144 19.2016 50 19.8449 50H20.1006L30.4108 47.6945C31.0541 47.5632 31.5737 46.8001 31.4418 46.152C31.318 45.3807 30.5427 44.9951 29.8994 45.1264Z" />
    <path d="M25.0001 8.45914C25.7754 8.45914 26.2868 7.95044 26.2868 7.17919V1.27995C26.2868 0.508697 25.7754 0 25.0001 0C24.2248 0 23.7134 0.508697 23.7134 1.27995V7.17919C23.7134 7.95044 24.2248 8.45914 25.0001 8.45914Z" />
    <path d="M11.473 13.3328C11.7287 13.5871 12.1164 13.7184 12.3721 13.7184C12.6278 13.7184 13.0154 13.5871 13.2711 13.3328C13.7825 12.8241 13.7825 12.0529 13.2711 11.536L9.14707 7.43356C8.63569 6.92486 7.86037 6.92486 7.34074 7.43356C6.82111 7.94225 6.82936 8.7135 7.34074 9.2304L11.473 13.3328Z" />
    <path d="M7.21709 23.7201H1.2867C0.511382 23.7201 0 24.2288 0 25C0 25.7713 0.511382 26.28 1.2867 26.28H7.21709C7.99241 26.28 8.50379 25.7713 8.50379 25C8.50379 24.2288 7.86044 23.7201 7.21709 23.7201Z" />
    <path d="M48.7132 23.7201H42.7828C42.0075 23.7201 41.4961 24.2288 41.4961 25C41.4961 25.7713 42.0075 26.28 42.7828 26.28H48.7132C49.4885 26.28 49.9999 25.7713 49.9999 25C49.9999 24.2288 49.3565 23.7201 48.7132 23.7201Z" />
    <path d="M37.6277 13.7184C38.0154 13.7184 38.2711 13.5871 38.5268 13.3328L42.6508 9.2304C43.1622 8.72171 43.1622 7.95046 42.6508 7.43356C42.1395 6.91665 41.3641 6.92486 40.8445 7.43356L36.7205 11.536C36.2091 12.0446 36.2091 12.8159 36.7205 13.3328C36.9844 13.5871 37.2483 13.7184 37.6277 13.7184Z" />
  </SvgIcon>
);

export default React.memo(LockIcon);
