import { 
  MARKET_REPORT_REQUEST, 
  MARKET_REPORT_REQUEST_FAILED, 
  MARKET_REPORT_REQUEST_SUCCESS, 
  ReportsActionsTypes, 
  ReportsState 
} from '../types/reports';

const initialState: ReportsState = {
  marketReport: [],
  total: 0,
  error: '',
  loading: false,
};

export default function ReportsReducer(
  state = initialState,
  action: ReportsActionsTypes,
): any {
  switch (action.type) {
    case MARKET_REPORT_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case MARKET_REPORT_REQUEST_SUCCESS: {
      return {
        ...state,
        marketReport: action.payload,
        total: action.payload.total,
        error: '',
        loading: false,
      };
    }

    case MARKET_REPORT_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
