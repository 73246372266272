import {
  STATES_REQUEST,
  STATES_REQUEST_FAILED,
  STATES_REQUEST_SUCCESS,
  StatesActionsTypes,
  StatesState,
} from '../../types/states';

const initialState: StatesState = {
  items: [],
  error: '',
  loading: false,
};

export default function StatesReducer(
  state = initialState,
  action: StatesActionsTypes,
): StatesState {
  switch (action.type) {
    case STATES_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case STATES_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        error: '',
        loading: false,
      };
    }

    case STATES_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
