import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  contentWrapper: {
    marginTop: '33px',
    boxShadow: theme.shadows[3],
    backgroundColor: '#fff',
    borderRadius: 10,
  },
  tableWrapper: {
    overflowX: 'auto',
    position: 'relative',
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  spacer: {
    flexGrow: 1,
  },
  toolbarActions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
      textTransform: 'capitalize',
    },
  },
  table: {
    minWidth: 1230,
    '& tr': {
      '&:hover > td': {
        color: theme.appPalette.orange,
        cursor: 'pointer',
      },
    },
  },
  controlButton: {
    backgroundColor: theme.appPalette.orange,
    color: '#fff',
    '&:hover': {
      color: theme.appPalette.orange,
    },
  },
  controlButtonPlace: {
    marginBottom: '25px',

  }
});

const useStyles = makeStyles(styles);

export default useStyles;