import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import HomeIcon from '@material-ui/icons/Home';
import { WithStyles } from '@material-ui/styles';
import styles from './styles';

type Props = { alignItems: 'center' | 'flex-start' } & WithStyles<typeof styles>;

const TimeLineItem: React.FC<Props> = (props) => {
  const { children, classes, alignItems } = props;
  return (
    <ListItem disableGutters component="li" alignItems={alignItems}>
      <ListAvatar>
        <Avatar component="div" className={classes.avatar}>
          <HomeIcon className={classes.icon} />
        </Avatar>
      </ListAvatar>
      {children}
    </ListItem>
  );
};

export default React.memo(TimeLineItem);
