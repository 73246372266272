import { createSelector } from 'reselect';
import { AppState } from '../store';
import { SurveyState } from '../types/survey';

const selectSurveyState = (state: AppState) => state.survey;
const selectQuestions = (state: SurveyState) => state.questions;
const selectLoading = (state: SurveyState) => state.loading;
const selectUpdating = (state: SurveyState) => state.updating;

export const selectSurveyQuestions = createSelector(
  selectSurveyState,
  selectQuestions,
);

export const selectSurveyRequestStatus = createSelector(
  selectSurveyState,
  selectLoading,
);

export const selectSurveyUpdateStatus = createSelector(
  selectSurveyState,
  selectUpdating,
);
