import React from 'react';
import { withStyles, WithStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import styles from './styles';
import { HubLockLogType } from '../../redux/types/hubs';

type Props = { message: HubLockLogType } & WithStyles<typeof styles>;

const HubLockLogMessage: React.FC<Props> = (props) => {
  const { message, classes } = props;
  const { msg, action } = message;

  const generateMessage = () => {
    switch (action) {
      case 'lock.auto_lock':
        return 'The door automatically locked';
      case 'lock.unlock_with_pin_code':
        return (
          <p>
            {message.visitor ? (
              <Link className={classes.link} to={`/customers/${message.visitor.customer_customer_id}`}>
                {message.visitor.firstname}
                {' '}
                {message.visitor.lastname}
              </Link>
            ) : 'Someone'}
            {' '}
            {' '}
            unlocked the door using
            {' '}
            {message.pinCodeName || 'a pin code'}
          </p>
        );
      case 'lock.lock_with_pin_code':
        return 'Someone locked the door';
      case 'lock.unlock_from_app':
        return 'The door was unlocked using the ezlo app';
      case 'lock.manual_unlock':
        return 'The door was manually unlocked';
      case 'lock.manual_lock':
        return 'The door was manually locked';
      default:
        return msg;
    }
  };

  return (
    <span>
      {generateMessage()}
    </span>
  );
};

export default withStyles(styles)(React.memo(HubLockLogMessage));
