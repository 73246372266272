import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  dropZoneContainer: {
    padding: '20px 20% 50px 20%',
  },

  headerText: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '20px',
    marginBottom: '20px',
  },

  disclosureContainer: {
    textAlign: 'left',
    fontSize: '18px',
    padding: '18px 14px',
    background: '#FFFFFF',
    border: '1px solid #E0E0E0',
    borderRadius: '10px',
    marginTop: '14px',
    marginBottom: '14px',
  },

  disclaimer: {
    fontSize: '18px',
  },

  subtitle: {
    fontSize: '20px',
    fontWeight: 700,
    padding: '10px 0',
  },
  dropZone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: '30px',
    height: '205px',
    background: '#FFFFFF',
    border: `1px dashed ${theme.appPalette.orange}`,
    boxSizing: 'border-box',
    borderRadius: '10px',
    marginBottom: '14px',
  },
  supportText: {
    color: '#8E8B9980',
  },

  disabled: {
    borderColor: '#E0E0E0',
    filter: 'grayscale(1)',
    opacity: '0.5',
    pointerEvents: 'none',
  },
  // progressBar: (props: { color: string }) => ({
  //   marginTop: '20px',
  //   borderRadius: '10px',
  //   overflow: 'hidden',
  //   border: `1px solid ${props.color}`,
  // }),
  progressStatus: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '1.28',
  },
  navButtons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
