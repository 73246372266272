import { Query } from '../../../utils/query';
import {
  HubsState,
  SET_CURRENT_HUB_FORM_NAME,
  SET_HUB_SELECTED_LOCK,
  SET_HUB_SELECTED_LIGHT,
  SET_HUB_SELECTED_MOTION_SENSOR,
  SET_HUB_SELECTED_THERMOSTAT,
  UPDATE_HUB_THERMOSTAT_FAILED,
  UPDATE_HUB_THERMOSTAT_REQUEST,
  HubThermostatInput,
  UPDATE_HUB_THERMOSTAT_SUCCESS,
  GET_HUB_DEVICES_REQUEST,
  GET_HUB_DEVICES_SUCCESS,
  GET_HUB_DEVICES_FAILED,
  GetHubDevicesResponse,
  REFRESH_HUB_DEVICES_REQUEST,
  GET_HUB_INFO_REQUEST,
  GET_HUB_INFO_SUCCESS,
  GET_HUB_INFO_FAILED,
  GetHubInfoResponse,
  Hub,
  GET_HUBS_SUCCESS,
  GET_HUBS_REQUEST,
  HUB_REBOOT_REQUEST,
  HUB_REBOOT_REQUEST_SUCCESS,
  HUB_REBOOT_REQUEST_FAILED,
  GET_HUB_LOCK_LOG_SUCCESS,
  GET_HUB_LOCK_LOG_FAILED,
  GET_HUB_LOCK_LOG_REQUEST,
  HubLockLogType,
} from '../../types/hubs';

export const hubRebootRequest = (payload: string) => ({
  type: HUB_REBOOT_REQUEST,
  payload,
} as const);

export const hubRebootRequestSuccess = () => ({
  type: HUB_REBOOT_REQUEST_SUCCESS,
} as const);

export const hubRebootRequestFailed = () => ({
  type: HUB_REBOOT_REQUEST_FAILED,
} as const);

export const setCurrentHubFormName = (payload: HubsState['currentHubFormName']) => ({
  type: SET_CURRENT_HUB_FORM_NAME,
  payload,
} as const);

export const setHubSelectedLock = (payload: HubsState['selectedLock']) => ({
  type: SET_HUB_SELECTED_LOCK,
  payload,
} as const);

export const setHubSelectedLight = (payload: HubsState['selectedLight']) => ({
  type: SET_HUB_SELECTED_LIGHT,
  payload,
} as const);

export const setHubSelectedThermostat = (payload: HubsState['selectedThermostat']) => ({
  type: SET_HUB_SELECTED_THERMOSTAT,
  payload,
} as const);

export const setHubSelectedMotionSensor = (payload: HubsState['selectedMotionSensor']) => ({
  type: SET_HUB_SELECTED_MOTION_SENSOR,
  payload,
} as const);

export const updateHubThermostatRequest = (payload: HubThermostatInput) => ({
  type: UPDATE_HUB_THERMOSTAT_REQUEST,
  payload,
} as const);

export const updateHubThermostatSuccess = () => ({
  type: UPDATE_HUB_THERMOSTAT_SUCCESS,
} as const);

export const updateHubThermostatFailed = () => ({
  type: UPDATE_HUB_THERMOSTAT_FAILED,
} as const);

export const refreshHubDevicesRequest = (payload: string) => ({
  type: REFRESH_HUB_DEVICES_REQUEST,
  payload,
} as const);

export const getHubDevicesRequest = (payload: string) => ({
  type: GET_HUB_DEVICES_REQUEST,
  payload,
} as const);

export const getHubDevicesSuccess = (payload: GetHubDevicesResponse) => ({
  type: GET_HUB_DEVICES_SUCCESS,
  payload,
} as const);

export const getHubsSuccess = (payload: Hub[]) => ({
  type: GET_HUBS_SUCCESS,
  payload,
} as const);

export const getHubsRequest = (query?: Query) => ({
  type: GET_HUBS_REQUEST,
  payload: query,

} as const);

export const getHubDevicesFailed = () => ({
  type: GET_HUB_DEVICES_FAILED,
} as const);

export const getHubInfoRequest = (payload: string) => ({
  type: GET_HUB_INFO_REQUEST,
  payload,
} as const);

export const getHubInfoSuccess = (payload: GetHubInfoResponse) => ({
  type: GET_HUB_INFO_SUCCESS,
  payload,
} as const);

export const getHubInfoFailed = () => ({
  type: GET_HUB_INFO_FAILED,
} as const);

export const getHubLockLogRequest = (hubId: string, queryObject?: any) => ({
  type: GET_HUB_LOCK_LOG_REQUEST,
  payload: {
    hubId,
    queryObject,
  } as any,
} as const);

export const getHubLockLogSuccess = (payload: HubLockLogType[]) => ({
  type: GET_HUB_LOCK_LOG_SUCCESS,
  payload,
} as const);

export const getHubLockLogFailed = () => ({
  type: GET_HUB_LOCK_LOG_FAILED,
} as const);
