/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WithStyles } from '@material-ui/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Spinner from '@material-ui/core/CircularProgress';
import SearchBar from '../SearchBar';
import TablePagination from '../../components/CustomTablePagination';
import Table from '../../components/CustomTable';
import styles from './styles';
import useAfterTourHours from './hooks/afterTourHours.hook';
import { ROWS_PER_PAGE_OPTIONS } from '../../constants/table';
import { formatToESTDate } from '../../utils/date';
import { HeaderRowConfig } from '../../components/CustomTable/CustomTable';
import { Order } from '../../utils/table';
import { Query } from '../../utils/query';
import { MimeType } from '../../redux/types/mime';
import { VisitsAfterTourHours } from '../../redux/types/afterTourHours';

type Props = {
  loading: boolean;
  exportAllLoading: boolean;
  visitsAfterTourHours: VisitsAfterTourHours[];
  visitsAfterTourHoursTotal: number;
  loadVisitsAfterTourHours: (query?: Query) => void;
  exportVisitsAfterTourHours: (selection: Array<number | string>) => void;
  exportAllRequest: (path: string, mimeType: MimeType) => void;
  printVisitsAfterTourHours: (selection: Array<number | string>) => void;
  clearFilterState: () => void;
} & WithStyles<typeof styles> &
RouteComponentProps;

const searchBarPlaceholder: string = 'Search all after tour hours';

const headRows: HeaderRowConfig<any>[] = [
  {
    key: 'property_id',
    align: 'left',
    disablePadding: false,
    label: 'Property ID',
  },
  {
    key: 'community',
    align: 'left',
    disablePadding: false,
    label: 'Community',
  },
  {
    key: 'tour_hours_window',
    align: 'left',
    disablePadding: true,
    label: 'Tour Hours Time Window ',
  },
  {
    key: 'modified_on',
    align: 'left',
    disablePadding: false,
    label: 'Attempt Time',
    prepareData: ({ modified_on, timezoneAbbr, timezoneValue }) => formatToESTDate(modified_on, timezoneAbbr, timezoneValue),
  },
];

const AfterTourHours: React.FC<Props> = (props) => {
  const {
    classes,
    visitsAfterTourHours,
    visitsAfterTourHoursTotal,
    loadVisitsAfterTourHours,
    loading,
    exportVisitsAfterTourHours,
    printVisitsAfterTourHours,
    history,
    exportAllRequest,
    exportAllLoading,
    clearFilterState,
  } = props;

  const {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    dispatchAfterTourHours,
  } = useAfterTourHours(loadVisitsAfterTourHours);

  const [selected, setSelected] = useState<VisitsAfterTourHours['_id'][]>([]);

  const selectedLength = selected.length;

  useEffect(() => {
    setSelected([]);
  }, [setSelected, page, rowsPerPage]);

  useEffect(
    () => () => {
      const pageNameIndex = 1;
      const pathElements = history.location.pathname.split('/');
      const pathname = pathElements[pageNameIndex];

      if (pathElements.length === 3) {
        return;
      }

      if (pathname !== 'afterTourHours') {
        dispatchAfterTourHours('RESET_STATE');
      }
    },
    [dispatchAfterTourHours, history.location.pathname],
  );

  useEffect(
    () => () => {
      clearFilterState();
    },
    [clearFilterState],
  );

  const handleExport = () => {
    if (selectedLength !== 0) {
      exportVisitsAfterTourHours(selected);
    }
  };

  const handleExportAll = () => {
    exportAllRequest('visits/afterTourHours', 'text/csv');
  };

  const handlePrint = () => {
    if (selectedLength !== 0) {
      printVisitsAfterTourHours(selected);
    }
  };

  const handleChangeOrder = (
    newOrder: Order,
    newOrderBy: keyof VisitsAfterTourHours,
  ) => {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    setPage(0);
    dispatchAfterTourHours('CHANGE_VISITS_AFTER_TOUR_HOURS', {
      currentOrder: newOrder,
      currentOrderBy: newOrderBy,
      currentPage: 0,
    });
  };

  const handleChangePage = (pageNumber: number) => {
    setPage(pageNumber);
    dispatchAfterTourHours('CHANGE_VISITS_AFTER_TOUR_HOURS', {
      currentPage: pageNumber,
    });
  };

  const handleChangeRowsPerPage = (rowsPerPageAmount: number) => {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
    dispatchAfterTourHours('CHANGE_VISITS_AFTER_TOUR_HOURS', {
      currentPage: 0,
      rowsPerPageAmount,
    });
  };

  const handleSearch = (value: string): void => {
    setSearch(value);
    setPage(0);
    dispatchAfterTourHours('CHANGE_VISITS_AFTER_TOUR_HOURS', {
      currentPage: 0,
      contextSearch: value,
    });
  };

  const handleSelect = (newSelected: any[]) => {
    setSelected(newSelected);
  };

  const handleRowClick = (data: VisitsAfterTourHours) => {
    const checked = selected.includes(data._id);

    if (checked) {
      const filteredVisits = selected.filter((id) => id !== data._id);
      setSelected(filteredVisits);

      return;
    }

    setSelected([...selected, data._id]);
  };

  return (
    <>
      <Grid container>
        <Grid item xs>
          <SearchBar
            defaultValue={search}
            placeholder={searchBarPlaceholder}
            onChange={handleSearch}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <Typography variant="h5">
              After Tour Hours
              {selectedLength > 0 ? ` ${selectedLength} selected.` : ''}
            </Typography>
          </div>
          <div className={classes.spacer} />
          {selectedLength > 0 && (
            <div className={classes.toolbarActions}>
              <span>Selected actions: </span>
              <Button
                id="fs-export-visit"
                size="small"
                disabled={selectedLength === 0}
                onClick={handleExport}
              >
                Export
              </Button>
              <Button
                size="small"
                disabled={selectedLength === 0}
                onClick={handlePrint}
              >
                Print
              </Button>
            </div>
          )}
          {exportAllLoading ? (
            <Spinner size={20} />
          ) : (
            <Button
              id="fs-export-visits"
              size="small"
              onClick={handleExportAll}
              disabled={visitsAfterTourHours?.length === 0}
            >
              Export All
            </Button>
          )}
        </Toolbar>
        <div className={classes.tableWrapper}>
          {loading && (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
          <Table<VisitsAfterTourHours>
            className={classes.table}
            IDKey="_id"
            selected={selected}
            data={visitsAfterTourHours}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            header={headRows}
            onSelect={handleSelect}
            onChangeOrder={handleChangeOrder}
            onRowClick={handleRowClick}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={visitsAfterTourHours.length}
          rowsTotal={visitsAfterTourHoursTotal}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default React.memo(AfterTourHours);
