import { createStyles } from '@material-ui/core';

export default () => createStyles({
  invoiceTableContainer: {
    paddingBottom: 30,
  },
  tableRow: {
    cursor: 'pointer',
  },
});
