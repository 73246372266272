import { createStyles } from '@material-ui/core';

export default () => createStyles({
  buttonBase: {
    width: '100%',
  },
  card: {
    borderRadius: 10,
    height: '100%',
    cursor: 'pointer',
  },
  iconWrapper: {
    textAlign: 'center',
    margin: '31px 0',
    lineHeight: 0,
    height: '133px',
  },
  icon: {
    fontSize: 114,
    color: '#DB5C0E',
    maxWidth: '100%',
    height: 'auto',
  },
  primaryText: {
    color: '#DB5C0E',
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: '28px',
  },
  secondaryText: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#979797',
    '& .deviceId': {
      fontWeight: 'bold',
      color: '#000',
    },
  },
});
