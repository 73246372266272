/* eslint-disable import/prefer-default-export */
import {
  BILLING_REQUEST,
  RETRIEVE_INVOICES,
  BILLING_REQUEST_SUCCESS,
  BILLING_REQUEST_FAILED,
  DOWNLOAD_FAKE_PDF,
  INVOICE_DETAILS,
  INVOICE_DETAILS_SUCCESS,
  INVOICE_DETAILS_FAILED,
  Invoice,
} from '../types/billing';
import { ErrorMessage } from '../../types/main';

export const billingRequest = (payload: object) => ({
  type: BILLING_REQUEST,
  payload,
} as const);

export const retrieveInvoices = (payload: {limit: number, page: number, status: string}) => ({
  type: RETRIEVE_INVOICES,
  payload,
} as const);

export const allInvoicesSuccess = (payload: {invoices: Invoice[], total: number}) => ({
  type: BILLING_REQUEST_SUCCESS,
  payload,
} as const);

export const allInvoicesFailed = (payload: ErrorMessage) => ({
  type: BILLING_REQUEST_FAILED,
  payload,
} as const);

export const downloadFakePdf = () => ({
  type: DOWNLOAD_FAKE_PDF,
} as const);

export const getInvoiceDetails = (payload: object) => ({
  type: INVOICE_DETAILS,
  payload,
} as const);

export const invoiceDetailsSuccess = (payload: Invoice) => ({
  type: INVOICE_DETAILS_SUCCESS,
  payload,
} as const);

export const invoiceDetailsFailed = (payload: ErrorMessage) => ({
  type: INVOICE_DETAILS_FAILED,
  payload,
} as const);
