import {
  TeamsState,
  TeamsActionsTypes,
  TEAMS_REQUEST,
  TEAMS_REQUEST_SUCCESS,
  TEAMS_REQUEST_FAILED,
  TEAMS_DELETE_REQUEST,
  TEAMS_DELETE_SUCCESS,
  TEAMS_DELETE_FAILED,
  TEAM_DETAILS_REQUEST_SUCCESS,
  TEAM_DETAILS_REQUEST,
  TEAM_DETAILS_REQUEST_FAILED,
  TEAMS_BY_BUILDER_REQUEST_SUCCESS,
  TEAMS_BY_BUILDER_REQUEST_FAILED,
  TEAMS_BY_BUILDER_REQUEST,
} from '../types/teams';

const initialState: TeamsState = {
  items: [],
  total: 0,
  error: '',
  loading: false,
  updating: false,
  selected: null,
};

export default function TeamsReducer(
  state = initialState,
  action: TeamsActionsTypes,
): TeamsState {
  switch (action.type) {
    case TEAM_DETAILS_REQUEST:
    case TEAMS_BY_BUILDER_REQUEST:
    case TEAMS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case TEAMS_BY_BUILDER_REQUEST_SUCCESS:
    case TEAMS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total,
        error: '',
        loading: false,
      };
    }

    case TEAM_DETAILS_REQUEST_SUCCESS: {
      return {
        ...state,
        error: '',
        selected: action.payload,
        loading: false,
      };
    }

    case TEAM_DETAILS_REQUEST_FAILED:
    case TEAMS_BY_BUILDER_REQUEST_FAILED:
    case TEAMS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case TEAMS_DELETE_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case TEAMS_DELETE_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
      };
    }

    case TEAMS_DELETE_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
