import {
  GET_INTEGRATIONS_REQUEST_SUCCESS,
  IntegrationsState,
  IntegrationsActionsTypes,
} from '../types/integrations';

const initialState: IntegrationsState = {
  integrations: [],
  total: 0,
  error: '',
  loading: false,
};

export default function IntegrationsReducer(
  state = initialState,
  action: IntegrationsActionsTypes,
): IntegrationsState {
  switch (action.type) {
    case GET_INTEGRATIONS_REQUEST_SUCCESS: {
      return {
        ...state,
        integrations: action.payload.integrations,
        error: '',
        loading: true,
      };
    }

    default: {
      return state;
    }
  }
}
