import * as yup from 'yup';

const webhookSchema = yup.object().shape({
  payload_url: yup.string().required('Required field'),
  content_type: yup.string().required('Required field'),
  event_type: yup.string().required('Required field'),
  secret: yup.string(),
});

export default webhookSchema;
