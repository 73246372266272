import {
  HubLight,
  UpdateHubLightInput,
  HubLock,
  HubLockUserCode,
  HubLockDevicePropertyUserCodes,
  HubLockStatus,
  UpdateHubLockCodeInput,
  HubLockCodeSlot,
  UpdateOrCreateHubLockCodeInput,
} from '../redux/types/hubs';

export const updateHubLockStatus = (lock: HubLock, status: HubLockStatus): HubLock => ({
  ...lock,
  properties: { ...lock.properties, status },
});

export const updateHubLockAutoLock = (lock: HubLock, autoLock: boolean): HubLock => ({
  ...lock,
  properties: { ...lock.properties, lockTimeoutEnabled: autoLock },
});

export const updateHubLockCode = (lock: HubLock, { code, slot }: UpdateHubLockCodeInput): HubLock => {
  const { userCodes } = lock.properties;
  const oldCode = userCodes && userCodes[slot];
  const newCode: HubLockUserCode = (oldCode) || { code, name: 'code', mode: 'enabled' };
  const newUserCodes = { ...userCodes, [slot]: { ...newCode, code } };
  return ({
    ...lock,
    properties: { ...lock.properties, userCodes: newUserCodes },
  });
};

export const updateHubLockCodes = (lock: HubLock, { codes }: UpdateOrCreateHubLockCodeInput): HubLock => {
  const newUserCodes: HubLockDevicePropertyUserCodes = {};

  codes.forEach((code) => {
    newUserCodes[code.key] = { code: code.code, name: code.name, mode: 'enabled' };
  });
  return ({
    ...lock,
    properties: { ...lock.properties, userCodes: newUserCodes },
  });
};

export const removeHubLockCode = (lock: HubLock, codeKey: HubLockCodeSlot): HubLock => {
  const { userCodes } = lock.properties;
  const newUserCodes: HubLockDevicePropertyUserCodes = { ...userCodes };

  if (newUserCodes.hasOwnProperty(codeKey)) {
    delete newUserCodes[codeKey];
  }

  return ({
    ...lock,
    properties: { ...lock.properties, userCodes: newUserCodes },
  });
};

export const updateLightsByLightInput = (lights: HubLight[], lightInput: UpdateHubLightInput) => lights.map((light) => {
  const newLight = { ...light };
  if (newLight.id === lightInput.id) {
    newLight.properties.status = lightInput.status;
  }
  return newLight;
});

export const updateSelectedLockByLocks = (selectedLock: HubLock|null, locks: HubLock[]): HubLock|null => {
  const newLock = locks.length ? locks[0] : null;
  return selectedLock ? newLock : null;
};

export const updateSelectedLightByLights = (selectedLight: HubLight|null, lights: HubLight[]): HubLight|null => {
  const newLight = lights.find((light) => selectedLight && (light.id === selectedLight.id));
  return newLight || null;
};
