import { combineReducers } from 'redux';
import catalog from './catalog';
import states from './states';
import appInit from './appInit';

export default combineReducers({
  catalog,
  states,
  appInit,
});
