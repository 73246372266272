import { ErrorMessage, ValueOf } from '../../types/main';
import * as WebhooksActions from '../actions/webhooks';

export const WEBHOOKS_REQUEST = 'WEBHOOKS_REQUEST';
export const WEBHOOKS_REQUEST_SUCCESS = 'WEBHOOKS_REQUEST_SUCCESS';
export const WEBHOOKS_REQUEST_FAILED = 'WEBHOOKS_REQUEST_FAILED';

export const WEBHOOKS_DETAILS_REQUEST = 'WEBHOOKS_DETAILS_REQUEST';
export const WEBHOOKS_DETAILS_REQUEST_SUCCESS = 'WEBHOOKS_DETAILS_REQUEST_SUCCESS';
export const WEBHOOKS_DETAILS_REQUEST_FAILED = 'WEBHOOKS_DETAILS_REQUEST_FAILED';

export const CREATE_WEBHOOKS_REQUEST = 'CREATE_WEBHOOKS_REQUEST';
export const CREATE_WEBHOOKS_SUCCESS = 'CREATE_WEBHOOKS_SUCCESS';
export const CREATE_WEBHOOKS_FAILED = 'CREATE_WEBHOOKS_FAILED';

export const UPDATE_WEBHOOKS_REQUEST = 'UPDATE_WEBHOOKS_REQUEST';
export const UPDATE_WEBHOOKS_SUCCESS = 'UPDATE_WEBHOOKS_SUCCESS';
export const UPDATE_WEBHOOKS_FAILED = 'UPDATE_WEBHOOKS_FAILED';

export const DELETE_WEBHOOKS_REQUEST = 'DELETE_WEBHOOKS_REQUEST';
export const DELETE_WEBHOOKS_SUCCESS = 'DELETE_WEBHOOKS_SUCCESS';
export const DELETE_WEBHOOKS_FAILED = 'DELETE_WEBHOOKS_FAILED';

export type WebhooksActionsTypes = ReturnType<ValueOf<typeof WebhooksActions>>;

export interface WebhooksRequestSuccess {
  items: Webhook[],
  total: number
}

export interface Webhook {
  id: number;
  builder_id: number,
  payload_url: string;
  content_type: string;
  event_type: string;
  secret: string;
  created_on: string;
}

export interface WebhookFormType {
  payload_url: string;
  content_type: string;
  secret: string;
  event_type: string;
}

export interface WebhooksState {
  items: Webhook[];
  loading: boolean;
  updating: boolean;
  total: number;
  error: ErrorMessage['message'];
  selected: Webhook | null
}
