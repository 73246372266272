import React from 'react';
import { WithStyles } from '@material-ui/styles';

import styles from './styles';

type Props = {
  type: string;
} & WithStyles<typeof styles>;

const TypeBadge: React.FC<Props> = ({ classes, type }) => (
  <div className={classes.badgeBase}>
    {type}
  </div>
);

export default React.memo(TypeBadge);
