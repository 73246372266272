/* eslint-disable react/no-array-index-key */
import React, { useMemo, useState, useEffect } from 'react';
import InputMask, { InputState } from 'react-input-mask';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, MenuItem, RadioGroup } from '@material-ui/core';
import { ArrowLeft } from '@material-ui/icons';
import { WithStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import {
  agentValidationSchema,
  username,
} from '../../validation/agentValidationSchema';
import styles from './styles';
import { AgentDetails, AgentRoleType } from '../../redux/types/agents';
import { isCheckable } from '../../utils/helpers';
import { ALL_GROUPS_FOR_AGENT } from '../../utils/constants';
import useAgentDetails from './useAgentDetails.hook';
import { AGENTS } from '../../constants/sortState';
import { clearStorage } from '../../services/storage';
import AgentRole from './AgentRole';
import Notifications from './Notifications';
import './styles.css';
import { Builder } from '../../redux/types/builders';

type Props = {
  addAgent: (agentPayload: Record<string, any>) => void;
  updateAgentById: (payload: Record<string, any>, id: string | number) => void;
  agentDetails: AgentDetails | null;
  loading: boolean;
  loadingBtnEmailResend: boolean;
  enterpriseId: number | null;
  isEnterprise: boolean;
  builderId: number;
  enterpriseBuilders: Builder[];
  loadingEnterpriseBuilders: boolean;
  loadAgentDetails: (username: string | number) => void;
  loadEnterpriseBuilders: (enterpriseId: number) => void;
  suggestedUsername: string;
  resetSuggestedUsername: () => void;
  createErrorSnackBar: (message: string) => void;
  resendEmail: (payload: { username: string; email: string }) => void;
} & WithStyles<typeof styles> &
RouteComponentProps<{ username: string }>;

const AgentForm: React.FC<Props> = (props) => {
  const {
    classes,
    loading,
    addAgent,
    loadingBtnEmailResend,
    match,
    updateAgentById,
    loadAgentDetails,
    agentDetails,
    suggestedUsername,
    enterpriseId,
    isEnterprise,
    builderId,
    enterpriseBuilders,
    loadEnterpriseBuilders,
    loadingEnterpriseBuilders,
    resetSuggestedUsername,
    history,
    createErrorSnackBar,
    resendEmail,
  } = props;

  const [selectedBuilder, setSelectedBuilder] = useState<number | null>(
    builderId,
  );

  useEffect(() => () => {
    const pageNameIndex = 1;
    const newPathName = history.location.pathname.split('/')[pageNameIndex];

    if (newPathName !== 'agents') {
      clearStorage(AGENTS)();
    }
  });

  const formType: 'add' | 'edit' = useMemo(
    () => (!match.params.username || match.params.username.toString() === 'add'
      ? 'add'
      : 'edit'),
    [match.params.username],
  );

  const [inputErrors, setInputErrors] = useState({
    firstname: '',
    username: '',
    phone: '',
    lastname: '',
    email: '',
    notification_email: '',
    pass_word: '',
    confirm_password: '',
  });
  const { agentPayload, setAgentPayload } = useAgentDetails(
    loadAgentDetails,
    formType,
    match.params.username,
    agentDetails,
  );

  const [staffId, setStaffId] = useState<number>(0);

  useEffect(() => {
    if (agentPayload?.roles.id) {
      if ([4].includes(agentPayload?.roles.id)) {
        setStaffId(4);
      } else if ([-1, 2].includes(agentPayload?.roles.id)) {
        setStaffId(2);
      } else if ([1, 3, 5].includes(agentPayload?.roles.id)) {
        setStaffId(1);
      } else if ([7].includes(agentPayload?.roles.id)) {
        setStaffId(7);
      } else if ([8].includes(agentPayload?.roles.id)) {
        setStaffId(8);
      } else {
        setStaffId(3);
      }
    }
  }, [agentPayload]);

  useEffect(() => {
    setAgentPayload((state) => ({
      ...state,
      builder_id: selectedBuilder,
    }));
  }, [setAgentPayload, selectedBuilder]);

  const [showPassword, setShowPassword] = useState<boolean>(
    !!agentPayload.pass_word,
  );
  const setPasswordVisibility = () => {
    const show = !showPassword;

    if (!show) {
      setAgentPayload((state: any) => ({
        ...state,
        pass_word: null,
        confirm_password: null,
      }));
    }

    setShowPassword(show);
  };

  const agentUsername = agentPayload.firstname && agentPayload.lastname
    ? `${agentPayload.firstname}.${agentPayload.lastname}`.toLowerCase()
    : '';

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value, checked } = e.currentTarget;

    if (['firstname', 'lastname'].includes(name)) {
      const regex = /(^[a-zA-Z]+(\s*[a-zA-Z]+)*$)|(^$)/;

      if (!regex.test(value)) {
        return e.preventDefault();
      }

      if (suggestedUsername) {
        resetSuggestedUsername();
      }
    }

    setAgentPayload((state) => ({
      ...state,
      [name]: isCheckable(name) ? Number(checked) : value,
    }));
    setInputErrors((state) => ({ ...state, [name]: '' }));
  }

  useEffect(() => {
    if (agentPayload.send_email === 1 && !agentPayload.notification_email) {
      setAgentPayload({
        ...agentPayload,
        notification_email: agentPayload.email
          ? agentPayload.email
          : 'No email set!',
      });
    }
  }, [agentPayload, setAgentPayload]);

  const [roleValue, setRoleValue] = useState('');

  useEffect(() => {
    const value = ALL_GROUPS_FOR_AGENT.find((role) => role.id === staffId);
    if (value) {
      setRoleValue(value.Label);
    }
  }, [agentPayload.roles.id, staffId]);
  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoleValue((event.target as HTMLInputElement).value);

    if (event.target.value === 'Other Staff') {
      setAgentPayload({
        ...agentPayload,
        roles: ALL_GROUPS_FOR_AGENT.find(
          (group) => group.Label === event.target.value,
        ) || { id: 0, Label: '' },
        send_sms: 0,
        send_email: 0,
      });
    } else {
      setAgentPayload({
        ...agentPayload,
        roles: ALL_GROUPS_FOR_AGENT.find(
          (group) => group.Label === event.target.value,
        ) || { id: 0, Label: '' },
      });
    }
  };
  useEffect(() => {
    setShowPassword(!!agentPayload.pass_word);
  }, [agentPayload]);

  useEffect(() => {
    if (enterpriseId) {
      loadEnterpriseBuilders(enterpriseId);
    }
  }, [loadEnterpriseBuilders, enterpriseId]);

  function handleResendEmail() {
    if (agentDetails?.username) {
      resendEmail({
        username: agentDetails.username,
        email: agentDetails.email,
      });
    }
  }

  function hidePasswordFields() {
    setAgentPayload((state: any) => ({
      ...state,
      pass_word: null,
      confirm_password: null,
    }));

    setShowPassword(false);
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();

    const { error, value } = agentValidationSchema.validate(
      {
        ...agentPayload,
        username:
          formType === 'add'
            ? suggestedUsername || agentUsername
            : agentDetails?.username,
      },
      { abortEarly: false },
    );

    if (!error) {
      if (formType === 'add') {
        addAgent(value);
      } else {
        // @ts-ignore
        updateAgentById(value, agentDetails.username);
      }

      hidePasswordFields();
    }

    if (error && error.isJoi) {
      if (
        error.details[0].message === '"roles.Label" is not allowed to be empty'
      ) {
        createErrorSnackBar('Select at least one role to the agent!');
      } else {
        const { details } = error;
        const errorsEntries = details.map(({ message, path }) => [
          path[0],
          message,
        ]);
        setInputErrors(Object.fromEntries(errorsEntries));
      }
    }
  }

  const handleSelectBuilder = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedBuilderId = Number(e.target.value);
    const selected = enterpriseBuilders.find(
      ({ builder_id }) => builder_id === selectedBuilderId,
    );

    if (selected) {
      setSelectedBuilder(selectedBuilderId);
      setAgentPayload((state) => ({
        ...state,
        builder_id: selectedBuilderId,
      }));
    }
  };

  return (
    <>
      <Grid container justify="space-between" style={{ marginBottom: 25 }}>
        <Grid item>
          <Button variant="contained" onClick={() => history.goBack()}>
            <ArrowLeft />
            {' GoBack'}
          </Button>
        </Grid>
      </Grid>
      <div className={classes.container}>
        <div className={classes.header} />
        <div className={classes.titleWrapper}>
          <Typography className={classes.title}>
            {formType === 'add' ? 'Add Staff' : 'Edit Staff'}
          </Typography>
        </div>
        <div className={classes.content}>
          <div>
            <form noValidate onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    className={classes.username}
                    disabled
                    margin="normal"
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    value={
                      formType === 'edit'
                        ? agentDetails?.username
                        : suggestedUsername || agentUsername
                    }
                    error={Boolean(inputErrors.username)}
                    helperText={inputErrors.username}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                {formType === 'add' && isEnterprise && (
                  <Grid item xs>
                    <TextField
                      select
                      required
                      fullWidth
                      margin="normal"
                      id="builder"
                      name="builder"
                      label="Select Builder"
                      value={enterpriseId && selectedBuilder}
                      onChange={handleSelectBuilder}
                      disabled={!enterpriseId && loadingEnterpriseBuilders}
                    >
                      <MenuItem value="" disabled>
                        Select Builder
                      </MenuItem>
                      {enterpriseBuilders?.map(({ builder_id, name }) => (
                        <MenuItem key={builder_id} value={builder_id}>
                          {name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    // variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="firstname"
                    label="First Name"
                    name="firstname"
                    onChange={handleChange}
                    value={agentPayload.firstname}
                    error={Boolean(inputErrors.firstname)}
                    helperText={inputErrors.firstname}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastname"
                    label="Last Name"
                    name="lastname"
                    onChange={handleChange}
                    value={agentPayload.lastname}
                    error={Boolean(inputErrors.lastname)}
                    helperText={inputErrors.lastname}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <InputMask
                    mask="999-999-9999"
                    onChange={handleChange}
                    value={agentPayload.phone}
                  >
                    {(inputProps: InputState) => (
                      <TextField
                        {...inputProps}
                        type="tel"
                        margin="normal"
                        required
                        fullWidth
                        id="phone"
                        label="Phone"
                        name="phone"
                        error={Boolean(inputErrors.phone)}
                        helperText={inputErrors.phone}
                      />
                    )}
                  </InputMask>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    value={agentPayload.email}
                    error={Boolean(inputErrors.email)}
                    helperText={inputErrors.email}
                  />
                </Grid>
              </Grid>

              {showPassword && (
                <div className="changePasswordModalFrame">
                  <div className={`${classes.container} changePasswordModal`}>
                    <div className={classes.header} />
                    <div className={classes.titleWrapper}>
                      <Typography className={classes.title}>
                        Set Temporary Password
                      </Typography>
                    </div>
                    <div className={classes.content}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="pass_word"
                            label="Password"
                            name="pass_word"
                            type="password"
                            onChange={handleChange}
                            value={agentPayload.pass_word}
                            error={Boolean(inputErrors.pass_word)}
                            helperText={inputErrors.pass_word}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="confirm_password"
                            label="Confirm Password"
                            name="confirm_password"
                            type="password"
                            onChange={handleChange}
                            value={agentPayload.confirm_password}
                            error={Boolean(inputErrors.confirm_password)}
                            helperText={inputErrors.confirm_password}
                          />
                        </Grid>
                      </Grid>
                      <div
                        className={`${classes.cardActions} .changePasswordModal__buttons`}
                      >
                        <div className={classes.buttonWrapper}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            disabled={loading}
                          >
                            Save
                          </Button>
                          {loading && (
                            <CircularProgress
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                        </div>
                        <div className={classes.buttonWrapper}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classNames(
                              classes.button,
                              classes.buttonText,
                            )}
                            onClick={setPasswordVisibility}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Grid
                container
                justify="space-around"
                style={{ paddingTop: '30px' }}
              >
                <Grid item sm={5}>
                  <Typography variant="h6">Roles</Typography>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={roleValue}
                    onChange={handleRoleChange}
                  >
                    {ALL_GROUPS_FOR_AGENT.map(
                      (group: AgentRoleType, index: number) => (
                        <AgentRole key={index} role={roleValue} group={group} />
                      ),
                    )}
                  </RadioGroup>
                </Grid>

                <Notifications
                  inputErrors={inputErrors}
                  handleChange={handleChange}
                  roleValue={roleValue}
                  agentPayload={agentPayload}
                />
              </Grid>
              <div className={classes.cardActions}>
                <div className={classes.buttonWrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={loading}
                  >
                    Save
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
                <div className={classes.buttonWrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classNames(classes.button, classes.buttonText)}
                    onClick={setPasswordVisibility}
                  >
                    Set a Temporary Password
                  </Button>
                </div>
                <div className={classes.buttonWrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={loadingBtnEmailResend || !match.params.username}
                    onClick={handleResendEmail}
                  >
                    Resend Password Link
                  </Button>
                  {loadingBtnEmailResend && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(AgentForm);
