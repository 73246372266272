import * as SharedHubActions from '../actions/hubs/hubs';
import * as HubLockActions from '../actions/hubs/hubLocks';
import * as HubLightActions from '../actions/hubs/hubLights';
import { ErrorMessage, ValueOf } from '../../types/main';

// Shared action types
export const REFRESH_HUB_DEVICES_REQUEST = 'REFRESH_HUB_DEVICES_REQUEST';
export const GET_HUB_DEVICES_REQUEST = 'GET_HUB_DEVICES_REQUEST';
export const GET_HUBS_REQUEST = 'GET_HUBS_REQUEST';
export const GET_HUB_DEVICES_SUCCESS = 'GET_HUB_DEVICES_SUCCESS';
export const GET_HUB_DEVICES_FAILED = 'GET_HUB_DEVICES_FAILED';
export const GET_HUBS_SUCCESS = 'GET_HUBS_SUCCESS';
export const GET_HUB_INFO_REQUEST = 'GET_HUB_INFO_REQUEST';
export const GET_HUB_INFO_SUCCESS = 'GET_HUB_INFO_SUCCESS';
export const GET_HUB_INFO_FAILED = 'GET_HUB_INFO_FAILED';
export const GET_HUB_LOCK_LOG_REQUEST = 'GET_HUB_LOCK_LOG_REQUEST';
export const GET_HUB_LOCK_LOG_SUCCESS = 'GET_HUB_LOCK_LOG_SUCCESS';
export const GET_HUB_LOCK_LOG_FAILED = 'GET_HUB_LOCK_LOG_FAILED';

export const UPDATE_HUB_LIGHT_REQUEST = 'UPDATE_HUB_LIGHT_REQUEST';
export const UPDATE_HUB_LIGHT_SUCCESS = 'UPDATE_HUB_LIGHT_SUCCESS';
export const UPDATE_HUB_LIGHT_FAILED = 'UPDATE_HUB_LIGHT_FAILED';
export const SET_HUB_LIGHTS = 'SET_HUB_LIGHTS';

export const UPDATE_HUB_THERMOSTAT_REQUEST = 'UPDATE_HUB_THERMOSTAT_REQUEST';
export const UPDATE_HUB_THERMOSTAT_SUCCESS = 'UPDATE_HUB_THERMOSTAT_SUCCESS';
export const UPDATE_HUB_THERMOSTAT_FAILED = 'UPDATE_HUB_THERMOSTAT_FAILED';

export const SET_CURRENT_HUB_FORM_NAME = 'SET_CURRENT_HUB_FORM_NAME';
export const SET_HUB_SELECTED_LOCK = 'SET_HUB_SELECTED_LOCK';
export const SET_HUB_SELECTED_LIGHT = 'SET_HUB_SELECTED_LIGHT';
export const SET_HUB_SELECTED_THERMOSTAT = 'SET_HUB_SELECTED_THERMOSTAT';
export const SET_HUB_SELECTED_MOTION_SENSOR = 'SET_HUB_SELECTED_MOTION_SENSOR';

// Lock action types
export const UPDATE_HUB_LOCK_STATUS_REQUEST = 'UPDATE_HUB_LOCK_STATUS_REQUEST';
export const UPDATE_HUB_LOCK_STATUS_SUCCESS = 'UPDATE_HUB_LOCK_STATUS_SUCCESS';
export const UPDATE_HUB_LOCK_STATUS_FAILED = 'UPDATE_HUB_LOCK_STATUS_FAILED';

export const UPDATE_HUB_LOCK_AUTO_LOCK_REQUEST = 'UPDATE_HUB_LOCK_AUTO_LOCK_REQUEST';
export const UPDATE_HUB_LOCK_AUTO_LOCK_SUCCESS = 'UPDATE_HUB_LOCK_AUTO_LOCK_SUCCESS';
export const UPDATE_HUB_LOCK_AUTO_LOCK_FAILED = 'UPDATE_HUB_LOCK_AUTO_LOCK_FAILED';

export const CREATE_HUB_LOCK_CODE_REQUEST = 'CREATE_HUB_LOCK_CODE_REQUEST';
export const CREATE_HUB_LOCK_CODE_SUCCESS = 'CREATE_HUB_LOCK_CODE_SUCCESS';
export const CREATE_HUB_LOCK_CODE_FAILED = 'CREATE_HUB_LOCK_CODE_FAILED';

export const UPDATE_HUB_LOCK_CODE_REQUEST = 'UPDATE_HUB_LOCK_CODE_REQUEST';
export const UPDATE_HUB_LOCK_CODE_SUCCESS = 'UPDATE_HUB_LOCK_CODE_SUCCESS';
export const UPDATE_HUB_LOCK_CODE_FAILED = 'UPDATE_HUB_LOCK_CODE_FAILED';

export const UPDATE_HUB_LOCK_CODES_REQUEST = 'UPDATE_HUB_LOCK_CODES_REQUEST';
export const UPDATE_HUB_LOCK_CODES_SUCCESS = 'UPDATE_HUB_LOCK_CODES_SUCCESS';
export const UPDATE_HUB_LOCK_CODES_FAILED = 'UPDATE_HUB_LOCK_CODES_FAILED';

export const DELETE_HUB_LOCK_CODE_REQUEST = 'DELETE_HUB_LOCK_CODE_REQUEST';
export const DELETE_HUB_LOCK_CODE_SUCCESS = 'DELETE_HUB_LOCK_CODE_SUCCESS';
export const DELETE_HUB_LOCK_CODE_FAILED = 'DELETE_HUB_LOCK_CODE_FAILED';

export const HUB_REBOOT_REQUEST = 'HUB_REBOOT_REQUEST';
export const HUB_REBOOT_REQUEST_SUCCESS = 'HUB_REBOOT_REQUEST_SUCCESS';
export const HUB_REBOOT_REQUEST_FAILED = 'HUB_REBOOT_REQUEST_FAILED';

export type HubFormName = 'lock-form' | 'light-form' | 'motion-form' | 'thermostat-form';

export enum HubLockCodeSlot {
  // SECRET_CODE = 1,
  MASTER_CODE = '2',
  TEMPORARY_CODE = '3',
  SECOND_TEMPORARY_CODE = '4',
  SCHEDULE_CODE = '5'
}

export type HubsState = {
  loading: boolean
  refreshLoading: boolean
  updating: boolean
  error: ErrorMessage['message']
  currentHubFormName: HubFormName | null
  locks: HubLock[]
  lights: HubLight[]
  info: any
  selectedLock: HubLock | null
  selectedLight: HubLight | null
  selectedThermostat: HubThermostat | null
  selectedMotionSensor: HubMotionSensor | null
  hubs: Hub[],
  lockLogs: HubLockLogType[],
};

export enum HubDeviceCategory {
  LIGHT = 'dimmable_light',
  LOCK = 'door_lock',
}

export interface HubDeviceInfo {
  'firmware.stack': string
  hardware: string
  manufacturer: string
  model: string
  protocol: string
  'zwave.node': string
  'zwave.smartstart': string
}

export interface HubDevice {
  id: string
  name: string
  category: HubDeviceCategory
  info: HubDeviceInfo
  properties: unknown
}

export interface HubInfo {
  data: {}
}

export interface HubLocation {
  latitude: number,
  longitude: number,
  state: string,
  timezone: string
}

export interface HubLockUserCode {
  code: string
  name: string
  mode: 'enabled' | 'disabled'
}

export type HubLockUserCodeUpdate = Pick<HubLockUserCode, 'code' | 'name'> & {
  key: string
};

export type HubLockStatus = 'unlocked' | 'locked' | 'unknown';
export type HubSwitchStatus = 'on' | 'off';

export interface HubLockDevicePropertyUserCodes {
  [key: string]: HubLockUserCode
}

export interface HubLockDeviceProperty {
  status: HubLockStatus
  battery: number
  lockTimeoutEnabled: boolean
  lockTimeout: number
  userCodes: HubLockDevicePropertyUserCodes | null
}

export interface HubLock extends HubDevice {
  category: HubDeviceCategory.LOCK
  properties: HubLockDeviceProperty
}

export interface HubLockInput {
  hubId: string
}
export interface HubLockStatusInput extends HubLockInput {
  status: HubLockStatus,
  deviceId?: string
}

export interface HubLockAutoLockInput extends HubLockInput {
  autoLock: boolean
}

export interface HubLockCodeInput extends HubLockInput {
  code: string
}

export interface UpdateHubLockCodeInput extends HubLockInput {
  code: string
  slot: HubLockCodeSlot
}

export interface UpdateOrCreateHubLockCodeInput extends HubLockInput {
  hubId: string
  codes: HubLockUserCodeUpdate[]
}

export interface HubLightDeviceProperty {
  status: HubSwitchStatus
}

export interface HubLight extends HubDevice {
  category: HubDeviceCategory.LIGHT
  properties: HubLightDeviceProperty
}

export interface UpdateHubLightInput extends HubLockInput {
  id: string
  status: HubSwitchStatus
}

export interface DeleteHubLockCodeInput extends HubLockInput {
  codeKey: string
}

export interface HubLog {
  value: string
  date: string
}

export interface HubThermostat {
  id: string
  name: string
  status: boolean
  temp: number
}
export type HubThermostatInput = Pick<HubThermostat, 'id' | 'status' | 'temp'>;

export interface HubMotionSensor {
  id: string
  name: string
  status: string
  logs: HubLog[]
}

export interface GetHubDevicesResponse {
  locks: []
  lights: []
}

export interface Hub {
  serial_number: number;
  hub_type: string;
  property_id: number;
}

export interface GetHubInfoResponse {
  data: {}
}

export type HubsActionsTypes =
  ReturnType<ValueOf<typeof SharedHubActions>>
  | ReturnType<ValueOf<typeof HubLockActions>>
  | ReturnType<ValueOf<typeof HubLightActions>>;

export interface HubLockLogType {
  id: string;
  created_on: string;
  hubId: string;
  deviceId: string;
  result: string;
  extra: string;
  pinCodeId: string;
  msg: string;
  type: string;
  action: string;
  pinCodeName: string;
  visitor?: {
    customer_customer_id: string;
    firstname: string;
    lastname: string;
  }
}

export interface HubActivityLog {
  id: number;
  serial_number: string;
  action: string;
  created_by: string;
  users_email: string;
  created_on: string;
  hub_type: string;
  lock_type: string;
  property_id: number;
}

export interface HubActivityLogsResponse {
  data: HubActivityLog[];
  total: number;
}
