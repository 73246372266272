import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    height: '50px',
    position: 'relative',
    display: 'flex',
    alignItems: 'baseline',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  orange: {
    color: '#DB5C0E',
  },
  gray: {
    color: '#B6B6B6',
  },
  black: {
    color: '#040620',
  },
  white: {
    color: '#FFFFFF',
  },
  title: {
    fontSize: '22px',
  },
  subTitle: {
    fontSize: '16px',
    marginLeft: '26px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    }
  },
});
