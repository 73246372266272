/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Table, TableHead, TableBody, TableRow, TableCell,
} from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { convertToUsdCurrencyFormat, devideDollarsFromCoins } from '../../../utils/price';
import { Invoice } from '../../../redux/types/billing';
import InvoiceTable from '../InvoiceTable';
import { formatUnixDate } from '../../../utils/unix-date';
import { formatDate } from '../../../utils/date';
import styles from './styles';


const topTableHeaders = ['Terms', 'Period'];
const mainTableHeaders = [{ width: 18, value: 'Item' }, { width: 46, value: 'Description' }, { width: 18, value: 'Rate' }, { width: 18, value: 'Amount' }];

type Props = {
  invoiceDetails: Invoice
} & WithStyles<typeof styles>;

const InvoiceDetailsTable: React.FC<Props> = props => {
  const { classes, invoiceDetails } = props;
  const { subscriptions } = invoiceDetails;
  const total : number = (Number(invoiceDetails.total) / 100);

  return (
    <>
      <InvoiceTable
        headers={topTableHeaders}
        invoiceDetails={invoiceDetails}
        tableBody={
          [[`Net ${invoiceDetails.net_term_days}`,
            formatDate(new Date(`${formatUnixDate(Number(invoiceDetails.due_date))}`), 'MMMM')]]
        }
        periodWidth
      />
      <Table>
        <TableHead>
          <TableRow>
            {
              mainTableHeaders.map(header => (
                <TableCell key={header.value} style={{ width: `${header.width}%` }} className={classes.headerCell}>
                  {header.value}
                </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            subscriptions.map(({ subscription, plan }, index) => {
              const cl = index === invoiceDetails.subscriptions.length - 1
                ? classes.lastTableCell : classes.mainTableCell;
              return (
                <TableRow key={index}>
                  <TableCell className={cl}>{plan.invoice_name}</TableCell>
                  <TableCell className={cl}>{plan.description}</TableCell>
                  <TableCell className={cl} align="right">{devideDollarsFromCoins(subscription.plan_unit_price)}</TableCell>
                  <TableCell className={cl} align="right">{devideDollarsFromCoins(subscription.plan_amount)}</TableCell>
                </TableRow>
              );
            })
          }
          <TableRow>
            <TableCell className={classNames(classes.bottomBorder, classes.leftBorder)} />
            <TableCell className={classes.bottomBorder} />
            <TableCell
              className={classNames(classes.bottomBorder, classes.leftBorder, classes.capitalText)}
            >
              Total
            </TableCell>
            <TableCell align="right" className={classNames(classes.bottomBorder, classes.rightBorder)}>
              {convertToUsdCurrencyFormat(total)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classNames(classes.hideBottom, classes.hideLeft)} />
            <TableCell className={classes.hideBottom} />
            <TableCell
              className={classNames(classes.bottomBorder, classes.leftBorder, classes.paymentsText)}
            >
              Payments/Credits
            </TableCell>
            <TableCell align="right" className={classNames(classes.bottomBorder, classes.rightBorder)}>
              {convertToUsdCurrencyFormat(invoiceDetails.credits_applied / 100)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classNames(classes.hideBottom, classes.hideLeft)} />
            <TableCell className={classes.hideBottom} />
            <TableCell className={classNames(
              classes.bottomBorder,
              classes.leftBorder,
              classes.paymentsText,
            )}
            >
              Balance Due
            </TableCell>
            <TableCell align="right" className={classNames(classes.bottomBorder, classes.rightBorder)}>
              {convertToUsdCurrencyFormat(invoiceDetails.amount_due / 100)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default InvoiceDetailsTable;
