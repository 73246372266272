import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
  Typography, CardMedia, WithStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import kwiksetImg from '../../assets/img/kwikset.png';
import styles from './styles';
import { SmartLock } from '../../redux/types/smartLocks';

type Props = {
  smartLock: SmartLock;
} & WithStyles<typeof styles>;


const SmartLockWidget: React.FC<Props> = (props) => {
  const { classes, smartLock } = props;
  const image = kwiksetImg;

  return (
    <Card
      role="presentation"
      className={classes.card}
      // onClick={() => navTo(`/inventory/${device.serial_number}`)}
    >
      <div className={classes.iconWrapper}>
        <CardMedia
          className={classes.icon}
          image={image}
        />
      </div>
      <CardContent>
        <Typography className={classes.primaryText}>
          Smart Lock Serial Number:
          &nbsp;
          <span className="serialNumber">{smartLock.serial_number}</span>
        </Typography>
        <Typography className={classes.secondaryText}>
          Smart Lock Type:
          &nbsp;
          <span className={classes.secondaryText}>{smartLock.smart_lock_type_id}</span>
        </Typography>
        <Typography className={classes.secondaryText}>
          Property ID:
          &nbsp;
          <Link className={classes.link} onClick={(e) => e.stopPropagation()} to={`/properties/${smartLock.property_id}`}>{smartLock.property_id}</Link>

        </Typography>
      </CardContent>
    </Card>
  );
};

export default React.memo(SmartLockWidget);