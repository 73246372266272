import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  card: {
    padding: 20,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    }
  },
  header: {
    fontSize: '22px',
  },
  contentText: {
    fontSize: '32px',
    fontWeight: 'bold',
  },
  orange: {
    background: 'linear-gradient(65.75deg, #DB5C0E 0.06%, #FFA166 98.73%)',
    color: 'white',
  },
  grey: {
    background: '#8E8B99',
    color: 'white',
  },
  white: {
    background: 'white',
    color: 'black',
  },
});
