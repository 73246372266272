import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styles from '../styles';
import BatteryIcon from '../../Icons/BatteryIcon';

type Props = {
  status: string
  battery: number
};

const HubLockTitleWithStatus: React.FC<Props> = (props: Props) => {
  const { status, battery } = props;
  const classes = makeStyles(styles)();
  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item>{status}</Grid>
      <Grid item>
        <Grid container>
          <Grid item className={classes.batteryCounter}>
            {battery}
            %
          </Grid>
          <Grid className={classes.iconWrapper}>
            <BatteryIcon className={classes.batteryIcon} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HubLockTitleWithStatus;
