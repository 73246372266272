import { createStyles } from '@material-ui/core';

export default () => createStyles({
  badgeBase: {
    display: 'inline-block',
    padding: '10px 25px',
    borderRadius: 5,
    fontSize: 18,
    backgroundColor: '#3F88C5',
    color: '#fff',
    textTransform: 'capitalize',
  },
  accessed: {
    backgroundColor: '#04A777',
    color: '#fff',
  },
  deny: {
    backgroundColor: '#D72638',
    color: '#fff',
  },
});
