import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { WithStyles } from '@material-ui/styles';
import styles from './styles';

type Props = WithStyles<typeof styles>;

const FullScreenLoader: React.FC<Props> = ({ classes }) => (
  <div className={classes.progressWrapper}>
    <CircularProgress className={classes.progress} size={48} />
  </div>
);

export default React.memo(FullScreenLoader);
