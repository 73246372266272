import {
  SCHEDULED_VISITS_REQUEST,
  SCHEDULED_VISITS_REQUEST_SUCCESS,
  // VISITS_REQUEST_FAILED,
  ScheduledVisitsState,
  SCHEDULED_VISIT_DETAILS_REQUEST_SUCCESS, ScheduledVisitsActionsTypes,
} from '../types/scheduledVisit';

const initialState: ScheduledVisitsState = {
  items: [],
  selected: null,
  total: 0,
  error: '',
  loading: false,
};

export default function VisitsReducer(
  state = initialState,
  action: ScheduledVisitsActionsTypes,
): ScheduledVisitsState {
  switch (action.type) {
    case SCHEDULED_VISITS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case SCHEDULED_VISITS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total,
        error: '',
        loading: false,
      };
    }

    // case VISITS_REQUEST_FAILED: {
    //   return {
    //     ...state,
    //     error: action.payload.message,
    //     loading: false,
    //   };
    // }
    case SCHEDULED_VISIT_DETAILS_REQUEST_SUCCESS: {
      return {
        ...state,
        selected: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
