import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid, Radio } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import useStyles from './styles';
import { AgentRoleType } from '../../../redux/types/agents';


type Props = {
  group: AgentRoleType;
  role: string;
  key: number;
};

const AgentRole: React.FC<Props> = ({ group, role, key }) => {
  const classes = useStyles();
  return (
    <Grid key={key} style={{ marginTop: '26px' }} container direction="row" wrap="nowrap" alignItems="center">
      <Grid item sm={3} style={{ minWidth: '175px' }}>
        <FormControlLabel
          className={classes.customCheckbox}
          style={{ backgroundColor: role === group.Label ? '#3CB371' : 'rgba(0, 0, 0, 0.09)' }}
          label={group.Label}
          value={group.Label}
          control={(
            <Radio
              checkedIcon={<CheckIcon style={{ color: 'white' }} />}
              name="send_sms"
            />
                )}
        />
      </Grid>
      <Grid item sm={6} style={{ fontSize: '16px' }}>
        {group.description}
      </Grid>
    </Grid>
  );
};

export default React.memo(AgentRole);
