import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  container: {
    marginBottom: 26,
  },
  title: {
    fontWeight: 600,
    color: '#DB5C0E',
  },
  primaryTitle: {
    fontSize: 20,
  },
  secondaryTitle: {
    fontSize: 16,
    minWidth: '130px',
    marginRight: '50px',
  },
  deviceTitle: {
    marginBottom: 20,
  },
  deviceName: {
    fontSize: 16,
    fontWeight: 700,
  },
  card: {
    padding: '14px 24px',
    borderRadius: 10,
    boxShadow: '0px 0px 10px 0px #00000040',
    cursor: 'pointer',

    '& + div': {
      marginTop: 16,
    },
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#DB5C0E',
  },
  cardStatus: {
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  smartLockTitle: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '24px',
    margin: '0 0 5px 0',
  },
  codesTitle: {
    color: '#8E8B99',
    fontSize: 28,
    marginBottom: '5px',
  },
  statusTitle: {
    marginTop: 16,
    fontSize: 16,
    color: '#8E8B99',
    textAlign: 'center',
  },
  progress: {
    color: theme.appPalette.orange,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: 20,
    marginLeft: -12,
  },
  progressWrapper: {
    position: 'relative',
    height: '100%',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  batteryIcon: {
    width: 54,
    height: 22,
    color: '#04A777',
  },
  batteryCounter: {
    marginRight: 4,
    fontSize: 16,
    fontWeight: 600,
    color: '#04A777',
  },
  form: {
    width: '100%',
    height: '100%',
    maxWidth: 480,
    padding: '16px 24px',
  },
  content: {
    height: '100%',
  },
  lineBreak: {
    margin: '16px 0 12px',
    borderBottom: '1px solid #8E8B99',
  },
  codeInput: {
    marginTop: 0,
    marginBottom: 0,

    '& > div > input': {
      marginLeft: 0,
      fontSize: 28,
      fontWeight: 700,
      textAlign: 'center',
    },

    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'none',
    },

    '& label': {
      width: '100%',
      left: 0,
      fontSize: 28,
      textAlign: 'center',
    },
    '& label.Mui-focused, & label.MuiFormLabel-filled': {
      textAlign: 'left',
      left: 12,
      fontSize: 16,
    },
  },
  deleteIcon: {
    marginRight: 8,
    cursor: 'pointer',
    color: '#8E8B99',
  },
  switchText: {
    padding: '4px 10px',
    backgroundColor: '#04A777',
    borderRadius: '20px',
    color: '#fff',
  },
  disabled: {
    filter: 'grayscale(1)',
    opacity: '0.5',
    pointerEvents: 'none',
  },
  select: {
    minWidth: '120px',
    height: '40px',
    '& .MuiSelect-root': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      padding: '0 0 0 10px',
      margin: 0,
    },
  },
  btnStyle: {
    marginTop: '20px',
  },
});
