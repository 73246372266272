import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  createErrorSnackBar,
  createSuccessSnackBar,
} from '../actions/snackbars';
import {
  surveyQuestionsRequestFailed,
  surveyQuestionsRequestSuccess,
  updateSurveyQuestions,
  updateSurveyQuestionsFailed,
  updateSurveyQuestionsSuccess,
} from '../actions/survey';
import { loadQuestions, updateQuestions } from '../../services/survey';
import { SURVEY_QUESTIONS, SURVEY_QUESTIONS_UPDATE } from '../types/survey';

function* loadSurveyQuestionsSaga() {
  try {
    const { data } = yield call(loadQuestions);
    yield put(surveyQuestionsRequestSuccess(data));
  } catch (err) {
    yield put(surveyQuestionsRequestFailed(err));
  }
}

function* updateSurveyQuestionsSaga(
  action: ReturnType<typeof updateSurveyQuestions>,
) {
  try {
    const { data } = yield call(updateQuestions, action.payload);
    yield put(createSuccessSnackBar('Questions updated'));
    yield put(updateSurveyQuestionsSuccess(data));
  } catch (err) {
    yield put(updateSurveyQuestionsFailed(err));
    yield put(
      createErrorSnackBar(
        err.response ? err.response.data.message : err.message,
      ),
    );
  }
}

export default all([
  takeLatest<any>(SURVEY_QUESTIONS, loadSurveyQuestionsSaga),
  takeLatest<any>(SURVEY_QUESTIONS_UPDATE, updateSurveyQuestionsSaga),
]);
