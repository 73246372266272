import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import PropertyHubDeviceList from './PropertyHubDeviceList';
import styles from './styles';
import { AppState } from '../../redux/store';
import { selectCurrentHubForm } from '../../redux/selectors/hubs';
import { setCurrentHubFormName } from '../../redux/actions/hubs/hubs';

const styledComponent = withStyles(styles)(PropertyHubDeviceList);

const mapStateToProps = (state: AppState) => ({
  currentHubFormName: selectCurrentHubForm(state),
});
const mapDispatchToProps = {
  setCurrentHubFormName,
};

export default connect(mapStateToProps, mapDispatchToProps)(styledComponent);
