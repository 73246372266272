import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { billingRequest } from '../../redux/actions/billing';
import Billing from './Billing';
import styles from './styles';


const mapStateToProps = () => ({

});

const mapDispatchToProps = {
  loadInvoices: billingRequest,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Billing),
);
