import {
  HubLockAutoLockInput,
  HubLockCodeInput,
  HubLockStatusInput,
  UpdateHubLockCodeInput,
  UpdateOrCreateHubLockCodeInput,
  DeleteHubLockCodeInput,
  CREATE_HUB_LOCK_CODE_FAILED,
  CREATE_HUB_LOCK_CODE_REQUEST,
  CREATE_HUB_LOCK_CODE_SUCCESS,
  DELETE_HUB_LOCK_CODE_FAILED,
  DELETE_HUB_LOCK_CODE_REQUEST,
  DELETE_HUB_LOCK_CODE_SUCCESS,
  UPDATE_HUB_LOCK_AUTO_LOCK_FAILED,
  UPDATE_HUB_LOCK_AUTO_LOCK_REQUEST,
  UPDATE_HUB_LOCK_AUTO_LOCK_SUCCESS,
  UPDATE_HUB_LOCK_CODE_FAILED,
  UPDATE_HUB_LOCK_CODE_REQUEST,
  UPDATE_HUB_LOCK_CODE_SUCCESS,
  UPDATE_HUB_LOCK_STATUS_FAILED,
  UPDATE_HUB_LOCK_STATUS_REQUEST,
  UPDATE_HUB_LOCK_STATUS_SUCCESS,
  UPDATE_HUB_LOCK_CODES_REQUEST,
  UPDATE_HUB_LOCK_CODES_SUCCESS,
  UPDATE_HUB_LOCK_CODES_FAILED,
} from '../../types/hubs';

export const updateHubLockStatusRequest = (payload: HubLockStatusInput) => ({
  type: UPDATE_HUB_LOCK_STATUS_REQUEST,
  payload,
} as const);
export const updateHubLockStatusSuccess = (payload: HubLockStatusInput) => ({
  type: UPDATE_HUB_LOCK_STATUS_SUCCESS,
  payload,
} as const);
export const updateHubLockStatusFailed = () => ({
  type: UPDATE_HUB_LOCK_STATUS_FAILED,
} as const);

export const updateHubLockAutoLockRequest = (payload: HubLockAutoLockInput) => ({
  type: UPDATE_HUB_LOCK_AUTO_LOCK_REQUEST,
  payload,
} as const);
export const updateHubLockAutoLockSuccess = (payload: HubLockAutoLockInput) => ({
  type: UPDATE_HUB_LOCK_AUTO_LOCK_SUCCESS,
  payload,
} as const);
export const updateHubLockAutoLockFailed = () => ({
  type: UPDATE_HUB_LOCK_AUTO_LOCK_FAILED,
} as const);

export const createHubLockMasterCodeRequest = (payload: HubLockCodeInput) => ({
  type: CREATE_HUB_LOCK_CODE_REQUEST,
  payload,
} as const);
export const createHubLockMasterCodeSuccess = (payload: HubLockCodeInput) => ({
  type: CREATE_HUB_LOCK_CODE_SUCCESS,
  payload,
} as const);
export const createHubLockMasterCodeFailed = () => ({
  type: CREATE_HUB_LOCK_CODE_FAILED,
} as const);

export const updateHubLockMasterCodeRequest = (payload: UpdateHubLockCodeInput) => ({
  type: UPDATE_HUB_LOCK_CODE_REQUEST,
  payload,
} as const);
export const updateHubLockMasterCodeSuccess = (payload: UpdateHubLockCodeInput) => ({
  type: UPDATE_HUB_LOCK_CODE_SUCCESS,
  payload,
} as const);
export const updateHubLockMasterCodeFailed = () => ({
  type: UPDATE_HUB_LOCK_CODE_FAILED,
} as const);

export const updateHubLockCodesRequest = (payload: UpdateOrCreateHubLockCodeInput) => ({
  type: UPDATE_HUB_LOCK_CODES_REQUEST,
  payload,
} as const);
export const updateHubLockCodesSuccess = (payload: UpdateOrCreateHubLockCodeInput) => ({
  type: UPDATE_HUB_LOCK_CODES_SUCCESS,
  payload,
} as const);
export const updateHubLockCodesFailed = () => ({
  type: UPDATE_HUB_LOCK_CODES_FAILED,
} as const);

export const deleteHubLockCodeRequest = (payload: DeleteHubLockCodeInput) => ({
  type: DELETE_HUB_LOCK_CODE_REQUEST,
  payload,
} as const);
export const deleteHubLockCodeSuccess = (payload: DeleteHubLockCodeInput) => ({
  type: DELETE_HUB_LOCK_CODE_SUCCESS,
  payload,
} as const);
export const deleteHubLockCodeFailed = () => ({
  type: DELETE_HUB_LOCK_CODE_FAILED,
} as const);
