import axios from './axios';
import { Query, buildQuery } from '../utils/query';
import { ExportAllParams } from '../redux/types/exportAll';

export const loadVisits = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/visits${query}`);
};

export const loadVisitsReport = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/visits/report${query}`);
};

export const loadContractorsVisits = () => axios.get('/visits/contractorsVisits');

export const loadVisitsExport = (
  selection: Array<number | string>,
  accept: 'application/pdf' | 'text/csv' = 'application/pdf',
) => {
  const headers = {
    Accept: accept,
  };

  return axios.post(
    '/visits/export',
    {
      selection,
    },
    { headers, responseType: 'blob' },
  );
};

export const loadVisitsReportExport = (
  selection: Array<number | string>,
  startDate: Date,
  endDate: Date,
  accept: 'application/pdf' | 'text/csv' = 'application/pdf',
) => {
  const headers = {
    Accept: accept,
  };

  return axios.post(
    '/visits/report/export',
    {
      selection,
      startDate,
      endDate,
    },
    { headers, responseType: 'blob' },
  );
};

export const loadExportAll = (path: string, accept: 'application/pdf' | 'text/csv' = 'application/pdf', params: ExportAllParams, query: Query) => {
  const headers = {
    Accept: accept,
  };
  return axios.post(
    `/${path}/exportAll`,
    { params, query },
    { headers, responseType: 'blob' },
  );
};
