import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  headerText: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px',
  },

  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },

  text: {
    fontSize: '18px',
  },

  icon: {
    marginRight: '5px',
    color: theme.appPalette.orange,
  },

  inputForm: {
    margin: '20px 0',
    borderTop: '1px solid rgba(142, 139, 153, 0.25)',
    borderBottom: '1px solid rgba(142, 139, 153, 0.25)',
    padding: '20px 0',
    height: '546px',
    overflowY: 'hidden',
  },

  navButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '44px 10% 60px 10%',

    '& button:first-child': {
      marginRight: '15px',
    },
  },
});
