import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import LogOutIcon from '@material-ui/icons/ExitToAppOutlined';
import AccountCircleRounded from '@material-ui/icons/AccountCircleRounded';
import Notifications from '@material-ui/icons/Notifications';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

import { Link } from 'react-router-dom';
import { WithStyles } from '@material-ui/styles';
import FeedbackDialog from '../FeedbackDialog';

import styles from './styles';
import { UserData } from '../../redux/selectors/auth';

type Props = {
  onLogOut: () => void;
  user: UserData;
} & WithStyles<typeof styles>;

const UserToolbar: React.FC<Props> = props => {
  const { classes, onLogOut, user } = props;
  const greetMessage = `Welcome, ${user.firstName} ${user.lastName}`;


  useEffect(() => {
    // @ts-ignore
    Canny('initChangelog', {
      appID: '641b2057cea6d145e08661b9',
      position: 'bottom',
      align: 'right',
    });
  }, []);

  return (
    <Toolbar classes={{ root: classes.root }}>
      <div className={classes.spacer} />
      <div className={classes.userGreeting}>
        <Typography variant="h5">{greetMessage}</Typography>
      </div>
      <FeedbackDialog />
      <div className={classes.iconsContainer}>
        <Tooltip title="Edit Profile" placement="right-start" arrow interactive TransitionProps={{ timeout: 600 }} TransitionComponent={Zoom}>
          <IconButton component={Link} to="/account">
            <AccountCircleRounded fontSize="large" />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Notifications"
          placement="right-start"
          arrow
          interactive
          TransitionProps={{ timeout: 600 }}
          TransitionComponent={Zoom}
          data-canny-changelog
        >
          <IconButton>
            <Notifications
              className={classes.notificationIcon}
              fontSize="large"
            />
          </IconButton>
        </Tooltip>
        
        <div className="canny-changelog"
          data-board-token="<YOUR_BOARD_TOKEN>"
          data-dark-mode="<true/false>"
          data-hide-powered-by="<true/false>"
          data-show-subscribe="<true/false>"
          data-title="Hello World"
        ></div>

        <div className={classes.logout}>
          <Tooltip
            title="Log Out"
            placement="right-start"
            arrow
            interactive
            TransitionProps={{ timeout: 600 }}
            TransitionComponent={Zoom}
          >
            <IconButton onClick={onLogOut}>
              <LogOutIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </div>
      </div>

    </Toolbar>
  );
};

export default React.memo(UserToolbar);
