import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import IntegrationsList from './IntegrationsList';
import styles from './style';

import { saveProjectId } from '../../redux/actions/properties';

import { AppState } from '../../redux/store';


const mapStateToProps = (state: AppState) => ({
});

const mapDispatchToProps = {

  saveProjectId,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(IntegrationsList));
