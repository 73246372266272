import { Theme, createStyles, makeStyles } from '@material-ui/core';


const styles = (theme: Theme) => createStyles({
  headerText: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: '40px',
    marginBottom: '20px',
  },
  container: {
    textAlign: 'center',
    padding: '50px 10% 50px 10%',
    borderTop: '1px solid rgba(142, 139, 153, 0.25)',
    borderBottom: '1px solid rgba(142, 139, 153, 0.25)',
  },
  card: {
    borderRadius: 10,
    height: '100%',
  },
  videoTitle: {
    marginTop: 20,
    textAlign: 'center',
    color: '#DB5C0E',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: '28px',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
