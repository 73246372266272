import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { exportAllRequest } from '../../redux/actions/exportAll';
import {
  selectTotalVisitsAfterTourHours,
  selectVisitsAfterTourHours,
  selectVisitsRequestStatus,
} from '../../redux/selectors/visits';
import { AppState } from '../../redux/store';
import { selectExportAllLoadingState } from '../../redux/selectors/exportAll';
import { clearFilter } from '../../redux/actions/filter';
import { builderTimezoneRequest } from '../../redux/actions/builders';
import { selectBuilderTimezone } from '../../redux/selectors/builders';
import {
  visitsAfterTourHoursExportAndPrintRequest,
  visitsAfterTourHoursExportRequest,
  visitsAfterTourHoursRequest,
} from '../../redux/actions/afterTourHours';
import styles from './styles';
import AfterTourHours from './AfterTourHours';

const mapStateToProps = (state: AppState) => ({
  visitsAfterTourHours: selectVisitsAfterTourHours(state),
  visitsAfterTourHoursTotal: selectTotalVisitsAfterTourHours(state),
  loading: selectVisitsRequestStatus(state),
  exportAllLoading: selectExportAllLoadingState(state),
  builderTimezone: selectBuilderTimezone(state),
});
const mapDispatchToProps = {
  loadVisitsAfterTourHours: visitsAfterTourHoursRequest,
  exportVisitsAfterTourHours: visitsAfterTourHoursExportRequest,
  printVisitsAfterTourHours: visitsAfterTourHoursExportAndPrintRequest,
  exportAllRequest,
  clearFilterState: clearFilter,
  getBuilderTimezone: builderTimezoneRequest,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AfterTourHours),
);
