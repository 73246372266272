import OfferDetails from './OfferDetails';
import { AppState } from '../../redux/store';
import { connect } from 'react-redux';
import {
    offerDetailsRequest,
} from '../../redux/actions/offers';
import { getSelected } from '../../redux/selectors/offers';
import { selectIsRental } from '../../redux/selectors/auth';


const mapStateToProps = (state: AppState) => ({
  offerDetails: getSelected(state),
  isRental: selectIsRental(state.auth)
});

const mapDispatchToProps = {
    offerDetailsRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetails);
