import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  container: {},
  switchRoot: {
    width: 90,
  },
  switchChecked: {
    transform: 'translateX(54px) !important',
  },
  title: {
    marginBottom: 6,
    fontSize: 18,
    color: '#8E8B99',
  },
  valueName: {
    fontSize: 18,
    fontWeight: 600,
  },
  switchWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});
