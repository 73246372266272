import { connect } from 'react-redux';
import { builderTimezoneRequest, enterpriseBuildersRequest, saveBuilderSettings } from '../../redux/actions/builders';
import {
  selectBuilderTimezone, selectBuilders, selectBuilderSettingsLoading, selectBuildersRequestStatus,
} from '../../redux/selectors/builders';
import { AppState } from '../../redux/store';
import BuilderSettingsForm from './BuilderSettingsForm';
import {
  getBuilderId, getEnterpriseId, selectEnterpriseStatus,
} from '../../redux/selectors/auth';

const mapStateToProps = (state: AppState) => ({
  builderTimezone: selectBuilderTimezone(state),
  builderId: getBuilderId(state),
  enterpriseId: getEnterpriseId(state),
  isEnterprise: selectEnterpriseStatus(state.auth),
  enterpriseBuilders: selectBuilders(state),
  loadingEnterpriseBuilders: selectBuildersRequestStatus(state),
  isLoading: selectBuilderSettingsLoading(state),
});

const mapDispatchToProps = {
  saveSettings: saveBuilderSettings,
  getBuilderTimezone: builderTimezoneRequest,
  loadEnterpriseBuilders: enterpriseBuildersRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuilderSettingsForm);
