export type Order = 'asc' | 'desc';

export function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

export function getSorting<T, K extends keyof T>(
  order: Order,
  orderBy: K,
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

export function isSelected<T extends string | number>(
  value: T,
  array: T[],
): boolean {
  return array.indexOf(value) !== -1;
}

export function countEmptyRows(rowsCount: number, rowsPerPage: number): number {
  return Math.min(rowsPerPage, Math.abs(rowsCount - rowsPerPage));
}
