import { ErrorMessage, ValueOf } from '../../types/main';
import * as OffersActions from '../actions/offers';

export const OFFERS_REQUEST = 'OFFERS_REQUEST';
export const EXPORT_ALL_REQUEST = 'EXPORT_ALL_REQUEST';
export const OFFER_DETAILS_REQUEST_SUCCESS = 'OFFER_DETAILS_REQUEST_SUCCESS';
export const OFFER_DETAILS_REQUEST = 'OFFER_DETAILS_REQUEST';
export const OFFERS_REQUEST_SUCCESS = 'OFFERS_REQUEST_SUCCESS';
export const OFFERS_REQUEST_FAILED = 'OFFERS_REQUEST_FAILED';
export const OFFERS_EXPORT_REQUEST = 'OFFERS_EXPORT_REQUEST';
export const OFFERS_EXPORT_REQUEST_SUCCESS = 'OFFERS_EXPORT_REQUEST_SUCCESS';
export const OFFERS_EXPORT_REQUEST_FAILED = 'OFFERS_EXPORT_REQUEST_FAILED';
export const OFFERS_EXPORT_AND_PRINT_REQUEST = 'OFFERS_EXPORT_AND_PRINT_REQUEST';
export const EXPORT_ALL_REQUEST_SUCCESS = 'EXPORT_ALL_REQUEST_SUCCESS';
export const EXPORT_ALL_REQUEST_FAILED = 'EXPORT_ALL_REQUEST_FAILED';

export type OffersExportRequestActionType =
  | ReturnType<typeof OffersActions.offersExportRequest>
  | ReturnType<typeof OffersActions.offersExportAndPrintRequest>;

export type OffersActionsTypes = ReturnType<ValueOf<typeof OffersActions>>;


export enum Contact {
  email,
  cell
}

export interface Offer {
  id: number
  customer_id: number
  property_id: number
  floor_plan?: string
  prequalified: boolean
  move_in: string
  comments: string
  preferred_contact: string
  firstname: string
  lastname: string
  phone: string
  email: string
}

export interface OffersRequestSuccess {
  items: Offer[]
  total: number
}

export interface OffersState {
  items: Offer[]
  selected: Offer | null
  total: number
  loading: boolean
  error: ErrorMessage['message']
}
