import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  tableRoot: {
    minWidth: 1230,
    minHeight: 100,
  },
  rowRoot: {
    '&:hover > td': {
      color: theme.appPalette.orange,
      cursor: 'pointer',
    },
  },
  spinnerWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  link: {
    color: theme.appPalette.orange,
  },
  tableWrapper: {
    overflowX: 'auto',
    position: 'relative',
  },
});
