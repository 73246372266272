import { createSelector } from 'reselect';
import { AppState } from '../store';
import { OffersState } from '../types/offers';

export const selectOffersState = (state: AppState) => state.offers;
const selectItems = (state: OffersState) => state.items;
const selectLoading = (state: OffersState) => state.loading;
const selectTotal = (state: OffersState) => state.total;
const selectSelected = (state: OffersState) => state.selected;

export const selectOffers = createSelector(
  selectOffersState,
  selectItems,
);

export const selectTotalOffers = createSelector(
  selectOffersState,
  selectTotal,
);

export const selectOffersRequestStatus = createSelector(
  selectOffersState,
  selectLoading,
);

export const getSelected = createSelector(
  selectOffersState,
  selectSelected,
);

export const getAll = createSelector(
  selectOffersState,
  selectTotal,
);
