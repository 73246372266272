import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import HubFormControls from './HubFormControls';
import styles from './styles';
import { refreshHubDevicesRequest } from '../../redux/actions/hubs/hubs';
import { AppState } from '../../redux/store';
import { selectPropertySelectedHubId } from '../../redux/selectors/properties';
import { selectHubRefreshLoading, selectHubUpdating } from '../../redux/selectors/hubs';

const styledComponent = withStyles(styles)(HubFormControls);

const mapStateToProps = (state: AppState) => ({
  hubId: selectPropertySelectedHubId(state),
  updating: selectHubUpdating(state),
  refreshLoading: selectHubRefreshLoading(state),
});

const mapDispatchToProps = { refreshHubDevices: refreshHubDevicesRequest };

export default (connect(mapStateToProps, mapDispatchToProps)(styledComponent));
