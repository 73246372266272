import { createSelector } from 'reselect';
import { AppState } from '../store';
import { TeamsState } from '../types/teams';

const selectTeamsState = (state: AppState) => state.teams;
const selectItems = (state: TeamsState) => state.items;
const selectLoading = (state: TeamsState) => state.loading;
const selectTotal = (state: TeamsState) => state.total;
const selectSelected = (state: TeamsState) => state.selected;

export const selectTeams = createSelector(selectTeamsState, selectItems);

export const selectTotalTeams = createSelector(
  selectTeamsState,
  selectTotal,
);

export const selectTeam = createSelector(
  selectTeamsState,
  selectSelected,
);

export const selectTeamsRequestStatus = createSelector(
  selectTeamsState,
  selectLoading,
);
