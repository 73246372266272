/* eslint-disable @typescript-eslint/camelcase */
import Joi from '@hapi/joi';

const username = Joi.string()
  .label('User name')
  // .regex(/^\w+\.\w+$/, 'required')
  .min(1)
  .max(250)
  .required();

const firstName = Joi.string()
  .label('First name')
  .alphanum()
  .min(1)
  .max(250)
  .required();

const lastName = Joi.string()
  .label('Last name')
  .alphanum()
  .min(1)
  .max(250)
  .required();

const email = Joi.string()
  .label('Email')
  .email({ minDomainSegments: 2, tlds: false })
  .required();

const send_email = Joi.number().required();
const send_sms = Joi.number().required();
const lock_battery_level_send_email = Joi.number().required();
const lock_battery_level_send_sms = Joi.number().required();
const lock_offline_send_email = Joi.number().required();
const lock_offline_send_sms = Joi.number().required();

const phone = Joi.string()
  .label('Phone')
  // .regex(/^\d{3}-\d{3}-\d{4}$/, 'required')
  .required();

const pass_word = Joi.string()
  .label('Password')
  .allow('');

const oldEmail = Joi.string().allow('');
const oldPhone = Joi.string().allow('');

export default Joi.object({
  username,
  firstName,
  lastName,
  phone,
  email,
  pass_word,
  send_email,
  send_sms,
  lock_battery_level_send_email,
  lock_battery_level_send_sms,
  lock_offline_send_email,
  lock_offline_send_sms,
  oldEmail,
  oldPhone,
});
