import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  COMMUNITIES_REQUEST,
  COMMUNITIES_DETAILS_REQUEST,
  SAVE_COMMUNITY,
  BUILDER_COMMUNITIES,
  COMMUNITIES_EXPORT_REQUEST,
  COMMUNITIES_DELETE_REQUEST,
  SAVE_QR_CODE_REQUEST,
  QR_CODES_REQUEST,
  QR_CODE_DELETE_REQUEST,
  COMMUNITIES_STATUSES,
} from '../types/communities';

import {
  getCommunities,
  getCommunitiesFailed,
  getCommunitiesSuccess,
  getCommunityDetails,
  saveQRCodeData,
  getCommunityDetailsSuccess,
  getQRCodesSuccess,
  getCommunityDetailsFailed,
  saveCommunity,
  saveCommunitySuccess,
  getBuilderCommunities,
  getBuilderCommunitiesSuccess,
  communitiesDeleteRequest,
  communitiesDeleteRequestSuccess,
  QRCodeDeleteRequest, communitiesExportRequest, getCommunitiesStatusesSuccess, getCommunitiesStatuses,
} from '../actions/communities';
import {
  createSuccessSnackBar,
  createErrorSnackBar,
} from '../actions/snackbars';
import {
  getCommunitiesList,
  fetchCommunityDetails,
  getQRCodesData,
  saveCommunityDetails,
  fetchBuilderCommunities,
  deleteCommunitiesRequest,
  saveQRCode,
  deleteQRCodeRequest,
  fetchCommunitiesStatuses,
} from '../../services/communities';
import { exportToFile } from '../../services/export';
import { exportCommunitiesRequest } from '../../services/properties';

function* getCommunitiesSaga(action: ReturnType<typeof getCommunities>) {
  try {
    const { data } = yield call(getCommunitiesList, action.payload);

    yield put(
      getCommunitiesSuccess({
        communities: data.communities,
        total: data.total,
      }),
    );
  } catch (error) {
    yield put(getCommunitiesFailed(error));
    yield put(createErrorSnackBar(error.response ? error.response.data.message : error.message));
  }
}

function* getCommunityDetailsSaga(
  action: ReturnType<typeof getCommunityDetails>,
) {
  try {
    const { data } = yield call(fetchCommunityDetails, action.payload.communityId);
    yield put(getCommunityDetailsSuccess(data));
  } catch (error) {
    yield put(getCommunityDetailsFailed(error));
    yield put(createErrorSnackBar(error.response ? error.response.data.message : error.message));
  }
}

function* getQRCodesSaga(
  action: ReturnType<typeof getCommunityDetails>,
) {
  try {
    const { data } = yield call(getQRCodesData, action.payload.communityId);
    yield put(getQRCodesSuccess(data));
  } catch (error) {
    yield put(getCommunityDetailsFailed(error));
    yield put(createErrorSnackBar(error.response ? error.response.data.message : error.message));
  }
}


function* getCommunitiesStatusesSaga(
  action: ReturnType<typeof getCommunitiesStatuses>,
) {
  try {
    const { data } = yield call(fetchCommunitiesStatuses);
    yield put(getCommunitiesStatusesSuccess(data));
  } catch (error) {
    yield put(createErrorSnackBar(error.response ? error.response.data.message : error.message));
  }
}

function* saveQRCodeSaga(
  action: ReturnType<typeof saveQRCodeData>,
) {
  try {
    yield call(saveQRCode, action.payload);
    const { data } = yield call(getQRCodesData, action.payload.communityId);
    yield put(getQRCodesSuccess(data));
    yield put(createSuccessSnackBar('Success!'));
  } catch (error) {
    yield put(getCommunityDetailsFailed(error));
    yield put(createErrorSnackBar(error.response ? error.response.data.message : error.message));
  }
}

function* saveCommunitySaga(action: ReturnType<typeof saveCommunity>) {
  try {
    const { data } = yield call(saveCommunityDetails, action.payload);

    yield put(saveCommunitySuccess(data));
    yield put(createSuccessSnackBar('Success!'));
  } catch (error) {
    yield put(createErrorSnackBar(error.response ? error.response.data.message : error.message));
  }
}

function* getBuilderCommunitiesSaga(action: ReturnType<typeof getBuilderCommunities>) {
  try {
    const { data } = yield call(fetchBuilderCommunities, action.payload);

    yield put(getBuilderCommunitiesSuccess(data));
  } catch (error) {
    yield put(createErrorSnackBar(error.response ? error.response.data.message : error.message));
  }
}

function* deleteCommunities(action: ReturnType<typeof communitiesDeleteRequest>) {
  try {
    yield call(deleteCommunitiesRequest, action.payload);

    yield put(communitiesDeleteRequestSuccess(action.payload));
    yield put(createSuccessSnackBar('Successfully deleted!'));
  } catch (error) {
    yield put(createErrorSnackBar(error.response ? error.response.data.message : error.message));
  }
}

function* deleteQRCode(action: ReturnType<typeof QRCodeDeleteRequest>) {
  try {
    yield call(deleteQRCodeRequest, action.payload.id);
    const { data } = yield call(getQRCodesData, action.payload.communityId);
    yield put(getQRCodesSuccess(data));
    yield put(createSuccessSnackBar('Successfully deleted!'));
  } catch (error) {
    yield put(createErrorSnackBar(error.response ? error.response.data.message : error.message));
  }
}

function* exportCommunities({ payload }: ReturnType<typeof communitiesExportRequest>) {
  try {
    const { selection, mimeType } = payload;
    const { data } = yield call(exportCommunitiesRequest, selection, mimeType);
    const fileType = { type: mimeType } as const;
    const blob = new Blob([data], fileType);

    const fileName = 'communities-export.csv';

    yield call(exportToFile, blob, fileType, fileName);
  } catch (error) {
    yield put(createErrorSnackBar(error.response ? error.response.data.message : error.message));
  }
}

export default all([
  takeLatest(COMMUNITIES_REQUEST, getCommunitiesSaga),
  takeLatest(SAVE_QR_CODE_REQUEST, saveQRCodeSaga),
  takeLatest(COMMUNITIES_DETAILS_REQUEST, getCommunityDetailsSaga),
  takeLatest(QR_CODES_REQUEST, getQRCodesSaga),
  takeLatest(SAVE_COMMUNITY, saveCommunitySaga),
  takeLatest(BUILDER_COMMUNITIES, getBuilderCommunitiesSaga),
  takeLatest(COMMUNITIES_EXPORT_REQUEST, exportCommunities),
  takeLatest(COMMUNITIES_DELETE_REQUEST, deleteCommunities),
  takeLatest(QR_CODE_DELETE_REQUEST, deleteQRCode),
  takeLatest(COMMUNITIES_STATUSES, getCommunitiesStatusesSaga),

]);
