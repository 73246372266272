import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';

export const defaultDateFormat = 'MM/dd/yyyy';

export const formatUnixDate = (
  dateString: number,
  dateFormat = defaultDateFormat,
): string => {
  if (!dateString) {
    return '';
  }

  try {
    return `${format(fromUnixTime(dateString), dateFormat)}`;
  } catch (e) {
    return '';
  }
};
