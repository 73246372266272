import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({

  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  scheduleCodeDate: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 16,
    fontWeight: 700,
  },
  input: {
    '& > div > input': {
      fontSize: 28,
      fontWeight: 700,
    },

    '& .MuiInput-underline.Mui-disabled:before': {
      borderBottomStyle: 'none',
    },
  },
  calendarInput: {
    paddingRight: 18,
  },
  calendarIcon: {
    width: 28,
    height: 28,
  },
  lockCodeTitle: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  lockCode: {
    fontSize: 28,
    fontWeight: 700,
    color: 'rgba(0, 0, 0, 0.87)',
  },
});
