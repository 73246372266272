import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const disclosuresSchema = yup.object().shape({
  custom_disclosure_active: yup.boolean(),
  custom_disclosure_name: yup.string().when('custom_disclosure_active', {
    is: true,
    then: yup.string().required('Required field!'),
  }),
  custom_disclosure_text: yup.string().when('custom_disclosure_active', {
    is: true,
    then: yup.string().required('Required field!'),
  }),
});
