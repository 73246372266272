import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  actionsBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& button': {
      textTransform: 'capitalize',
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  actionButtonsWrapper: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  contentWrapper: {
    marginTop: theme.spacing(3),
  },
  card: {
    overflow: 'hidden',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  cardHeader: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    backgroundColor: '#E9ECF2',
  },
  cardContent: {
    backgroundColor: '#fefefe',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    '&:first-child': {
      paddingTop: theme.spacing(3),
    },
    '&:last-child': {
      paddingBottom: theme.spacing(3),
    },
  },
});
