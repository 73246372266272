import { SET_SORT_DATA, setSortActionType } from '../types/sort';

export interface FilterState {
  [key: string] : {
    [key: string]: string,
  }
}

const initialState: FilterState = {
  customers: {
    firstName: '',
    lastName: '',
    lastUse: '',
    created: '',
  },

  properties: {
    propertyId: '',
    subdivision: '',
    lockNum: '',
    lockStatus: '',
    lockDisposition: '',
    address: '',
    installDate: '',
  },

  visits: {
    firstName: '',
    lastName: '',
    cell: '',
    type: '',
    status: '',
    subdivision: '',
    address: '',
    platform: '',
    visitDate: '',
  },
};

export default function SortReducer(
  state = initialState,
  action: setSortActionType,
) {
  switch (action.type) {
    case SET_SORT_DATA: {
      return {
        ...state,
        [action.payload.type]: {
          ...state[action.payload.type],
          [action.payload.name]: action.payload.value,
        },

      };
    }

    default: {
      return state;
    }
  }
}
