import { createSelector } from 'reselect';
import { AppState } from '../store';
import { CommunitiesState, Community } from '../types/communities';

const selectCommunitiesState = (state: AppState) => state.communities;
const selectQRCodes = (state: CommunitiesState) => state.qrCodes;
const selectCommunities = (state: CommunitiesState) => state.communities;
const selectLoading = (state: CommunitiesState) => state.loading;
const selectTotal = (state: CommunitiesState) => state.total;
const getSelected = (state: CommunitiesState) => state.selected;
const selectExternalIds = (state: CommunitiesState) => state.communities.reduce(
  (acc: string[], community: Community) => {
    if (
      community.external_community_id
      && acc.indexOf(community.external_community_id) === -1
    ) {
      acc.push(community.external_community_id);
    }
    return acc;
  }, [],
);

const getBuilderCommunities = (state: CommunitiesState) => state.builderCommunities;
const selectSaveStatus = (state: CommunitiesState) => state.successfullySaved;
const selectDeleted = (state: CommunitiesState) => state.successfullyDeleted;
const selectStatuses = (state: CommunitiesState) => state.statuses;
const selectLockCommunities = (state: CommunitiesState) => state.lockCommunities;

export const selectAllCommunities = createSelector(
  selectCommunitiesState,
  selectCommunities,
);

export const selectCommunitiesRequestStatus = createSelector(
  selectCommunitiesState,
  selectLoading,
);

export const getTotalCommunities = createSelector(
  selectCommunitiesState,
  selectTotal,
);

export const selectCommunitySelected = createSelector(
  selectCommunitiesState,
  getSelected,
);

export const selectCommunityExternalIds = createSelector(
  selectCommunitiesState,
  selectExternalIds,
);

export const selectSavedStatus = createSelector(
  selectCommunitiesState,
  selectSaveStatus,
);

export const selectBuilderCommunities = createSelector(
  selectCommunitiesState,
  getBuilderCommunities,
);

export const selectCommunitiesDeleted = createSelector(
  selectCommunitiesState,
  selectDeleted,
);

export const selectQRCodesData = createSelector(
  selectCommunitiesState,
  selectQRCodes,
);

export const getCommunityStatuses = createSelector(
  selectCommunitiesState,
  selectStatuses,
);

export const getLockCommunitiesState = createSelector(
  selectCommunitiesState,
  selectLockCommunities,
);
