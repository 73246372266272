import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Grid from '@material-ui/core/Grid';
// import { SingleSelect } from 'react-select-material-ui';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Button } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useRouteMatch } from 'react-router-dom';
import { Agent } from '../../redux/types/agents';
import useStyles from './styles';
import { getUsername } from '../../redux/selectors/auth';
import { createErrorSnackBar } from '../../redux/actions/snackbars';

type Options = {
  value: Agent
  label:string
};

type Props = {
  availableAgents: Array<Agent>;
  assignedAgents: Array<Agent>;
  handleInputChange: (value: Agent) => void;
  onAddAgent: () => void;
  onRemoveAgent: (agent: Agent) => void;
  newAgent: Agent;
};


const AvailableAgents: React.FC<Props> = (props) => {
  const {
    availableAgents,
    handleInputChange,
    assignedAgents,
    onAddAgent,
    onRemoveAgent,
    newAgent,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(getUsername);
  const { path } = useRouteMatch();
  const isCommunitiesPath = path.split('/')[1] === 'communities';

  const [availableAgentsOptions, setAvailableAgentsOptions] = useState<Options[]>([]);
  const [value, setValue] = useState<Options | null | undefined>(null);

  useEffect(() => {
    const options = availableAgents
      .filter((agent) => {
        const assignedAgent = assignedAgents.find(({ username }) => agent.username === username);
        const isContractor = agent?.groups !== undefined && agent?.groups.find(({ GroupID }) => GroupID === 7);
        const isOtherStaff = agent?.groups !== undefined && agent?.groups.find(({ GroupID }) => GroupID === 1);

        return agent.username !== newAgent.username && !assignedAgent && !isContractor && !isOtherStaff;
      })
      .sort((a: Agent, b: Agent) => -(Number(a?.is_project_manager) - Number(b?.is_project_manager)))
      .map((obj) => ({
        value: obj,
        label: `${obj?.is_project_manager ? 'PM' : 'SS'} ${obj?.firstname} ${obj?.lastname}`,
      }));

    setAvailableAgentsOptions(options);
  }, [availableAgents, assignedAgents, newAgent]);

  return (
    <List component="ul">
      <ListItem>
        <ListItemAvatar><></></ListItemAvatar>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={8}>
            <Autocomplete
              value={value}
              id="availableAgents"
              noOptionsText="No agents were found"
              options={availableAgentsOptions}
              onChange={(
                e: React.ChangeEvent<{}>,
                selected: Options | null,
              ) => {
                if (!selected) {
                  return;
                }

                setValue(selected);
                handleInputChange(selected?.value);
              }}
              getOptionLabel={(option) => option.label}
              renderOption={(option) => <span>{option.label}</span>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Available Agents"
                  variant="standard"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={4}
            direction="row"
            alignContent="flex-end"
          >
            <Grid item xs={12} sm={12}>
              <Button
                className={classes.addButton}
                disabled={newAgent.username === ''}
                onClick={() => {
                  onAddAgent();
                  setValue(null);
                }}
              >
                <AddIcon />
                Add Agent
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>

      {assignedAgents.map((agent) => {
        const agentRole = agent.is_project_manager ? 'PM' : 'SS';
        const listItemTextContent = `${agentRole}: ${agent.firstname} ${agent.lastname}`;
        const handleClick = () => {
          if (currentUser === agent.username && !isCommunitiesPath) {
            dispatch(createErrorSnackBar('You cannot remove yourself'));
            return;
          }

          onRemoveAgent(agent);
        };
        const { email, phone } = agent;

        return (
          <ListItem
            component="li"
            key={listItemTextContent}
            className={classes.mainList}
          >
            <ListItemAvatar>
              <Avatar component="div" className={classes.icon}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <List>
              <ListItemText>{listItemTextContent}</ListItemText>
              {email && <ListItemText>{`Email: ${email}`}</ListItemText>}
              {phone && <ListItemText>{`Phone: ${phone}`}</ListItemText>}
            </List>
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={handleClick}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

export default React.memo(AvailableAgents);
