import React, { useEffect } from 'react';
import {
  RouteComponentProps,
} from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import Spinner from '@material-ui/core/CircularProgress';
import BrandHeader from './BrandHeader';
import InvoiceTable from './InvoiceTable';
import InvoiceDetailsTable from './InvoiceDetailsTable';
import { ReactComponent as Logo } from '../../assets/menu.svg';
import styles from './styles';
import { formatUnixDate } from '../../utils/unix-date';
import { Invoice } from '../../redux/types/billing';


const mainInfoHeaders = ['Date', 'Invoice#'];
const billTableHeader = ['Bill To'];

type Props = {
  loadInvoiceDetails: (params: object) => void,
  invoiceDetails: Invoice | null,
} & WithStyles<typeof styles> & RouteComponentProps<{ id: string }>;

const InvoiceDetails: React.FC<Props> = (props) => {
  const {
    loadInvoiceDetails, invoiceDetails, classes, match,
  } = props;

  const customer = invoiceDetails && invoiceDetails.customer;
  const mainInfoDetails = invoiceDetails && [[`${formatUnixDate(Number(invoiceDetails.date))}`, invoiceDetails.id]];
  const billDetails = customer && [[customer.company],
    [`Attn ${customer?.first_name ?? ''} ${customer?.last_name ?? ''}`],
    [customer?.billing_address?.line1 ?? ''],
    [customer?.billing_address?.city ? `${customer?.billing_address?.city ?? ''}, ${customer?.billing_address?.state_code ?? ''} ${customer?.billing_address?.zip ?? ''}` : ''],
  ];
  const currentInvoiceId = match.params.id;

  useEffect(() => {
    loadInvoiceDetails({ invoiceId: currentInvoiceId });
  }, [loadInvoiceDetails, currentInvoiceId]);

  if (!invoiceDetails || (invoiceDetails && invoiceDetails.id !== currentInvoiceId)) {
    return (
      <div className={classes.spinnerWrapper}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={classes.content}>
      <>
        <Grid container justify="flex-start" wrap="nowrap">
          <Grid item>
            <Logo />
          </Grid>
          <BrandHeader />
          <div>
            <p className={classes.invoiceHeader}>Invoice</p>
            <InvoiceTable
              headers={mainInfoHeaders}
              invoiceDetails={invoiceDetails}
              tableBody={mainInfoDetails}
            />
          </div>
        </Grid>
        <Grid container justify="flex-start" wrap="wrap">
          <InvoiceTable
            headers={billTableHeader}
            invoiceDetails={invoiceDetails}
            tableBody={billDetails}
            hideBorder
            innerBorder
            billTable
            bodyClass
          />
        </Grid>
        <Grid container alignItems="flex-end" wrap="nowrap" direction="column">
          <InvoiceDetailsTable invoiceDetails={invoiceDetails} />
        </Grid>
        <InvoiceTable
          headers={['Phone #']}
          invoiceDetails={invoiceDetails}
          tableBody={[['678-910-1811']]}
          alignSelfLeft
        />
      </>
    </div>
  );
};

export default InvoiceDetails;
