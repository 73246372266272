import { ErrorMessage, ValueOf } from '../../types/main';
import { Agent } from './agents';
import * as TeamsActions from '../actions/teams';

export const TEAMS_REQUEST = 'TEAMS_REQUEST';
export const TEAMS_REQUEST_SUCCESS = 'TEAMS_REQUEST_SUCCESS';
export const TEAMS_REQUEST_FAILED = 'TEAMS_REQUEST_FAILED';

export const TEAMS_BY_BUILDER_REQUEST = 'TEAMS_BY_BUILDER_REQUEST';
export const TEAMS_BY_BUILDER_REQUEST_SUCCESS = 'TEAMS_BY_BUILDER_REQUEST_SUCCESS';
export const TEAMS_BY_BUILDER_REQUEST_FAILED = 'TEAMS_BY_BUILDER_REQUEST_FAILED';

export const TEAM_DETAILS_REQUEST = 'TEAM_DETAILS_REQUEST';
export const TEAM_DETAILS_REQUEST_SUCCESS = 'TEAM_DETAILS_REQUEST_SUCCESS';
export const TEAM_DETAILS_REQUEST_FAILED = 'TEAM_DETAILS_REQUEST_FAILED';

export const CREATE_TEAM_REQUEST = 'CREATE_TEAM_REQUEST';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_FAILED = 'CREATE_TEAM_FAILED';

export const UPDATE_TEAM_REQUEST = 'UPDATE_TEAM_REQUEST';
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS';
export const UPDATE_TEAM_FAILED = 'UPDATE_TEAM_FAILED';

export const TEAMS_DELETE_REQUEST = 'TEAMS_DELETE_REQUEST';
export const TEAMS_DELETE_SUCCESS = 'TEAMS_DELETE_SUCCESS';
export const TEAMS_DELETE_FAILED = 'TEAMS_DELETE_FAILED';

export const ADD_TEAM_AGENT_REQUEST = 'ADD_TEAM_AGENT_REQUEST';
export const ADD_TEAM_AGENT_SUCCESS = 'ADD_TEAM_AGENT_SUCCESS';
export const ADD_TEAM_AGENT_FAILED = 'ADD_TEAM_AGENT_FAILED';

export const DELETE_TEAM_AGENT_REQUEST = 'DELETE_TEAM_AGENT_REQUEST';
export const DELETE_TEAM_AGENT_SUCCESS = 'DELETE_TEAM_AGENT_SUCCESS';
export const DELETE_TEAM_AGENT_FAILED = 'DELETE_TEAM_AGENT_FAILED';

export type TeamsActionsTypes = ReturnType<ValueOf<typeof TeamsActions>>;

export interface TeamsRequestSuccess {
  items: Team[];
  total: number;
}

export interface Team {
  id: number;
  name: string;
  description: string;
  total_members: number;
  agents?: Agent[];
  created_on: string;
  builder_id?: number;
}

export interface TeamFormType {
  name: string;
  description: string;
  agents: Agent[];
  builderId?: number
}

export interface TeamsState {
  items: Team[];
  loading: boolean;
  updating: boolean;
  total: number;
  error: ErrorMessage['message'];
  selected: Team | null;
}
