import { createSelector } from 'reselect';
import { AppState } from '../store';

const selectAppResourcesState = (state: AppState) => state.appResources;
const selectAppInit = (state: AppState['appResources']) => state.appInit;

// eslint-disable-next-line import/prefer-default-export
export const selectAppInitState = createSelector(
  selectAppResourcesState,
  selectAppInit,
);
