import axios from './axios';
import { Query, buildQuery } from '../utils/query';
import { ExportAllParams } from '../redux/types/exportAll';

export const loadOffers = (queryObject?: Query) => {
  const query = buildQuery(queryObject);

  return axios.get(`/offers${query}`);
};

export const loadOfferDetails = (offerId: number) => {
    return axios.get(`/offers/${offerId}`);
  }
  
export const loadOffersExport = (
  selected: Array<number | string>,
  accept: 'application/pdf' | 'text/csv' = 'application/pdf',
) => {
  const headers = {
    Accept: accept,
  };
  return axios.post(
    '/offers/export',
    {
        selected,
    },
    { headers, responseType: 'blob' },
  );
};

export const loadExportAll = (path: string, accept: 'application/pdf' | 'text/csv' = 'application/pdf', params: ExportAllParams) => {
  const headers = {
    Accept: accept,
  };

  return axios.post(
    `/offers/exportAll`,
    { params },
    { headers, responseType: 'blob' },
  );
};
