import {
  FEEDBACK_REQUEST,
  FEEDBACK_REQUEST_SUCCESS,
  FEEDBACK_REQUEST_FAILED,
} from '../types/feedback';

export const sendFeedback = (payload: string) => ({
  type: FEEDBACK_REQUEST,
  payload,
} as const);

export const sendFeedbackSuccess = () => ({
  type: FEEDBACK_REQUEST_SUCCESS,
} as const);

export const sendFeedbackFailed = () => ({
  type: FEEDBACK_REQUEST_FAILED,
} as const);
