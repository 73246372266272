import { Property } from './properties';
import * as CommunitiesActions from '../actions/communities';
import { ErrorMessage, ValueOf } from '../../types/main';
import { Agent } from './agents';

export const COMMUNITIES_REQUEST = 'COMMUNITIES_REQUEST';
export const COMMUNITIES_REQUEST_SUCCESS = 'COMMUNITIES_REQUEST_SUCCESS';
export const COMMUNITIES_REQUEST_FAILED = 'COMMUNITIES_REQUEST_FAILED';
export const COMMUNITIES_DETAILS_REQUEST = 'COMMUNITIES_DETAILS_REQUEST';
export const QR_CODES_REQUEST = 'QR_CODES_REQUEST';
export const QR_CODES_REQUEST_SUCCESS = 'QR_CODES_REQUEST_SUCCESS';
export const COMMUNITIES_DETAILS_SUCCESS = 'COMMUNITIES_DETAILS_SUCCESS';
export const COMMUNITIES_DETAILS_FAILED = 'COMMUNITIES_DETAILS_FAILED';
export const COMMUNITIES_EXTERNAL_IDS_REQUEST = 'COMMUNITIES_EXTERNAL_IDS_REQUEST';
export const SAVE_COMMUNITY = 'SAVE_COMMUNITY';
export const SAVE_COMMUNITY_SUCCESS = 'SAVE_COMMUNITY_SUCCESS';
export const CANCEL_SUCCESSFULLY_SAVE = 'CANCEL_SUCCESSFULLY_SAVE';
export const BUILDER_COMMUNITIES = 'BUILDER_COMMUNITIES';
export const COMMUNITIES_STATUSES_SUCCESS = 'COMMUNITIES_STATUSES_SUCCESS';
export const COMMUNITIES_STATUSES = 'COMMUNITIES_STATUSES';
export const BUILDER_COMMUNITIES_SUCCESS = 'BUILDER_COMMUNITIES_SUCCESS';
export const COMMUNITIES_EXPORT_REQUEST = 'COMMUNITIES_EXPORT_REQUEST';
export const COMMUNITIES_DELETE_REQUEST = 'COMMUNITIES_DELETE_REQUEST';
export const QR_CODE_DELETE_REQUEST_SUCCESS = 'QR_CODE_DELETE_REQUEST_SUCCESS';
export const QR_CODE_DELETE_REQUEST = 'QR_CODE_DELETE_REQUEST';
export const COMMUNITIES_DELETE_REQUEST_SUCCESS = 'COMMUNITIES_DELETE_REQUEST_SUCCESS';
export const COMMUNITIES_DELETED_RESET = 'COMMUNITIES_DELETED_RESET';
export const SAVE_QR_CODE_REQUEST = 'SAVE_QR_CODE_REQUEST';
export const LOCK_COMMUNITIES_REQUEST = 'LOCK_COMMUNITIES_REQUEST';
export const LOCK_COMMUNITIES_SUCCESS = 'LOCK_COMMUNITIES_SUCCESS';
export const LOCK_COMMUNITIES_FAILED = 'LOCK_COMMUNITIES_FAILED';
export const SMART_LOCK_COMMUNITIES_REQUEST = 'SMART_LOCK_COMMUNITIES_REQUEST';
export const SMART_LOCK_COMMUNITIES_SUCCESS = 'SMART_LOCK_COMMUNITIES_SUCCESS';
export const SMART_LOCK_COMMUNITIES_FAILED = 'SMART_LOCK_COMMUNITIES_FAILED';

export type CommunitiesActionsTypes = ReturnType<ValueOf<typeof CommunitiesActions>>;

export interface Community {
  id: number;
  name?: string;
  city?: string;
  state?: string;
  zip?: string;
  external_community_id?: string;
  created_on: string;
  created_by?: string;
  modified_on: string;
  modified_by?: string;
  builder_id?: number | null;
  builderName: string;
  status?: string;
  sales_agent?: string;
  emergency_contacts?: string;
  community_url?: string;
  floor_plan_url?: string;
  virtual_tour_url?: string;
  additional_notes?: string;
  privacy_additional_notes?: string;
  privacy_logo?: string;
  weeklyTourHours?: WeeklyTourHours[] | null;
  lasso_community_project_id?: string,
  novihome_integration?: string,
  community_backup_code?: string,
  trade_code?: string,
  sales_code?: string,
  agents?: Agent[],
  teams?: number[]
}

export interface CommunitiesRequestSuccess {
  communities: Community[]
  total: number
}

export interface CommunitiesState {
  communities: Community[]
  total: number
  loading: boolean
  error: ErrorMessage['message']
  selected: SelectedCommunity | null
  successfullySaved: boolean
  successfullyDeleted: Array<string | number>
  builderCommunities: BuilderCommunities[]
  qrCodes: QRCode[],
  statuses: CommunityStatus[],
  lockCommunities: string[],
}

export interface BuilderCommunities {
  id: number
  name: string
  city?: string
  state?: string
  tourHoursCommunity?: WeeklyTourHours[]
}

export interface QRCode {
  QR_code_text: string
  QR_code_name: string
  id: number
}

export interface CommunityStatus {
  status_id: string
  description: string
  status: string
}

export interface QRInput {
  qrCodeData: string
  qrCodeName: string
  id: number
}

export interface SelectedCommunity {
  community: Community | null;
  communityProperties: Property[];
  qrCodes: QRCode[];
  communityTourHours: TourHoursType[] | null;
  agents: Agent[],
  teams: number[]
}


export type WeeklyTourHours = {
  day: string;
  checked: boolean;
  tourHoursStart: number;
  tourHoursEnd: number;
  isSecondWindowEnabled?: boolean;
  secondWindowStart?: { hour: number; minute: number } | null
  secondWindowEnd?: { hour: number; minute: number } | null
  startTime: { hour: number; minute: number } | null;
  endTime: { hour: number; minute: number } | null;
};

export type TourHoursType = {
  dayOfWeek: number;
  tourHoursStart: string | null;
  tourHoursEnd: string | null;
  isToursAvailable: boolean;
  secondWindowStart?: { hour: number; minute: number } | null
  secondWindowEnd?: { hour: number; minute: number } | null
  isSecondWindowEnabled?: boolean;
};
