import { createMuiTheme, Theme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';

const orange = {
  main: '#DB5C0E',
  light: '#F4D18C',
};

const theme: Theme = createMuiTheme({
  palette: {
    text: {
      primary: 'rgba(4, 6, 32, 1)',
      secondary: 'rgba(151, 151, 151, 1)',
    },
  },
  typography: {
    fontFamily: '"Source Sans Pro", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  appPalette: {
    appBackgroundColor: '#F4F6F9',
    orange: orange.main,
  },
  overrides: {
    MuiTabs: {
      root: {
        color: '#8E8B99',
        '& .Mui-selected': {
          color: orange.main,
        },
        '& .MuiTab-wrapper': {
          textTransform: 'none',
          fontSize: '16px',
        },
      },

      indicator: {
        backgroundColor: orange.main,
      },
    },

    MuiButton: {
      root: {
        textTransform: 'unset',
        color: orange.main,
        '&:hover': {
          backgroundColor: fade(orange.main, 0.05),
        },
      },
      contained: {
        backgroundColor: '#fff',
        color: orange.main,
      },
      containedPrimary: {
        backgroundColor: orange.main,
        color: '#fff',
        '&:hover': {
          backgroundColor: fade(orange.main, 0.45),
        },
      },
    },

    MuiFormControl: {
      root: {
        background: '#FFFFFF',
        border: '1px solid #DBDDE3',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.25)',
        borderRadius: 5,
      },
    },

    MuiSelect: {
      root: {
        fontFamily: 'Source Sans Pro',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 16,
        display: 'flex',
        alignItems: 'flex-end',
        color: 'rgba(0, 0, 0, 0.87)',
        marginLeft: 12,
      },
    },

    MuiTextField: {
      root: {
        background: '#FFFFFF',
        border: '1px solid #DBDDE3',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 1px rgba(0, 0, 0, 0.25)',
        borderRadius: 5,
        '& > label.MuiFormLabel-filled, label.Mui-focused': {
          height: 12,
          left: 12,
          top: 6,
        },
        '& > label': {
          height: 12,
          left: 12,
          top: -6,
        },
        '& > div > input': {
          fontFamily: 'Source Sans Pro',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: 16,
          lineHeight: 1.5,
          display: 'flex',
          alignItems: 'flex-end',
          color: 'rgba(0, 0, 0, 0.87)',
          marginLeft: 12,
        },
        '&:focus-within': {
          background: 'rgba(219, 92, 14, 0.08)',
          mixBlendMode: 'normal',
          border: '1px solid #DB5C0E',
        },
        // backgroundColor: orange.main,
        '& label.Mui-focused': {
          color: orange.main,
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: orange.main,
        },
        '& .MuiFilledInput-root': {
          '&:-internal-autofill-selected ': {
            backgroundColor: orange.main,
          },
        },
        '& .MuiFilledInput-underline:after': {
          borderBottomColor: orange.main,
        },
      },
    },

    MuiCircularProgress: {
      colorPrimary: {
        color: orange.main,
      },
    },

    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },

    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: orange.light,
      },
      barColorPrimary: {
        backgroundColor: orange.main,
      },
    },

    MuiCheckbox: {
      colorSecondary: {
        '&.Mui-checked, &.MuiCheckbox-indeterminate': {
          color: orange.main,
        },
      },
    },

    MuiSwitch: {
      colorSecondary: {
        '&.Mui-checked': {
          color: orange.main,
          '&&:hover': {
            backgroundColor: fade(orange.main, 0.08),
          },
        },
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: orange.main,
        },
      },
    },
  },
});

export default theme;
