import { createStyles } from '@material-ui/core';

export default () => createStyles({
  widgetsWrapper: {
    marginTop: 33,
  },
  drawerRoot: {
    maxWidth: '489px',
    width: '100%',
  },
});
