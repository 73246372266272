import history from '../browserHsitory';

const storage = window.localStorage;

export const addItem = (key: string, value: string): void => {
  storage.setItem(key, value);
};

export const getItem = (key: string): string => storage.getItem(key) || '';

export const removeItem = (key: string): void => {
  storage.removeItem(key);
};

export const clear = (): void => {
  storage.clear();
};

interface Data {
  [key: string]: string | number;
}

export const saveListState = (listName: string) => (data: Data) => {
  const savedCustomers = getItem(listName);
  if (!savedCustomers) {
    const state = JSON.stringify(data);
    addItem(listName, state);
  } else {
    const state = JSON.parse(savedCustomers);
    const newState = Object.entries(data).reduce((acc, entry) => ({
      ...acc,
      [entry[0]]: entry[1],
    }), state);

    addItem(listName, JSON.stringify(newState));
  }
};

export const clearStorage = (name: string) => () => {
  const pageNameIndex = 1;
  const pathElements = history.location.pathname.split('/');
  const pathname = pathElements[pageNameIndex];

  if (name === 'visits' && pathname === 'customers' && pathElements.length === 3) {
    return;
  }

  if ((pathname !== name)) {
    removeItem(name);
  }
};
