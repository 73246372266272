import { Theme, createStyles, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  contentWrapper: {
    marginTop: '33px',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: theme.shadows[3],
  },
  tableWrapper: {
    overflowX: 'auto',
    position: 'relative',
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
