import * as SortActions from '../actions/sort';
import { ValueOf } from '../../types/main';

export const SET_SORT_DATA = 'SET_SORT_DATA';

export type setSortActionType = ReturnType<ValueOf<typeof SortActions>>;

export interface Sort {
  [key: string]: string
}
