import { ErrorMessage, ValueOf } from '../../types/main';
import * as SmartLocksActions from '../actions/smartLocks';

export const SMART_LOCKS_REQUEST = 'SMART_LOCKS_REQUEST';
export const SMART_LOCKS_REQUEST_SUCCESS = 'SMART_LOCKS_REQUEST_SUCCESS';
export const SMART_LOCKS_REQUEST_FAILED = 'SMART_LOCKS_REQUEST_FAILED';
export const SMART_LOCK_DETAILS_REQUEST = 'SMART_LOCK_DETAILS_REQUEST';
export const REFRESH_SMART_LOCK_DETAILS_REQUEST = 'REFRESH_SMART_LOCK_DETAILS_REQUEST';
export const SMART_LOCK_DETAILS_REQUEST_SUCCESS = 'SMART_LOCK_DETAILS_REQUEST_SUCCESS';
export const SMART_LOCK_DETAILS_REQUEST_FAILED = 'SMART_LOCK_DETAILS_REQUEST_FAILED';
export const UPDATE_SMART_LOCK_CODE_REQUEST = 'UPDATE_SMART_LOCK_CODE_REQUEST';
export const UPDATE_SMART_LOCK_CODE_REQUEST_SUCCESS = 'UPDATE_SMART_LOCK_CODE_REQUEST_SUCCESS';
export const UPDATE_SMART_LOCK_CODE_REQUEST_FAILED = 'UPDATE_SMART_LOCK_CODE_REQUEST_FAILED';
export const DELETE_SMART_LOCK_CODE_REQUEST = 'DELETE_SMART_LOCK_CODE_REQUEST';
export const DELETE_SMART_LOCK_CODE_REQUEST_SUCCESS = 'DELETE_SMART_LOCK_CODE_REQUEST_SUCCESS';
export const DELETE_SMART_LOCK_CODE_REQUEST_FAILED = 'DELETE_SMART_LOCK_CODE_REQUEST_FAILED';

export type SmartLocksActionsTypes = ReturnType<
ValueOf<typeof SmartLocksActions>
>;

export interface SmartLock {
  builder_id: number;
  property_id: number;
  address_1: string;
  house_num: string;
  smart_lock_status_value: string;
  serial_number: string;
  device_id: string;
  smart_lock_type_id: number;
  comments: string;
  created_on: string;
  modified_on: string;
  community_name?: string;
  supported_code_lengths?: number[]
  name?: string;
  battery_level?: number;
  locked?: boolean;
  accessCodes?: SmartLockCode[];
}

export type SmartLockCode = {
  name: string;
  code: string;
  device_id?: string
  status?: string;
  access_code_id?: string;
};

export type DeleteSmartLockCode = {
  smartLockSerial: string;
  access_code_id: string;
};

export type SmartLocksRequestSuccess = SmartLock[];
export type SmartLockDetailsRequestSuccess = SmartLock;

export type SmartLocksState = {
  items: SmartLock[];
  loading: boolean;
  refreshing: boolean;
  error: ErrorMessage['message'];
  selected: SmartLock | null;
};
