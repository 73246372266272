import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  logoWrapper: {
    textAlign: 'center',
    margin: '35px 0',
    lineHeight: 0,
    height: '95px',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
  },
  card: {
    borderRadius: 10,
    height: '100%',
  },
  apiName: {
    color: '#DB5C0E',
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: '28px',
  },
  apiDescription: {
    fontSize: '16px',
    lineHeight: '20px',
    color: '#979797',
    '& .deviceId': {
      fontWeight: 'bold',
      color: '#000',
    },
  },
  apiAction: {
    textAlign: 'center',
    marginTop: '20px'
  },
  controlButton: {
    backgroundColor: theme.appPalette.orange,
    color: '#000',
    '&:hover': {
      color: theme.appPalette.orange,
    },
  },
});
