import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  searchBarRoot: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[3],
    '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.appPalette.orange,
    },
    '&, & fieldset.MuiOutlinedInput-notchedOutline': {
      borderRadius: 10,
    },
  },
  searchBarIcon: {
    color: '#757575',
  },
});
