import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import { STATES_REQUEST } from '../types/states';

import { loadStates } from '../../services/states';
import {
  statesRequestFailed,
  statesRequestSuccess,
} from '../actions/states';

function* loadStatesSaga() {
  try {
    const { data } = yield call(loadStates);
    yield put(statesRequestSuccess(data));
  } catch (err) {
    yield put(statesRequestFailed(err));
  }
}

export default all([
  takeLatest<any>(STATES_REQUEST, loadStatesSaga),
]);
