export enum PropertyTabs {
  TIMELINE,
  AGENTS,
  AUTOMATIONS,
  INTEGRATIONS,
  TOUR_HOUR,
  HUB,
  LOCK_HISTORIES,
  SMART_LOCK
}
