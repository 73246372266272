import { ErrorMessage } from '../../types/main';
import { Query } from '../../utils/query';
import { Agent } from '../types/agents';
import {
  TEAMS_REQUEST,
  TEAMS_REQUEST_FAILED,
  TEAMS_REQUEST_SUCCESS,
  TEAMS_DELETE_REQUEST,
  TEAMS_DELETE_SUCCESS,
  TEAMS_DELETE_FAILED,
  TeamFormType,
  TeamsRequestSuccess,
  TEAM_DETAILS_REQUEST,
  TEAM_DETAILS_REQUEST_SUCCESS,
  TEAM_DETAILS_REQUEST_FAILED,
  Team,
  CREATE_TEAM_FAILED,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_REQUEST,
  UPDATE_TEAM_REQUEST,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_FAILED,
  ADD_TEAM_AGENT_REQUEST,
  ADD_TEAM_AGENT_SUCCESS,
  ADD_TEAM_AGENT_FAILED,
  DELETE_TEAM_AGENT_REQUEST,
  DELETE_TEAM_AGENT_SUCCESS,
  DELETE_TEAM_AGENT_FAILED,
  TEAMS_BY_BUILDER_REQUEST,
  TEAMS_BY_BUILDER_REQUEST_SUCCESS,
  TEAMS_BY_BUILDER_REQUEST_FAILED,
} from '../types/teams';

export const teamsRequest = (query?: Query) => ({
  type: TEAMS_REQUEST,
  payload: query,
} as const);

export const teamsRequestSuccess = (payload: TeamsRequestSuccess) => ({
  type: TEAMS_REQUEST_SUCCESS,
  payload,
} as const);

export const teamsRequestFailed = (payload: ErrorMessage) => ({
  type: TEAMS_REQUEST_FAILED,
  payload,
} as const);

export const teamsByBuilderRequest = (builderId: number) => ({
  type: TEAMS_BY_BUILDER_REQUEST,
  payload: builderId,
} as const);

export const teamsByBuilderRequestSuccess = (payload: TeamsRequestSuccess) => {
  return ({
    type: TEAMS_BY_BUILDER_REQUEST_SUCCESS,
    payload,
  } as const);
};

export const teamsByBuilderRequestFailed = (payload: ErrorMessage) => ({
  type: TEAMS_BY_BUILDER_REQUEST_FAILED,
  payload,
} as const);

export const teamDetailsRequest = (payload: number | string) => ({
  type: TEAM_DETAILS_REQUEST,
  payload,
} as const);

export const teamDetailsRequestSuccess = (payload: Team) => ({
  type: TEAM_DETAILS_REQUEST_SUCCESS,
  payload,
} as const);

export const teamDetailsRequestFailed = (payload: ErrorMessage) => ({
  type: TEAM_DETAILS_REQUEST_FAILED,
  payload,
} as const);

export const teamsDeleteRequest = (payload: Array<number | string>) => ({
  type: TEAMS_DELETE_REQUEST,
  payload,
} as const);

export const teamsDeleteSuccess = (payload: any) => ({
  type: TEAMS_DELETE_SUCCESS,
  payload,
} as const);

export const teamsDeleteFailed = (payload: ErrorMessage) => ({
  type: TEAMS_DELETE_FAILED,
  payload,
} as const);

export const createTeamRequest = (team: TeamFormType) => ({
  type: CREATE_TEAM_REQUEST,
  payload: team,
} as const);

export const createTeamSuccess = (team: TeamFormType) => ({
  type: CREATE_TEAM_SUCCESS,
  payload: team,
} as const);

export const createTeamFailed = (team: TeamFormType) => ({
  type: CREATE_TEAM_FAILED,
  payload: team,
} as const);

export const updateTeamRequest = (team: {
  teamId: number | string;
  team: TeamFormType;
}) => ({
  type: UPDATE_TEAM_REQUEST,
  payload: team,
} as const);

export const updateTeamSuccess = (team: TeamFormType) => ({
  type: UPDATE_TEAM_SUCCESS,
  payload: team,
} as const);

export const updateTeamFailed = (team: TeamFormType) => ({
  type: UPDATE_TEAM_FAILED,
  payload: team,
} as const);

export const addTeamAgentRequest = (payload: {
  teamId: number | string;
  agent: Agent;
}) => ({
  type: ADD_TEAM_AGENT_REQUEST,
  payload,
} as const);

export const addTeamAgentSuccess = (agent: any) => ({
  type: ADD_TEAM_AGENT_SUCCESS,
  payload: agent,
} as const);

export const addTeamAgentFailed = (agent: any) => ({
  type: ADD_TEAM_AGENT_FAILED,
  payload: agent,
} as const);

export const deleteTeamAgentRequest = (payload: {
  teamId: number | string;
  agent: Agent;
}) => ({
  type: DELETE_TEAM_AGENT_REQUEST,
  payload,
} as const);

export const deleteTeamAgentSuccess = (agent: any) => ({
  type: DELETE_TEAM_AGENT_SUCCESS,
  payload: agent,
} as const);

export const deleteTeamAgentFailed = (payload: ErrorMessage) => ({
  type: DELETE_TEAM_AGENT_FAILED,
  payload,
} as const);
