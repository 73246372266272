import React, { RefObject } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { Grid, SvgIcon } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { endOfDay, startOfDay } from 'date-fns';
import useFilterStateProvider from '../../hooks/useFilterStateProvider';
import styles from './styles';
import useUrlLocation from '../../hooks/useUrlLocation';
import { isValidDate } from '../../utils/date';
import { selectFilterState } from '../../redux/selectors/filter';
import {
  FilterPageKey,
  FilterPagePropertyKey,
  SetFilterPayload,
} from '../../redux/types/filter';
import { pagesWithDateFilter } from './constants';
import { DateFilterName } from './type';
import { selectBuilders } from '../../redux/selectors/builders';

type Props = {
  searchRequest?: () => void
  setFilterState: (payload: SetFilterPayload) => void
  clearFilterState: () => void
  searchBarRef: RefObject<HTMLDivElement> | null
} & WithStyles<typeof styles>;

type FilterElement = {
  [key: string]: string
};

const filterElement: FilterElement = {
  customerStatus: 'Customer Status',
  visitorType: 'Visitor Type',
  lastStatus: 'Visit Status',
  lockStatus: 'Lock Status',
  lockDisposition: 'Property Disposition',
  shipmentStatus: 'Shipment Status',
  community: 'Community',
  builder: 'Builder',
};

const SearchFilterElement: React.FC<Props> = (props) => {
  const {
    setFilterState, classes, searchBarRef, clearFilterState,
  } = props;

  const path = useUrlLocation() as FilterPageKey;
  const filterFields = useFilterStateProvider();
  const filterState = useSelector(selectFilterState);
  const enterpriseBuilders = useSelector(selectBuilders);
  const currentTab = filterState[path];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const paperWidth = (searchBarRef?.current?.clientWidth || 500) - (216 / 2) + 8;
  const paperHeight = searchBarRef?.current?.offsetTop || 500;

  const handleDateChange = (date: Date | null, name: DateFilterName) => {
    let convertedDate = null;

    if (date) {
      convertedDate = name === 'startDate' ? startOfDay(date) : endOfDay(date);
    }

    if (!convertedDate || (convertedDate && isValidDate(convertedDate))) {
      const page = pagesWithDateFilter.find((p) => p === path);
      if (page) {
        setFilterState({ type: page, name, value: convertedDate });
      }
    }
  };

  const handleClick = () => {
    if (searchBarRef && searchBarRef.current) {
      setAnchorEl(searchBarRef.current);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setInitialDate = () => {
    clearFilterState();
  };

  return (
    <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <SvgIcon>
          <path d="M0.392477 0C0.239263 0 0.10112 0.090411 0.0358153 0.228539C-0.0269773 0.366667 -0.00688366 0.529909 0.0935844 0.647945L7.6086 9.43037V21.6107C7.6086 21.7438 7.67893 21.8694 7.79195 21.9397C7.90498 22.0126 8.04815 22.0201 8.16871 21.9623L14.1717 19.1345C14.3098 19.0692 14.3952 18.9285 14.3952 18.7779V9.42786L21.9077 0.645434C22.0057 0.529909 22.0283 0.366667 21.9655 0.228539C21.9027 0.090411 21.7646 0.00251142 21.6138 0H0.392477Z" fill="#DB5C0E" />
        </SvgIcon>
        <h4>Filter</h4>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        keepMounted
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{
          top: paperHeight, left: paperWidth,
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {Object.entries(filterFields).map(([key, values]) => (
          <FormControl key={key} variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">{filterElement[key]}</InputLabel>
            <Select
              name={filterElement[key]}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={currentTab[key as FilterPagePropertyKey] ?? ''}
              onChange={(e) => {
                setFilterState({
                  name: key,
                  value: e.target.value,
                  type: path,
                });
              }}
              label={filterElement[key]}
            >
              {
                  values.map((value) => {
                    if (key === 'builder') {
                      const builderId = enterpriseBuilders.find((builder) => builder.name === value)?.builder_id;

                      return (
                        <MenuItem key={value} value={builderId}>{value}</MenuItem>
                      );
                    }

                    return (
                      <MenuItem key={value} value={value}>{value}</MenuItem>
                    );
                  })
                }
            </Select>
          </FormControl>
        ))}
        {pagesWithDateFilter.map((value) => value === path
          && (
            <FormControl key={value} variant="outlined" className={classes.formControl}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="start-date-picker-inline"
                  label="Start date"
                  value={'startDate' in currentTab ? currentTab.startDate : null}
                  onChange={(date: Date | null) => handleDateChange(date, 'startDate')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="end-date-picker-inline"
                  label="End date"
                  value={'endDate' in currentTab ? currentTab.endDate : null}
                  onChange={(date: Date | null) => handleDateChange(date, 'endDate')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          ))}

        <FormControl variant="outlined" className={classes.formControl}>
          <Grid container>
            {/* <Button */}
            {/*  variant="contained" */}
            {/*  color="primary" */}
            {/*  onClick={searchRequest} */}
            {/* > */}
            {/* Apply */}
            {/* </Button> */}
            <Button
              variant="contained"
              color="primary"
              onClick={setInitialDate}
            >
              Clear
            </Button>
          </Grid>
        </FormControl>
      </Menu>
    </>
  );
};

export default SearchFilterElement;
