import React, { useState, useEffect } from 'react';
import {
  Grid, Toolbar, Typography, Button,
} from '@material-ui/core';
import Spinner from '@material-ui/core/CircularProgress';
import { RouteComponentProps } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '../../components/CustomTablePagination';
import Table from '../../components/CustomTable';
import { ScheduledVisit } from '../../redux/types/scheduledVisit';
import useStyles from './styles';
import SearchBar from '../SearchBar';
import useScheduledVisits from './useScheduledVisits.hook';
import { Order } from '../../utils/table';
import { headRows } from '../../constants/scheduledVisits';
import { ROWS_PER_PAGE_OPTIONS } from '../../constants/table';

type Props = {
  loading: boolean;
  scheduledVisitsRequest: () => any;
  scheduledVisits: ScheduledVisit[];
  totalVisits: number;
  exportAllLoading: boolean;
  exportAllScheduledVisits: (path: string) => void;
  deleteVisits: (selected: number[]) => void;
  exportVisits: (selected: number[]) => void;
} & RouteComponentProps;

const searchBarPlaceholder: string = 'Search all scheduled visits';

const ScheduledVisits: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    scheduledVisitsRequest, history, loading,
    scheduledVisits, totalVisits, exportAllLoading,
    exportAllScheduledVisits, deleteVisits, exportVisits,
  } = props;
  const {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    setSearch,
    setOrder,
    setOrderBy,
    setPage,
    setRowsPerPage,
    dispatchVisits,
  } = useScheduledVisits(scheduledVisitsRequest);
  const [selected, setSelected] = useState<ScheduledVisit['id'][]>([]);
  const [visits, setVisits] = useState(scheduledVisits);
  const selectedLength = selected.length;

  useEffect(() => {
    addStatusStyle();
    setVisits(scheduledVisits);
  }, [scheduledVisits]);

  useEffect(() => {
    setSelected([]);
  }, [setSelected, page, rowsPerPage]);

  useEffect(() => () => {
    const pageNameIndex = 1;
    const pathElements = history.location.pathname.split('/');
    const pathname = pathElements[pageNameIndex];
    if (pathElements.length === 3) {
      return;
    }
    if ((pathname !== 'schedule-visit')) {
      dispatchVisits('RESET_STATE');
    }
  }, [dispatchVisits, history.location.pathname]);

  const addStatusStyle = () => {
    const row:any = headRows.filter((info) => info.key === 'status')[0];

    row.render = (visit: any) => {
      const status = visit.status.toLowerCase();

      const cellStyles = {
        color: status === 'created' ? '#1e72c5' : status === 'cancelled' ?  '#ba0f0f' : '#04A777'
      };
      return <TableCell style={cellStyles} align="center">{visit.status}</TableCell>;
    };
  };

  function handleCustomerVisitSelect(visit: ScheduledVisit) {
    history.push(`/schedule-visit/${visit.id}`);
  }

  function handleChangeOrder(newOrder: Order, newOrderBy: keyof ScheduledVisit) {
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    setPage(0);
    dispatchVisits('CHANGE_SCHEDULED_VISIT', { currentOrder: newOrder, currentOrderBy: newOrderBy, currentPage: 0 });
  }

  function handleChangePage(pageNumber: number) {
    setPage(pageNumber);
    // saveLocalState({ page: pageNumber });
    dispatchVisits('CHANGE_SCHEDULED_VISIT', { currentPage: pageNumber });
  }

  function handleChangeRowsPerPage(rowsPerPageAmount: number) {
    setPage(0);
    setRowsPerPage(rowsPerPageAmount);
    dispatchVisits('CHANGE_SCHEDULED_VISIT', { currentPage: 0, rowsPerPageAmount });
  }

  const handleSearch = (value: string): void => {
    setSearch(value);
    setPage(0);
    dispatchVisits('CHANGE_SCHEDULED_VISIT', { currentPage: 0, contextSearch: value });
  };

  function handleSelect(newSelected: any[]) {
    setSelected(newSelected);
  }

  function handleExport() {
    if (selectedLength !== 0) {
      exportVisits(selected);
    }
  }

  const exportAll = () => {
    exportAllScheduledVisits('schedule_visit');
  };

  function handleDelete() {
    if (selectedLength !== 0) {
      deleteVisits(selected);

      const filteredVisits = visits.filter((visit: ScheduledVisit) => !selected.includes(visit.id));

      setVisits(filteredVisits);
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs>
          <SearchBar
            // listName={VISITS}
            defaultValue={search}
            placeholder={searchBarPlaceholder}
            onChange={handleSearch}
          />
        </Grid>
      </Grid>
      <div className={classes.contentWrapper}>
        <Toolbar>
          <div>
            <Typography variant="h5">
              Scheduled Visits
              {selectedLength > 0 ? ` ${selectedLength} selected.` : ''}
            </Typography>
          </div>
          <div className={classes.spacer} />
          {selectedLength > 0 && (
            <div className={classes.toolbarActions}>
              <span>Selected actions: </span>
              <Button
                id="fs-export-scheduled-visit"
                size="small"
                disabled={selectedLength === 0}
                onClick={handleExport}
              >
                Export
              </Button>
              <Button
                size="small"
                disabled={selectedLength === 0}
                onClick={handleDelete}
              >
                Delete
              </Button>
            </div>
          )}
          {exportAllLoading ? (<Spinner size={20} />) : (
            <Button
              id="fs-export-scheduled-visits"
              size="small"
              onClick={exportAll}
              disabled={scheduledVisits.length === 0}
            >
              Export All
            </Button>
          )}
        </Toolbar>
        <div className={classes.tableWrapper}>
          {loading && (
            <div className={classes.spinnerWrapper}>
              <Spinner />
            </div>
          )}
          <Table<ScheduledVisit>
            className={classes.table}
            IDKey="id"
            selected={selected}
            data={visits}
            rowsPerPage={rowsPerPage}
            order={order}
            orderBy={orderBy}
            header={headRows}
            onSelect={handleSelect}
            onChangeOrder={handleChangeOrder}
            onRowClick={handleCustomerVisitSelect}
          />
        </div>
        <TablePagination
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          rowsPerPage={rowsPerPage}
          currentPage={page}
          rowsCount={scheduledVisits.length}
          rowsTotal={totalVisits}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default React.memo(ScheduledVisits);
