import { combineReducers } from 'redux';
import {
  APP_INIT,
  APP_INIT_FAILED,
  APP_INIT_SUCCESS,
} from '../../types/appInit';

const initialized = (state = false, action: any): boolean => {
  switch (action.type) {
    case APP_INIT:
    case APP_INIT_FAILED: {
      return false;
    }

    case APP_INIT_SUCCESS: {
      return true;
    }

    default: {
      return state;
    }
  }
};

const error = (state = '', action: any): string => {
  switch (action.type) {
    case APP_INIT:
    case APP_INIT_SUCCESS: {
      return '';
    }

    case APP_INIT_FAILED: {
      return 'Error in initializing app';
    }

    default: {
      return state;
    }
  }
};

export default combineReducers({
  error,
  initialized,
});
