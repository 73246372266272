import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import {
  CUSTOMERS_REQUEST,
  CUSTOMER_DETAILS_REQUEST,
  CUSTOMERS_EXPORT_REQUEST,
  CUSTOMERS_EXPORT_AND_PRINT_REQUEST, CustomersExportRequestActionType, UPDATE_CUSTOMER_REQUEST,
} from '../types/customers';
import {
  customerDetailsRequest,
  customerDetailsRequestSuccess,
  customerDetailsRequestFailed,
  customersExportRequestSuccess,
  customersExportRequestFailed,
  customersRequestSuccess,
  customersRequestFailed,
  customersRequest,
  updateCustomerRequest,
  updateCustomerRequestSuccess,
  updateCustomerRequestFailed,
} from '../actions/customers';

import { exportToFile } from '../../services/export';
import { openPrintDialog } from '../../services/print';

import {
  loadCustomers,
  loadCustomerById,
  loadCustomersExport,
  updateCustomerById,
} from '../../services/customers';
import { createErrorSnackBar } from '../actions/snackbars';


function* loadCustomersSaga(action: ReturnType<typeof customersRequest>) {
  try {
    const { data } = yield call(loadCustomers, action.payload);

    yield put(customersRequestSuccess(data));
  } catch (err) {
    yield put(customersRequestFailed(err));
  }
}

function* loadCustomerDetailsSaga(action: ReturnType<typeof customerDetailsRequest>) {
  try {
    const { data } = yield call(loadCustomerById, action.payload);

    yield put(customerDetailsRequestSuccess(data));
  } catch (err) {
    yield put(customerDetailsRequestFailed(err));
    yield put(
      createErrorSnackBar(err.response ? err.response.data.message : err.message),
    );
  }
}

function* loadCustomersExportSaga(
  action: CustomersExportRequestActionType,
) {
  try {
    const { selection, mimeType } = action.payload;
    const { data } = yield call(loadCustomersExport, selection, mimeType);
    const fileType = { type: mimeType } as const;
    const blob = new Blob([data], fileType);

    if (blob && action.type === CUSTOMERS_EXPORT_AND_PRINT_REQUEST) {
      openPrintDialog(blob);
    } else {
      const fileName = 'customers-export.csv';

      yield call(exportToFile, blob, fileType, fileName);
    }

    yield put(customersExportRequestSuccess());
  } catch (err) {
    yield put(customersExportRequestFailed(err));
  }
}

function* updateCustomerSaga(action: ReturnType<typeof updateCustomerRequest>) {
  try {
    const { data } = yield call(updateCustomerById, action.payload);

    yield put(updateCustomerRequestSuccess(data));
  } catch (err) {
    yield put(updateCustomerRequestFailed(err));
    yield put(
      createErrorSnackBar(err.response ? err.response.data.message : err.message),
    );
  }
}

export default all([
  takeLatest(CUSTOMERS_REQUEST, loadCustomersSaga),
  takeLatest(CUSTOMER_DETAILS_REQUEST, loadCustomerDetailsSaga),
  takeLatest(UPDATE_CUSTOMER_REQUEST, updateCustomerSaga),
  takeLatest(
    [CUSTOMERS_EXPORT_REQUEST, CUSTOMERS_EXPORT_AND_PRINT_REQUEST],
    loadCustomersExportSaga,
  ),
]);
