import { createSelector } from 'reselect';
import { AppState } from '../store';
import { HubsState } from '../types/hubs';

const selectHubState = (state: AppState): HubsState => state.hubs;

export const selectHubLoading = createSelector(
  selectHubState,
  (state: HubsState): HubsState['loading'] => state.loading,
);

export const selectHubUpdating = createSelector(
  selectHubState,
  (state: HubsState): HubsState['updating'] => state.updating,
);

export const selectHubRefreshLoading = createSelector(
  selectHubState,
  (state: HubsState): HubsState['refreshLoading'] => state.refreshLoading,
);

export const selectHubHasDevices = createSelector(
  selectHubState,
  (state: HubsState): boolean => Boolean(state.locks.length || state.lights.length),
);

export const selectHubLocks = createSelector(
  selectHubState,
  (state: HubsState): HubsState['locks'] => state.locks,
);

export const selectHubLights = createSelector(
  selectHubState,
  (state: HubsState): HubsState['lights'] => state.lights,
);

export const selectCurrentHubForm = createSelector(
  selectHubState,
  (state: HubsState): HubsState['currentHubFormName'] => state.currentHubFormName,
);

export const selectHubLockSelected = createSelector(
  selectHubState,
  (state: HubsState): HubsState['selectedLock'] => state.selectedLock,
);

export const selectHubLightSelected = createSelector(
  selectHubState,
  (state: HubsState): HubsState['selectedLight'] => state.selectedLight,
);

export const selectHubThermostatSelected = createSelector(
  selectHubState,
  (state: HubsState): HubsState['selectedThermostat'] => state.selectedThermostat,
);

export const selectHubMotionSensorSelected = createSelector(
  selectHubState,
  (state: HubsState): HubsState['selectedMotionSensor'] => state.selectedMotionSensor,
);

export const selectHubInfo = createSelector(
  selectHubState,
  (state: HubsState): HubsState['info'] => state.info,
);

export const selectHubLogs = createSelector(
  selectHubState,
  (state: HubsState): HubsState['lockLogs'] => state.lockLogs,
);

export const selectHubLogLoading = createSelector(
  selectHubState,
  (state: HubsState): HubsState['loading'] => state.loading,
);

export const selectHubs = createSelector(
  selectHubState,
  (state: HubsState): HubsState['hubs'] => state.hubs,
);
