import {
  CREATE_HUB_LOCK_CODE_FAILED,
  CREATE_HUB_LOCK_CODE_REQUEST,
  DELETE_HUB_LOCK_CODE_FAILED,
  DELETE_HUB_LOCK_CODE_REQUEST,
  DELETE_HUB_LOCK_CODE_SUCCESS,
  GET_HUB_DEVICES_FAILED,
  GET_HUB_DEVICES_REQUEST,
  GET_HUB_DEVICES_SUCCESS,
  GET_HUB_LOCK_LOG_REQUEST,
  GET_HUB_LOCK_LOG_SUCCESS,
  GET_HUB_LOCK_LOG_FAILED,
  HubLockCodeSlot,
  HubsActionsTypes,
  HubsState,
  REFRESH_HUB_DEVICES_REQUEST,
  SET_CURRENT_HUB_FORM_NAME,
  SET_HUB_SELECTED_LIGHT,
  SET_HUB_SELECTED_LOCK,
  SET_HUB_SELECTED_MOTION_SENSOR,
  SET_HUB_SELECTED_THERMOSTAT,
  UPDATE_HUB_LIGHT_FAILED,
  UPDATE_HUB_LIGHT_REQUEST,
  UPDATE_HUB_LIGHT_SUCCESS,
  UPDATE_HUB_LOCK_AUTO_LOCK_SUCCESS,
  UPDATE_HUB_LOCK_CODE_FAILED,
  UPDATE_HUB_LOCK_CODE_REQUEST,
  UPDATE_HUB_LOCK_CODE_SUCCESS,
  UPDATE_HUB_LOCK_CODES_REQUEST,
  UPDATE_HUB_LOCK_CODES_SUCCESS,
  UPDATE_HUB_LOCK_STATUS_FAILED,
  UPDATE_HUB_LOCK_STATUS_REQUEST,
  UPDATE_HUB_LOCK_STATUS_SUCCESS,
  GET_HUB_INFO_SUCCESS,
  GET_HUBS_REQUEST,
  GET_HUBS_SUCCESS,
} from '../types/hubs';
import {
  removeHubLockCode,
  updateHubLockAutoLock,
  updateHubLockCode,
  updateHubLockCodes,
  updateHubLockStatus,
  updateLightsByLightInput,
  updateSelectedLightByLights,
  updateSelectedLockByLocks,
} from '../../utils/hub';

const initialState: HubsState = {
  error: '',
  loading: false,
  refreshLoading: false,
  updating: false,
  currentHubFormName: null,
  locks: [],
  lights: [],
  selectedLock: null,
  selectedLight: null,
  selectedThermostat: null,
  selectedMotionSensor: null,
  info: {},
  hubs: [],
  lockLogs: [],
};

export default function HubsReducer(
  state = initialState,
  action: HubsActionsTypes,
): HubsState {
  switch (action.type) {
    case REFRESH_HUB_DEVICES_REQUEST:
      return { ...state, refreshLoading: true };

    case GET_HUBS_REQUEST:
      return { ...state, loading: true };

    case GET_HUB_DEVICES_REQUEST:
      return {
        ...state, loading: true, locks: [], lights: [],
      };

    case GET_HUB_DEVICES_FAILED:
      return { ...state, loading: false, refreshLoading: false };

    case UPDATE_HUB_LIGHT_REQUEST:
    case UPDATE_HUB_LOCK_STATUS_REQUEST:
    case CREATE_HUB_LOCK_CODE_REQUEST:
    case UPDATE_HUB_LOCK_CODE_REQUEST:
    case DELETE_HUB_LOCK_CODE_REQUEST:
    case UPDATE_HUB_LOCK_CODES_REQUEST:
      return { ...state, updating: true };

    case CREATE_HUB_LOCK_CODE_FAILED:
    case UPDATE_HUB_LOCK_CODE_FAILED:
    case DELETE_HUB_LOCK_CODE_FAILED:
    case UPDATE_HUB_LIGHT_FAILED:
    case UPDATE_HUB_LOCK_STATUS_FAILED:
      return { ...state, updating: false };

    case UPDATE_HUB_LOCK_STATUS_SUCCESS:
      return {
        ...state,
        updating: false,
        selectedLock: state.selectedLock && updateHubLockStatus(state.selectedLock, action.payload.status),
        locks: state.locks.length ? [updateHubLockStatus(state.locks[0], action.payload.status)] : [],
      };
    case UPDATE_HUB_LOCK_AUTO_LOCK_SUCCESS:
      return {
        ...state,
        updating: false,
        selectedLock: state.selectedLock && updateHubLockAutoLock(state.selectedLock, action.payload.autoLock),
        locks: state.locks.length ? [updateHubLockAutoLock(state.locks[0], action.payload.autoLock)] : [],
      };
    // case CREATE_HUB_LOCK_MASTER_CODE_SUCCESS:
    case UPDATE_HUB_LOCK_CODE_SUCCESS:
      return {
        ...state,
        updating: false,
        selectedLock: state.selectedLock && updateHubLockCode(state.selectedLock, action.payload),
        locks: state.locks.length ? [updateHubLockCode(state.locks[0], action.payload)] : [],
      };
    case UPDATE_HUB_LOCK_CODES_SUCCESS:
      return {
        ...state,
        updating: false,
        selectedLock: state.selectedLock && updateHubLockCodes(state.selectedLock, action.payload),
        locks: state.locks.length ? [updateHubLockCodes(state.locks[0], action.payload)] : [],
      };
    case DELETE_HUB_LOCK_CODE_SUCCESS:
      return {
        ...state,
        updating: false,
        selectedLock: state.selectedLock && removeHubLockCode(state.selectedLock, action.payload.codeKey as HubLockCodeSlot),
        locks: state.locks.length ? [removeHubLockCode(state.locks[0], action.payload.codeKey as HubLockCodeSlot)] : [],
      };

    case SET_CURRENT_HUB_FORM_NAME:
      return { ...state, currentHubFormName: action.payload };

    case SET_HUB_SELECTED_LOCK:
      return { ...state, selectedLock: action.payload };

    case SET_HUB_SELECTED_LIGHT:
      return { ...state, selectedLight: action.payload };

    case SET_HUB_SELECTED_MOTION_SENSOR:
      return { ...state, selectedMotionSensor: action.payload };

    case SET_HUB_SELECTED_THERMOSTAT:
      return { ...state, selectedThermostat: action.payload };

    case GET_HUB_DEVICES_SUCCESS:
      return {
        ...state,
        locks: action.payload.locks,
        lights: action.payload.lights,
        loading: false,
        refreshLoading: false,

        selectedLock: updateSelectedLockByLocks(state.selectedLock, action.payload.locks),
        selectedLight: updateSelectedLightByLights(state.selectedLight, action.payload.lights),
      };

    case GET_HUB_INFO_SUCCESS:
      return {
        ...state,
        info: action.payload,
      };

    case GET_HUB_LOCK_LOG_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_HUB_LOCK_LOG_FAILED:
      return {
        ...state,
        loading: false,
      };

    case GET_HUB_LOCK_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        lockLogs: action.payload,
      };

    case UPDATE_HUB_LIGHT_SUCCESS:
      return {
        ...state,
        updating: false,
        lights: updateLightsByLightInput(state.lights, action.payload),
      };
    case GET_HUBS_SUCCESS:
      return {
        ...state,
        hubs: action.payload,
      };
    default: {
      return state;
    }
  }
}
