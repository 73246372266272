import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => {
  return createStyles({
  root: {
    display: 'flex',
    height: '100vh',
  },
  content: {
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%',
    padding: '0 57px 55px 45px',
  },
})};
