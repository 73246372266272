import {
  useEffect, useState, useContext,
} from 'react';
import { Query } from '../../../utils/query';
import { Order } from '../../../utils/table';
import { VisitReport } from '../../../redux/types/visits';
import { SearchSortContext } from '../../../context/SearchSortContext';
import { ChangeSearchPayload, PageSearchSortContextActionType } from '../../../context/types';
import parseUrlFilterSearch from '../../../hooks/parseUrlFilterSearch';
import useUrlFilterSearch from '../../../hooks/useUrlFilterSearch';
import { UrlParam } from '../../../redux/types/filter';

interface UrlParamProperties extends UrlParam {
  builderId?: string | number | null
  startDate?: Date | null
  endDate?: Date | null
}

const useVisitsReport = (loadVisitsReport: (query: Query) => void) => {
  const urlParse = parseUrlFilterSearch<UrlParamProperties>();
  const { dispatch, state: { reports } } = useContext(SearchSortContext);
  const {
    currentOrderBy, currentOrder, currentPage, rowsPerPageAmount, contextSearch,
  } = reports;

  const [page, setPage] = useState<number>(urlParse.page || currentPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(urlParse.limit || rowsPerPageAmount);
  const [search, setSearch] = useState<string>(urlParse.search || contextSearch);
  const [order, setOrder] = useState<Order>(urlParse.order || currentOrder);
  const [orderBy, setOrderBy] = useState<keyof VisitReport>(urlParse.orderBy || currentOrderBy);
  const [builderId, setBuilderId] = useState<string | number | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useUrlFilterSearch<UrlParamProperties>({
    search, order, orderBy, page, limit: rowsPerPage, builderId, startDate, endDate,
  });

  const dispatchVisitsReport = (
    type: PageSearchSortContextActionType<'CHANGE_VISITS_REPORT'>,
    payload?: ChangeSearchPayload<VisitReport>,
  ) => {
    dispatch({ type, payload: { ...reports, ...payload } });
  };

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }

    loadVisitsReport({
      search, order, orderBy, page, limit: rowsPerPage, builderId, startDate, endDate,
    });
  }, [order, orderBy, page, rowsPerPage, search,
    loadVisitsReport, builderId, startDate, endDate]);

  const searchRequest = () => loadVisitsReport({
    search, order, orderBy, page, limit: rowsPerPage, startDate, endDate,
  });

  return {
    order,
    orderBy,
    page,
    search,
    rowsPerPage,
    builderId,
    startDate,
    endDate,
    setSearch,
    setOrder,
    setOrderBy,
    setBuilderId,
    setStartDate,
    setEndDate,
    setPage,
    setRowsPerPage,
    dispatchVisitsReport,
    searchRequest,
  };
};

export default useVisitsReport;
