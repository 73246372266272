import {
  Theme, createStyles, makeStyles,
} from '@material-ui/core';


const drawerWidth = 240;

const styles = (theme: Theme) => createStyles({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  smallLogo: {
    display: 'block',
    width: 'auto',
    height: 'auto',
    fontSize: 'initial',
  },
  menuItem: {
    marginTop: theme.spacing(3),
  },
  menuItems: {
    marginTop: theme.spacing(2),
  },
  logoWrapper: {
    paddingTop: 40,
  },
  menuItemText: {
    fontSize: '18px',
    color: '#8E8B99',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  newIcon: {
    width: '12%',
    marginLeft: 5,
  },
  betaIcon: {
    width: '15%',
  },
  sublink: {
    position: 'relative',
    marginLeft: '15px',
    paddingLeft: '46px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '1.25',
    color: '#8E8B99',

    '&::before': {
      position: 'absolute',
      top: '-12px',
      left: '0',
      content: '""',
      width: '2px',
      height: '48px',
      backgroundColor: '#E0E0E0',
    },
  },
  selectedLink: {
    color: '#DB5C0E',

    '&::before': {
      width: '3px',
      backgroundColor: 'currentColor',
    },
  },
  selectedSvgLink: {
    '& path': {
      fill: '#DB5C0E',
    },
  },
});

const useStyles = makeStyles(styles);

export default useStyles;
