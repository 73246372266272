import { createStyles } from '@material-ui/core';

export default () => createStyles({
  listItem: { display: 'flex', flexDirection: 'column', alignItems: 'start' },
  btnStyle: {backgroundColor: '#DB5C0E', color: 'white'},
  btnWrap: {display: 'flex', justifyContent: 'flex-end'},
  padTyphography: {
    paddingTop: '20px',
  },
});
