import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { WithStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import RefreshRounded from '@material-ui/icons/RefreshRounded';
import Spinner from '@material-ui/core/CircularProgress';
import styles from './styles';
import { clearFilter, setFilter } from '../../redux/actions/filter';
import { SetFilterPayload } from '../../redux/types/filter';
import DateRange from '../DateRange';
import { selectHubAccessHistoryFilters } from '../../redux/selectors/filter';

type Props = {
  onRefresh: () => void,
  loading?: boolean,
  setFilterState: (payload: SetFilterPayload) => void
  clearFilterState: () => void } & WithStyles<typeof styles>;

const TIMEOUT = 15;

const HubLockLog: React.FC<Props> = (props) => {
  const {
    children,
    onRefresh,
    setFilterState,
    loading,
    classes,
  } = props;
  const [time, setTime] = useState<number>(TIMEOUT);
  const { startDate, endDate } = useSelector(selectHubAccessHistoryFilters);

  const handleRefresh = () => {
    setTime(TIMEOUT);
    onRefresh();
  };

  // Count down to 0 and trigger onRefresh
  useEffect(() => {
    handleRefresh();
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 0) {
          onRefresh();
          return TIMEOUT;
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [startDate, endDate]);

  return (
    <>
      <div className={classes.flex}>
        <DateRange onChange={(start, end) => {
          setFilterState({ name: 'startDate', value: start, type: 'hubAccessHistory' });
          setFilterState({ name: 'endDate', value: end, type: 'hubAccessHistory' });
        }}
        />
        <Button
          startIcon={loading ? <Spinner size={14} /> : <RefreshRounded />}
          onClick={() => handleRefresh()}
          disabled={loading}
        >
          {loading ? 'Fetching...' : `Refresh ${time}s`}
        </Button>
      </div>
      <List>
        {children}
      </List>
    </>
  );
};

const mapDispatchToProps = {
  setFilterState: setFilter,
  clearFilterState: clearFilter,
};

const HubLockLogConnect = withStyles(styles)(connect(null, mapDispatchToProps)(HubLockLog));
export default React.memo(HubLockLogConnect);
