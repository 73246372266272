import { createStyles } from '@material-ui/core';

export default () => createStyles({
  contentWrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  },
});
