import {
  VISITS_REQUEST,
  VISITS_REQUEST_SUCCESS,
  VISITS_REQUEST_FAILED,
  VisitsState, VisitsActionsTypes, VISITS_REPORT_REQUEST, VISITS_REPORT_REQUEST_SUCCESS, VISITS_REPORT_REQUEST_FAILED,
} from '../types/visits';

const initialState: VisitsState = {
  items: [],
  visitsReport: [],
  total: 0,
  error: '',
  loading: false,
};

export default function VisitsReducer(
  state = initialState,
  action: VisitsActionsTypes,
): VisitsState {
  switch (action.type) {
    case VISITS_REPORT_REQUEST:
    case VISITS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case VISITS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total,
        error: '',
        loading: false,
      };
    }

    case VISITS_REPORT_REQUEST_SUCCESS: {
      return {
        ...state,
        visitsReport: action.payload.visitsReport,
        total: action.payload.total,
        error: '',
        loading: false,
      };
    }

    case VISITS_REPORT_REQUEST_FAILED:
    case VISITS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
