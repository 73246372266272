import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { Link, RouteComponentProps } from 'react-router-dom';

import useStyles from './styles';
import { Offer } from '../../redux/types/offers';

const initialOffer = {
  id: 0,
  property_id: 0,
  customer_id: 0,
  prequalified: false,
  move_in: '',
  comments: '',
  preferred_contact: '',
  firstname: '',
  lastname: '',
  phone: '',
  email: '',
};

type Props = {
  offerDetailsRequest: (offerId: number) => void;
  offerDetails: Offer | null;
  isRental: boolean
} & RouteComponentProps<{ offerId: string }>;

const OfferDetails: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { offerDetailsRequest, match, offerDetails, isRental } = props;
  const [offer, setOffer] = useState<Offer>(initialOffer);

  useEffect(() => {
    offerDetailsRequest(Number(match.params.offerId));
  }, [offerDetailsRequest, match.params.offerId]);

  useEffect(() => {
    if (offerDetails) {
      setOffer(offerDetails);
    }
  }, [offerDetails, offer]);


  return (
    <>
      <div className={classes.actionsBar}>
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            className={classes.backToButton}
            component={Link}
            to="/offers"
          >
            <ArrowLeftIcon />
           {isRental ? 'Ready to Rent' : 'Ready to Buy'}
          </Button>
        </div>
      </div>

      <Grid item xs={12} sm={12}>
        <Grid className={classes.container}>
          <Grid className={classes.titleWrapper}>
            <Typography className={classes.title}>Ready to Buy</Typography>
          </Grid>
          <Grid className={classes.content}>
            <div className={classes.visitContainer}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={2}>
                  <Typography color="textSecondary">Move In</Typography>
                  <Typography>
                    {' '}
                    {offer.move_in}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Typography color="textSecondary">Property ID</Typography>
                  <Typography>{offer.property_id}</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography color="textSecondary">Prequalified</Typography>
                  {
                    offer.prequalified && offer.prequalified
                      ? <Typography>YES</Typography>
                      : <Typography>NO</Typography>
                  }
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography color="textSecondary">Preferred Contact</Typography>
                  <Typography>{offer.preferred_contact}</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography color="textSecondary">First Name</Typography>
                  <Typography>{offer.firstname}</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography color="textSecondary">Last Name</Typography>
                  <Typography>{offer.lastname}</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography color="textSecondary">Customer Contact</Typography>
                  {
                    offer.preferred_contact && offer.preferred_contact !== 'email'
                      ? <Typography>{offer.phone}</Typography>
                      : <Typography>{offer.email}</Typography>
                  }
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography color="textSecondary">Floor Plan</Typography>
                  <Typography>{offer.floor_plan}</Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item>
                  <Typography color="textSecondary">Comments:</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.comment}>{offer.comments}</Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(OfferDetails);
