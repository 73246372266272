import {
  LOCKS_REQUEST,
  LOCKS_REQUEST_SUCCESS,
  LOCKS_REQUEST_FAILED,
  LOCK_DETAILS_REQUEST,
  LOCK_DETAILS_REQUEST_SUCCESS,
  LOCK_DETAILS_REQUEST_FAILED,
  LocksState,
  LocksActionsTypes,
} from '../types/locks';

const initialState: LocksState = {
  items: [],
  error: '',
  loading: false,
  selected: null,
};

export default function LocksReducer(
  state = initialState,
  action: LocksActionsTypes,
): LocksState {
  switch (action.type) {
    case LOCKS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case LOCKS_REQUEST_SUCCESS: {
      return {
        ...state,
        items: action.payload,
        error: '',
        loading: false,
      };
    }

    case LOCKS_REQUEST_FAILED:
    case LOCK_DETAILS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case LOCK_DETAILS_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
        selected: null,
      };
    }

    case LOCK_DETAILS_REQUEST_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
        selected: action.payload,
      };
    }


    default: {
      return state;
    }
  }
}
