import { ErrorMessage, ValueOf } from '../../types/main';
import * as StatesActions from '../actions/states';

export const STATES_REQUEST = 'STATES_REQUEST';
export const STATES_REQUEST_SUCCESS = 'STATES_REQUEST_SUCCESS';
export const STATES_REQUEST_FAILED = 'STATES_REQUEST_FAILED';

export type StatesActionsTypes = ReturnType<ValueOf<typeof StatesActions>>;

export interface State {
  state_id: string
  state_name: string
}

export type StatesRequestSuccess = State[];

export interface StatesState {
  items: State[]
  loading: boolean
  error: ErrorMessage['message']
}
