import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { visitsReportExportAndPrintRequest, visitsReportExportRequest, visitsReportRequest } from '../../redux/actions/visits';
import {
  selectTotalVisits,
  selectVisitsReport,
  selectVisitsRequestStatus,
} from '../../redux/selectors/visits';
import styles from './styles';
import { AppState } from '../../redux/store';
import ReportVisit from './ReportVisit';
import { setFilter } from '../../redux/actions/filter';
import { exportAllRequest } from '../../redux/actions/exportAll';
import { selectExportAllLoadingState } from '../../redux/selectors/exportAll';

const mapStateToProps = (state: AppState) => ({
  visitsReport: selectVisitsReport(state),
  visitsReportTotal: selectTotalVisits(state),
  loading: selectVisitsRequestStatus(state),
  exportAllLoading: selectExportAllLoadingState(state),
});
const mapDispatchToProps = {
  loadVisitsReport: visitsReportRequest,
  exportVisitsReport: visitsReportExportRequest,
  printVisitsReport: visitsReportExportAndPrintRequest,
  exportAllVisitsReport: exportAllRequest,
  setFilterState: setFilter,
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ReportVisit),
);
