import {
  CREATE_SNACKBAR,
  DELETE_SNACKBAR, SnackbarActionsTypes,
  SnackbarsState,
} from '../types/snackbars';

const initialState: SnackbarsState = [];

export default function SnackbarsReducer(
  state = initialState,
  action: SnackbarActionsTypes,
): SnackbarsState {
  switch (action.type) {
    case CREATE_SNACKBAR: {
      const item = {
        ...action.payload,
        id: Date.now(),
      };

      return [...state, item];
    }

    case DELETE_SNACKBAR: {
      return state.filter((item) => item.id !== action.payload);
    }

    default: {
      return state;
    }
  }
}
