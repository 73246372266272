import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Offers from './Offers';
import {
  selectTotalOffers,
  selectOffers,
  selectOffersRequestStatus,
} from '../../redux/selectors/offers';
import { exportAllRequest } from '../../redux/actions/exportAll';
import {
  offersRequest,
  offersExportRequest,
  offersExportAndPrintRequest,
} from '../../redux/actions/offers';
import { selectExportAllLoadingState } from '../../redux/selectors/exportAll';
import styles from './styles';

import { AppState } from '../../redux/store';
import { selectIsRental } from '../../redux/selectors/auth';

const mapStateToProps = (state: AppState) => ({
  offers: selectOffers(state),
  offersTotal: selectTotalOffers(state),
  isRental: selectIsRental(state.auth),
  loading: selectOffersRequestStatus(state),
  exportAllLoading: selectExportAllLoadingState(state),
});

const mapDispatchToProps = {
  loadOffers: offersRequest,
  exportOffers: offersExportRequest,
  printOffers: offersExportAndPrintRequest,
  exportAllOffers: exportAllRequest,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Offers),
);
