import { AppState } from '../store';
import { FilterState } from '../types/filter';

export const selectFilterState = (state: AppState): FilterState => state.filter;

export const selectInventoryFilters = (state: AppState) => state.filter.inventory;
export const selectVisitsFilters = (state: AppState) => state.filter.visits;
export const selectVisitsReportFilters = (state: AppState) => state.filter.reports;
export const selectCustomerFilters = (state: AppState) => state.filter.customers;
export const selectPropertyFilters = (state: AppState) => state.filter.properties;
export const selectCommunityFilters = (state: AppState) => state.filter.communities;
export const selectAgentsFilters = (state: AppState) => state.filter.agents;
export const selectHubAccessHistoryFilters = (state: AppState) => state.filter.hubAccessHistory;
