import React from 'react';
import Grid from '@material-ui/core/Grid';
import InsightCard from '../../components/InsightCard';
import RefreshIcon from '../../components/Icons/RefreshIcon';
import salesForcePlatformImg from '../../assets/img/sales_force_platform.png';

const mocks = [
  {
    title: 'Connect Salesforce to boost your CR',
    description:
      'Connecting Salesforce can improve your chances at maximizing your profits. Integrate now',
    media: salesForcePlatformImg,
  },
  {
    title: 'Keep your customer information current',
    description:
      'Keeping your customer information up-to-date will ensure your leads convert well.',
    media: RefreshIcon,
  },
];

const Insights: React.FC = () => (
  <Grid container spacing={4}>
    {mocks.map(insight => (
      <Grid key={insight.title} item xs={12} md={6}>
        <InsightCard
          title={insight.title}
          description={insight.description}
          media={insight.media}
        />
      </Grid>
    ))}
  </Grid>
);

export default React.memo(Insights);
