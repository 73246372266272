import CommunityInfo from './CommunityInfo'
import { connect } from 'react-redux';
import { selectStates } from '../../../redux/selectors/states';
import { selectAvailableAgents } from '../../../redux/selectors/agents';
import { AppState } from '../../../redux/store';
import { selectEnterpriseStatus } from '../../../redux/selectors/auth';


const mapStateToProps = (state: AppState) => ({
  states: selectStates(state),
  agents: selectAvailableAgents(state),
  isEnterprise: selectEnterpriseStatus(state.auth),
});


export default connect(mapStateToProps)(CommunityInfo);


