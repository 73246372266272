import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { navTo } from '../../browserHsitory';
import {
  addTeamAgent,
  createTeamData,
  deleteTeamAgent,
  deleteTeams,
  loadTeamById,
  loadTeams,
  loadTeamsByBuilder,
  updateTeamData,
} from '../../services/teams';
import {
  createErrorSnackBar,
  createSuccessSnackBar,
} from '../actions/snackbars';
import {
  createTeamFailed,
  createTeamRequest,
  createTeamSuccess,
  updateTeamRequest,
  updateTeamSuccess,
  updateTeamFailed,
  teamDetailsRequest,
  teamDetailsRequestFailed,
  teamDetailsRequestSuccess,
  teamsDeleteFailed,
  teamsDeleteRequest,
  teamsDeleteSuccess,
  teamsRequest,
  teamsRequestFailed,
  teamsRequestSuccess,
  addTeamAgentRequest,
  addTeamAgentSuccess,
  addTeamAgentFailed,
  teamsByBuilderRequest,
  teamsByBuilderRequestSuccess,
  teamsByBuilderRequestFailed,
} from '../actions/teams';
import {
  ADD_TEAM_AGENT_REQUEST,
  CREATE_TEAM_REQUEST,
  DELETE_TEAM_AGENT_REQUEST,
  TEAMS_BY_BUILDER_REQUEST,
  TEAMS_DELETE_REQUEST,
  TEAMS_REQUEST,
  TEAM_DETAILS_REQUEST,
  UPDATE_TEAM_REQUEST,
} from '../types/teams';

function* loadTeamsSaga(action: ReturnType<typeof teamsRequest>) {
  try {
    const { data } = yield call(loadTeams, action.payload);
    yield put(teamsRequestSuccess(data));
  } catch (err) {
    yield put(teamsRequestFailed(err));
  }
}

function* loadTeamsByBuilderSaga(action: ReturnType<typeof teamsByBuilderRequest>) {
  try {
    const { data } = yield call(loadTeamsByBuilder, action.payload);
    yield put(teamsByBuilderRequestSuccess(data));
  } catch (err) {
    yield put(teamsByBuilderRequestFailed(err));
  }
}

function* loadTeamDetailsSaga(action: ReturnType<typeof teamDetailsRequest>) {
  try {
    const { data } = yield call(loadTeamById, action.payload);
    yield put(teamDetailsRequestSuccess(data));
  } catch (err) {
    yield put(teamDetailsRequestFailed(err));
  }
}

function* createTeamSaga(action: ReturnType<typeof createTeamRequest>) {
  try {
    const { data } = yield call(createTeamData, action.payload);
    yield put(createTeamSuccess(data.message));
    yield put(createSuccessSnackBar(data.message));
    yield call(navTo, `/teams/${data.id}`);
  } catch (err) {
    yield put(createTeamFailed(err));
    yield put(
      createErrorSnackBar(
        err.response ? err.response.data.message : err.message,
      ),
    );
  }
}

function* updateTeamSaga(action: ReturnType<typeof updateTeamRequest>) {
  try {
    const { data } = yield call(updateTeamData, action.payload);
    yield put(updateTeamSuccess(data.message));
    yield put(createSuccessSnackBar(data.message));
  } catch (err) {
    yield put(updateTeamFailed(err));
    yield put(
      createErrorSnackBar(
        err.response ? err.response.data.message : err.message,
      ),
    );
  }
}

function* addTeamAgentSaga(action: ReturnType<typeof addTeamAgentRequest>) {
  try {
    const { data } = yield call(addTeamAgent, action.payload);
    yield put(addTeamAgentSuccess(data.message));
    yield put(createSuccessSnackBar(data.message));
  } catch (err) {
    yield put(addTeamAgentFailed(err));
    yield put(
      createErrorSnackBar(
        err.response ? err.response.data.message : err.message,
      ),
    );
  }
}

function* deleteTeamAgentSaga(action: ReturnType<typeof addTeamAgentRequest>) {
  try {
    const { data } = yield call(deleteTeamAgent, action.payload);
    yield put(addTeamAgentSuccess(data.message));
    yield put(createSuccessSnackBar(data.message));
  } catch (err) {
    yield put(addTeamAgentFailed(err));
    yield put(
      createErrorSnackBar(
        err.response ? err.response.data.message : err.message,
      ),
    );
  }
}

function* deleteTeamSaga(action: ReturnType<typeof teamsDeleteRequest>) {
  try {
    yield call(teamsDeleteRequest, action.payload);
    yield call(deleteTeams, action.payload);
    const { data } = yield call(loadTeams);
    yield put(teamsRequestSuccess(data));
    yield put(teamsDeleteSuccess(action.payload));
    yield put(createSuccessSnackBar('Successfully deleted!'));
  } catch (err) {
    yield put(teamsDeleteFailed(err));
    yield put(
      createErrorSnackBar(
        err.response ? err.response.data.message : err.message,
      ),
    );
  }
}

export default all([
  takeLatest<any>(TEAMS_REQUEST, loadTeamsSaga),
  takeLatest<any>(TEAMS_BY_BUILDER_REQUEST, loadTeamsByBuilderSaga),
  takeLatest<any>(TEAM_DETAILS_REQUEST, loadTeamDetailsSaga),
  takeLatest<any>(CREATE_TEAM_REQUEST, createTeamSaga),
  takeLatest<any>(UPDATE_TEAM_REQUEST, updateTeamSaga),
  takeLatest<any>(TEAMS_DELETE_REQUEST, deleteTeamSaga),
  takeLatest<any>(ADD_TEAM_AGENT_REQUEST, addTeamAgentSaga),
  takeLatest<any>(DELETE_TEAM_AGENT_REQUEST, deleteTeamAgentSaga),
]);
