import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  feedbackButton: {
    marginRight: 4,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  feedbackIcon: {
    marginRight: 4,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
});
