import { connect } from 'react-redux';
import Teams from './Teams';
import { AppState } from '../../redux/store';
import { teamsRequest, teamsDeleteRequest } from '../../redux/actions/teams';
import { selectTeams, selectTeamsRequestStatus, selectTotalTeams } from '../../redux/selectors/teams';


const mapStateToProps = (state: AppState) => ({
  teams: selectTeams(state),
  teamsTotal: selectTotalTeams(state),
  loading: selectTeamsRequestStatus(state),
});

const mapDispatchToProps = {
  loadTeams: teamsRequest,
  deleteTeams: teamsDeleteRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
