import { ErrorMessage, ValueOf } from '../../types/main';
import * as AgentsActions from '../actions/agents';

export const AGENT_DETAILS_REQUEST = 'AGENT_DETAILS_REQUEST';
export const AGENT_DETAILS_REQUEST_SUCCESS = 'AGENT_DETAILS_REQUEST_SUCCESS';
export const AGENT_DETAILS_REQUEST_FAILED = 'AGENT_DETAILS_REQUEST_FAILED';

export const AGENTS_REQUEST = 'AGENTS_REQUEST';
export const AGENTS_REQUEST_SUCCESS = 'AGENTS_REQUEST_SUCCESS';
export const AGENTS_REQUEST_FAILED = 'AGENTS_REQUEST_FAILED';

export const AVAILABLE_AGENTS_REQUEST = 'AVAILABLE_AGENTS_REQUEST';
export const AVAILABLE_AGENTS_REQUEST_SUCCESS = 'AVAILABLE_AGENTS_REQUEST_SUCCESS';
export const AVAILABLE_AGENTS_REQUEST_FAILED = 'AVAILABLE_AGENTS_REQUEST_FAILED';

export const ADD_AGENT_REQUEST = 'ADD_AGENT_REQUEST';
export const ADD_AGENT_REQUEST_SUCCESS = 'ADD_AGENT_REQUEST_SUCCESS';
export const ADD_AGENT_REQUEST_FAILED = 'ADD_AGENT_REQUEST_FAILED';

export const UPDATE_AGENT_REQUEST = 'UPDATE_AGENT_REQUEST';
export const UPDATE_AGENT_REQUEST_SUCCESS = 'UPDATE_AGENT_REQUEST_SUCCESS';
export const UPDATE_AGENT_REQUEST_FAILED = 'UPDATE_AGENT_REQUEST_FAILED';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_REQUEST_SUCCESS = 'UPDATE_PROFILE_REQUEST_SUCCESS';

export const RESEND_EMAIL_REQUEST = 'RESEND_EMAIL_REQUEST';
export const RESEND_EMAIL_FAILED = 'RESEND_EMAIL_FAILED';
export const RESEND_EMAIL_SUCCESS = 'RESEND_EMAIL_SUCCESS';

export const DELETE_AGENTS_REQUEST = 'DELETE_AGENTS_REQUEST';

export const UPDATE_SUGGESTED_USERNAME = 'UPDATE_SUGGESTED_USERNAME';
export const RESET_SUGGESTED_USERNAME = 'RESET_SUGGESTED_USERNAME';


export type AgentsActionTypes = ReturnType<ValueOf<typeof AgentsActions>>;

export interface Agent {
  username: string
  firstname: string
  lastname: string
  phone?: string
  email?: string
  modified_on?: string
  is_project_manager?: boolean | number;
  userType?: string
  builder?: string
  teamId?: string | number
  agent_username?: string
  groups?: Group[]
}

export interface Group {
  GroupID: number
  Label: string
}

export interface ListAgent {
  username: string
  value: string
  is_project_manager?: boolean | number
}

export interface AgentDetails extends Agent {
  send_sms: number
  send_email: number
  email: string
  phone: string
  roles: AgentRoleType
  notification_email?: string
  pass_word: string | null
  confirm_password: string | null
  oldEmail?: string
  oldPhone?: string
}

export interface AgentsRequestSuccess {
  items: Agent[]
  total: number
}

export type AgentDetailsRequestSuccess = AgentDetails;

export interface AgentsState {
  items: Agent[]
  total: number
  availableAgents: Agent[]
  loading: boolean
  loadingBtnEmailResend: boolean
  error: ErrorMessage['message']
  selected: AgentDetails | null
  suggestedUsername: string
}

export interface AgentRoleType {
  Label: string
  description?: string
  id?: number
}
