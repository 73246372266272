import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const LockIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon width="49" height="18" viewBox="0 0 49 18" fill="none" {...props}>
    <rect width="45" height="18" fill="#04A777" />
    <rect x="45" y="7" width="4" height="5" fill="#04A777" />
  </SvgIcon>
);

export default React.memo(LockIcon);
