import { buildQuery } from '../utils/query';
import axios from './axios';


export const loadScheduledVisits = (queryObject?: any) => {
  const query = buildQuery(queryObject);

  return axios.get(`/schedule_visit${query}`);
}

export const loadScheduledVisitDetails = (visitId: number) => {
  return axios.get(`/schedule_visit/${visitId}`);
}

export const deleteScheduleVisits = (selected: number[]) => {
  return axios.post('/schedule_visit/delete', { selected });
}

export const exportScheduleVisits = (selected: number[], accept: 'application/pdf' | 'text/csv' = 'text/csv') => {
  const headers = {
    Accept: accept,
  };

  return axios.post('/schedule_visit/export', { selected }, { headers, responseType: 'blob' });
}