import { createStyles } from '@material-ui/core';

export default () => createStyles({
  progressWrapper: {
    backgroundColor: '#fefefe',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  progress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-24px',
    marginLeft: '-24px',
  },
});
