import { createStyles, Theme, makeStyles } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  actionsBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 20,
    '& button': {
      textTransform: 'capitalize',
      '&:last-of-type': {
        marginRight: 0,
      },
    },
  },
  actionButtonsWrapper: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
  backToButton: {
    backgroundColor: '#fff',
    color: theme.appPalette.orange,
  },
  actionButton: {
    backgroundColor: theme.appPalette.orange,
    color: '#fff',
    '&:hover': {
      color: theme.appPalette.orange,
    },
  },
  cardHeader: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    backgroundColor: '#E9ECF2',
  },
  cardContent: {
    backgroundColor: '#fefefe',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    '&:first-child': {
      paddingTop: theme.spacing(3),
    },
    '&:last-child': {
      paddingBottom: theme.spacing(3),
    },
  },
  content: {
    height: '100%',
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    backgroundColor: '#fff',
    padding: '40px 30px',
  },
  rootAvatar: {
    margin: '0 auto',
    width: 81,
    height: 81,
    fontSize: 32,
    textTransform: 'uppercase',
    backgroundColor: theme.appPalette.orange,
  },
  customerName: {
    fontSize: 32,
    textTransform: 'capitalize',
    textAlign: 'center',
  },
  row: {
    marginTop: 21,
  },
  title: {
    fontSize: 20,
    marginBottom: 16,
  },
  email: {
    color: theme.appPalette.orange,
  },
  container: {
    height: '100%',
    overflow: 'hidden',
    boxShadow: theme.shadows[3],
    borderRadius: 10,
    marginLeft: 20,
    backgroundColor: '#fff',
    '& > div': {
      paddingLeft: 43,
      paddingRight: 43,
    },
  },
  titleWrapper: {
    margin: '32px 0',
  },
  visitContainer: {
    position: 'relative',
  },
  activityDate: {
    color: theme.appPalette.orange,
  },
  comment: {
    fontSize: 16,
  },
});
const useStyles = makeStyles(styles);

export default useStyles;
