import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import { CATALOG_REQUEST } from '../types/catalog';

import { loadCatalog } from '../../services/catalog';
import {
  catalogRequestSuccess,
  catalogRequestFailed,
} from '../actions/catalog';

function* loadCatalogSaga() {
  try {
    const { data } = yield call(loadCatalog);
    yield put(catalogRequestSuccess(data));
  } catch (err) {
    yield put(catalogRequestFailed(err));
  }
}

export default all([
  takeLatest<any>(CATALOG_REQUEST, loadCatalogSaga),
]);
