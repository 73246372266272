import { Theme, createStyles } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  root: {
    height: 23,
    backgroundColor: '#fff',
  },
  bar: {
    backgroundColor: theme.appPalette.orange,
  },
});
