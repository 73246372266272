import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import TimezoneSelect from './TimezoneSelect';
import { AppState } from '../../redux/store';
import { timezonesCatalog } from '../../redux/selectors/catalog';
import styles from './styles';

const styledComponent = withStyles(styles)(TimezoneSelect);

const mapStateToProps = (state: AppState) => ({
  timezones: timezonesCatalog(state),
});

export default connect(mapStateToProps)(styledComponent);
