/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { AppState } from '../store';
import { SplitState } from '../types/split';

const selectSplitState = (state: AppState) => state.split;

export const selectFeatures = createSelector(
  selectSplitState,
  (state: SplitState) => state.features,
);
