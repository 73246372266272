import { connect } from 'react-redux';
import PrivateRoute from './PrivateRoute';

import { selectAuthData } from '../../redux/selectors/auth';
import { AppState } from '../../redux/store';

const selectedRole = (state: AppState) => {
  if (selectAuthData(state).isPM) { 
    return 'PM'
  } else if (selectAuthData(state).userRoleId !== 1 && selectAuthData(state).userRoleId !== 7) {
    return 'USER'
  } else return 'OTHER STAFF'
}

const mapStateToProps = (state: AppState) => ({
  isAuthorized: Boolean(selectAuthData(state).token),
  userRole: selectedRole(state)
});

export default connect(mapStateToProps)(PrivateRoute);
