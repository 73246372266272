import {
  COMMUNITIES_REQUEST,
  COMMUNITIES_REQUEST_SUCCESS,
  COMMUNITIES_DETAILS_REQUEST,
  COMMUNITIES_REQUEST_FAILED,
  COMMUNITIES_DETAILS_SUCCESS,
  SAVE_COMMUNITY_SUCCESS,
  CANCEL_SUCCESSFULLY_SAVE,
  BUILDER_COMMUNITIES_SUCCESS,
  COMMUNITIES_DELETE_REQUEST_SUCCESS,
  QR_CODES_REQUEST_SUCCESS,
  COMMUNITIES_DELETED_RESET,
  CommunitiesActionsTypes,
  CommunitiesState,
  COMMUNITIES_STATUSES_SUCCESS,
  LOCK_COMMUNITIES_REQUEST,
  LOCK_COMMUNITIES_SUCCESS,
  LOCK_COMMUNITIES_FAILED,
  BUILDER_COMMUNITIES,
} from '../types/communities';

const initialState: CommunitiesState = {
  communities: [],
  total: 0,
  error: '',
  loading: false,
  selected: null,
  successfullySaved: false,
  successfullyDeleted: [],
  builderCommunities: [],
  qrCodes: [],
  statuses: [],
  lockCommunities: [],
};
export default function CommunitiesReducer(
  state = initialState,
  action: CommunitiesActionsTypes,
): CommunitiesState {
  switch (action.type) {
    case COMMUNITIES_REQUEST: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case COMMUNITIES_REQUEST_SUCCESS: {
      return {
        ...state,
        communities: action.payload.communities,
        total: action.payload.total,
        error: '',
        loading: false,
      };
    }

    case COMMUNITIES_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    case COMMUNITIES_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case COMMUNITIES_DETAILS_SUCCESS: {
      return {
        ...state,
        selected: action.payload,
        loading: false,
      };
    }
    case QR_CODES_REQUEST_SUCCESS: {
      return {
        ...state,
        qrCodes: action.payload,
      };
    }

    case SAVE_COMMUNITY_SUCCESS: {
      return {
        ...state,
        successfullySaved: action.payload,
      };
    }

    case CANCEL_SUCCESSFULLY_SAVE: {
      return {
        ...state,
        successfullySaved: false,
      };
    }

    case BUILDER_COMMUNITIES: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case BUILDER_COMMUNITIES_SUCCESS: {
      return {
        ...state,
        builderCommunities: action.payload,
        loading: false,
      };
    }

    case COMMUNITIES_DELETE_REQUEST_SUCCESS: {
      return {
        ...state,
        successfullyDeleted: action.payload,
      };
    }

    case COMMUNITIES_DELETED_RESET: {
      return {
        ...state,
        successfullyDeleted: [],
      };
    }

    case COMMUNITIES_STATUSES_SUCCESS: {
      return {
        ...state,
        statuses: action.payload,
      };
    }

    case LOCK_COMMUNITIES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case LOCK_COMMUNITIES_SUCCESS: {
      return {
        ...state,
        lockCommunities: action.payload,
      };
    }

    case LOCK_COMMUNITIES_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
