import * as yup from 'yup';

// eslint-disable-next-line import/prefer-default-export
export const builderSettingsCreateSchema = yup.object().shape({
  builderName: yup.string().nullable(),
  primary_color: yup.string().required().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
  secondary_color: yup.string().required().matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
  country: yup.string(),
  legal_name: yup.string().nullable(),
  marketing_name: yup.string().nullable(),
  logo: yup.string().nullable(),
  button_settings: yup.string().nullable(),
  selfie_verification_active: yup.boolean(),
});
