import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export type SimpleTableHeaderProp<T> = {
  key: keyof T;
  label: string;
  date?: boolean;
}[];

type Props<T> = {
  items: T[];
  header: SimpleTableHeaderProp<T>;
};

const SimpleTable = <T extends object>(props: Props<T>) => {
  const { items, header } = props;

  return (
    <Table>
      <TableHead>
        <TableRow>
          {header.map((head, idx: number) => (
            <TableCell
              key={String(head.key)}
              align={idx !== 0 ? 'right' : undefined}
            >
              {head.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={idx}>
            {header.map(({ key }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableCell key={index} align={index !== 0 ? 'right' : undefined}>
                {item[key]}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default SimpleTable;
