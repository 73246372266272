import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { selectProgressBarStatus } from '../../redux/selectors/progressBar';
import ProgressBar from './ProgressBar';
import styles from './styles';
import { AppState } from '../../redux/store';

const mapStateToProps = (state: AppState) => ({
  loadingStatus: selectProgressBarStatus(state),
});

export default withStyles(styles)(connect(mapStateToProps)(ProgressBar));
