import {
  SurveyActionsTypes,
  SurveyState,
  SURVEY_QUESTIONS,
  SURVEY_QUESTIONS_FAILED,
  SURVEY_QUESTIONS_SUCCESS,
  SURVEY_QUESTIONS_UPDATE,
  SURVEY_QUESTIONS_UPDATE_FAILED,
  SURVEY_QUESTIONS_UPDATE_SUCCESS,
} from '../types/survey';

const initialState: SurveyState = {
  questions: [],
  error: '',
  loading: false,
  updating: false,
};

export default function SurveyReducer(
  state = initialState,
  action: SurveyActionsTypes,
): SurveyState {
  switch (action.type) {
    case SURVEY_QUESTIONS_UPDATE: {
      return {
        ...state,
        error: '',
        updating: true,
        questions: action.payload,
      };
    }

    case SURVEY_QUESTIONS_UPDATE_FAILED: {
      return {
        ...state,
        updating: false,
      };
    }

    case SURVEY_QUESTIONS_UPDATE_SUCCESS: {
      return {
        ...state,
        questions: action.payload,
        updating: false,
      };
    }

    case SURVEY_QUESTIONS: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case SURVEY_QUESTIONS_SUCCESS: {
      return {
        ...state,
        questions: action.payload,
        error: '',
        loading: false,
      };
    }

    case SURVEY_QUESTIONS_FAILED: {
      return {
        ...state,
        error: '',
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
