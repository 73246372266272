import React from 'react';
import { WithStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ProfitBar from '../../components/ProfitBar';
import styles from './styles';

type Props = WithStyles<typeof styles>;

const ProfitCenter: React.FC<Props> = props => {
  const { classes } = props;

  return (
    <Grid container>
      <Grid item xs>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <Typography className={classes.title}>Top Customers</Typography>
          </div>
          <div className={classes.content}>
            <div className={classes.customers}>
              <p>Brenda Morris</p>
              <p>Erika Gold</p>
              <p>Rebecca Calder</p>
              <p>Tera Foster</p>
            </div>
            <div className={classes.profitBars}>
              <ProfitBar variant="determinate" value={90} />
              <ProfitBar variant="determinate" value={75} />
              <ProfitBar variant="determinate" value={45} />
              <ProfitBar variant="determinate" value={20} />
            </div>
            <div className={classes.profitAmounts}>
              <p>$10k</p>
              <p>$9.7k</p>
              <p>$8.2k</p>
              <p>$4.2k</p>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default React.memo(ProfitCenter);
