import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  select: {
    fontWeight: 700,
  },
  input: {
    marginLeft: 12,
  },
  disabledInput: {
    color: '#000000',
  },
});
