import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import MatTableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import Checkbox from '@material-ui/core/Checkbox';
import { Order } from '../../../utils/table';
import { Community } from '../../../redux/types/communities';

type Props = {
  onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: keyof Community;
  numSelected: number;
  rowCount: number;
  onRequestSort: (
    event: React.SyntheticEvent,
    property: keyof Community
  ) => void;
};

type HeadRow = {
  id: keyof Community;
  align: 'left' | 'center' | 'right';
  disablePadding: boolean;
  label: string;
};

const headRows: HeadRow[] = [
  {
    id: 'name',
    align: 'center',
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'city',
    align: 'center',
    disablePadding: true,
    label: 'City',
  },
  {
    id: 'state',
    align: 'center',
    disablePadding: true,
    label: 'State/Province',
  },
  {
    id: 'zip',
    align: 'center',
    disablePadding: true,
    label: 'Zip',
  },
  {
    id: 'external_community_id',
    align: 'center',
    disablePadding: true,
    label: 'External Community Id',
  },
  {
    id: 'created_on',
    align: 'center',
    disablePadding: true,
    label: 'Created On',
  },
  {
    id: 'created_by',
    align: 'center',
    disablePadding: true,
    label: 'Author',
  },
  {
    id: 'modified_on',
    align: 'center',
    disablePadding: true,
    label: 'Modified On',
  },
  {
    id: 'builder_id',
    align: 'center',
    disablePadding: true,
    label: 'Builder',
  },
  {
    id: 'status',
    align: 'center',
    disablePadding: true,
    label: 'Community status',
  },

];

const TableHead: React.FC<Props> = (props) => {
  const {
    onRequestSort,
    onSelectAllClick,
    orderBy,
    order,
    numSelected,
    rowCount,
  } = props;

  const createSortHandler = (property: keyof Community) => (
    event: React.SyntheticEvent,
  ): void => {
    onRequestSort(event, property);
  };

  return (
    <MatTableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'Select all Properties' }}
          />
        </TableCell>
        {headRows.map(({
          id, align, disablePadding, label,
        }) => (
          <TableCell
            key={id}
            align={align}
            padding={disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === id ? order : false}
            onClick={createSortHandler(id)}
          >
            {label}
            <TableSortLabel
              active={orderBy === id}
              direction={order}
              IconComponent={ArrowIcon}
            />
          </TableCell>
        ))}
      </TableRow>
    </MatTableHead>
  );
};

export default React.memo(TableHead);
