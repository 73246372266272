import { createSelector } from 'reselect';
import { AppState } from '../store';
import { VisitsAfterTourHoursState } from '../types/afterTourHours';
import { VisitsState } from '../types/visits';

export const selectVisitsState = (state: AppState) => state.visits;
const selectVisitsAfterTourHoursState = (state: AppState) => state.visitsAfterTourHours;
const selectItems = (state: VisitsState) => state.items;
const selectReport = (state: VisitsState) => state.visitsReport;
const selectAfterTourHoursItems = (state: VisitsAfterTourHoursState) => state.items;
const selectLoading = (state: VisitsState) => state.loading;
const selectTotal = (state: VisitsState) => state.total;
const selectTotalVisitAfterTourHours = (state: VisitsAfterTourHoursState) => state.total;

export const selectVisits = createSelector(
  selectVisitsState,
  selectItems,
);

export const selectVisitsReport = createSelector(
  selectVisitsState,
  selectReport,
);

export const selectTotalVisits = createSelector(
  selectVisitsState,
  selectTotal,
);

export const selectVisitsRequestStatus = createSelector(
  selectVisitsState,
  selectLoading,
);

export const selectVisitsAfterTourHours = createSelector(
  selectVisitsAfterTourHoursState,
  selectAfterTourHoursItems,
);

export const selectTotalVisitsAfterTourHours = createSelector(
  selectVisitsAfterTourHoursState,
  selectTotalVisitAfterTourHours,
);
