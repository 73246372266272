import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleIcon: {
    marginRight: 16,
    width: 36,
    height: 46,
    color: '#DB5C0E',
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '24px',
  },
  titleRecordName: {
    color: '#DB5C0E',
  },
  lineBreak: {
    margin: '16px 0 12px',
    borderBottom: '1px solid #8E8B99',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});
