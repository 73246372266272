import { createSelector } from 'reselect';
import { AppState } from '../store';
import { WebhooksState } from '../types/webhooks';

const selectWebhooksState = (state: AppState) => state.webhooks;
const selectItems = (state: WebhooksState) => state.items;
const selectLoading = (state: WebhooksState) => state.loading;
const selectTotal = (state: WebhooksState) => state.total;
const selectSelected = (state: WebhooksState) => state.selected;

export const selectWebhooks = createSelector(selectWebhooksState, selectItems);

export const selectTotalWebhooks = createSelector(
  selectWebhooksState,
  selectTotal,
);

export const selectWebhook = createSelector(
  selectWebhooksState,
  selectSelected,
);

export const selectWebhooksRequestStatus = createSelector(
  selectWebhooksState,
  selectLoading,
);
