import * as yup from 'yup';
import { checkOnlyDigits } from '../../utils/helpers';

export const communityInputSchema = yup.object().shape({
  name: yup.string().required('Name is required field'),
  state: yup.string().required('State is required field'),
  city: yup.string().required('City is required field'),
  zip: yup.string().required('Zip is required field'),
  builder_id: yup.number().required('Builder is required field'),
  sales_agent: yup
    .string()
    .nullable()
    .required('Sales contact is a required field'),
  emergency_contacts: yup
    .string()
    .nullable()
    .required('Point person is a required field'),
  community_url: yup.string().nullable(),
  floor_plan_url: yup.string().nullable(),
  virtual_tour_url: yup.string().nullable(),
  additional_notes: yup.string().nullable(),
  privacy_additional_notes: yup.string().nullable(),
  privacy_logo: yup.string().nullable(),
});

export const automationsSchema = yup.object().shape({
  community_backup_code: yup
    .string()
    .test('Digits only', 'The field should have digits only', checkOnlyDigits)
    .default('')
    .nullable(),
  trade_code: yup
    .string()
    .test('Digits only', 'The field should have digits only', checkOnlyDigits)
    .default('')
    .nullable(),
  sales_code: yup
    .string()
    .test('Digits only', 'The field should have digits only', checkOnlyDigits)
    .default('')
    .nullable(),
});
