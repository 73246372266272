import { createStyles, Theme } from '@material-ui/core';

export default (theme: Theme) => createStyles({
  tableRoot: {
    minWidth: 1230,
  },
  rowRoot: {
    '&:hover > td': {
      color: theme.appPalette.orange,
      cursor: 'pointer',
    },
  },
  link: {
    color: theme.appPalette.orange,
  },
  tableWrapper: {
    overflowX: 'auto',
    position: 'relative',
  },
});
