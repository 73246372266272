import * as yup from 'yup';
import { checkOnlyDigits } from '../../utils/helpers';

// eslint-disable-next-line import/prefer-default-export
export const propertyCreateSchema = yup.object().shape({
  property_id: yup.number(),
  house_num: yup.string(),
  latitude: yup.number(),
  longitude: yup.number(),
  address_1: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zipcode: yup.string().required('Zipcode is required'),
  price: yup.number(),
  bedrooms: yup.number().required('Bedrooms is required'),
  halfBathrooms: yup.number().required('Half Bathrooms is required'),
  fullBathrooms: yup.number().required('Full Bathrooms is required'),
  squareFootage: yup.number().required('Square Footage is required'),
  isModel: yup.boolean().required('Is Model is required'),
  timezone: yup.number().notOneOf([0], 'Timezone is required'),
  agents: yup.array(),
  property_backup_code: yup
    .string()
    .test('Digits only', 'The field should have digits only', checkOnlyDigits)
    .default(''),
  trade_code: yup
    .string()
    .test('Digits only', 'The field should have digits only', checkOnlyDigits)
    .default(''),
  sales_code: yup
    .string()
    .test('Digits only', 'The field should have digits only', checkOnlyDigits)
    .default(''),
});

export const propertyUpdateSchema = yup.object().shape({
  property_id: yup.number().required(),
  latitude: yup
    .number()
    .transform((cv: unknown, ov: unknown) => (typeof ov === 'string' && ov.trim() === '' ? undefined : cv))
    .typeError('Must be a number')
    .required('Latitude is required'),
  longitude: yup
    .number()
    .transform((cv: unknown, ov: unknown) => (typeof ov === 'string' && ov.trim() === '' ? undefined : cv))
    .typeError('Must be a number')
    .required('Longitude is required'),
  address_1: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zipcode: yup.string().required('Zipcode is required'),
  price: yup
    .number()
    .transform((cv: unknown, ov: unknown) => (typeof ov === 'string' && ov.trim() === '' ? undefined : cv))
    .typeError('Must be a number'),
  bedrooms: yup
    .number()
    .transform((cv: unknown, ov: unknown) => (typeof ov === 'string' && ov.trim() === '' ? undefined : cv))
    .typeError('Must be a number')
    .required('Bedrooms is required'),
  halfBathrooms: yup
    .number()
    .transform((cv: unknown, ov: unknown) => (typeof ov === 'string' && ov.trim() === '' ? undefined : cv))
    .typeError('Must be a number')
    .required('Half Bathrooms is required'),
  fullBathrooms: yup
    .number()
    .transform((cv: unknown, ov: unknown) => (typeof ov === 'string' && ov.trim() === '' ? undefined : cv))
    .typeError('Must be a number')
    .required('Full Bathrooms is required'),
  squareFootage: yup
    .number()
    .transform((cv: unknown, ov: unknown) => (typeof ov === 'string' && ov.trim() === '' ? undefined : cv))
    .typeError('Must be a number')
    .required('Square Footage is required'),
  isModel: yup.boolean().required('Is Model is required'),
  timezone: yup.number().notOneOf([0], 'Timezone is required'),
  builder: yup.string().required(),
  disposition_id: yup.string(),
  lock_serial_number: yup.string(),
  lock_status: yup.number(),
  tourHoursStart: yup.number(),
  tourHoursEnd: yup.number(),
});

export const automationsSchema = yup.object().shape({
  property_backup_code: yup
    .string()
    .test('Digits only', 'The field should have digits only', checkOnlyDigits)
    .default(''),
  trade_code: yup
    .string()
    .test('Digits only', 'The field should have digits only', checkOnlyDigits)
    .default(''),
  sales_code: yup
    .string()
    .test('Digits only', 'The field should have digits only', checkOnlyDigits)
    .default(''),
});
