import {
  UPDATE_HUB_LIGHT_FAILED,
  UPDATE_HUB_LIGHT_REQUEST,
  UPDATE_HUB_LIGHT_SUCCESS,
  UpdateHubLightInput,
} from '../../types/hubs';

export const updateHubLightRequest = (payload: UpdateHubLightInput) => ({
  type: UPDATE_HUB_LIGHT_REQUEST,
  payload,
} as const);
export const updateHubLightSuccess = (payload: UpdateHubLightInput) => ({
  type: UPDATE_HUB_LIGHT_SUCCESS,
  payload,
} as const);
export const updateHubLightFailed = () => ({
  type: UPDATE_HUB_LIGHT_FAILED,
} as const);
