import React, { useCallback, useState, useEffect, ChangeEvent } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import { useDropzone } from 'react-dropzone';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  Divider,
  FormControlLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  TextareaAutosize,
  Typography,
  Switch,
} from '@material-ui/core';
import Spinner from '@material-ui/core/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { ReactComponent as DropZoneIcon } from '../../assets/dropzoneLogo.svg';
import CustomButton from '../CustomButton';
import { Builder, BuilderPreviewSizes, BuilderSettings } from '../../redux/types/builders';
import { builderSettingsCreateSchema } from './BuilderSettingsInputValidation';
import {
  builderSettingsRequest,
  builderTimezoneUpdateRequest,
} from '../../redux/actions/builders';
import { getBuilderName } from '../../redux/selectors/auth';
import { selectBuilderSettings } from '../../redux/selectors/builders';
import { StatusColor, UploadStatus } from '../../types/main';
import { beautifyErrors, ValidationErrors } from '../../utils/helpers';
import { updateProfileRequestSuccess } from '../../redux/actions/auth';
import ButtonGenerator from '../ButtonGenerator/ButtonGenerator';
import appConfig from '../../config';
import TimezoneSelect from '../Timezone';

type Props = {
  handleChanges: () => void;
  saveSettings: (settings: FormData) => void;
  builderTimezone: number | null;
  getBuilderTimezone: () => void;
  loadEnterpriseBuilders: (enterpriseId: number) => void;
  enterpriseId: number | null;
  builderId: number;
  enterpriseBuilders: Builder[];
  loadingEnterpriseBuilders: boolean;
  isLoading: boolean;
};

const defaultSettings: BuilderSettings = {
  builderName: null,
  primary_color: '#DB5C0E',
  secondary_color: '#8E8B99',
  country: 'USA',
  logo: '',
  button_settings: JSON.stringify({
    text: 'Schedule a Tour',
    textColor: '#ffffff',
    backgroundColor: '#DB5C0E',
    borderRadius: 5,
    fontSize: 14,
  }),
  selfie_verification_active: false,
};

const defaultPreviewSizes: BuilderPreviewSizes = {
  desktopWidth: 0,
  desktopHeight: 0,
  mobileWidth: 0,
  mobileHeight: 0,
}

const BuilderSettingsForm: React.FC<Props> = (props) => {
  const {
    saveSettings,
    builderTimezone: builderTz,
    getBuilderTimezone,
    loadEnterpriseBuilders,
    loadingEnterpriseBuilders,
    enterpriseId,
    builderId,
    enterpriseBuilders,
    isLoading,
  } = props;

  const builderName = useSelector(getBuilderName);
  const builderSettings = useSelector(selectBuilderSettings);

  const [settings, setSettings] = useState<BuilderSettings>(defaultSettings);
  const [isPreview, setIsPreview] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>({
    status: '',
    value: '',
  });
  const [color, setColor] = useState<string>('');
  const [validationErrors, setValidationErrors] = useState<
  ValidationErrors<BuilderSettings>
  >({});
  const [builderTimezone, setBuilderTimezone] = useState<number | null>(null);
  const [selectedBuilder, setSelectedBuilder] = useState<number | null>(builderId);
  const [selectedBuilderName, setSelectedBuilderName] = useState<string | null>(null);
  const [showInPreview, setShowInPreview] = useState<'map' | 'list'>('map');
  const [hideHeaderPic, setHideHeaderPic] = useState<boolean>(false);
  const [previewSizes, setPreviewSizes] = useState<BuilderPreviewSizes>(defaultPreviewSizes);
  const [useCustomSizes, setUseCustomSizes] = useState<boolean>(false);

  const styleProps = { color: color ?? 'transparent' };
  const classes = useStyles(styleProps);

  const dispatch = useDispatch();

  useEffect(() => {
    getBuilderTimezone();
  }, [getBuilderTimezone]);

  useEffect(() => {
    setBuilderTimezone(builderTz);
  }, [builderTz]);

  useEffect(() => {
    const statusColor: StatusColor = {
      success: '#4BB543',
      error: '#D11A2A',
      loading: '#DB5C0E',
    };

    setColor(statusColor[uploadStatus.status as keyof StatusColor]);
  }, [uploadStatus]);

  useEffect(() => {
    if (builderSettings && Object.keys(builderSettings).length) {
      setSettings(builderSettings);
    }
  }, [builderSettings]);

  useEffect(() => {
    if (!settings?.button_settings) {
      const newSettings = {
        ...settings,
        button_settings: defaultSettings.button_settings,
      };

      setSettings((prevSettings) => ({ ...prevSettings, ...newSettings }));
    }

    if (!settings?.country) {
      const newSettings = {
        ...settings,
        country: defaultSettings.country,
      };

      setSettings((prevSettings) => ({ ...prevSettings, ...newSettings }));
    }

    if (!settings?.primary_color) {
      const newSettings = {
        ...settings,
        primary_color: defaultSettings.primary_color,
      };

      setSettings((prevSettings) => ({ ...prevSettings, ...newSettings }));
    }

    if (!settings?.secondary_color) {
      const newSettings = {
        ...settings,
        secondary_color: defaultSettings.secondary_color,
      };

      setSettings((prevSettings) => ({ ...prevSettings, ...newSettings }));
    }
  }, [settings]);

  useEffect(() => {
    dispatch(builderSettingsRequest(selectedBuilder ?? builderId));
  }, [dispatch, builderId, selectedBuilder]);

  useEffect(() => {
    if (enterpriseId) {
      loadEnterpriseBuilders(enterpriseId);
    }
  }, [loadEnterpriseBuilders, enterpriseId]);

  const handleChangeSizes = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPreviewSizes((prevState) => ({ ...prevState, [name]: Number(value) }))
  }

  const onDrop = useCallback((acceptedFiles) => {
    setProgress(0);
    setUploadStatus({ status: '', value: '' });

    acceptedFiles.forEach((file: any) => {
      const isImage = file.type === 'image/png' || file.type === 'image/jpeg';

      if (!isImage) {
        setUploadStatus({ status: 'error', value: 'Unsupported file type' });

        return;
      }

      if (file.size / (1024 * 1024) > 50) {
        setUploadStatus({ status: 'error', value: 'Max logo size is 50mb' });

        return;
      }

      const reader = new FileReader();

      reader.onprogress = (data) => {
        setUploadStatus({ status: 'loading', value: 'Uploading' });

        if (data.lengthComputable) {
          setProgress((data.loaded / data.total) * 100);
        }
      };

      reader.onerror = () => {
        setUploadStatus({ status: 'error', value: 'Uploading Failed' });
      };

      reader.onload = () => {
        const logo = String(reader.result);

        setUploadStatus({ status: 'success', value: 'Sucessfully Uploaded' });
        setSettings((prevSettings) => ({
          ...prevSettings,
          file,
          logo,
        }));
      };

      const base = reader.readAsDataURL(file);

      return base;
    });
  }, []);

  const handleChangeSettings = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (name === 'selfie_verification_active') {
      setSettings((prevSettings) => ({
        ...prevSettings,
        selfie_verification_active: value === 'on',
      }));

      return;
    }

    if (name) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        [name]: value,
      }));
    }
  };

  const handleChangeShowInPreview = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShowInPreview((event.target as HTMLInputElement).value as 'map' | 'list');
  };

  const handleButtonSettingsChange = (buttonSettings: string) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      button_settings: buttonSettings,
    }));
  };

  const handleSaveSettings = async () => {
    try {
      const validSettings = builderSettingsCreateSchema.validateSync(settings, {
        abortEarly: false,
      }) as BuilderSettings;

      const {
        file,
        country,
        legal_name: legalName,
        marketing_name: marketingName,
        primary_color: primaryColor,
        secondary_color: secondaryColor,
        button_settings: buttonSettings,
        selfie_verification_active: selfieVerificationActive,
      } = validSettings;

      const formData = new FormData();

      if (file) {
        formData.append('file', file);
      }

      formData.append('builderName', settings.builderName || builderName || '');
      formData.append('builder_id', String(builderId));
      formData.append('country', country);
      formData.append('legal_name', legalName ?? '');
      formData.append('marketing_name', marketingName ?? '');
      formData.append('primary_color', primaryColor);
      formData.append('secondary_color', secondaryColor);
      formData.append('button_settings', buttonSettings);
      formData.append(
        'selfie_verification_active',
        String(selfieVerificationActive),
      );

      saveSettings(formData);

      if (settings.builderName) {
        dispatch(
          updateProfileRequestSuccess({
            builder: settings.builderName,
          }),
        );
      }

      setValidationErrors({});
    } catch (errors) {
      setValidationErrors(beautifyErrors(errors));
    }
  };

  const handleSelectBuilder = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedBuilderId = Number(e.target.value);
    const selected = enterpriseBuilders.find(
      ({ builder_id }) => builder_id === selectedBuilderId,
    );

    if (selected) {
      setSelectedBuilder(selectedBuilderId);
      setSelectedBuilderName(selected.name);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <>
      <Divider />

      {enterpriseId && (
        <div className={classes.headerText}>
          <span>Select Builder</span>
          <Grid container alignItems="center" justify="center">
            <Grid item xs={7}>
              <TextField
                select
                required
                fullWidth
                margin="normal"
                id="builder"
                name="builder"
                label="Select Builder"
                value={enterpriseId && selectedBuilder}
                onChange={handleSelectBuilder}
                disabled={!enterpriseId && loadingEnterpriseBuilders}
              >
                <MenuItem value="" disabled>
                  Select Builder
                </MenuItem>
                {enterpriseBuilders.map(({ builder_id, name }) => (
                  <MenuItem key={builder_id} value={builder_id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </div>
      )}

      {isLoading && (
        <Grid container justify="center">
          <div className={classes.spinnerWrapper}>
            <Spinner />
          </div>
        </Grid>
      )}

      {!isLoading && (selectedBuilderName || builderName) && (
        <>
          <div className={classes.headerText}>
            <span>Company Settings</span>
          </div>
          <div className={classes.dropZoneContainer}>
            <div className={classes.dropZone} {...getRootProps()}>
              <input {...getInputProps()} />
              {settings.logo ? (
                <img src={settings.logo} alt="logo" className={classes.logo} />
              ) : (
                <DropZoneIcon />
              )}
              <h3>Drop your logo here or browse</h3>
              <span className={classes.supportText}>
                {' '}
                Support: JPG, JPEG, PNG
                {' '}
              </span>
            </div>

            {uploadStatus.status && (
              <>
                <p className={classes.progressStatus} style={{ color }}>
                  {uploadStatus.value}
                </p>
                <ProgressBar
                  className={classes.progressBar}
                  completed={progress}
                  isLabelVisible={false}
                  bgColor={color}
                  baseBgColor="transparent"
                  transitionDuration="2s"
                  // @ts-ignore
                  color={color}
                />
              </>
            )}
          </div>
          <div className={classes.inputForm}>
            <Grid container spacing={4}>
              <Grid container item xs={12} sm={12} alignItems="center">
                <Grid item>
                  <Typography
                    className={classes.cardTitle}
                    style={{ color: '#DB5C0E' }}
                  >
                    Company Name:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.cardTitle}>
                    {` ${settings.builderName ?? selectedBuilderName ?? builderName}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <span className={classes.subtitle}>
                    To be used in disclosures, privacy, and legal etc.
                  </span>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Company Legal Name"
                  name="legal_name"
                  value={settings.legal_name ?? ''}
                  onChange={handleChangeSettings}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography variant="h6">Country</Typography>
                <RadioGroup
                  name="country"
                  onChange={handleChangeSettings}
                  row
                  value={settings.country}
                >
                  <FormControlLabel
                    value="USA"
                    control={<Radio className={classes.radio} />}
                    label="USA"
                  />
                  <FormControlLabel
                    value="Canada"
                    control={<Radio className={classes.radio} />}
                    label="Canada"
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TimezoneSelect
                  id="timezone"
                  name="timezone"
                  label="Builder Timezone"
                  defaultTimezone={builderTimezone}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = event.target;
                    dispatch(builderTimezoneUpdateRequest(Number(value)));
                    setBuilderTimezone(Number(value));
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <Divider />
          <div className={classes.headerText}>
            <span>Marketing Settings</span>
          </div>
          <div className={classes.inputForm}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                <TextField
                  margin="normal"
                  fullWidth
                  label="Company Marketing Name"
                  name="marketing_name"
                  value={settings.marketing_name ?? ''}
                  onChange={handleChangeSettings}
                />
                <span className={classes.subtitle}>
                  Used in customer facing applications, emails, and texts etc.
                </span>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid
                  container
                  className={classes.card}
                  direction="row"
                  alignItems="center"
                >
                  <Typography
                    className={classes.cardTitle}
                    style={{ color: '#DB5C0E' }}
                  >
                    Webflow Name:
                  </Typography>
                  <Typography className={classes.cardTitle}>
                    {settings.webflow_name || selectedBuilderName || builderName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid
                  container
                  className={classes.card}
                  direction="row"
                  alignItems="center"
                >
                  {(selectedBuilderName || builderName) && (
                    <>
                       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Typography
                          className={classes.webflowTitle}
                          style={{ color: '#DB5C0E' }}
                        >
                          Global URL for webflow:
                        </Typography>
                        <Link
                          href={`${
                            appConfig.WEBFLOW_URL
                          }${settings.webflow_name || selectedBuilderName || builderName}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.webflowLink}
                          underline="none"
                          style={{ marginTop: '10px' }}
                        >
                          {encodeURI(
                            `${appConfig.WEBFLOW_URL}${settings.webflow_name
                              || selectedBuilderName || builderName.toLowerCase()}`,
                          )}
                        </Link>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={() => navigator.clipboard.writeText(encodeURI(
                              `${appConfig.WEBFLOW_URL}${settings.webflow_name
                                || selectedBuilderName || builderName.toLowerCase()}`,
                            ))}
                            style={{ marginTop: '20px' }}
                          >
                          Copy
                        </Button>
                      </div>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex' }}>
                  <div
                    className={classes.colorContainer}
                    style={{ background: `${settings.primary_color}` }}
                  />
                  <TextField
                    placeholder="example: FF2324"
                    margin="normal"
                    required
                    fullWidth
                    label="Primary Color"
                    name="primary_color"
                    error={Boolean(validationErrors.primary_color)}
                    helperText={
                      validationErrors.primary_color && 'Invalid HEX Format'
                    }
                    value={
                      settings.primary_color || defaultSettings.primary_color
                    }
                    onChange={handleChangeSettings}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ display: 'flex' }}>
                  <div
                    className={classes.colorContainer}
                    style={{ background: `${settings.secondary_color}` }}
                  />
                  <TextField
                    margin="normal"
                    placeholder="example: FF2324"
                    fullWidth
                    label="Secondary Color"
                    name="secondary_color"
                    error={Boolean(validationErrors.secondary_color)}
                    helperText={
                      validationErrors.secondary_color && 'Invalid HEX Format'
                    }
                    value={
                      settings.secondary_color
                      || defaultSettings.secondary_color
                    }
                    onChange={handleChangeSettings}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <ButtonGenerator
            builderName={selectedBuilderName || builderName}
            onButtonSettingsChange={handleButtonSettingsChange}
          />
          <div className={classes.headerText}>
            <span>Tour Settings</span>
          </div>
          <div style={{ padding: '20px 20%' }}>
            <h2 className={classes.title}>Verification settings</h2>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6">Selfie verification</Typography>
              <RadioGroup
                name="selfie_verification_active"
                onChange={handleChangeSettings}
                row
                value={settings.selfie_verification_active ? 'on' : 'off'}
              >
                <FormControlLabel
                  value="on"
                  control={<Radio className={classes.radio} />}
                  label="On"
                />
                <FormControlLabel
                  value="off"
                  control={<Radio className={classes.radio} />}
                  label="Off"
                />
              </RadioGroup>
            </Grid>
          </div>
          <Divider style={{ marginBottom: '20px' }} />
          <div className={classes.headerText}>
            <span>Tour Now Page</span>
          </div>
          <div style={{ padding: '20px 20%' }}>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6">Show in preview</Typography>
              <RadioGroup
                name="show_in_preview"
                onChange={handleChangeShowInPreview}
                row
                value={showInPreview}
              >
                <FormControlLabel
                  value="map"
                  control={<Radio className={classes.radio} />}
                  label="Map"
                />
                <FormControlLabel
                  value="list"
                  control={<Radio className={classes.radio} />}
                  label="List"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6">Hide header picture in preview</Typography>
              <Switch
                id="day"
                checked={hideHeaderPic}
                onChange={() => {setHideHeaderPic(!hideHeaderPic)}}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6">Use custom sizes for preview</Typography>
              <RadioGroup
                name="use_custom_sizes"
                onChange={()=>{setUseCustomSizes(!useCustomSizes)}}
                row
                value={useCustomSizes ? 'yes' : 'no'}
              >
                <FormControlLabel
                  value="no"
                  control={<Radio className={classes.radio} />}
                  label="No"
                />
                <FormControlLabel
                  value="yes"
                  control={<Radio className={classes.radio} />}
                  label="Yes"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                fullWidth
                margin="normal"
                id="desktopWidth"
                name="desktopWidth"
                label="Desktop Width"
                value={previewSizes.desktopWidth}
                onChange={handleChangeSizes}
                disabled={!useCustomSizes}
              />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                fullWidth
                margin="normal"
                id="desktopHeight"
                name="desktopHeight"
                label="Desktop Height"
                value={previewSizes.desktopHeight}
                onChange={handleChangeSizes}
                disabled={!useCustomSizes}
              />
            </Grid>
          </div>
          <div
            style={{ padding: '20px 20%', textAlign: 'left' }}
            className={classes.headerText}
          >
            <div className={classes.tourNowHeader}>
              <h2 className={classes.title}>Embed Tour-Now Page</h2>
              <Button variant="outlined" onClick={() => setIsPreview(true)}>
                Show Preview
              </Button>
            </div>
            <div>
              <Typography variant="h6" style={{ marginBottom: '5px' }}>
                For React.js
              </Typography>
              <TextareaAutosize
                className={classes.textArea}
                readOnly
                value={`<iframe style={{ ${useCustomSizes
                  ? `width: '${previewSizes.desktopWidth}px', height: '${previewSizes.desktopHeight}px', alignSelf: 'center', display: 'block', margin: '0 auto'` 
                  : "width: '100%', height: '100vh'"} }} title="tour-now" src="${encodeURI(
                    `https://tournow.nternow.com/${settings.builderName
                      ?? selectedBuilderName ?? builderName}${showInPreview === 'list' || hideHeaderPic ? '?' : ''}` +
                    `${showInPreview === 'list' ? 'showList=true&' : ''}${hideHeaderPic ? 'removeHeaderPic=true&' : ''}`,
                  )}" frameBorder="0" />`}
              />

              <Typography variant="h6" style={{ marginBottom: '5px' }}>
                For HTML File
              </Typography>
              <TextareaAutosize
                className={classes.textArea}
                readOnly
                value={`<iframe style="${useCustomSizes 
                  ? `width: ${previewSizes.desktopWidth}px; height: ${previewSizes.desktopHeight}px; align-self: center; display: block; margin: 0 auto`
                  : 'width: 100%; min-height: 100vh'}" title="tour-now" src="${encodeURI(
                    `https://tournow.nternow.com/${settings.builderName
                      ?? selectedBuilderName ?? builderName}${showInPreview === 'list' || hideHeaderPic ? '?' : ''}` + 
                    `${showInPreview === 'list' ? 'showList=true&' : ''}${hideHeaderPic ? 'removeHeaderPic=true&' : ''}`,
                  )}" frameborder="0" />`}
              />
            </div>
          </div>

          {isPreview && (
          <div className={classes.previewContainer}>
            <div className={classes.previewModal}>
              <button
                type="button"
                onClick={() => setIsPreview(false)}
                className={classes.closeBtn}
              >
              &#10005;
              </button>
              <iframe
                style={useCustomSizes 
                  ? {width: `${previewSizes.desktopWidth}px`, height: `${previewSizes.desktopHeight}px`, alignSelf: 'center', display: 'block', margin: '0 auto'} 
                  : { width: '100%', minHeight: '100%' } 
                }
                title="tour-now"
                src={`${encodeURI(
                  `https://tournow.nternow.com/${settings.builderName
                    ?? selectedBuilderName ?? builderName}${showInPreview === 'list' || hideHeaderPic ? '?' : ''}` + 
                  `${showInPreview === 'list' ? 'showList=true&' : ''}${hideHeaderPic ? 'removeHeaderPic=true&' : ''}`,
                )}`}
                frameBorder="0"
              />
            </div>
          </div>
          )}

          <Divider style={{ marginBottom: '20px' }} />
          <div className={classes.navButtons}>
            <CustomButton variant="orange" onClick={() => handleSaveSettings()}>
          Submit
            </CustomButton>
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(BuilderSettingsForm);
