import {
  BUILDERS_SETTINGS_REQUEST,
  BUILDERS_SETTINGS_REQUEST_FAILED,
  BUILDERS_SETTINGS_REQUEST_SUCCESS,
  BuildersActionsTypes, BuildersSettingsState,
  SAVE_BUILDER_DISCLOSURES,
  SAVE_BUILDER_DISCLOSURES_FAILED,
  SAVE_BUILDER_DISCLOSURES_SUCCESS,
} from '../types/builders';

const initialState: BuildersSettingsState = {
  item: null,
  error: '',
  loading: false,
};

export default function BuilderSettingsReducer(
  state = initialState,
  action: BuildersActionsTypes,
): BuildersSettingsState {
  switch (action.type) {
    case BUILDERS_SETTINGS_REQUEST:
    case SAVE_BUILDER_DISCLOSURES: {
      return {
        ...state,
        error: '',
        loading: true,
      };
    }

    case BUILDERS_SETTINGS_REQUEST_SUCCESS: {
      return {
        ...state,
        item: action.payload,
        error: '',
        loading: false,
      };
    }

    case SAVE_BUILDER_DISCLOSURES_SUCCESS: {
      return {
        ...state,
        error: '',
        loading: false,
      };
    }

    case SAVE_BUILDER_DISCLOSURES_FAILED:
    case BUILDERS_SETTINGS_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.message,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
}
