import React from 'react';
import Typography from '@material-ui/core/Typography';
import { WithStyles } from '@material-ui/styles';
import IdeaIcon from '../Icons/IdeaIcon';


import styles from './styles';

type Props = {
  title: string;
  description: string;
  media?: any;
} & WithStyles<typeof styles>;

const InsightCard: React.FC<Props> = props => {
  const {
    classes, title, description, media: Media,
  } = props;

  let mediaContent = null;
  if (Media) {
    mediaContent = Media && typeof Media === 'string' ? (
      <img src={Media} alt="" />
    ) : (
      <Media />
    );
  }

  return (
    <div className={classes.card}>
      <div className={classes.content}>
        <div>
          <div className={classes.titleWrapper}>
            <IdeaIcon className={classes.icon} />
            <Typography className={classes.title}>{title}</Typography>
          </div>
          <div className={classes.description}>{description}</div>
        </div>
        {mediaContent && (
          <div className={classes.imgWrapper}>
            <div className={classes.imgWrapper}>{mediaContent}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(InsightCard);
