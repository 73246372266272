/* eslint-disable @typescript-eslint/camelcase */
import React, { ChangeEvent, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { WithStyles } from '@material-ui/core/styles';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import {
  Checkbox, Collapse, FormControlLabel, Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import InputPriceWrapper from '../InputPriceWrapper';
import CommunitySearch from '../../containers/CommunitySearch';
import { propertyStatuses } from '../../constants/property';
import styles from './styles';
import { PropertyDetailsInfo, PropertyInput } from '../../redux/types/properties';
import { CatalogEntity } from '../../redux/types/catalog';
import { State } from '../../redux/types/states';
import { propertyUpdateSchema } from '../PropertyInput/PropertyValidation';
import TimezoneSelect from '../Timezone';
import { createErrorSnackBar, createSuccessSnackBar } from '../../redux/actions/snackbars';
import appConfig from '../../config';
import { getBuilderId, getBuilderName } from '../../redux/selectors/auth';
import { beautifyErrors, ValidationErrors } from '../../utils/helpers';
import { builderSettingsRequest } from '../../redux/actions/builders';
import { selectBuilderSettings } from '../../redux/selectors/builders';


const getInitialPropertyState = ({
  property_id,
  builder,
  disposition_id,
  house_num,
  address_1,
  subdivision,
  city,
  state,
  zipcode,
  latitude,
  longitude,
  price,
  bedrooms,
  halfBathrooms,
  fullBathrooms,
  squareFootage,
  isModel,
  lock_serial_number,
  smart_lock_serial_number,
  lock_status,
  contract_date,
  community_id,
  url_1,
  rental_application_url,
  timezone,
  hub_id,
  hub_type,
  alarm,
  alarm_company,
  armed_hours,
  disarm_code,
  emergency_notes,
  marketing_name,
  temp_backup_code,
  property_backup_code,
  trade_code,
  sales_code,
  resident_code,
  mls_number,
  external_identifier,
  access_notes,
}: PropertyDetailsInfo) => ({
  property_id,
  builder,
  disposition_id,
  house_num,
  address_1,
  subdivision,
  city,
  state,
  zipcode,
  latitude,
  longitude,
  price,
  bedrooms,
  halfBathrooms,
  fullBathrooms,
  squareFootage,
  isModel,
  lock_serial_number,
  smart_lock_serial_number,
  lock_status,
  contract_date,
  community_id,
  url_1,
  rental_application_url,
  timezone,
  hub_id,
  hub_type,
  alarm,
  alarm_company,
  armed_hours,
  disarm_code,
  emergency_notes,
  marketing_name,
  temp_backup_code,
  property_backup_code,
  trade_code,
  sales_code,
  resident_code,
  mls_number,
  external_identifier,
  access_notes
});

type Props = {
  info: PropertyDetailsInfo;
  loadCatalog: () => void;
  loadingCatalog: boolean;
  lockStatusCatalog: CatalogEntity[];
  states: State[];
  loadingStates: boolean;
  loadStates: () => void;
  saveProperty: (property: PropertyInput) => void;
  isRental: boolean;
} & WithStyles<typeof styles>;

const PropertyInfoCard: React.FC<Props> = (props) => {
  const {
    classes,
    info,
    loadCatalog,
    loadingCatalog,
    loadStates,
    loadingStates,
    saveProperty,
    states,
    isRental,
  } = props;
  const [propertyState, setPropertyState] = useState(getInitialPropertyState(info));
  const [validationErrors, setValidationErrors] = useState < ValidationErrors<PropertyInput>>({});
  const [webflowUrl, setWebflowUrl] = useState<string>('');
  const [showAccessNotes, setShowAccessNotes] = useState<boolean>(!!propertyState.access_notes)

  const dispatch = useDispatch();
  const builderName = useSelector(getBuilderName);
  const builderId = useSelector(getBuilderId);
  const builderSettings = useSelector(selectBuilderSettings);


  React.useEffect(() => {
    loadCatalog();
    loadStates();
  }, [loadCatalog, loadStates]);

  React.useEffect(() => {
    setPropertyState(getInitialPropertyState(info));
  }, [info]);

  React.useEffect(() => {
    setWebflowUrl(encodeURI(`${appConfig.WEBFLOW_URL}${builderSettings?.webflow_name || propertyState.builder || builderName}/property/${propertyState.property_id}`));
  }, [propertyState, builderName, builderSettings]);

  React.useEffect(() => {
    dispatch(builderSettingsRequest(builderId));
  }, [dispatch, builderId]);

  function onSubmit(e: React.FormEvent) {
    e.preventDefault();
    try {
      const uProperty = propertyUpdateSchema.validateSync(propertyState, { abortEarly: false }) as PropertyInput;
      if (uProperty) {
        setValidationErrors({});
        saveProperty(uProperty);
      }
    } catch (error) {
      setValidationErrors(beautifyErrors<PropertyInput>(error));
      console.log(error);
    }
  }

  const handleChangeAccessNotes = () => {
    setShowAccessNotes(!showAccessNotes)
    if(!showAccessNotes) {
      propertyState.access_notes = '';
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    setPropertyState((prevState) => ({ ...prevState, [name]: value }));

    if (name === 'isModel') {
      setPropertyState((prevState) => ({ ...prevState, [name]: checked }));
    }

    if (name === 'alarm') {
      setPropertyState((prevState) => ({
        ...prevState,
        [name]: checked,
        alarm_company: checked ? info.alarm_company : '',
        armed_hours: checked ? info.armed_hours : '',
        disarm_code: checked ? info.disarm_code : '',
        emergency_notes: checked ? info.emergency_notes : '',
      }));
    }
  };

  const handleDateChange = (date: Date | null) => {
    setPropertyState((prevState) => ({
      ...prevState, contract_date: date,
    }));
  };

  const handleCommunityChange = (value: number, rest?: any) => {
    setPropertyState((prevState) => ({
      ...prevState,
      community_id: value,
      subdivision: rest?.label,
    }));
  };

  const handleCopyWebflowUrl = async () => {
    try {
      await navigator.clipboard.writeText(webflowUrl);

      dispatch(createSuccessSnackBar('URL copied to clipboard!'));
    } catch (error) {
      console.log(error);
      dispatch(createErrorSnackBar('Failed to copy'));
    }
  };

  const soldPropertyStatusId = 'PROPERTY_SOLD';
  const isPropertySold = propertyState.disposition_id === soldPropertyStatusId;

  return (
    <form className={classes.content} onSubmit={onSubmit} noValidate>
      <Grid container>
        <TextField
          required
          fullWidth
          margin="normal"
          id="property_id"
          name="property_id"
          label="Property ID"
          value={propertyState.property_id}
          disabled
        />
        <TextField
          required
          fullWidth
          margin="normal"
          id="builder"
          name="builder"
          label="Builder"
          value={propertyState.builder}
          disabled
        />

        <Grid item xs={12}>
          <CommunitySearch
            handleChange={handleCommunityChange}
            initialValue={propertyState.community_id}
            builderId={info.builder_id}
          />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.progressWrapper}>
            <TextField
              select
              required
              fullWidth
              margin="normal"
              id="disposition_id"
              name="disposition_id"
              label="Property Disposition"
              value={propertyState.disposition_id}
              onChange={handleChange}
              disabled={loadingCatalog}
            >
              <MenuItem disabled value="">Select Property Disposition</MenuItem>
              {propertyStatuses.map(({ catalog_id, value }) => (
                <MenuItem
                  key={catalog_id}
                  value={catalog_id}
                >
                  {value}
                </MenuItem>
              ))}
            </TextField>
            {loadingCatalog && <CircularProgress size={24} className={classes.progress} />}
          </div>
        </Grid>

        {isPropertySold && (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={12}>

              <KeyboardDatePicker
                variant="inline"
                fullWidth
                label="Contract Date"
                value={propertyState.contract_date}
                placeholder="10/10/2012"
                onChange={handleDateChange}
                format="MM/dd/yyyy"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        )}

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="house_num"
            name="house_num"
            label="Lot #"
            value={propertyState.house_num}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TimezoneSelect
            fullWidth
            margin="normal"
            id="timezone"
            name="timezone"
            label="Timezone"
            required
            defaultTimezone={propertyState.timezone}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            margin="normal"
            id="address_1"
            name="address_1"
            label="Full Street Address"
            value={propertyState.address_1}
            onChange={handleChange}
            error={Boolean(validationErrors.address_1)}
            helperText={validationErrors.address_1}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="subdivision"
            name="subdivision"
            label="Subdivision"
            value={propertyState.subdivision ?? ''}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="marketing_name"
            name="marketing_name"
            label="Marketing Name"
            value={propertyState.marketing_name ?? ''}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="url_1"
            name="url_1"
            label="Url"
            value={propertyState.url_1 ?? ''}
            onChange={handleChange}
          />
        </Grid>

        {isRental && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              id="rental_applicationb_url"
              name="rental_application_url"
              label="Rental Application Url"
              value={propertyState.rental_application_url ?? ''}
              onChange={handleChange}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            margin="normal"
            id="city"
            name="city"
            label="City"
            value={propertyState.city}
            onChange={handleChange}
            error={Boolean(validationErrors.city)}
            helperText={validationErrors.city}
          />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.progressWrapper}>
            <TextField
              select
              required
              fullWidth
              margin="normal"
              id="state"
              name="state"
              label="State/Province"
              value={propertyState.state}
              onChange={handleChange}
              disabled={loadingStates}
              error={Boolean(validationErrors.state)}
              helperText={validationErrors.state}
            >
              <MenuItem value="" disabled>Select State</MenuItem>
              {states.map(({ state_id, state_name }) => (
                <MenuItem
                  key={state_id}
                  value={state_id}
                >
                  {state_name}
                </MenuItem>
              ))}
            </TextField>
            {loadingStates && <CircularProgress size={24} className={classes.progress} />}
          </div>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            margin="normal"
            id="zipcode"
            name="zipcode"
            label="Zip Code"
            value={propertyState.zipcode}
            onChange={handleChange}
            error={Boolean(validationErrors.zipcode)}
            helperText={validationErrors.zipcode}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            margin="normal"
            id="latitude"
            name="latitude"
            label="Latitude"
            value={propertyState.latitude}
            onChange={handleChange}
            error={Boolean(validationErrors.latitude)}
            helperText={validationErrors.latitude}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            margin="normal"
            id="longitude"
            name="longitude"
            label="Longitude"
            value={propertyState.longitude}
            onChange={handleChange}
            error={Boolean(validationErrors.longitude)}
            helperText={validationErrors.longitude}
          />
        </Grid>

        <Grid item xs={12}>
          <InputPriceWrapper>
            <TextField
              required
              fullWidth
              margin="normal"
              id="price"
              name="price"
              label={isRental ? 'Rent per Month' : 'Price'}
              value={propertyState.price}
              onChange={handleChange}
              error={Boolean(validationErrors.price)}
              helperText={validationErrors.price}
            />
          </InputPriceWrapper>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="bedrooms"
            name="bedrooms"
            label="Bedrooms"
            value={propertyState.bedrooms}
            onChange={handleChange}
            error={Boolean(validationErrors.bedrooms)}
            helperText={validationErrors.bedrooms}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="halfBathrooms"
            name="halfBathrooms"
            label="Half Bathrooms"
            value={propertyState.halfBathrooms}
            onChange={handleChange}
            error={Boolean(validationErrors.halfBathrooms)}
            helperText={validationErrors.halfBathrooms}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="fullBathrooms"
            name="fullBathrooms"
            label="Full Bathrooms"
            value={propertyState.fullBathrooms}
            onChange={handleChange}
            error={Boolean(validationErrors.fullBathrooms)}
            helperText={validationErrors.fullBathrooms}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="squareFootage"
            name="squareFootage"
            label="Square Footage"
            value={propertyState.squareFootage}
            onChange={handleChange}
            error={Boolean(validationErrors.squareFootage)}
            helperText={validationErrors.squareFootage}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="mlsNumber"
            name="mls_number"
            label="MLS Number"
            value={propertyState.mls_number}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="normal"
            id="externalIdentifier"
            name="external_identifier"
            label="External Identifier"
            value={propertyState.external_identifier}
            onChange={handleChange}
          />
        </Grid>
        {propertyState.lock_serial_number && (
        <Grid item xs={12}>
          <TextField
            disabled
            fullWidth
            margin="normal"
            id="lock_serial_number"
            name="lock_serial_number"
            label="Lock Serial Number"
            value={propertyState.lock_serial_number}
          />
        </Grid>
        )}
        {propertyState.smart_lock_serial_number && (
        <Grid item xs={12}>
          <TextField
            disabled
            fullWidth
            margin="normal"
            id="smart_lock_serial_number"
            name="smart_lock_serial_number"
            label="Smart Lock Serial Number"
            value={propertyState.smart_lock_serial_number}
          />
        </Grid>
        )}
        {info.lock_status_value && (
        <Grid item xs={12}>
          <div className={classes.progressWrapper}>
            <TextField
              disabled
              fullWidth
              margin="normal"
              id="lock_status_value"
              name="lock_status_value"
              label="Lock Status"
              value={info.lock_status_value ?? ''}
            />
          </div>
        </Grid>
        )}
        {propertyState.hub_id && (
        <Grid item xs={12}>
          <TextField
            disabled
            fullWidth
            margin="normal"
            id="hub_serial_number"
            name="hub_serial_number"
            label="Hub Serial Number"
            value={propertyState.hub_id}
          />
        </Grid>
        )}

        <Collapse
          in={Boolean(propertyState?.alarm)}
          className={classes.alarmContainer}
        >
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              id="alarm_company"
              name="alarm_company"
              label="Alarm Company"
              value={propertyState?.alarm_company ?? ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              id="armed_hours"
              name="armed_hours"
              label="Armed Hours"
              value={propertyState?.armed_hours ?? ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              id="disarm_code"
              name="disarm_code"
              label="Disarm Code"
              value={propertyState?.disarm_code ?? ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              margin="normal"
              rows={4}
              id="emergency_notes"
              name="emergency_notes"
              label="Emergency Notes"
              value={propertyState?.emergency_notes ?? ''}
              onChange={handleChange}
              inputProps={{
                style: {
                  padding: '0 12px',
                },
              }}
            />
          </Grid>
        </Collapse>

        <Grid item xs={12}>
          <FormControlLabel
            control={(
              <Checkbox
                color="secondary"
                name="alarm"
                checked={Boolean(propertyState.alarm)}
                onChange={handleChange}
              />
              )}
            label="Alarm"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={(
              <Checkbox
                color="secondary"
                name="isModel"
                checked={Boolean(propertyState.isModel)}
                onChange={handleChange}
              />
              )}
            label="Is Model"
          />
        </Grid>
        <Grid item xs={12}>
        <FormControlLabel
            control={(
              <Checkbox
                color="secondary"
                checked={showAccessNotes}
                onChange={handleChangeAccessNotes}
              />
              )}
            label="Access Notes"
          />
        </Grid>
        {showAccessNotes && (
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              id="access_notes"
              name="access_notes"
              label="ex: press check mark after code"
              value={propertyState.access_notes}
              onChange={handleChange}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <Typography className={classes.title}>
                    Webflow URL:
          </Typography>
          <Typography className={classes.text}>
            {webflowUrl}
          </Typography>
        </Grid>
        <Grid>
          <Button
            type="button"
            variant="contained"
            onClick={handleCopyWebflowUrl}
            style={{ marginTop: '16px', marginBottom: '8px' }}
          >
            <FileCopyIcon style={{ marginRight: '10px' }} />
Copy
          </Button>
        </Grid>


        <Grid item xs={12}>
          <div className={classes.formActions}>
            <Button type="submit" variant="contained">Save</Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default React.memo(PropertyInfoCard);
