import { createStyles } from '@material-ui/core';

export default () => createStyles({
  widgetsWrapper: {
    marginTop: 33,
    width: '100%',
  },
  expandRoot: {
    backgroundColor: '#fefefe',
  },
  inventoryRotateIcon: {
    transform: 'rotate(180deg)',
    transition: '0.2s',
  },
});
