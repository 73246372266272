import { SplitFactory } from '@splitsoftware/splitio';
import appConfig from '../config';

const settings: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: appConfig.SPLIT_AUTH_KEY || '',
    key: appConfig.SPLIT_KEY || '',
  },
};

const sdk: SplitIO.ISDK = SplitFactory(settings);

export const createClient = (): SplitIO.IClient => sdk.client();

export default sdk;
