import {
  CATALOG_REQUEST,
  CATALOG_REQUEST_SUCCESS,
  CATALOG_REQUEST_FAILED,
  CatalogRequestSuccess,
} from '../types/catalog';
import { ErrorMessage } from '../../types/main';

export const catalogRequest = () => ({
  type: CATALOG_REQUEST,
} as const);

export const catalogRequestFailed = (payload: ErrorMessage) => ({
  type: CATALOG_REQUEST_FAILED,
  payload,
} as const);

export const catalogRequestSuccess = (payload: CatalogRequestSuccess) => ({
  type: CATALOG_REQUEST_SUCCESS,
  payload,
} as const);
