import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import {
  TextField,
  Paper,
  Button,
  Divider,
  MenuItem,
} from '@material-ui/core';
import { ArrowLeft } from '@material-ui/icons';
import useStyles from './styles';
import CustomButton from '../CustomButton';
import { Webhook, WebhookFormType } from '../../redux/types/webhooks';
import { ActionPayload } from '../../types/main';
import {
  createWebhookRequest,
  webhookDetailsRequest,
  updateWebhookRequest,
} from '../../redux/actions/webhooks';

import webhookSchema from './WebhookValidation';
import { ValidationErrors, beautifyErrors } from '../../utils/helpers';
import { Query } from '../../utils/query';

type Props = {
  webhooks: Webhook[];
  selectedWebhook: Webhook | null;
  createWebhook: (webhook: ActionPayload<typeof createWebhookRequest>) => void;
  updateWebhook: (webhook: ActionPayload<typeof updateWebhookRequest>) => void;
  loadWebhooks: (query?: Query) => void;
  loadWebhookById: (
    webhook: ActionPayload<typeof webhookDetailsRequest>
  ) => void;
};

type Select = {
  label: string;
  value: string;
};

const contentTypes: Select[] = [
  { label: 'application/json', value: 'json' },
  { label: 'text/html', value: 'html' },
  { label: 'text/plain', value: 'plain' },
];

const eventTypes: Select[] = [
  { label: 'Tours Enable', value: 'tours.enable' },
  { label: 'Tours Disable', value: 'tours.disable' },
];

const WebhookForm: React.FC<Props> = (props) => {
  const {
    webhooks,
    selectedWebhook,
    createWebhook,
    updateWebhook,
    loadWebhooks,
    loadWebhookById,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const { webhookId } = useParams<{ webhookId?: string }>();

  const [webhookForm, setWebhookForm] = useState<WebhookFormType>({
    payload_url: '',
    content_type: '',
    secret: '',
    event_type: '',
  });
  const [validationErrors, setValidationErrors] = useState<
  ValidationErrors<Webhook>
  >({});

  useEffect(() => {
    loadWebhooks();
  }, [loadWebhooks]);

  useEffect(() => {
    if (!webhookId) {
      setWebhookForm({
        payload_url: '',
        content_type: contentTypes[0].label,
        secret: '',
        event_type: '',
      });
      return;
    }
    loadWebhookById(webhookId);
  }, [webhookId, loadWebhookById]);

  useEffect(() => {
    if (!selectedWebhook || !webhookId) {
      return;
    }

    setWebhookForm({
      payload_url: selectedWebhook.payload_url,
      content_type: selectedWebhook.content_type,
      secret: selectedWebhook.secret,
      event_type: selectedWebhook.event_type,
    });
  }, [selectedWebhook, webhookId]);

  const validateForm = () => {
    try {
      webhookSchema.validateSync(webhookForm, { abortEarly: false });
      setValidationErrors({});
      return true;
    } catch (error) {
      setValidationErrors(beautifyErrors<Webhook>(error));
      return false;
    }
  };

  const handleSubmit = () => {
    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    if (webhookId) {
      updateWebhook({ webhookId, webhook: webhookForm });
      return;
    }

    createWebhook(webhookForm);
  };

  const handleChangeWebhookForm = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setWebhookForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeContentType = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setWebhookForm((prevState) => ({
      ...prevState,
      [name]: contentTypes.find((c) => c.value === value)?.label,
    }));
  };

  const handleChangeEventType = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setWebhookForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <Grid container justify="space-between" style={{ marginBottom: 25 }}>
        <Grid item>
          <Button variant="contained" onClick={() => history.push('/webhooks')}>
            <ArrowLeft />
            {' GoBack'}
          </Button>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        <div className={classes.headerText}>
          <span>{webhookId ? 'Edit Webhook' : 'Add New Webhook'}</span>
        </div>
        <div className={classes.inputForm}>
          <Grid item xs={12} sm={12}>
            <TextField
              autoComplete="off"
              onChange={handleChangeWebhookForm}
              margin="normal"
              required
              value={webhookForm.payload_url}
              fullWidth
              label="Payload URL"
              name="payload_url"
              error={Boolean(validationErrors.payload_url)}
              helperText={validationErrors.payload_url}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              select
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                },
              }}
              onChange={handleChangeContentType}
              margin="normal"
              fullWidth
              label="Content Type"
              name="content_type"
              value={
                contentTypes.find((c) => c.label === webhookForm.content_type)
                  ?.value || ''
              }
              error={Boolean(validationErrors.content_type)}
              helperText={validationErrors.content_type}
            >
              <MenuItem value="" disabled>
                Select Content Type
              </MenuItem>
              {contentTypes.map((content, index) => (
                <MenuItem
                  key={content.label}
                  value={content.value}
                  selected={index === 0}
                >
                  {content.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              select
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                },
              }}
              onChange={handleChangeEventType}
              margin="normal"
              fullWidth
              label="Event Type"
              name="event_type"
              value={
                eventTypes.find((c) => c.value === webhookForm.event_type)
                  ?.value || ''
              }
              error={Boolean(validationErrors.event_type)}
              helperText={validationErrors.event_type}
            >
              <MenuItem value="" disabled>
                Select Event Type
              </MenuItem>
              {eventTypes.map((content, index) => {
                const isEventTypeExists = webhooks.some(
                  (webhook) => webhook.event_type === content.value,
                );

                return (
                  <MenuItem
                    key={content.label}
                    value={content.value}
                    selected={index === 0}
                    disabled={isEventTypeExists}
                  >
                    {content.value}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              autoComplete="off"
              onChange={handleChangeWebhookForm}
              margin="normal"
              value={webhookForm.secret}
              fullWidth
              label="Secret"
              name="secret"
              type="text"
            />
          </Grid>
        </div>
        <Divider />
        <CustomButton
          className={classes.submitButton}
          variant="orange"
          onClick={handleSubmit}
        >
          {webhookId ? 'Update' : 'Create'}
        </CustomButton>
      </Paper>
    </>
  );
};

export default WebhookForm;
