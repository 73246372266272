import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import clsx from 'clsx';
import useStyles from './styles';

type Props = {
  variant: 'white' | 'orange'
} & Omit<ButtonProps, 'variant'>;

const CustomButton: React.FC<Props> = (
  { children, variant, ...props },
) => {
  const { className, ...restProps } = props;
  const classes = useStyles();
  return (
    <Button
      className={clsx({
        [classes.white]: variant === 'white',
        [classes.orange]: variant === 'orange',
      }, className)}
      {...restProps}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
