import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import {
  loadVisitsAfterTourHours,
  loadVisitsAfterTourHoursExport,
} from '../../services/afterTourHours';
import { exportToFile } from '../../services/export';
import { openPrintDialog } from '../../services/print';
import {
  visitsAfterTourHoursExportFailed,
  visitsAfterTourHoursExportSuccess,
  visitsAfterTourHoursRequest,
  visitsAfterTourHoursRequestFailed,
  visitsAfterTourHoursRequestSuccess,
} from '../actions/afterTourHours';
import { createErrorSnackBar } from '../actions/snackbars';
import {
  VisitsAfterTourHoursExportRequestActionType,
  VISITS_AFTER_TOUR_HOURS_EXPORT_AND_PRINT_REQUEST,
  VISITS_AFTER_TOUR_HOURS_EXPORT_REQUEST,
  VISITS_AFTER_TOUR_HOURS_REQUEST,
} from '../types/afterTourHours';

function* loadVisitsAfterTourHoursSaga(
  action: ReturnType<typeof visitsAfterTourHoursRequest>,
) {
  try {
    const { data } = yield call(loadVisitsAfterTourHours, action.payload);

    yield put(visitsAfterTourHoursRequestSuccess(data));
  } catch (err) {
    yield put(
      createErrorSnackBar(
        err.response ? err.response.data.message : err.message,
      ),
    );
    yield put(visitsAfterTourHoursRequestFailed(err));
  }
}

function* loadVisitsAfterTourHoursExportSaga(
  action: VisitsAfterTourHoursExportRequestActionType,
) {
  try {
    const { selected, mimeType } = action.payload;
    const { data } = yield call(
      loadVisitsAfterTourHoursExport,
      selected,
      mimeType,
    );

    const fileType = { type: mimeType } as const;
    const blob = new Blob([data], fileType);

    if (
      blob
      && action.type === VISITS_AFTER_TOUR_HOURS_EXPORT_AND_PRINT_REQUEST
    ) {
      openPrintDialog(blob);
    } else {
      const fileName = 'after-tour-hours-export.csv';

      yield call(exportToFile, blob, fileType, fileName);
    }

    yield put(visitsAfterTourHoursExportSuccess());
  } catch (err) {
    yield put(visitsAfterTourHoursExportFailed(err));
  }
}

export default all([
  takeLatest<any>(
    VISITS_AFTER_TOUR_HOURS_REQUEST,
    loadVisitsAfterTourHoursSaga,
  ),
  takeLatest<any>(
    [
      VISITS_AFTER_TOUR_HOURS_EXPORT_AND_PRINT_REQUEST,
      VISITS_AFTER_TOUR_HOURS_EXPORT_REQUEST,
    ],
    loadVisitsAfterTourHoursExportSaga,
  ),
]);
