import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { WithStyles } from '@material-ui/styles';
import Feedback from '@material-ui/icons/Feedback';

import styles from './styles';

type Props ={sendUserFeedback: (message: string) => void} & WithStyles<typeof styles>;

const FeedbackDialog: React.FC<Props> = props => {
  const { classes, sendUserFeedback } = props;
  const [isOpen, toogleFeedback] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.currentTarget.value);
  };

  const showFeedbackDialog = () => {
    toogleFeedback(true);
  };

  const handleClose = () => {
    toogleFeedback(false);
    setMessage('');
  };

  const sendFeedback = () => {
    sendUserFeedback(message);
    handleClose();
  };

  return (
    <>
      <Button variant="contained" className={classes.feedbackButton} onClick={showFeedbackDialog}>
        <Feedback className={classes.feedbackIcon} />
        <span> Feedback about this page?</span>
      </Button>
      <Dialog open={isOpen} maxWidth="md" fullWidth>
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>
          We value your opinion! Please describe the requested feature or issue you are experiencing below.
          </DialogContentText>
          <TextField
            value={message}
            onChange={handleChange}
            autoFocus
            margin="dense"
            label="Feedback"
            rows="4"
            multiline
            id="message"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={!message}
            onClick={sendFeedback}
          >
          Send Feedback
          </Button>
          <Button variant="contained" onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FeedbackDialog;
