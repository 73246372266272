import { Query } from '../../utils/query';
import { ErrorMessage } from '../../types/main';
import {
  allVisitsRequest,
  allVisitsRequestFailed,
  allVisitsRequestSuccess, contractorsCountRequest, contractorsCountRequestFailed, contractorsCountRequestSuccess,
  loadSoldPropertiesStats, monthlyVisitsRequest, monthlyVisitsRequestFailed, monthlyVisitsRequestSuccess,
  propertiesActiveStatsRequestSuccess,
  propertiesSoldStatsRequestSuccess,
  propertiesStatsRequest,
  propertiesStatsRequestFailed,
  soldPropertiesRequestFailed,
  soldPropertiesRequestSuccess,
  weeklyVisitsRequest,
  weeklyVisitsRequestFailed, weeklyVisitsRequestSuccess,
  yearlyVisitsRequest,
  yearlyVisitsRequestFailed,
  yearlyVisitsRequestSuccess,
} from '../actions/stats';

export const WEEKLY_VISITS_REQUEST = 'WEEKLY_VISITS_REQUEST';
export const WEEKLY_VISITS_REQUEST_SUCCESS = 'WEEKLY_VISITS_REQUEST_SUCCESS';
export const WEEKLY_VISITS_REQUEST_FAILED = 'WEEKLY_VISITS_REQUEST_FAILED';

export const MONTHLY_VISITS_REQUEST = 'MONTHLY_VISITS_REQUEST';
export const MONTHLY_VISITS_REQUEST_SUCCESS = 'MONTHLY_VISITS_REQUEST_SUCCESS';
export const MONTHLY_VISITS_REQUEST_FAILED = 'MONTHLY_VISITS_REQUEST_FAILED';

export const YEARLY_VISITS_REQUEST = 'YEARLY_VISITS_REQUEST';
export const YEARLY_VISITS_REQUEST_SUCCESS = 'YEARLY_VISITS_REQUEST_SUCCESS';
export const YEARLY_VISITS_REQUEST_FAILED = 'YEARLY_VISITS_REQUEST_FAILED';

export const ALL_VISITS_REQUEST = 'ALL_VISITS_REQUEST';
export const ALL_VISITS_REQUEST_SUCCESS = 'ALL_VISITS_REQUEST_SUCCESS';
export const ALL_VISITS_REQUEST_FAILED = 'ALL_VISITS_REQUEST_FAILED';

export const ALL_PROPERTIES_STATS_REQUEST = 'ALL_PROPERTIES_STATS_REQUEST';
export const ALL_PROPERTIES_STATS_REQUEST_FAILED = 'ALL_PROPERTIES_STATS_REQUEST_FAILED';

export const CONTRACTORS_SERVED_COUNT_REQUEST = 'CONTRACTORS_SERVED_COUNT_REQUEST';
export const CONTRACTORS_SERVED_COUNT_REQUEST_SUCCESS = 'CONTRACTORS_SERVED_COUNT_REQUEST_SUCCESS';
export const CONTRACTORS_SERVED_COUNT_REQUEST_FAILED = 'CONTRACTORS_SERVED_COUNT_REQUEST_FAILED';

export const SOLD_PROPERTIES_STATS = 'SOLD_PROPERTIES_STATS';
export const SOLD_PROPERTIES_REQUEST_SUCCESS = 'SOLD_PROPERTIES_REQUEST_SUCCESS';
export const SOLD_PROPERTIES_REQUEST_FAILED = 'SOLD_PROPERTIES_REQUEST_FAILED';

export const ALL_PROPERTIES_ACTIVE_STATS_REQUEST_SUCCESS = 'ALL_PROPERTIES_ACTIVE_STATS_REQUEST_SUCCESS';
export const ALL_PROPERTIES_SOLD_STATS_REQUEST_SUCCESS = 'ALL_PROPERTIES_SOLD_STATS_REQUEST_SUCCESS';

export interface PropertyStats {
  LASSO_custom_apikey?: string
  LASSO_project_id?: number
  LASSO_rpi: string
  address_1?: string
  address_2?: string
  agent_2_old?: string
  agent_3_old?: string
  agent_4_old?: string
  agent_old?: string
  builder?: string
  builder_id?: number
  city?: string
  closing_date?: string
  contracted_by?: number
  created_by?: string
  created_on?: string
  disposition_id?: number
  grouping?: string
  house_num?: string
  latitude: number
  lock_assigned?: string
  lock_serial_number: string
  lock_terminated?: string
  longitude: number
  lot_num?: string
  modified_by?: string
  modified_on?: string
  non_cak?: number
  phonedummy?: string
  price: number
  bedrooms: number
  halfBathrooms: number
  fullBathrooms: number
  squareFootage: number
  isModel: boolean
  property_id: number
  remarks?: string
  state?: string
  subdivision?: string
  texting_active: number
  texting_text: string
  timezone?: number
  under_contract?: number
  under_contract_by?: string
  under_contract_date?: string
  url_1?: string
  rental_application_url?: string
  url_image?: string
  zipcode?: string
}

export type PropertiesStatsQuery = Query & { status: 'Accessible' | 'sold' };

export interface PropertiesStatsState {
  propertiesActive: PropertyStats[]
  propertiesSold: PropertyStats[]
  loading: boolean
  error: ErrorMessage['message']
}

export interface AllVisitStats {
  customer_id: number
  customer_visit_id: number
  visit_status_id: number
  property_id: number
  modified_on: string
}

export interface WeeklyVisitStats {
  visit_count: number
  date: string
  day_of_week: number
  name_of_day: string
}

export interface MonthlyVisitStats {
  visit_count: number
  date: string
  day_of_week: number
  name_of_day: string
}

export interface YearlyVisitStats {
  visit_count: number
  month_of_year: number
  name_of_month: string
}

export interface WeeklyVisitsState {
  items: WeeklyVisitStats[]
  loading: boolean
  error: ErrorMessage['message']
}

export interface MonthlyVisitsState {
  items: MonthlyVisitStats[]
  loading: boolean
  error: ErrorMessage['message']
}

export interface YearlyVisitsState {
  items: YearlyVisitStats[]
  loading: boolean
  error: ErrorMessage['message']
}

export interface AllVisitsState {
  items: AllVisitStats[]
  loading: boolean
  error: ErrorMessage['message']
}

export interface StatsState {
  properties: PropertiesStatsState
  weeklyVisits: WeeklyVisitsState
  monthlyVisits: MonthlyVisitsState
  yearlyVisits: YearlyVisitsState
  allVisits: AllVisitsState
}

export interface ContractorsState {
  served: number
  error: string
  loading: boolean
}


export type AllVisitsRequestActionType = ReturnType<typeof allVisitsRequest>;
export type YearlyVisitsRequestActionType = ReturnType<typeof yearlyVisitsRequest>;
export type SoldPropertiesStatsRequest = ReturnType<typeof loadSoldPropertiesStats>;
export type PropertiesStatsRequestActionType = ReturnType<typeof propertiesStatsRequest>;

export type YearlyVisitsStatsActionsTypes =
  | YearlyVisitsRequestActionType
  | ReturnType<typeof yearlyVisitsRequestFailed>
  | ReturnType<typeof yearlyVisitsRequestSuccess>;

export type AllVisitsStatsActionsTypes =
  | AllVisitsRequestActionType
  | ReturnType<typeof allVisitsRequestFailed>
  | ReturnType<typeof allVisitsRequestSuccess>;

export type PropertiesStatsActionsTypes =
  | PropertiesStatsRequestActionType
  | ReturnType<typeof propertiesStatsRequestFailed>
  | ReturnType<typeof propertiesActiveStatsRequestSuccess>
  | ReturnType<typeof propertiesSoldStatsRequestSuccess>
  | ReturnType<typeof soldPropertiesRequestSuccess>
  | ReturnType<typeof soldPropertiesRequestFailed>;
export type WeeklyVisitsRequestActionType = ReturnType<
  typeof weeklyVisitsRequest
>;

export type WeeklyVisitsStatsActionsTypes =
  | WeeklyVisitsRequestActionType
  | ReturnType<typeof weeklyVisitsRequestFailed>
  | ReturnType<typeof weeklyVisitsRequestSuccess>;

export type MonthlyVisitsRequestActionType = ReturnType<typeof monthlyVisitsRequest>;

export type MonthlyVisitsStatsActionsTypes =
  | MonthlyVisitsRequestActionType
  | ReturnType<typeof monthlyVisitsRequestFailed>
  | ReturnType<typeof monthlyVisitsRequestSuccess>;

export type ContractorsActionsTypes =
  | ReturnType<typeof contractorsCountRequest>
  | ReturnType<typeof contractorsCountRequestFailed>
  | ReturnType<typeof contractorsCountRequestSuccess>;
