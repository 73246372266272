import React, { useCallback } from 'react';
import { Grid, Drawer } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';
import PropertyHubDevice, { PropertyHubDeviceProps } from '../PropertyHubDevice/PropertyHubDevice';
import PropertyHubDeviceListTitle from './PropertyHubDeviceListTitle';
import styles from './styles';
import { HubFormName } from '../../redux/types/hubs';

type Props = {
  icon: React.ReactElement
  title: string
  records: Omit<PropertyHubDeviceProps, 'onClick'>[]
  recordSelectHandler: (index: number) => void
  hubForm: React.ReactElement | null
  hubFormName: HubFormName
  currentHubFormName: HubFormName | null
  setCurrentHubFormName: (name: HubFormName | null) => void
} & WithStyles<typeof styles>;

const PropertyHubDeviceList: React.FC<Props> = (props) => {
  const {
    title, icon, classes, hubForm,
    setCurrentHubFormName, currentHubFormName, hubFormName,
    records, recordSelectHandler,
  } = props;
  const isOpenSidebar = hubFormName === currentHubFormName;

  const recordNativeSelectHandler = useCallback((index: number) => {
    recordSelectHandler(index);
    setCurrentHubFormName(hubFormName);
  }, [recordSelectHandler, hubFormName, setCurrentHubFormName]);

  return (
    <Grid className={classes.container}>
      <PropertyHubDeviceListTitle
        title={title}
        icon={icon}
      />
      <Grid>
        {records.map((record, index) => (
          <PropertyHubDevice
            {...record}
            key={record.name}
            onClick={() => recordNativeSelectHandler(index)}
          />
        ))}
      </Grid>
      <Drawer
        anchor="right"
        open={isOpenSidebar}
        onClose={() => setCurrentHubFormName(null)}
      >
        {hubForm}
      </Drawer>
    </Grid>
  );
};

export default React.memo(PropertyHubDeviceList);
